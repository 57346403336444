import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpResponseBody } from '../../../../../objects/response/HttpResponseBody';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../../../../services/app-config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../../../constants/app.constants';
@Injectable({
  providedIn: 'root'
})
export class HsgPateintCondtionService {
  private API_HSG_URL;
  private API_CMS_MANAGEMENT_URL;
  hsgCareReportForm: FormGroup;
  private HSGSFLSub = new Subject<any[]>();
  private hsgVaccineSub = new Subject<any[]>();
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private fb: FormBuilder,
    private formManager: AkitaNgFormsManager,
  ) {
    this.API_HSG_URL = appConfig.getConfig().API_HSG_URL;
    this.API_CMS_MANAGEMENT_URL = appConfig.getConfig().API_CMS_MANAGEMENT_URL;
  }

  private initFormGroup() {
    this.hsgCareReportForm = this.fb.group({
      patientCareReportEntity: this.createPatientCareReportEntityForm(),
      patientName: '',
      patientId: '',
      id: '',
      userId: {
        idType: '',
        number: '',
      },
    });
    this.formManager.upsert('hsgCareReportForm', this.hsgCareReportForm);
  }

  createPatientCareReportEntityForm(): FormGroup {
    return this.fb.group({
      careReportStatus: '',
      conditions: this.fb.group({
        code: '',
        diagnosisIds: [],
        status: '',
        yearOfOnSet: ''
      }),
      patientExtraDetails: this.fb.group({
        chasUsage: '',
        referenceSource: '',
        remark: '',
        yearOfOnSet: '',
        rightSiting: false,
        smokingStatus: '',
        yearStartOfSmoke: ''
      }),
      vaccinations: this.fb.group({
        vaccineId: '',
        vaccinationDate: moment().format(DISPLAY_DATE_FORMAT),
        nextDoseDueDate: moment().format(DISPLAY_DATE_FORMAT),
      }),
      sflScreening: this.fb.group({
        screeningDate: '',
        screeningType: '',
        followUpOutcome: '',
        screeningOutcome: '',
      }),
      vitalsAndAssessments: this.fb.group({
        visitDate: '',
        visitId: '',
        visitNumber: '',
        vitals: this.fb.group({
          patientId: '',
          code: '',
          takenTime: '',
          value: ''
        }),
        dispatchItemEntities : [],
        assessment: this.fb.group({
          assessmentId : '',
          assessments: this.fb.group({
            ASSESS_SMOKE: '',
            ASSESS_COPD: '',
            ASSESS_EYE: '',
            ASSESS_FOOT: '',
            ATC_S: '',
            EFGR: '',
            GINATestDate: '',
            GINA_S: '',
            HBAIC: '',
            LDL_C: '',
            SERUM_CREATININE: '',
            UACR: '',
            eyeTestDate: '',
            footTestDate: '',
            UPCR: '',
            FPG: '',
            OGTT : ''
          }),
          automated: false,
          comment: '',
          indicatorType: '',
          patientId: '',
          takenTime: '',
          visitId: ''
        })
      })
    })
  }

  initCdlensForm() {
    this.initFormGroup();
  }

  openCareReport(periodId, patientGroupId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/${periodId}/${patientGroupId}`,
      {}
    );
  }

  submitCareReport(periodId: string, payload) {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/submit/bulk/${periodId}`, 
      payload
    );
  }

  saveCareReport(periodId: string, payload) {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/save/${periodId}`,
      payload
    );
  }

  calculateBmi(weightInKg, heigthInCm) {
    if (weightInKg > 0 && heigthInCm > 0) {
      const heigthInMeter = heigthInCm / 100;
      const bmi = (weightInKg / (heigthInMeter * heigthInMeter)).toFixed(1);
      return bmi;
    }
  }

  public setSflDetails(data): void {
    this.HSGSFLSub.next(data);
  }

  public getSflDetails(): Observable<any> {
    return this.HSGSFLSub.asObservable();
  }

  public setVaccineDetails(data): void {
    this.hsgVaccineSub.next(data);
  }

  public getVaccineDetails(): Observable<any> {
    return this.hsgVaccineSub.asObservable();
  }

   /** SDD CODES */
   searchSddCodes(
    term: string,
  ): Observable<HttpResponseBody> {

    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/item/sdd/search/${term}`,
      {}
    );
  }
}
