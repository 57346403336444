<div class="modal-header">
    <h4 class="modal-title pull-left"> {{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <alert></alert>
    <form [formGroup]="addFormGroup">
        <div class="row mb-2">
            <div class="col-md-6">
                <div class="wrapper-form-control">
                    <label class="mb-0 required">Screening Date</label>
                    <app-date-picker-with-icon customStyles="true" [config]="screeningDateDatePickerConfig" [dateControl]="addFormGroup.get('screenDate')">
                    </app-date-picker-with-icon>
                </div>
                <errors [control]="addFormGroup.get('screenDate')"></errors>
            </div>
            <div class="col-md-6">
                <div class="wrapper-form-control">
                    <label class="mb-0 required">Screening Type</label>
                    <ng-select name="screeningType" class='custom-select-new custom-select-extra-medium-width'
                        [items]="screeningTypes" bindLabel='cmsName' bindValue='cmsCode' (change)="sflScreeningTypeChange(addFormGroup.get('screeningType'))"
                        formControlName="screeningType">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6">
                <div class="wrapper-form-control">
                    <label class="mb-0 required">Test Type</label>
                    <ng-select name="screeningSubTypes" class='custom-select-new custom-select-extra-medium-width' (change)="sflTestTypeChange()"
                        [items]="screeningSubTypesFiltered" bindLabel='cmsName' bindValue='cmsCode' formControlName="testType">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="wrapper-form-control">
                    <label class="mb-0 required">Test Order</label>
                    <ng-select name="testOrder" class='custom-select-new custom-select-extra-medium-width'
                        [items]="testOrders" bindLabel='label' bindValue='value'  (change)="sflTestOrderChange(addFormGroup.get('testOrder'))" 
                        formControlName="testOrder">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row mb-2" *ngIf="(addFormGroup.get('screeningType').value === 'BREAST_CANCER_SCREENING' && addFormGroup.get('testType').value === 'MAMMO') 
        || (addFormGroup.get('screeningType').value === 'COLORECTAL_CANCER_SCREENING' && addFormGroup.get('testType').value === 'COLO')">
            <div class="col-md-12">
                <div class="wrapper-form-control">
                    <label class="mb-0" [class.required]="screeningExceptionalConditions.length">Screening Exceptional Condition</label>
                    <ng-select name="conditionCodes" class='custom-select-new custom-select-extra-medium-width'
                        [items]="screeningExceptionalConditions" bindLabel='cmsName' bindValue='cmsCode' [multiple]="true"
                        formControlName="conditionCodes">
                    </ng-select>
                </div>
                <errors [control]="addFormGroup.get('conditionCodes')"></errors>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="wrapper-form-control">
                    <label class="mb-0" [class.required]="addFormGroup.get('testOrder').value == '4'">Followup Outcome</label>
                    <ng-select name="followUpOutcome" class='custom-select-new custom-select-extra-medium-width'
                        [items]="followUpOutcomes" bindLabel='cmsName' bindValue='cmsCode' [readonly]="addFormGroup.get('testOrder').value != '4'"
                        formControlName="followUpOutcome">
                    </ng-select>
                </div>
                <errors [control]="addFormGroup.get('followUpOutcome')"></errors>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-brand-secondary" (click)="onBtnSave()" [disabled]="!addFormGroup.valid || disableSaveBtn">Save</button>
</div>
