import {
  PreRegistration,
  PreRegistrationEntity,
} from './../objects/response/PatientRegistryListResponse';
import { ETHNICITIES_WITH_DEFAULT } from './../constants/ethnicities';
import { PatientPayable } from './../objects/response/PatientPayable';
import { AlertService } from './alert.service';
import {
  DISPLAY_DATE_FORMAT,
  EMERGENCY_CONTACT_RELATIONSHIPS,
} from './../constants/app.constants';
import { ApiCmsManagementService } from './api-cms-management.service';
import { MedicalAlerts } from './../objects/request/MedicalAlerts';
import { Subject, Observable, of, timer, BehaviorSubject } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as moment from 'moment';
import { ApiPatientInfoService } from './api-patient-info.service';
import {
  MedicalCoverageResponse,
  createMedicalCoverageResponse,
} from './../objects/response/MedicalCoverageResponse';
import { COUNTRIES } from '../constants/countries';
import { COMMUNICATIONS } from '../constants/communications';
import { ETHNICITIES } from '../constants/ethnicities';
import { GENDERS } from '../constants/genders';
import { LANGUAGES } from '../constants/languages';
import { MARITAL_STATUS } from '../constants/marital.status';
import { NATIONALITIES } from '../constants/nationalities';
import { OCCUPATIONS } from '../constants/occupations';
import { TITLES } from '../constants/titles';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
} from '@angular/forms';
import PatientInfo from '../objects/state/PatientInfo';
import { Clinic } from '../objects/state/Clinic';
import { AkitaAppQuery } from './states/akita-app.query';
import { MemoObject } from '../objects/state/Case';
import UserId from '../objects/UserId';
import { RelationshipEntity } from '../objects/request/RelationshipEntity';
import { StoreService } from './store.service';
import { UtilsService } from './utils.service';
import { StringValidators } from '../validators/string.validators';
import { DateOfBirthValidator } from '../validators/DateOfBirthValidator';

@Injectable()
export class PatientService {
  patientDetailFormGroup: FormGroup;
  patientAddFormGroup: FormGroup;
  checkUserExist = false;

  private isUserIDValidated = new Subject<any>();
  private documentRefresh = new Subject<boolean>();

  /** FOR PRE_REGISTRATION */
  private _preRegistrationData: PreRegistrationEntity;
  private messageSource = new BehaviorSubject('');
  currentKYC = this.messageSource.asObservable();

  gstOnly: number = UtilsService.getGSTOnly();

  changeKYC(message: string) {
    this.messageSource.next(message);
  }
  /** FOR PRE_REGISTRATION */

  private currentlyOpenedNehrTab: Window;

  constructor(
    private fb: FormBuilder,
    private apiCmsManagementService: ApiCmsManagementService,
    private alertService: AlertService,
    private akitaAppQuery: AkitaAppQuery,
    private storeService: StoreService
  ) {
    this.patientDetailFormGroup = this.createFormGroup('PatientDetail');
    this.patientAddFormGroup = this.createFormGroup('PatientAdd');
  }

  createFormGroup(key: string): FormGroup {
    switch (key) {
      case 'PatientDetail':
        return this.createPatientDetailFormGroup();
      case 'PatientAdd':
        return this.createPatientAddFormGroup();
      default:
        return this.createPatientDetailFormGroup();
    }
  }

  getDocumentRefresh() {
    return this.documentRefresh.asObservable();
  }

  callDocumentRefresh() {
    this.documentRefresh.next(true);
  }

  addMedicalAlertsForm(): FormArray {
    const medicalAlerts = this.fb.group({
      alerts: new MedicalAlerts(),
    });

    return new FormArray([medicalAlerts]);
  }

  addMedicalCoverageForm(): FormArray {
    const medicalCoverage = this.fb.group({
      coverage: createMedicalCoverageResponse(),
    });

    return new FormArray([medicalCoverage]);
  }

  createPatientAddFormGroup(): FormGroup {
    const formGroup = this.fb.group({
      needRefresh: true,
      headerFormGroup: this.fb.group({
        name: 'Add new patient',
      }),
      basicInfoFormGroup: this.fb.group({
        title: [''],
        titles: {
          value: TITLES.map(title => {
            return { value: title, label: title };
          }),
        },
        name: ['', this.isNEHROpt() ? [Validators.required, StringValidators.containsASCIICharacters] : [Validators.required]],
        // birth: "",
        dob: ['', [Validators.required, DateOfBirthValidator]],
        gender: ['', Validators.required],
        genderOptions: {
          value: GENDERS.map(gender => {
            return { value: gender.toUpperCase(), label: gender };
          }),
        },
        fullId: this.fb.group({
          id: ['', [Validators.required, Validators.minLength(4)]],
          idType: ['', Validators.required],
          selectedCountry: '',
        }),
        kycData: this.fb.group({
          kycStatus: '',
          verifiedDate: '',
        }),
        idTypes: {
          value: [
            { value: 'NRIC_PINK', label: 'NRIC (Pink)' },
            { value: 'NRIC_BLUE', label: 'NRIC (Blue)' },
            { value: 'MIC', label: 'Malaysian IC' },
            { value: 'FIN', label: 'FIN' },
            { value: 'PASSPORT', label: 'Passport' },
            { value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate' },
            { value: 'OTHER', label: 'Other' },
          ],
        },
        address1: ['', this.isNEHROpt() ? [Validators.required, StringValidators.containsASCIICharacters] : [Validators.required]],
        address2: ['', this.isNEHROpt() ? StringValidators.containsASCIICharacters : null],
        email: ['', this.validateEmail],
        postcode: ['', Validators.minLength(6)],
        consentGiven: false,
        marketingOptOut: false,
        nehrOptedInStatus: '',
        currentNehrOptedInStatus:{},
        contactNumber: ['', [Validators.required, this.validateNumber]],
        countryCode: '',
        countries: {
          value: this.storeService.countries.map(country => {
            return { value: country.name, label: country.name };
          }),
        },
        country: 'SINGAPORE',
        maritalStatusDropdown: {
          value: MARITAL_STATUS.map(status => {
            return { value: status.toUpperCase(), label: status };
          }),
        },
        maritalStatus: 'OTHER',
        preferredMethodOfCommunication: ['', Validators.required],
        communicationMode: {
          value: COMMUNICATIONS.map(communication => {
            return {
              value: communication.toLowerCase(),
              label: communication,
            };
          }),
        },
        race: '', // "Chinese",
        raceDropdown: {
          value: ETHNICITIES_WITH_DEFAULT.map(ethnicity => {
            return {
              value: ethnicity.name,
              label: ethnicity.name,
              category: ethnicity.category,
            };
          }),
        },
        nationality: ['', Validators.required],
        patientIdentifier: '',
        additionalIdentifier: '',
        nationalitiesDropdown: {
          value: this.storeService.nationalities.map(nationality => {
            return { value: nationality.name, label: nationality.name };
          }),
        },
        patientExtraDetails: this.fb.group({
          chasUsage: '',
          smokingStatus: null,
          rightSiting: false,
          referenceSource: '',
          remark: '',
          rightSitingReferenceSource: '',
          stateOfChange: null,
          yearStartOfSmoke: ''
        })
      }),
      alertFormGroup: this.fb.group({
        alertArray: this.fb.array([]),
        state: '',
        isAdd: false,
        specialNotes: '',
        requiredSave: false,
      }),
      medicalAlertFormGroup: this.fb.group({
        alertArray: this.fb.array([]),
        state: '',
        isAdd: false,
        requiredSave: false,
      }),
      medicalCoverageFormGroup: this.fb.group({
        selectedPlans: '',
        testCoverage: this.addMedicalCoverageForm(),
      }),
      selectedPlans: this.fb.array([]),
      attachedPlans: this.fb.array([]),
      // emergencyContactFormGroup: this.fb.group({
      //   name: '',
      //   contact: ['', this.validateNumber],
      //   relationship: '',
      //   relationshipDropdown: {
      //     value: EMERGENCY_CONTACT_RELATIONSHIPS,
      //   },
      // }),
      relationshipEntityFormGroup: this.createRelationshipFormGroup(),
      otherInfoFormGroup: this.fb.group({
        preferredLanguage: '', // "English",
        languagesDropdown: {
          value: LANGUAGES.map(language => {
            return { value: language, label: language };
          }),
        },
      }),
      companyInfoFormGroup: this.fb.group({
        company: '',
        occupation: '',
        address1: '',
        address2: '',
        postalCode: ['', Validators.minLength(6)],
        occupations: {
          value: OCCUPATIONS.map(occupation => {
            return { value: occupation, label: occupation };
          }),
        },
      }),
      primaryCareNetwork: this.fb.group({
        optIn: false,
        optOut: false,
        optInDate: '',
      }),
    });

    formGroup
      .get('basicInfoFormGroup')
      .get('postcode')
      .setAsyncValidators(
        this.findAddress(
          this.apiCmsManagementService,
          formGroup.get('basicInfoFormGroup').get('postcode'),
          formGroup.get('basicInfoFormGroup').get('address1'),
          formGroup.get('companyInfoFormGroup').get('address2'),
          <FormGroup>formGroup.get('basicInfoFormGroup')
        )
      );

    formGroup
      .get('companyInfoFormGroup')
      .get('postalCode')
      .setAsyncValidators(
        this.findAddress(
          this.apiCmsManagementService,
          formGroup.get('companyInfoFormGroup').get('postalCode'),
          formGroup.get('companyInfoFormGroup').get('address1'),
          formGroup.get('companyInfoFormGroup').get('address2'),
          <FormGroup>formGroup.get('companyInfoFormGroup')
        )
      );

    return formGroup;
  }

  createPatientDetailHistoryFormGroup(): FormGroup {
    return this.fb.group({
      historyFilterFormGroup: this.fb.group({
        dateFrom: new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        ),
        dateTo: new Date(),
        doctors: { value: [] },
        doctor: '',
        paymentStatus: { value: [] },
        status: '',
      }),
      historyListFormGroup: this.fb.group({
        formArray: this.fb.array([]),
      }),
      historyDetailFormGroup: this.fb.group({
        patientInfo: {},
        consultationInfo: {},
        paymentInfo: {},
        gstValue: this.gstOnly,
        overallCharges: { value: [] },
        doctorId: '',
        billNo: '',
        date: '',
        consultationStartTime: '',
        consultationEndTime: '',
        purpose: '',
        notes: '',
        diagnosisArray: this.fb.array([]),
        notesArray: this.fb.array([]),
        serviceArray: this.fb.array([]),
        drugArray: this.fb.array([]),
        filter: '',
        documentsArray: this.fb.array([]),
        newDocumentsArray: this.fb.array([]),
        testArray: this.fb.array([]),
        vaccineArray: this.fb.array([]),
        certificateArray: this.fb.array([]),
        // referralId: '',
        referralArray: this.fb.array([]),
        memoArray: this.fb.array(new Array<MemoObject>()),
        startTime: '',
        endTime: '',
        timeChitFrom: '',
        timeChitTo: '',
        printFormGroup: this.fb.group({
          receiptTypes: {
            value: [
              { value: 'general', label: 'General' },
              // { value: 'detail', label: 'Detail' },
              { value: 'breakdown', label: 'Breakdown' },
            ],
          },
          receiptType: 'general',
          printAll: false,
          disablePageBreak: false,
        }),
        followupConsultationFormGroup: this.fb.group({
          id: '',
          patientId: '',
          patientVisitId: '',
          followupDate: '',
          remarks: '',
        }),
        clinicNotes: '',
      }),
    });
  }

  createPatientDetailFormGroup(): FormGroup {
    const formGroup = this.fb.group({
      needRefresh: true,
      isHistoryList: true,
      historyDetailIndex: -1,
      headerFormGroup: this.fb.group({
        name: '',
      }),
      alertFormGroup: this.fb.group({
        alertArray: this.fb.array([]),
        state: '',
        isAdd: false,
        requiredSave: false,
      }),
      medicalAlertFormGroup: this.fb.group({
        alertArray: this.fb.array([]),
        trashArray: this.fb.array([]),
        inActivateArray: this.fb.array([]),
        state: '',
        isAdd: false,
        requiredSave: false,
      }),
      basicInfoFormGroup: this.createPatientBasicInfoFormGroup(),
      companyInfoFormGroup: this.fb.group({
        company: '',
        occupations: {
          value: OCCUPATIONS.map(occupation => {
            return { value: occupation, label: occupation };
          }),
        },
        occupation: '',
        line1: '',
        line2: '',
        postCode: '',
      }),
      relationshipEntityFormGroup: this.createRelationshipFormGroup(),
      documentsFormGroup: this.fb.group({
        filter: '',
        filterStart: '',
        filterEnd: '',
        documentsArray: this.fb.array([]),
        newDocumentsArray: this.fb.array([]),
      }),
      selectedPlans: this.fb.array([]),
      consultationFormGroup: this.fb.group({
        search: '',
        dateFrom: new Date(),
        dateTo: new Date(),
      }),
      historyFilterFormGroup: this.fb.group({
        dateFrom: new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        ),
        dateTo: new Date(),
        doctors: { value: [] },
        doctor: '',
        paymentStatus: { value: [] },
        status: '',
      }),
      historyListFormGroup: this.fb.group({
        formArray: this.fb.array([]),
      }),
      historyDetailFormGroup: this.fb.group({
        caseId: '',
        patientInfo: {},
        consultationInfo: {},
        paymentInfo: {},
        gstValue: this.gstOnly,
        overallCharges: { value: [] },
        doctorId: '',
        billNo: '',
        date: '',
        consultationStartTime: '',
        consultationEndTime: '',
        purpose: '',
        notes: '',
        diagnosisArray: this.fb.array([]),
        secondaryDiagnosisArray: this.fb.array([]),
        notesArray: this.fb.array([]),
        itemArray: this.fb.array([]),
        filter: '',
        documentsArray: this.fb.array([]),
        newDocumentsArray: this.fb.array([]),
        certificateArray: this.fb.array([]),
        referralArray: this.fb.group({
          referralId: '',
          patientReferrals: this.fb.array([]),
        }),
        memoArray: this.fb.array(new Array<MemoObject>()),
        startTime: '',
        endTime: '',
        timeChitFrom: '',
        timeChitTo: '',
        printFormGroup: this.fb.group({
          receiptTypes: {
            value: [
              { value: 'general', label: 'General' },
              // { value: 'detail', label: 'Detail' },
              { value: 'breakdown', label: 'Breakdown' },
            ],
          },
          receiptType: 'general',
          printAll: false,
          disablePageBreak: false,
        }),
        followupConsultationFormGroup: this.fb.group({
          id: '',
          patientId: '',
          patientVisitId: '',
          followupDate: '',
          remarks: '',
        }),
        clinicNotes: '',
      }),
      primaryCareNetwork: this.fb.group({
        optIn: false,
        optOut: false,
        optInDate: '',
      }),
      newBornFormGroup: this.fb.group({
        attendingDoctor: ' ',
        referralHospital: ' ',
        referralDoctor: ' ',
        birthOder: ' ',
        birthTime: null,
        breastfed: ' ',
        standby: ' ',
        modeOfDelivery: ' ',
        gestationWeeks: ' ',
        apgar: ' ',
        apgar2: ' ',
        weight: ' ',
        length: ' ',
        headCircum: ' ',
        g6pd: ' ',
        bilirubin1: ' ',
        bilirubin2: ' ',
        freeT4: ' ',
        tsh: ' ',
        pku: ' ',
        metabolicScreen: ' ',
        galactosemia: ' ',
        bld: ' ',
        hbs: ' ',
        hepBVaccine: ' ',
        hyperIgG: ' ',
        bcg: ' ',
        congenital: ' ',
        neonatalProblem: ['', [Validators.required, Validators.minLength(5)]],
        hearingTest: ' ',
        remarks: ' ',
      }),
    });

    const patientSourceRequired = this.akitaAppQuery
      .select(entity => entity.clinic)
      .subscribe((clinic: Clinic) => {
        if (clinic && clinic.clinicFeatures) {
          if (clinic.clinicFeatures.indexOf('PATIENT_SOURCES') !== -1)
            formGroup
              .get('basicInfoFormGroup')
              .get('patientSources')
              .setValidators([Validators.required]);
        }

        if (patientSourceRequired) patientSourceRequired.unsubscribe();
      });

    return formGroup;
  }

  getPatientDetailFormGroup(): FormGroup {
    return this.patientDetailFormGroup;
  }

  setPatientDetailFormGroup(formGroup: FormGroup) {
    this.patientDetailFormGroup = formGroup;
  }

  resetPatientDetailFormGroup() {
    this.patientDetailFormGroup = this.createPatientDetailFormGroup();
  }

  getPatientAddFormGroup(): FormGroup {
    return this.patientAddFormGroup;
  }

  setPatientAddFormGroup(formGroup: FormGroup) {
    this.patientAddFormGroup = formGroup;
  }

  resetPatientAddFormGroup() {
    this.patientAddFormGroup = this.createPatientAddFormGroup();
  }

  // Methods to create form groups for individual components

  createPatientOtherPatientInfoFormGroup(): FormGroup {
    return this.fb.group({
      nationality: ['', Validators.required],
      race: ['', Validators.required],
      patientIdentifier: '',
      additionalIdentifier: '',
      maritalStatus: '', // "SINGLE",
      languageSpoken: '', // "English",
      nationalitiesDropdown: {
        value: this.storeService.nationalities.map(nationality => {
          return { value: nationality.name, label: nationality.name };
        }),
      },
      raceDropdown: {
        value: ETHNICITIES.map(ethnicity => {
          return { value: ethnicity, label: ethnicity };
        }),
      },
      maritalStatusDropdown: {
        value: MARITAL_STATUS.map(status => {
          return { value: status.toUpperCase(), label: status };
        }),
      },
      languagesDropdown: {
        value: LANGUAGES.map(language => {
          return { value: language, label: language };
        }),
      },
    });
  }

  createPatientBasicInfoFormGroup(): FormGroup {
    return this.fb.group({
      titles: {
        value: TITLES.map(title => {
          return { value: title, label: title };
        }),
      },
      title: [''],
      name: ['', this.isNEHROpt() ? [Validators.required, StringValidators.containsASCIICharacters] : [Validators.required]],
      birth: [new Date(), [Validators.required, DateOfBirthValidator]],
      genders: {
        value: GENDERS.map(gender => {
          return { value: gender.toUpperCase(), label: gender };
        }),
      },
      gender: ['', Validators.required],
      fullId: this.fb.group({
        id: ['', [Validators.required, Validators.minLength(4)]],
        idType: ['', Validators.required],
        selectedCountry: 'SINGAPORE',
      }),
      kycData: this.fb.group({
        kycStatus: '',
        verifiedDate: '',
      }),
      idTypes: {
        value: [
          { value: 'NRIC_PINK', label: 'NRIC (Pink)' },
          { value: 'NRIC_BLUE', label: 'NRIC (Blue)' },
          { value: 'MIC', label: 'Malaysian IC' },
          { value: 'FIN', label: 'FIN' },
          { value: 'PASSPORT', label: 'Passport' },
          { value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate' },
          { value: 'OTHER', label: 'Other' },
        ],
      },
      countries: {
        value: this.storeService.countries.map(country => {
          return { value: country.name, label: country.name };
        }),
      },
      country: ['SINGAPORE', Validators.required],
      races: {
        value: ETHNICITIES_WITH_DEFAULT.map(ethnicity => {
          return {
            value: ethnicity.name,
            label: ethnicity.name,
            category: ethnicity.category,
          };
        }),
      },
      race: '',
      patientIdentifier: '',
      additionalIdentifier: '',
      nationalities: {
        value: this.storeService.nationalities.map(nationality => {
          return { value: nationality.name, label: nationality.name };
        }),
      },

      nationality: ['', Validators.required],
      maritalStatus: {
        value: MARITAL_STATUS.map(status => {
          return {
            value: status.toUpperCase(),
            label: status,
          };
        }),
      },

      status: [''],
      languages: {
        value: LANGUAGES.map(language => {
          return { value: language, label: language };
        }),
      },
      language: '',
      primary: ['', [Validators.required, this.validateNumber]],
      // primary: ['', Validators.required],
      secondary: ['', this.validateNumber],
      line1: ['', this.isNEHROpt() ? [Validators.required, StringValidators.containsASCIICharacters] : [Validators.required]],
      line2: ['', this.isNEHROpt() ? StringValidators.containsASCIICharacters : null],
      postCode: '',
      email: ['', this.validateEmail],
      communications: {
        value: COMMUNICATIONS.map(communication => {
          return {
            value: communication.toLowerCase(),
            label: communication,
          };
        }),
      },
      communicationMode: ['phone', Validators.required],
      consentGiven: false,
      marketingOptOut: false,

      patientSources: '',
      currentPatientSources: {},
      nehrOptedInStatus: '',
      currentNehrOptedInStatus:{},
      onGoingMedications: new FormArray([]),
      patientExtraDetails: this.fb.group({
        chasUsage: '',
        smokingStatus: null,
        rightSiting: false,
        referenceSource: '',
        remark: '',
        rightSitingReferenceSource: '',
        stateOfChange: null,
        yearStartOfSmoke: ''
      })
    });
  }

  createPrimaryCareNetworkFormGroup(): FormGroup {
    return this.fb.group({
      optIn: false,
      optOut: false,
      optInDate: '',
    });
  }

  createPatientCompanyInfoFormGroup(): FormGroup {
    return this.fb.group({
      company: '',
      occupations: {
        value: OCCUPATIONS.map(occupation => {
          return { value: occupation, label: occupation };
        }),
      },
      occupation: '',
      line1: '',
      line2: '',
      postCode: '',
    });
  }

  createRelationshipFormGroup() {
    return this.fb.group({
      relationshipEntities: this.fb.array([]),
      state: '',
      isAdd: false,
      requiredSave: false,
      relationshipOptions: {
        value: EMERGENCY_CONTACT_RELATIONSHIPS,
      },
      idTypes: {
        value: [
          { value: 'NRIC_PINK', label: 'NRIC (Pink)' },
          { value: 'NRIC_BLUE', label: 'NRIC (Blue)' },
          { value: 'MIC', label: 'Malaysian IC' },
          { value: 'FIN', label: 'FIN' },
          { value: 'PASSPORT', label: 'Passport' },
          { value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate' },
          { value: 'OTHER', label: 'Other' },
        ],
      },
    });
  }

  createRelationshipEntityFormGroup(
    primary?,
    name?,
    contactNumber?,
    relationship?,
    userId?: UserId,
    dob?,
    address?,
    email?,
    consentValidTo?,
    medisave?
  ) {
    primary = primary || false;
    name = name || '';
    contactNumber = contactNumber || '';
    relationship = relationship || '';
    userId = userId || new UserId();

    userId.idType = userId.idType || null;
    userId.number = userId.number || '';
    dob = dob || '';
    address = {
      line1: address && address.address && address.address !== '' ? address.address.split('\n')[0] : address && address.line1 && address.line1 !== '' ? address.line1 : '',
      line2: address && address.address && address.address !== '' ? address.address.split('\n')[1] : address && address.line2 && address.line2 !== '' ? address.line2 : '',
    };
    email = email || '';
    consentValidTo = consentValidTo || '';
    medisave = medisave || false;

    return this.fb.group({
      primary: new FormControl(primary),
      name: name,
      contactNumber: contactNumber,
      relationship: relationship,
      userId: this.fb.group({
        idType: userId.idType,
        number: userId.number,
      }),
      dob: dob,
      address: this.fb.group(address),
      email: email,
      consentValidTo: consentValidTo,
      medisave: medisave,
    });
  }

  // Check Methods

  checkBasicDetailInfo(patientInfo: PatientInfo, formGroup: FormGroup) {
    // Basic Contact Info
    patientInfo.title = formGroup.get('title').value;
    patientInfo.preferredMethodOfCommunication = formGroup.get(
      'communicationMode'
    ).value;
    patientInfo.consentGiven = formGroup.get('consentGiven').value;
    patientInfo.marketingOptOut = formGroup.get('marketingOptOut').value;
    patientInfo.race = formGroup.get('race').value;
    patientInfo.preferredLanguage = formGroup.get('language').value;
    patientInfo.patientIdentifier = formGroup.get('patientIdentifier').value;
    patientInfo.additionalIdentifier = formGroup.get(
      'additionalIdentifier'
    ).value;
    patientInfo.patientExtraDetails.chasUsage = formGroup
      .get('patientExtraDetails')
      .get('chasUsage').value;
    patientInfo.patientExtraDetails.referenceSource = formGroup
      .get('patientExtraDetails')
      .get('referenceSource').value;
    patientInfo.patientExtraDetails.rightSiting = formGroup
      .get('patientExtraDetails')
      .get('rightSiting').value;
    patientInfo.patientExtraDetails.smokingStatus = formGroup
      .get('patientExtraDetails')
      .get('smokingStatus').value;
    patientInfo.patientExtraDetails.remark = formGroup
      .get('patientExtraDetails')
      .get('remark').value;
    patientInfo.patientExtraDetails.rightSitingReferenceSource = formGroup
      .get('patientExtraDetails')
      .get('rightSitingReferenceSource').value;
    patientInfo.patientExtraDetails.stateOfChange = formGroup
      .get('patientExtraDetails')
      .get('stateOfChange').value;
    patientInfo.patientExtraDetails.yearStartOfSmoke = formGroup
      .get('patientExtraDetails')
      .get('yearStartOfSmoke').value;
    patientInfo.name = formGroup.get('name').value;
    patientInfo.dob = moment(formGroup.get('birth').value).format(
      DISPLAY_DATE_FORMAT
    );
    patientInfo.userId.number = formGroup.get('fullId').get('id').value;
    patientInfo.userId.idType = formGroup.get('fullId').get('idType').value;
    patientInfo.kycData.kycStatus = formGroup
      .get('kycData')
      .get('kycStatus').value;
    patientInfo.kycData.verifiedDate = formGroup
      .get('kycData')
      .get('verifiedDate').value;

    patientInfo.gender = formGroup.get('gender').value;
    patientInfo.contactNumber.number = formGroup.get('primary').value;
    patientInfo.status = 'ACTIVE';
    patientInfo.address.country = formGroup.get('country').value;
    patientInfo.address.address =
      formGroup.get('line1').value +
      (formGroup.get('line1').value.endsWith('\n') ? '' : '\n') +
      formGroup.get('line2').value;
    patientInfo.emailAddress = formGroup.get('email').value;
    patientInfo.nationality = formGroup.get('nationality').value;
    patientInfo.maritalStatus = formGroup.get('status').value;
    patientInfo.address.postalCode = formGroup.get('postCode').value;

    let patientSources = { ...formGroup.get('currentPatientSources').value };
    patientSources[this.akitaAppQuery.getValue().clinic.id] = formGroup.get(
      'patientSources'
    ).value;
    patientInfo.patientSources = patientSources;

    let nehrOptedInStatus = { ...formGroup.get('currentNehrOptedInStatus').value };
    nehrOptedInStatus[this.akitaAppQuery.getValue().clinic.id] = formGroup.get(
      'nehrOptedInStatus'
    ).value;
    patientInfo.nehrOptedInStatus = nehrOptedInStatus;

    let tempSec: string = formGroup.get('secondary').value;

    if (tempSec) {
      if (patientInfo.secondaryNumber) {
        patientInfo.secondaryNumber.number = tempSec;
      } else {
        const secondaryNumber = { number: tempSec };
        patientInfo['secondaryNumber'] = secondaryNumber;
      }
    } else {
      patientInfo.secondaryNumber = {
        number: '',
      };
    }

    patientInfo.onGoingMedications = formGroup.get('onGoingMedications').value;

    return patientInfo;
  }

  checkEmergencyContactInfo(patientInfo, formGroup: FormArray) {
    if (
      formGroup.length > 0 &&
      !formGroup.getRawValue().some(contact => contact.primary)
    ) {
      formGroup.at(0).patchValue({ primary: true });
    }

    formGroup.getRawValue().forEach(element => {
      if (element.name && element.contactNumber && element.relationship) { 
        patientInfo.relationshipEntities.push(
          new RelationshipEntity(
            element.primary,
            element.name,
            element.contactNumber,
            element.relationship,
            element.userId,
            element.dob ? moment(element.dob).format(DISPLAY_DATE_FORMAT) : null,
            element.address,
            element.email,
            element.consentValidTo ? moment(element.consentValidTo).format(DISPLAY_DATE_FORMAT) : null,
            element.medisave
          )
        );
      }
    });

    return patientInfo;
  }

  checkCompanyInfo(patientInfo, formGroup: FormGroup) {
    patientInfo.company.name = formGroup.get('company').value;
    patientInfo.company.occupation = formGroup.get('occupation').value;
    patientInfo.company.postalCode = formGroup.get('postCode').value;
    patientInfo.company.address =
      formGroup.get('line1').value +
      (formGroup.get('line1').value.endsWith('\n') ? '' : '\n') +
      formGroup.get('line2').value;

    return patientInfo;
  }

  // Validation/Helper functions for FormGroups:
  // Includes
  // 1) Validating user existence in system
  // 2) Validation of NRIC
  // 3) Populate address based on valid zipcode entered into input
  // 4) Validating email address
  checkUserInSystem(
    apiPatientInfoService: ApiPatientInfoService,
    idType,
    idValue
  ) {
    const promise = new Promise(function(resolve, reject) {
      apiPatientInfoService
        .validateID(idType + ':' + idValue)
        .subscribe(res => {
          if (res.payload) resolve('ID is valid');
          else reject('ID is not valid');
        });
    });

    return promise;
  }

  checkIDisValid() {
    const promise = new Promise(function(resolve, reject) {
      this.apiCmsManagementService
        .validateIdentification('NRIC', 'S8835803A')
        .subscribe(res => {
          if (res.payload) resolve('ID is valid');
          else reject('ID is not valid');
        });
    });
    return promise;
  }

  validateIdentification(
    apiCmsManagementService: ApiCmsManagementService,
    currentControl: AbstractControl,
    controlType
  ): AsyncValidatorFn {
    //    this.isUserIDValidated = null;
    return (control: AbstractControl) => {
      let idType = '';
      let idValue = '';
      if (controlType === 'idType') {
        idType = currentControl.value;
        idValue = control.value;
      } else if (controlType === 'id') {
        idType = control.value;
        idValue = currentControl.value;
      }

      idType =
        idType === 'NRIC_PINK' || idType === 'NRIC_BLUE' ? 'NRIC' : idType;

      if (idValue && (idType === 'NRIC' || idType === 'FIN')) {
        // control.markAsTouched();
        return timer(500).pipe(
          switchMap(() => {
            return apiCmsManagementService
              .validateIdentification(idType, idValue)
              .pipe(
                map(res => {
                  if (res.payload) {
                    this.isUserIDValidated = null;
                  } else {
                    this.isUserIDValidated = {
                      userIdIsNotValid: { value: idValue },
                    } as any;
                    return this.isUserIDValidated;
                  }
                }),
                catchError(this.handleError)
              );
          })
        );
      } else {
        return of(null);
      }
    };
  }

  checkWhetherUserExists(
    apiPatientInfoService: ApiPatientInfoService,
    currentControl: AbstractControl,
    controlType
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      let idType = '';
      let idValue = '';

      if (controlType === 'idType') {
        idType = currentControl.value;
        idValue = control.value;
      } else if (controlType === 'id') {
        idType = control.value;
        idValue = currentControl.value;
      }

      if (idValue.length > 0) {
        control.markAsTouched();
        return apiPatientInfoService.validateID(idType + ':' + idValue).pipe(
          map(
            res => {
              if (res.payload) {
                // USER EXISTS
                return { userid: { value: idValue } };
              } else {
                return null;
              }
            },
            err => this.handleError
          ),
          catchError(this.handleError)
        );
      } else {
        return of(null);
      }
    };
  }

  findAddress(
    apiCmsManagementService: ApiCmsManagementService,
    postCode: AbstractControl,
    address1Input: AbstractControl,
    address2Input: AbstractControl,
    formGroup: FormGroup
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const debounceTime = 500; // milliseconds
      return timer(debounceTime).pipe(
        switchMap(() => {
          if (control.value) {
            return apiCmsManagementService.listAddress(control.value + '').pipe(
              map(
                res => {
                  if (res.payload) {
                    let addr: string = res.payload.address;
                    addr = addr.split(control.value).join(' ');

                    address1Input.patchValue(addr);
                    address2Input.patchValue('');
                    formGroup.patchValue({ addressInput: addr });
                    return null;
                  } else {
                    return null;
                  }
                },
                err => {
                  this.alertService.error(JSON.stringify(err));
                  console.error('ERROR', err);
                }
              ),
              catchError(this.handleError)
            );
          } else {
            return of(null);
          }
        })
      );
    };
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    return of(null);
  }

  validateEmail(control: FormGroup) {
    const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;
    const email = control.value;

    if (
      (email && (EMAIL_PATTERN.test(email) || email.length === 0)) ||
      email === undefined ||
      email === ''
    ) {
      return null;
    } else {
      return {
        vaildEmail: { value: email, message: 'Email address is invalid' },
      };
    }
  }

  validateNumber(control: FormGroup) {
    const number = control.value;
    if (number === '') {
      return null;
    }

    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const NUMBER_PATTERN = /^\+?[1-9][\s\d]{1,14}$/;
      const result = phoneUtil.parseAndKeepRawInput(number, 'SG');
      if (phoneUtil.isPossibleNumber(result) && NUMBER_PATTERN.test(number)) {
        return null;
      }
      return { validNumber: { value: number, message: 'Number is invalid' } };
    } catch {
      return { validNumber: { value: number, message: 'Number is invalid' } };
    }
  }

  getIsUserIDValidated(): Observable<any> {
    return this.isUserIDValidated;
  }

  resetIsUserIDValidated() {
    this.isUserIDValidated = {} as any;
  }

  getPatientPayableGroup() {
    return this.fb.group(new PatientPayable());
  }

  getPatientPayableArray() {
    return this.fb.array([this.getPatientPayableGroup()]);
  }

  getPopulatedPatientPayableArray(patientPayables) {
    const patientPayablesFA = [];
    patientPayables.forEach(patientPayable =>
      patientPayablesFA.push(this.fb.group(patientPayable))
    );
    return this.fb.array(patientPayablesFA);
  }

  getEmptyPatientPayableArray() {
    return this.fb.array([]);
  }

  /** FOR PRE_REGISTRATION */
  public get preRegistrationData(): PreRegistrationEntity {
    return this._preRegistrationData;
  }
  public set preRegistrationData(value: PreRegistrationEntity) {
    this._preRegistrationData = value;
  }

  public preRegistrationDataValid(): Boolean {
    return (
      this.preRegistrationData !== undefined &&
      this.preRegistrationData !== null
    );
  }
  /** FOR PRE_REGISTRATION */

  checkEmailMandatory() {
    this.patientDetailFormGroup
      .get('basicInfoFormGroup')
      .get('email')
      .clearAsyncValidators();
    const emailValue = this.patientDetailFormGroup
      .get('basicInfoFormGroup')
      .get('communicationMode').value;
    if (emailValue && emailValue === 'email') {
      this.patientDetailFormGroup
        .get('basicInfoFormGroup')
        .get('email')
        .setValidators([Validators.required, this.validateEmail]);
    } else {
      this.patientDetailFormGroup
        .get('basicInfoFormGroup')
        .get('email')
        .setValidators([this.validateEmail]);
    }
    this.patientDetailFormGroup
      .get('basicInfoFormGroup')
      .get('email')
      .markAsTouched();
    this.patientDetailFormGroup
      .get('basicInfoFormGroup')
      .get('email')
      .updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  createPatientNewBornFormGroup(): FormGroup {
    return this.fb.group({
      attendingDoctor: ' ',
      referralHospital: ' ',
      referralDoctor: ' ',
      birthOder: ' ',
      birthTime: null,
      breastfed: ' ',
      standby: ' ',
      modeOfDelivery: ' ',
      gestationWeeks: ' ',
      apgar: ' ',
      apgar2: ' ',
      weight: ' ',
      length: ' ',
      headCircum: ' ',
      g6pd: ' ',
      bilirubin1: ' ',
      bilirubin2: ' ',
      freeT4: ' ',
      tsh: ' ',
      pku: ' ',
      metabolicScreen: ' ',
      galactosemia: ' ',
      bld: ' ',
      hbs: ' ',
      hepBVaccine: ' ',
      hyperIgG: ' ',
      bcg: ' ',
      congenital: ' ',
      neonatalProblem: ' ',
      hearingTest: ' ',
      remarks: ' ',
    });
  }

  isNEHROpt() {
    return this.akitaAppQuery.checkClinicFeatureExist('NEHR_SUBMISSION');
  }

  public checkRelationshipsValidation(relationshipEntities: RelationshipEntity[], dob: string): boolean {
    let isContactListValid: boolean = true;

    if(dob && moment().diff(moment(dob, 'DD-MM-YYYY'), 'years') < 13) {
      if(!relationshipEntities.filter(item => item.relationship === 'MOTHER').length) {
        this.alertService.error('Mother\s contact details required');
        isContactListValid = false;
      }
    }

    return isContactListValid;
  }

  public enableDisableBirthTime(idType: string, dob: string, birthTimeFormControl: FormControl): boolean {
    /**
    *   If 
     *    idType is NOT NRIC_PINK, NRIC_BLUE, FIN, PASSPORT, BIRTH_CERTIFICATE  
     *  AND
     *    age is below 1 years old
     *  enable birth time and make it mandatory
     */

    let displayTimeOfBirth: boolean = false;
    const age = moment().diff(moment(dob), 'years');
    
    if (idType && idType.length > 0) {
      if (
        idType !== 'NRIC_PINK' &&
        idType !== 'NRIC_BLUE' &&
        idType !== 'FIN' &&
        idType !== 'PASSPORT' &&
        idType !== 'BIRTH_CERTIFICATE' &&
        age < 1
      ) {
        displayTimeOfBirth = true;
        birthTimeFormControl.setValidators([Validators.required]);
      } else {
        displayTimeOfBirth = false;
        birthTimeFormControl.removeValidators([Validators.required]);
      }
    } else {
      displayTimeOfBirth = false;
      birthTimeFormControl.removeValidators([Validators.required]);
    }

    birthTimeFormControl.updateValueAndValidity();

    return displayTimeOfBirth;
  }

  public setCurrentlyOpenedNEHRTab(tab: Window): void {
    this.currentlyOpenedNehrTab = tab;
  }

  public getCurrentlyOpenedNEHRTab(): Window | null {
    return this.currentlyOpenedNehrTab ? this.currentlyOpenedNehrTab : null;
  }
}