import {AkitaPatientAppQuery} from './states/akita-patient/akita-patient-app.query';
import {ApiPatientInfoService} from './api-patient-info.service';
import {
  DISPLAY_DATE_FORMAT,
  INSUFFICIENT_STOCK,
  ITEM_ONLY_AVAILABLE_FOR_HSG_PATIENT,
  SEPARATE_BATCH_AVAILABLE,
  UNAVAILABLE_INVENTORY
} from '../constants/app.constants';

import * as moment from 'moment';

import {ApiCaseManagerService} from './api-case-manager.service';
import {AlertService} from './alert.service';

import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Injectable} from '@angular/core';
import {StoreService} from './store.service';
import {ChargeDetailsItem} from '../../app/objects/request/ChargeDetails';

import {get} from 'lodash';
import {generate} from 'shortid';
import {AkitaChargeItemQuery} from "./states/akita-charge-item.query";
import {AkitaWhitelistedItemQuery} from "./states/akita-whitelisted-items.query";
import {AkitaClinicQuery} from "./states/akita-clinic.query";
import {ChargeItem} from "../objects/state/ChargeItem";
import {AkitaCaseVisitQuery} from "./states/akita-case-visit.query";
import {
  AttachedMedicalCoveragesQuery
} from "./states/akita-case/akita-attached-medical-coverages/attached-medical-coverages.query";
import {distinctUntilChanged, first, map} from "rxjs/operators";
import {coverageCappedAllowed} from "./states/akita-whitelisted-items.store";
import {WhitelistedItemService} from "./whitelisted-item.service";
import {Logger} from "../util/logger";
import {AkitaSflItemQuery} from "./states/akita-sfl-item.query";

@Injectable()
export class CaseChargeFormService {
  chargeItemDetails: FormArray;
  caseItemPriceObservable = new BehaviorSubject<any>(undefined);
  drugAllergy = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private apiCaseManagerService: ApiCaseManagerService,
    private apiPatientInfoService: ApiPatientInfoService,
    private alertService: AlertService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaWhitelistedItemQuery: AkitaWhitelistedItemQuery,
    private akitaSflItemQuery: AkitaSflItemQuery,
    private akitaClinicQuery: AkitaClinicQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private attachedMedCovQuery: AttachedMedicalCoveragesQuery,
    private whitelistedItemService: WhitelistedItemService
  ) {}

  resetForm() {
    if (this.chargeItemDetails) {
      this.chargeItemDetails = new FormArray([]);
    }

    return this.chargeItemDetails;
  }

  addMultipleChargeItems(
    addNewRow: boolean,
    count: number,
    reverseOrder = true,
    remoteDelivery?: boolean
  ) {
    if (!addNewRow) {
      this.chargeItemDetails = null;
    }
    for (let index = 0; index < count; index++) {
      this.buildDrugDispatchDetails({
        remoteDelivery
      }, reverseOrder);
    }
    return this.chargeItemDetails;
  }
  createEmptyDispatchDetails() {
    this.chargeItemDetails = new FormArray([]);
    return this.chargeItemDetails;
  }

  buildDrugDispatchDetails(option, reverseOrder, shortId?) {
    let dosageQty = 1,
      dosageUom = null,
      salesUom = null,
      dosageInstructionCode = '',
      instructionCode = '',
      durationFreq = 1,
      qty = 1,
      remarkStr = '',
      packageIdCode = '',
      salesId = '',
      type = '',
      drugRouteOfAdministration = '',
      remoteDelivery = false;
    if (option !== undefined) {
      dosageQty = option.dosageQty || '';
      dosageUom = option.dosageUom || null;
      salesUom = option.salesUom || null;
      dosageInstructionCode = null;
      instructionCode = option.frequencyCode || '';
      qty = option.standardDispenseAmount || 1;
      remarkStr = option.remarks || '';
      packageIdCode = option.packageId || '';
      salesId = option.salesItemId || '';
      type = option.itemType || '';
      drugRouteOfAdministration = option.routeOfAdministration || '';
      remoteDelivery = option.remoteDelivery || false;
      // durationFreq =  1;
    }

    const dose = this.buildDose(dosageUom, dosageQty);
    const dosageInstruction = this.buildDoseInstruction(
      dosageInstructionCode,
      ''
    );
    const instruction = this.buildInstruction(instructionCode);
    const priceAdjustment = this.buildPriceAdjustment('0', '', '');
    const medicalCoverages = this.buildAttachedMedicalCoverage('', '');
    const drugId = new FormControl((option || { id: '' }).id);
    const batchNumber = new FormControl('');
    const expiryDate = new FormControl('');
    const remark = new FormControl(remarkStr);
    const purchaseQty = new FormControl(qty);
    const duration = new FormControl(durationFreq);
    const stock = new FormControl('');
    const inventoryInvalid = new FormControl('', remoteDelivery ? undefined : validateInventory);
    const excludedCoveragePlanIds = new FormControl([]); //to be kept as formcontrol, DO NOT CHANGE TO FORMARRAY
    const cautionary = this.fb.array([]);
    const isChecked = new FormControl('');
    const unitPrice = this.buildUnitPrice(0, false);
    const oriTotalPrice = new FormControl('');
    const adjustedTotalPrice = new FormControl(0, {
      asyncValidators: this.validateWhitelistedItemPrice.bind(this),
      updateOn: 'blur'
    });
    const adjustedUnitPrice = new FormControl(0);
    const calculatedTotalPrice = new FormControl(0);
    const inventories = this.fb.array([]);
    const drugDispenseShortId = new FormControl(
      shortId ? shortId : generate()
    );
    const packageId = new FormControl(packageIdCode);
    const itemType = new FormControl(type);
    const salesItemId = new FormControl(salesId);
    const salesUomControl = new FormControl(salesUom);
    const vaccinationInfo = option !== undefined && option.itemType === 'VACCINATION' ? this.buildVaccinationInfo(option ? option.multiVaccineSubItems : []) : this.fb.group({});
    const routeOfAdministration = new FormControl(drugRouteOfAdministration);
    const remoteDeliveryFormControl = new FormControl(remoteDelivery)
    const deliveryLocationFormControl = new FormControl('')
    const chargeBigDeci = new FormGroup({
      price: new FormControl(),
      taxIncluded: new FormControl(),
    });

    const sflData = this.fb.group({
      id: [],
      screenDate: [, this.requiredIfSfl(drugId)],
      screeningType: [, this.requiredIfSfl(drugId)],
      testType: [, this.requiredIfSfl(drugId)],
      testOrder: [, this.requiredIfSfl(drugId)],
      followUpOutcome: [],
      screeningOutcome: [],
      followUpDate: [],
      visitId: [],
      conditionCodes: []
    })

    const newDrugDispatchDetail = this.createDrugDispatchDetailsFormGroup(
      drugId,
      batchNumber,
      expiryDate,
      remark,
      dose,
      dosageInstruction,
      instruction,
      priceAdjustment,
      medicalCoverages,
      purchaseQty,
      duration,
      stock,
      inventoryInvalid,
      excludedCoveragePlanIds,
      cautionary,
      isChecked,
      unitPrice,
      oriTotalPrice,
      adjustedTotalPrice,
      adjustedUnitPrice,
      calculatedTotalPrice,
      inventories,
      drugDispenseShortId,
      packageId,
      salesItemId,
      itemType,
      salesUomControl,
      vaccinationInfo,
      routeOfAdministration,
      remoteDeliveryFormControl,
      deliveryLocationFormControl,
      chargeBigDeci,
      sflData
    );

    if(option && option.itemType === 'VACCINATION'){
      newDrugDispatchDetail.addControl('vaccineDosageAvailableList', new FormControl(option.dosages.map(item => {
        return JSON.stringify(item);
      })));
      newDrugDispatchDetail.get('dosageInstruction').patchValue(dosageInstruction);
    }

    if (this.chargeItemDetails) {
      // this.chargeItemDetails.push(newDrugDispatchDetail);
      if (reverseOrder) {
        this.chargeItemDetails.insert(0, newDrugDispatchDetail);
      } else {
        this.chargeItemDetails.push(newDrugDispatchDetail);
      }
    } else {
      this.chargeItemDetails = new FormArray([newDrugDispatchDetail]);
    }
    return this.chargeItemDetails;
  }

  requiredIfSfl(drugId: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.isSfl(drugId.value)) {
        return null;
      }

      if (!control.value) {
        return {
          required: true
        }
      }
      return null;
    }
  }

  isEmptyOrNull(value) {
    if (value === '' || value === null || value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  getCaseItemPrices(items) {
    const chargeDetailItems = [];
    items.forEach(item => {
      const tempItem = {
        itemId: item.itemId,
        quantity: item.quantity || '',
        excludedPlans: item.excludedCoveragePlanIds,
      };
      chargeDetailItems.push(tempItem);
    });

    const chargeDetails = { chargeDetails: chargeDetailItems };
    return this.apiCaseManagerService.getCaseItemPrices(
      this.store.getCaseId(),
      chargeDetails
    );
  }

  createDrugDispatchDetailsFormGroup(
    drugId: FormControl,
    batchNumber: FormControl,
    expiryDate: FormControl,
    remark: FormControl,
    dose: FormGroup,
    dosageInstruction: FormGroup,
    instruction: FormGroup,
    priceAdjustment: FormGroup,
    medicalCoverage: FormGroup,
    purchaseQty: FormControl,
    duration: FormControl,
    stock: FormControl,
    inventoryInvalid: FormControl,
    excludedCoveragePlanIds: FormControl,
    cautionary: FormArray,
    isChecked: FormControl,
    unitPrice: FormGroup,
    oriTotalPrice: FormControl,
    adjustedTotalPrice: FormControl,
    adjustedUnitPrice: FormControl,
    calculatedTotalPrice: FormControl,
    inventories: FormArray,
    drugDispenseShortId: FormControl,
    packageId: FormControl,
    salesItemId: FormControl,
    itemType: FormControl,
    salesUom: FormControl,
    vaccinationInfo: FormGroup,
    routeOfAdministration: FormControl,
    remoteDelivery: FormControl,
    deliveryLocation: FormControl,
    chargeBigDeci: FormGroup,
    sflData: FormGroup
  ) {
    const newDrugDispatchDetail = new FormGroup({
      drugId: drugId,
      batchNumber: batchNumber,
      expiryDate: expiryDate,
      remark: remark,
      dose: dose,
      dosageInstruction: dosageInstruction,
      instruction: instruction,
      priceAdjustment: priceAdjustment,
      attachedMedicalCoverages: medicalCoverage,
      purchaseQty: purchaseQty,
      duration: duration,
      stock: stock,
      inventoryInvalid: inventoryInvalid,
      excludedCoveragePlanIds: excludedCoveragePlanIds,
      cautionary: cautionary,
      isChecked: isChecked,
      unitPrice: unitPrice,
      oriTotalPrice: oriTotalPrice,
      adjustedTotalPrice: adjustedTotalPrice,
      adjustedUnitPrice: adjustedUnitPrice,
      calculatedTotalPrice: calculatedTotalPrice,
      inventories: inventories,
      drugDispenseShortId: drugDispenseShortId,
      packageId: packageId,
      salesItemId: salesItemId,
      itemType: itemType,
      salesUom: salesUom,
      vaccinationInfo: vaccinationInfo,
      routeOfAdministration: routeOfAdministration,
      remoteDelivery: remoteDelivery,
      deliveryLocationId: deliveryLocation,
      chargeBigDeci: chargeBigDeci,
      sflData: sflData
    }, [], [this.validateWhitelistedItem.bind(this)]);

    this.akitaCaseVisitQuery.selectCaseCoverage().pipe(
      distinctUntilChanged(),
    ).subscribe(caseCoverage => {
      newDrugDispatchDetail.markAsTouched()
    })

    return newDrugDispatchDetail;
  }

  private whitelistedLogger = new Logger('CaseChargeFormService', 'whitelisted');

  validateWhitelistedItem(control: AbstractControl): Observable<ValidationErrors | null> {

    let attachedMedicalCoverages$ = this.attachedMedCovQuery.attachedMedicalCoverages$.pipe(first())
    let selectCaseCoverage = this.akitaCaseVisitQuery.selectCaseCoverage().pipe(first());
    let patientInfo$ = this.akitaPatientAppQuery.patientInfo$.pipe(first());
    return combineLatest([attachedMedicalCoverages$, selectCaseCoverage, patientInfo$])
      .pipe(map(([attachedMedicalCoverages, caseCoverage, patientInfo]) => {
          let value = control.value;
          if (!value.drugId) {
            this.whitelistedLogger.log('No drug id', value)
            return null;
          }
          let clinic = this.akitaClinicQuery.getCurrentClinic()
          if (!clinic.clinicFeatures.includes('HSG')) {
            this.whitelistedLogger.log('No clinic or clinic does not have HSG feature', clinic)
            return null;
          }

          if (!clinic.clinicFeatures.includes('HSG_1_5')) {
            this.whitelistedLogger.log('No clinic or clinic does not have HSG_1_5 feature', clinic)
            return null;
          }

          let chargeItem: ChargeItem = this.akitaChargeItemQuery.getChargeItem(value.drugId)
          let whitelistedItem = this.akitaWhitelistedItemQuery.getEntity(chargeItem.id);
          if (!whitelistedItem) {
            whitelistedItem = this.akitaWhitelistedItemQuery.getEntity(chargeItem.genericSddCode)
          }
          if (!whitelistedItem) {
            this.whitelistedLogger.log('No whitelisted item', whitelistedItem)
            return null;
          }

          let medicalCoverageIds = caseCoverage.map(({medicalCoverageId}) => medicalCoverageId);

          if (whitelistedItem.limitOnlyToHSG) {
            let isPatientEnrolledInCurrentClinic = patientInfo.hsgEnrollment && patientInfo.hsgEnrollment?.enrolledClinicId === clinic.id;
            if (!isPatientEnrolledInCurrentClinic) {
              return { [ITEM_ONLY_AVAILABLE_FOR_HSG_PATIENT]: true };
            }
          }
          return null
        }),
        // distinctUntilChanged(),
        // tap(a => {
        //   control.setErrors(a)
        // })
      );
  }

  validateWhitelistedItemPrice(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.parent) return of(null)

    let whitelistedItemInfo$ = this.whitelistedItemService.withWhitelistedItemFor(control.parent.value.drugId).pipe(first());
    let attachedMedicalCoverages$ = this.attachedMedCovQuery.attachedMedicalCoverages$.pipe(first())
    let selectCaseCoverage = this.akitaCaseVisitQuery.selectCaseCoverage().pipe(first());

    return combineLatest([whitelistedItemInfo$, attachedMedicalCoverages$, selectCaseCoverage])
      .pipe(map(([whitelistedItemInfo, attachedMedicalCoverages, caseCoverage]) => {
        if (!whitelistedItemInfo) {
          this.whitelistedLogger.log('No whitelisted item info', whitelistedItemInfo)
          return null;
        }
        let {whitelistedItem, chargeItem} = whitelistedItemInfo;

        let value = control.parent.value;

        let medicalCoverageIds = caseCoverage.map(({medicalCoverageId}) => medicalCoverageId);
        let coverageInAllowedCoverages = medicalCoverageIds.some(medicalCoverageId => coverageCappedAllowed(whitelistedItem, medicalCoverageId));

        let isCapAllowed = whitelistedItem.itemRefId || coverageInAllowedCoverages;

        if (isCapAllowed) {
          let salesUom = value.salesUom;
          let itemQtyMultiplier = whitelistedItem.uomMatrix?.[salesUom];
          if (!itemQtyMultiplier) return { uomUnavailable: salesUom }
          if (Math.round(whitelistedItem.perUnitPriceCap.price * itemQtyMultiplier * value.purchaseQty * 100) < Math.round(value.adjustedUnitPrice * value.purchaseQty * 100)) {
            this.whitelistedLogger.log('Cap is not allowed', {
              whitelistedItem,
              caseCoverage,
              value,
              limit: whitelistedItem.perUnitPriceCap.price * itemQtyMultiplier * value.purchaseQty,
              calculatedTotalPrice: value.calculatedTotalPrice
            })
            return { max: { max: (whitelistedItem.perUnitPriceCap.price * itemQtyMultiplier * value.purchaseQty).toFixed(2)} }
          } else {
            this.whitelistedLogger.log('Cap is allowed', {
              whitelistedItem,
              caseCoverage,
              value,
              limit: whitelistedItem.perUnitPriceCap.price * itemQtyMultiplier * value.purchaseQty,
              calculatedTotalPrice: value.calculatedTotalPrice
            })
          }
        } else {
          this.whitelistedLogger.log('Cap is not allowed', {whitelistedItem, caseCoverage})
        }
        return null
      }))
  }

  buildDose(uom: string, quantity: number) {
    return this.fb.group({
      uom: this.fb.control(uom),
      quantity: this.fb.control(quantity),
    });
  }

  buildUnitPrice(price: number, taxIncluded: boolean) {
    return this.fb.group({
      price: this.fb.control(price),
      taxIncluded: this.fb.control(taxIncluded),
    });
  }

  buildCost(price: string, taxIncluded: boolean) {
    return this.fb.group({
      price: this.fb.control(price),
      taxIncluded: this.fb.control(taxIncluded),
    });
  }

  buildDoseInstruction(code: string, instruct: string) {
    return new FormGroup({
      code: new FormControl(code),
      instruct: new FormControl(instruct),
    });
  }

  buildInstruction(code: string) {
    return new FormGroup({
      code: new FormControl(code),
    });
  }

  buildPriceAdjustment(
    adjustedValue: string,
    paymentType: string,
    remark: string
  ) {
    return new FormGroup({
      adjustedValue: new FormControl(adjustedValue),
      paymentType: new FormControl(paymentType),
      remark: new FormControl(remark),
    });
  }

  buildAttachedMedicalCoverage(medicalCoverageId: string, planId: string) {
    return new FormGroup({
      medicalCoverageId: new FormControl(medicalCoverageId),
      planId: new FormControl(planId),
    });
  }

  buildVaccinationInfo(vaccineSubItems = []) {
    const vaccineFormGroupArray = vaccineSubItems.map(item => {
      return new FormGroup({
        code: new FormControl(item.code),
        description: new FormControl(item.description),
        doseId: new FormControl(''),
        dosages: new FormControl(item.dosages)
      })
    });

    return new FormGroup({
      givenDate: new FormControl(moment().format(DISPLAY_DATE_FORMAT), [Validators.required]),
      vaccineDosage: new FormControl(undefined),
      administrator: new FormGroup({
        regNo: new FormControl('', [Validators.required]),
        name: new FormControl(''),
        profession: new FormControl('DOCTOR', [Validators.required])
      }),
      administrationInfo: new FormGroup({
        site: new FormControl(''),
        route: new FormControl('', [Validators.required]),
        notGiven: new FormControl(false),
        reason: new FormControl('')
      }),
      multiVaccineSubItems: new FormArray(vaccineFormGroupArray),
      vaccinationSchedules: new FormArray([
        new FormGroup({
          vaccineId: new FormControl(''),
          doseId: new FormControl(''),
          scheduledDate: new FormControl(''),
          scheduledTime: new FormControl('')
        }),
      ]),
      conditionCodes: new FormControl([]),
      doseType: new FormControl(null),
      covidConditionCodes: new FormControl([]),
    });
  }

  // Bind Charge Item to Purchase Item
  bindChargeItemsToDispatchitemEntities(formArr) {
    let newPurchaseitems = [];
    if (formArr) {
      newPurchaseitems = formArr
        .filter(function(payload) {
          if (
            payload.value.drugId === '' ||
            payload.value.drugId === undefined ||
            payload.value.drugId == null
          )
            return false;
          return true;
        })
        .map(payload => {
          if (payload.value.drugId === '') return null;
          const isService =
            payload.value.dose && payload.value.instruction.code ? false : true;
          const tempItem = {
            itemId: payload.value.drugId,
            duration: payload.value.duration,
            quantity: payload.value.purchaseQty,
            oriTotalPrice: Math.round(payload.value.oriTotalPrice),
            batchNo: payload.value.batchNumber,
            expiryDate: payload.value.expiryDate,
            remarks: payload.value.remark,
            itemPriceAdjustment: {
              adjustedValue: Math.round(
                payload.value.priceAdjustment.adjustedValue
              ),
              paymentType:
                payload.value.priceAdjustment.paymentType || 'DOLLAR',
              remark: payload.value.priceAdjustment.remark,
            },
            excludedCoveragePlanIds:
              payload.value.excludedCoveragePlanIds || [],
          };
          if (!isService) {
            tempItem['instruct'] = payload.value.instruction.code;
            tempItem['dosageUom'] = payload.value.dose.uom;
            tempItem['dosage'] = payload.value.dose.quantity;
            tempItem['dosageInstruction'] =
              payload.value.dosageInstruction.code;
          }
          return tempItem;
        });
    }
    return newPurchaseitems;
  }

  buildChargeDetailsItem(
    itemId: string,
    excludedPlans: any[],
    quantity?: number
  ) {
    return new ChargeDetailsItem(itemId, excludedPlans, quantity);
  }

  disableFieldsBasedOnItem(prescriptionItem: FormGroup, item) {
    const instructionCode = prescriptionItem.get('instruction').get('code');
    const doseUom = prescriptionItem.get('dose').get('uom');
    const doseQty = prescriptionItem.get('dose').get('quantity');
    const duration = prescriptionItem.get('duration');
    const dosageInstruction = prescriptionItem.get('dosageInstruction');
    const dosageInstructionInstruct = prescriptionItem
      .get('dosageInstruction')
      .get('instruct');
    const expiryDate = prescriptionItem.get('expiryDate');
    const batchNo = prescriptionItem.get('batchNumber');

    if (this.isService(item)) {
      // If it is a service , disable
      if(this.isVaccination(item)){
        // dosageInstruction.reset();
      } else{
        if (this.isDrugPrintLabel(item)) {
          this.resetAndEnable(dosageInstruction);
        } else {
          this.resetAndDisable(dosageInstruction);
        }
      }

      if (this.isDrugPrintLabel(item) && !this.isVaccination(item)) {
        this.resetAndEnable(instructionCode);
        this.resetAndEnable(doseUom);
        this.resetAndEnable(doseQty);
        this.resetAndEnable(duration);
      } else {
        this.resetAndDisable(instructionCode);
        this.resetAndDisable(doseUom);
        this.resetAndDisable(doseQty);
        this.resetAndDisable(duration);
      }

      /*  if (this.isVaccination(item)) {
        this.resetAndEnable(expiryDate);
        this.resetAndEnable(batchNo);
      } else {
        this.resetAndDisable(expiryDate);
        this.resetAndDisable(batchNo);
      } */
    } else {
      // If it is a drug, enable all
      this.resetAndEnable(dosageInstruction);
      this.resetAndEnable(instructionCode);
      this.resetAndEnable(doseUom);
      if (this.dosageInstructionHasHash(dosageInstructionInstruct)) {
        this.resetAndEnable(doseQty);
      }
      this.resetAndEnable(duration);
      // this.resetAndEnable(expiryDate);
      // this.resetAndEnable(batchNo);
    }

    if (item && item.inventoried && item.trackingCode) {
      this.resetAndEnable(expiryDate);
      this.resetAndEnable(batchNo);
    } else {
      this.resetAndDisable(expiryDate);
      this.resetAndDisable(batchNo);
    }
  }

  dosageInstructionHasHash(dosageInstruction: AbstractControl) {

    if (dosageInstruction) {
      if (dosageInstruction.value) {
        return dosageInstruction.value.includes('#') ? true : false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  disableAllFields(prescriptionItem: FormGroup) {
    const drugId = prescriptionItem.get('drugId');
    const instructionCode = prescriptionItem.get('instruction').get('code');
    const doseUom = prescriptionItem.get('dose').get('uom');
    const doseQty = prescriptionItem.get('dose').get('quantity');
    const duration = prescriptionItem.get('duration');
    const dosageInstruction = prescriptionItem.get('dosageInstruction');
    const purchaseQty = prescriptionItem.get('purchaseQty');
    const adjustedTotalPrice = prescriptionItem.get('adjustedTotalPrice');
    const batchNumber = prescriptionItem.get('batchNumber');
    const remark = prescriptionItem.get('remark');
    const adjPriceRemark = prescriptionItem
      .get('priceAdjustment')
      .get('remark');

    const doseQuantity = prescriptionItem.get('dose').get('quantity');

    const expiryDate = prescriptionItem.get('expiryDate');

    this.disable(drugId);
    this.disable(dosageInstruction);
    this.disable(instructionCode);
    this.disable(doseUom);
    this.disable(doseQty);
    this.disable(duration);
    this.disable(expiryDate);
    this.disable(purchaseQty);
    this.disable(adjustedTotalPrice);
    this.disable(batchNumber);
    this.disable(remark);
    this.disable(adjPriceRemark);
    this.disable(doseQuantity);
  }

  disable(control: AbstractControl) {
    control.disable({ emitEvent: false });
  }

  resetAndDisable(control: AbstractControl) {
    control.reset();
    control.disable({ emitEvent: false });
  }

  resetAndEnable(control: AbstractControl) {
    // control.reset();
    control.enable({ emitEvent: false });
  }

  isDrugPrintLabel(item) {
    return get(item, 'printDrugLabel', false);
  }

  isService(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType !== 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  isDrug(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  isVaccination(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'VACCINATION') {
        return true;
      } else {
        return false;
      }
    }
  }

  isDrugOrVaccine(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'VACCINATION' || itemType === 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  instructionSearchFn(term: string, option) {
    term = term.toLocaleLowerCase();
    let hasWhiteSpace = term.indexOf(' ') > -1 ? true : false;

    // If string is less than 4 characters and does not contain space,
    // search only through code

    if (!hasWhiteSpace) {
      if (term.length < 4) {
        return option.code.toLocaleLowerCase().indexOf(term) > -1;
      }
    }
    term = term.trim();
    return (
      option.instruct.toLocaleLowerCase().indexOf(term) > -1 ||
      option.code.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  checkDrugAllergies(id) {
    const errorArray = [];
    this.apiPatientInfoService
      .checkAllergies(this.akitaPatientAppQuery.getId(), id)
      .subscribe(
        res => {
          let isAllergic;
          if (res.payload && res.payload.allergies) {
            // assume only checking for 1 drug
            const allergyFound = res.payload.allergies.find(x => {
              return x === id[0];
            });
            if (allergyFound) {
              isAllergic = { id: id[0], allergic: true };
            } else {
              isAllergic = { id: id[0], allergic: false };
            }

            this.drugAllergy.next(isAllergic);
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error['message']));
        }
      );
  }

  getDrugAllergyAlert() {
    return this.drugAllergy;
  }

  isSfl(id: any) {
    return this.attachedMedCovQuery.getValue().coverage
        .flatMap(a => a.coveragePlans)
        .some((a: any) => a.isSflChas) &&
      !!this.findSflData(id)
  }

  findSflData(id: any) {
    return !!id && this.akitaSflItemQuery.getEntity(id);
  }
}

export function validateInventory(control: FormControl) {
  if (control.value === UNAVAILABLE_INVENTORY) {
    return { invalidInventory: { message: control.value } };
  } else if (control.value === INSUFFICIENT_STOCK) {
    return { invalidInventory: { message: control.value } };
  } else if (control.value === SEPARATE_BATCH_AVAILABLE) {
    return { invalidInventory: { message: control.value } };
  }
}

export function mulitplierValidator(multiplier: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return (control.value * 1000) % (multiplier * 1000) !== 0
      ? { multiplierError: { multiplier: multiplier } }
      : null;
  };
}
