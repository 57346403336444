export default class PatientHsgInfo {
    carePlanAvailable: boolean;
    carePlanNextCheckUpDate: string;
    carePlanDueStatus?: string;

    constructor(
        carePlanAvailable: boolean,
        carePlanNextCheckUpDate: string,
        carePlanDueStatus?: string,
    ) {
        this.carePlanAvailable = carePlanAvailable || false;
        this.carePlanNextCheckUpDate = carePlanNextCheckUpDate || null;
        this.carePlanDueStatus = carePlanDueStatus || null;
    }
}

export enum CarePlanDueStatus {
    NEAR_DUE = 'NEAR_DUE',
    DUE = 'DUE',
    OVERDUE = 'OVERDUE',
}