import { Injectable } from '@angular/core';

import { ApiPatientNehrService } from './api-patient-nehr.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class PatientNehrService {

  private currentlyOpenedNehrTab: Window;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _apiPatientNehrService: ApiPatientNehrService,
  ) { }

  public setCurrentlyOpenedNEHRTab(tab: Window): void {
    this.currentlyOpenedNehrTab = tab;
  }

  public getCurrentlyOpenedNEHRTab(): Window | null {
    return this.currentlyOpenedNehrTab ? this.currentlyOpenedNehrTab : null;
  }

  public logoutNehrPatient(isDoctor: boolean, isNehrClinic: boolean): void {
    if (isDoctor && isNehrClinic) {
      const currentTab = this.getCurrentlyOpenedNEHRTab();
      if (currentTab) {
        currentTab.location.href = this._apiPatientNehrService.getLogoutNehrPatienUrl();
        this.closeTab(currentTab);
      } else {
        const newTab = window.open('', 'nehrPreviewTab');
        if (newTab) {
          newTab.document.open();
          newTab.location.href = this._apiPatientNehrService.getLogoutNehrPatienUrl();
          this.closeTab(newTab);
        } else {
          this._alertService.error('Browser Pop-up blocked! Please allow pop-ups on your browser.');
        }
      }
    }
  }

  private closeTab(tab: Window): void {
    setTimeout(() => {
      tab.close();
    }, 3000);
  }
}