<div class="row mb-2">
    <div class="col-md-12">
        <div class="col-md-12" style="text-align: right;">
            <span>Last synced : {{plansSyncTime}}</span>
        </div>
        <div class="card-custom">
            <div class="card-header">Plan Listing </div>
            <table class="custom-table custom-table-light">
                <thead>
                    <tr>
                        <th>No</th>
                        <th >Plan date</th>
                        <th >Next appt.</th>
                        <th>Clinic</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        *ngFor="let plan of plans; let i = index;" 
                        [ngClass]="plan.selected && plan.selected === true ? 'active-tr' : ''"
                        (click)="onView(plan, $event)">
                        <td>{{ i + 1 }}</td>
                        <td>{{ getDateString(plan.visitDate || plan.submittedDateTime) }}</td>
                        <td>{{ getDateString(plan.nextCheckUpDate) }}</td>
                        <td>{{ plan.hsgId ? plan.enrolledClinicName : getClinicName(plan.clinicId) }}</td>
                        <td>{{ plan.healthPlanStatus }}</td>
                        <td (click)="$event.stopPropagation()">
                            <div *ngIf="plan.healthPlanStatus !== 'NEW' && (!plan.hsgId || (plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED'))"
                                class="wrapper-table-action"
                                dropdown 
                                container="body" 
                                [autoClose]="true">
                                <img src="/assets/svg/left-panel/settings-primary.svg"  class="img-table-action" dropdownToggle>
                                <ul 
                                    id="dropdown-basic" 
                                    *dropdownMenu 
                                    class="dropdown-menu action-drop-down"
                                    role="menu" 
                                    aria-labelledby="basic-link">
                                    <li *ngIf="!plan.hsgId" role="menuitem" (click)="onPrintPlan(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            {{plan.healthPlanStatus === 'SUBMIT' ? 'Print' : 'Print Draft'}}
                                        </a>
                                    </li>
                                    <li role="menuitem" *ngIf="plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED'" (click)="onDeletePlan(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            Delete Plan
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div class="col-md-12">
        <div class="card-custom wrapper-conditions">
            <div class="card-header">Chronic Disease Management</div>
            <p class="sub-title ml-2 mb-2">Tick the condition(s) that the patient has:</p>
    
            <div class="row ml-2">
                <div class="col-md-3 checkbox form-check" *ngFor="let condition of patientConditions">
                    <input type="checkbox" (ngModelChange)="onConditionCheck($event, condition)" [ngModel]="condition.selected"
                    [disabled]="!enableConditions">
                    <label>{{ condition.name }}</label>
                </div>
            </div>
        </div>
    </div> -->
</div>