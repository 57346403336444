import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StoreService } from '../../../services/store.service';
import { AlertService } from '../../../services/alert.service';
import { ApiPatientInfoService } from '../../../services/api-patient-info.service';
import { AkitaPatientStoreService } from '../../../services/states/akita-patient/akita-patient-store.service';
import { HsgPateintCondtionService } from '../../../views/components/nhps/hsg/hsg-patient-conditions/hsg-pateint-condtion.service';

import { AkitaPatientAppQuery } from '../../../services/states/akita-patient/akita-patient-app.query';

import { BsModalService } from 'ngx-bootstrap';
import { cloneDeep } from 'lodash'

import { PatientSflDetailsModalComponent } from './patient-sfl-details-modal/patient-sfl-details-modal.component';

import { SFLHistoryList, createSFL } from '../../../model/SFLItem';
import { DISPLAY_DATE_FORMAT, SFL_TEST_ORDER } from '../../../constants/app.constants';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-detail-sfl',
  templateUrl: './patient-detail-sfl.component.html',
  styleUrls: ['./patient-detail-sfl.component.scss']
})
export class PatientDetailSflComponent implements OnInit {
  @Input() patientInfo;
  @Input() historyInfo;
  @Input() careReportFlow;
  @Input() patientId;
  @Input() patientGroupId;
  sflScreening: any[] = [];
  addFormGroup: FormGroup;
  patientSfl: SFLHistoryList[] = [];
  patientSflFormGroup: FormGroup;
  allScreeningTypes: any[] = [];
  screeningSubTypes: any[] = [];
  screeningTypes: any[] = [];
  followUpTypes: any[] = [];
  allScreeningExceptionalConditions: any[] = [];
  testOrders = SFL_TEST_ORDER;
  followUpOutcomes: any[] = [];
  hsgSflDataSub: Subscription;
  groupedSflsForCareReport: any = {};
  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private akitaPatientStore: AkitaPatientStoreService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private apiPatientInfoService: ApiPatientInfoService,
    private hsgPateintCondtionService: HsgPateintCondtionService,
  ) {
    this.akitaPatientAppQuery.patientSfl$.pipe(takeUntil(this.componentDestroyed)).subscribe(res => {
      this.patientSfl = [];
      res.forEach(v => {
        this.patientSfl.push(createSFL(v));
      });
      this.initSflFormGroup();
    });
    this.addFormGroup = this.fb.group({
      id: '',
      screenDate: ['', Validators.required],
      screeningType: ['', Validators.required],
      testType: ['', Validators.required],
      testOrder: ['', Validators.required],
      followUpOutcome: '',
      screeningOutcome: '',
      followUpDate: '',
      visitId: '',
      conditionCodes: []
    });
   }

  ngOnInit(): void {
    this.subscribeFormGroupChange();
    this.populateData();
    this.hsgSflDataSub = this.hsgPateintCondtionService.getSflDetails().subscribe({
      next: result => {
        this.sflScreening = result;
        if (this.careReportFlow) {
          this.groupedSflsForCareReport = this.groupBy(this.sflScreening, 'visitDate');
        }
      }
    });
  }

  groupBy(array: any[], key) {
    return array.reduce((result, currentValue) => {
      let groupKey = currentValue[key] as string;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  }

  getVisitNumbers(vaccines: any[]) {
    return vaccines.map(v => v.visitNumber).join(', ');
  }

  getDate(dateString) {
    if (dateString) {
      if (dateString.includes('T')) {
        return moment(dateString).format(DISPLAY_DATE_FORMAT);
      } else {
        return moment(dateString, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
      }
    } else {
      return dateString;
    }
  }

  private populateData() {
    this.getSFLSetupMapping();
  }

  private getSFLSetupMapping() {
    this.allScreeningTypes = this.store.SFLSetupData.find(types => types.name === 'SCREENING_TYPE').values;
    this.screeningSubTypes = this.store.SFLSetupData.find(types => types.name === 'SCREENING_SUB_TYPE').values;
    this.followUpOutcomes = this.store.SFLSetupData.find(types => types.name === 'FOLLOW_UP_OUTCOME').values;
    this.followUpTypes = this.store.SFLSetupData.find(types => types.name === 'FOLLOW_UP_TYPE').values;
    this.allScreeningExceptionalConditions = this.store.SFLSetupData.find(types => types.name === 'SCREENING_EXCEPTIONAL_CONDITION').values;
    //Filter
    this.screeningTypes = this.allScreeningTypes.filter(item => !(item.externalCode === 'H' || item.externalCode === 'D'))
  }

  showEditSflDetails(sfl = null){
    const initialState = {
      title: 'Sfl additional details',
      addFormGroup: sfl ? cloneDeep(sfl) : this.addFormGroup,
      isEdit: sfl ? true : false,
      screeningTypes: this.screeningTypes,
      followUpOutcomes: this.followUpOutcomes,
      allScreeningTypes: this.allScreeningTypes,
      screeningSubTypes: this.screeningSubTypes,
      testOrders: this.testOrders,
      allScreeningExceptionalConditions: this.allScreeningExceptionalConditions,
      patientId: this.patientId,
      patientGroupId: this.patientGroupId,
      careReportFlow: this.careReportFlow,
    };

    this.modalService.show(PatientSflDetailsModalComponent, {
      initialState,
      class: 'modal-lg custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  subscribeFormGroupChange(){
    this.subscribeSflDetailAdd();
  }

  subscribeSflDetailAdd() {
    this.alertService.getSflDetailsAddedEvent().subscribe(res => {
      this.addFormGroup.reset();
      this.addFormGroup.patchValue(
        {
          placeGiven: this.store.clinicCode,
          addedByClinicId:this.store.getClinicId(),
          isEditable : true
        },
        { emitEvent: false }
      );
      this.addFormGroup.markAsUntouched();
      this.akitaPatientStore.getPatientInfo();
    });
  }

  initSflFormGroup(){
    this.patientSflFormGroup = this.fb.group({
      sflFormArr : this.fb.array(
        this.patientSfl.map(sflItem=> this.createSflFormGroup(sflItem))
      )
    })
  }

  createSflFormGroup (sflItem) : any{
    let addFormGroup = this.fb.group({
      id: sflItem.id,
      visitId: [sflItem.visitId],
      screenDate: [sflItem.screenDate, Validators.required],
      screeningType: [sflItem.screeningType, Validators.required],
      testType: [sflItem.testType, Validators.required],
      testOrder: [sflItem.testOrder, Validators.required],
      followUpOutcome: [sflItem.followUpOutcome, sflItem.testOrder == 4 ? Validators.required : null],
      screeningOutcome: [sflItem.screeningOutcome],
      followUpDate: [sflItem.followUpDate],
      conditionCodes: [sflItem.conditionCodes]
    })
    return addFormGroup;
  }

  deleteItem(sflAssociateId, index) {
    const isDelete = confirm(this.careReportFlow ? `Deleting SFL from care report will reflect in patient profile.\n\nDo you want to continue to Delete?` : 'Delete this sfl?');
    const patientId = this.akitaPatientAppQuery.getId() || this.patientId;
    if (isDelete) {
      this.apiPatientInfoService
        .removePatientSflAssociation(patientId, sflAssociateId)
        .subscribe(
          res => {
            alert('SFL has been removed successfully.');
            if(this.careReportFlow) {
              this.sflScreening.splice(index, 1);
              this.alertService.sendReloadCareReportEvent({patientId: this.patientId , patientGroupId: this.patientGroupId});
            } else {
              this.akitaPatientStore.getPatientInfo();
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    }
  }

  getScreeningTypeName(code) {
    let screeningType = '';
    if (this.allScreeningTypes) {
      screeningType = this.allScreeningTypes.find(sfl => sfl.cmsCode === code)?.cmsName || '';
    }
    return screeningType;
  }

  getScreeningSubTypeName(code) {
    let screeningSubType = '';
    if (this.screeningSubTypes) {
      screeningSubType = this.screeningSubTypes.find(sfl => sfl.cmsCode === code)?.cmsName || '';
    }
    return screeningSubType;
  }

  getTestOrder(code) {
    let testOrder;
    if (this.testOrders) {
      testOrder = this.testOrders.find(sfl => sfl.value == code)?.label || '';
    }
    return testOrder;
  }

  getFollowupOutcome(code) {
    let followUpOutcome = '';
    if (this.followUpOutcomes) {
      followUpOutcome = this.followUpOutcomes.find(sfl => sfl.cmsCode === code)?.cmsName || '';
    }
    return followUpOutcome;
  }

  showEditSflDetailsCareReport(item){
    const sflItem = this.createSflFormGroup(item);
    this.showEditSflDetails(sflItem);
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    if (this.hsgSflDataSub) {
      this.hsgSflDataSub.unsubscribe();
    }
  }
}