import { FormControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function CustomMinDateValidator(minDate: Date): ValidatorFn {
    return function validate(control: FormControl) {

        if (control.value) {
            const date = moment(control.value);
            if (date.isBefore(minDate)) {
                return {
                    invalidDate: true,
                };
            }
        }

        return null;
    };
}