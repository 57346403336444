import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { StoreService } from '../../../../../../services/store.service';

import { ExercisePlanValue } from '../../../../../../model/care-plan/CarePlanExerciseRecommendation';
import { VaccinationRecommendation } from '../../../../../../model/care-plan/CarePlanVaccination';
import { DietRecommendation } from '../../../../../../model/care-plan/CarePlanDietRecommendation';

import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../../../constants/app.constants';

import { CustomMinDateValidator } from '../../../../../../validators/CustomDateValidator';

import { groupBy } from 'lodash';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CarePlanService {
  private formGroup: FormGroup;

  private isFormValidSub = new BehaviorSubject<boolean>(true);
  private updateCarePlanFormSub = new Subject<boolean>();
  private synCarePlanSub = new Subject<boolean>();

  public updatedPatientConditionsFromCDM: Array<any> = [];
  private updatedPatientConditionsFromCDMSub = new Subject<Array<string>>();

  constructor(
    private store: StoreService,
  ) { }

  public initCarePlanFormGroup(): void {
    this.formGroup = new FormGroup({
      id: new FormControl(null),
      healthPlanStatus: new FormControl(null, [Validators.required]),
      responseStatus: new FormControl(null),
      visitId: new FormControl(null, [Validators.required]),
      patientId: new FormControl(null, [Validators.required]),
      cmsConditionsForHSG: new FormArray([]),
      healthGoals: new FormArray([]),
      vaccinationRecommendationList: new FormArray([]),
      exerciseRecommendationList: new FormArray([]),
      dietRecommendationList: new FormArray([]),
      other: new FormGroup({}),
      otherHealthGoalDetails: new FormGroup({
        thingsToStartToday: new FormControl(null, [Validators.maxLength(1000)]),
        nextCheckUpDate: new FormControl(
          moment().add(6, 'months').format(DISPLAY_DATE_FORMAT),
        ),
        recordOfDiscussion: new FormControl(null),
        nextCheckUpAppointmentId: new FormControl(null),
      }),
    });

    this.formGroup.valueChanges.subscribe(result => {
      // If no formGroup, no need to check for validatio, therefore, returning true as it's always valid
      this.isFormValidSub.next(!this.formGroup ? true : this.formGroup.valid);
    });
  }

  public getCarePlanFormValidStatus(): Observable<boolean> {
    return this.isFormValidSub.asObservable();
  }

  public removeFormGroup(): void {
    this.formGroup = undefined;
    this.isFormValidSub.next(true);
  }

  public getCarePlanFormGroup(): FormGroup {
    return this.formGroup;
  }

  public getHsgCarePlanForm(isComplete: boolean = false): any {
    if (!this.formGroup) {
      return null;
    }

    const hsgFormValue = this.formGroup.value;
    const vaccinationRecommendationValues = this.onSubmitVaccinationRecommendation(isComplete);
    const dietRecommendationValues = this.onSubmitDietRecommendation(isComplete);
    const exerciseRecommendationValues = this.onSubmitExerciseRecommendation(isComplete);
    const gealthGoalsValues = this.onSubmitHealthGoals(isComplete);
    const otherHealthGoalDetailsValues = this.formGroup.get('otherHealthGoalDetails').value;

    const payload = {
      id: hsgFormValue.id ? hsgFormValue.id : null,
      templateVersion: 2,
      visitId: hsgFormValue.visitId,
      patientId: hsgFormValue.patientId,
      clinicId: this.store.getClinicId(),
      thingsToStartToday: otherHealthGoalDetailsValues.thingsToStartToday,
      nextCheckUpDate: this.getNextCheckupDate(otherHealthGoalDetailsValues.nextCheckUpDate),
      recordOfDiscussion: otherHealthGoalDetailsValues.recordOfDiscussion,
      healthGoals: [
        ...this.getHealthGoalsSubmissionData(gealthGoalsValues),
        this.getExerciseSubmissionData(exerciseRecommendationValues),
      ],
      plannedItemDetails: {
        diet: this.getDietSubmissionData(dietRecommendationValues),
        vaccination: this.getVaccinationSubmissionData(vaccinationRecommendationValues),
      },
      healthPlanStatus: !hsgFormValue.healthPlanStatus || hsgFormValue.healthPlanStatus === 'NEW' ? 'DRAFT' : hsgFormValue.healthPlanStatus,
      cmsConditionsForHSG: this.getPatientConditions(),
      submittedDateTime: hsgFormValue.submittedDateTime ? moment(hsgFormValue.submittedDateTime).format(DB_FULL_DATE_FORMAT) : moment().format(DB_FULL_DATE_FORMAT),
      responseStatus: hsgFormValue.responseStatus ? hsgFormValue.responseStatus : null,
      nextCheckUpAppointmentId: hsgFormValue.nextCheckUpAppointmentId ? hsgFormValue.nextCheckUpAppointmentId : '',
      completableDraft: this.formGroup.valid,
    }

    return payload;
  }

  private getNextCheckupDate(value: any): string {
    if (value === '') {
      return null;
    }

    if (value instanceof Date) {
      return moment(value).format(DISPLAY_DATE_FORMAT)
    } else if (typeof value === 'string') {
      return value;
    } else {
      return null
    }
  }

  private getPatientConditions(): Array<string> {
    const patientConditionsValue = this.formGroup.getRawValue().cmsConditionsForHSG as Array<any>;
    return patientConditionsValue.filter(item => item.selected && item.code).map(item => item.code);
  };

  private getHealthGoalsSubmissionData(values: Array<{ goalId: string; values: Array<ExercisePlanValue> }>): any {
    const originalHsgHealthPlans = this.store.originalHsgHealthPlans;

    const submissionData = [];

    originalHsgHealthPlans.forEach(healPlan => {
      const valueForHealthPlan = values.find(value => value.goalId === healPlan.goalId);
      if (valueForHealthPlan) {
        submissionData.push(
          {
            hsgGoalId: healPlan.goalId,
            workflow: healPlan.workflow,
            values: valueForHealthPlan.values,
          }
        )
      }
    });

    return submissionData;
  }

  private getExerciseSubmissionData(values: Array<ExercisePlanValue>): any {
    return {
      workflow: this.store.originalHsgExerciseRecommendationsFromPayload.workflow,
      values: values.filter(value => value.placeholder !== 'selected' && value.placeholder !== 'placeholder' && value.placeholder !== 'hsgMeasureName'),
    }
  }

  private getDietSubmissionData(values: Array<ExercisePlanValue>): any {
    return {
      availableItems: this.store.originalHsgDietRecommendations,
      recordedValues: values
    }
  }

  private getVaccinationSubmissionData(values: Array<ExercisePlanValue>): any {
    return {
      availableItems: this.store.originalHsgVaccinationRecommendations,
      recordedValues: values
    }
  }

  // Vaccination Recommendation
  private onSubmitVaccinationRecommendation(isComplete: boolean): Array<ExercisePlanValue> {
    let isAnyFormInvalid: boolean = false;

    const formArray = this.formGroup.get('vaccinationRecommendationList') as FormArray;
    const formControls = formArray.controls as Array<FormGroup>;

    formControls.forEach(formGroup => {
      if (isComplete && formGroup.invalid) {
        isAnyFormInvalid = true;
        return;
      }
    });

    if (isComplete && isAnyFormInvalid) {
      formControls.forEach(formGroup => formGroup.markAllAsTouched());
      return;
    }

    const values: Array<ExercisePlanValue> = [];

    formControls.forEach(formGroup => {
      const formValue = formGroup.value as VaccinationRecommendation;

      if (formValue.selected) {
        values.push(VaccinationRecommendation.toServer(formGroup.value))
      }
    });

    return values;
  }

  // Diet Recommendation
  private onSubmitDietRecommendation(isComplete: boolean): Array<ExercisePlanValue> {
    const formArray = this.formGroup.get('dietRecommendationList') as FormArray;
    const formControls = formArray.controls as Array<FormGroup>;

    const values: Array<ExercisePlanValue> = [];

    formControls.forEach(formGroup => {
      const formValue = formGroup.value as DietRecommendation;

      if (formValue.selected) {
        values.push(DietRecommendation.toServer(formGroup.value))
      }
    });

    return values;
  }

  // Exercise Recommendation
  private onSubmitExerciseRecommendation(isComplete: boolean): Array<ExercisePlanValue> {
    const formArray = this.formGroup.get('exerciseRecommendationList') as FormArray;
    const formArrayControls = formArray.controls as Array<FormGroup>;

    if (isComplete && formArray.invalid) {
      formArrayControls.forEach(formGroup => formGroup.markAllAsTouched());
      return;
    }

    const values: Array<ExercisePlanValue> = [];

    (formArray.value as Array<any>).forEach(formValue => {
      if (!Object.is(formValue, {})) {
        if (Object.keys(formValue).includes('comment')) {
          let data: any = {
            placeholder: formValue.placeholder,
            value: formValue.hsgMeasureName,
          }

          if (formValue.comment && formValue.comment !== '') {
            data.contextValue = formValue.comment;
          }

          values.push(data);
        } else {
          for (const [key, value] of Object.entries(formValue)) {
            values.push({
              placeholder: key,
              value: value as any,
            });
          }
        }
      }
    });

    return values;
  }

  // Health Goals
  private onSubmitHealthGoals(isComplete: boolean): Array<{ goalId: string; values: Array<ExercisePlanValue> }> {
    const formArray = this.formGroup.get('healthGoals') as FormArray;
    const formArrayControls = formArray.controls as Array<FormGroup>;

    if (isComplete && formArray.invalid) {
      formArrayControls.forEach(formGroup => formGroup.markAllAsTouched());
      return;
    }

    const groupedFormArray = groupBy(formArray.value, value => value.goalId);

    const values: Array<{ goalId: string; values: Array<ExercisePlanValue> }> = [];

    for (const [key, value] of Object.entries(groupedFormArray)) {
      values.push(this.extractHealthGoalValues(key, (value as Array<any>)));
    }

    return values;
  }

  private extractHealthGoalValues(goalId: string, formValues: Array<any>): { goalId: string; values: Array<ExercisePlanValue> } {
    let data: { goalId: string; values: Array<ExercisePlanValue> } = {
      goalId: '',
      values: [],
    };

    data.goalId = goalId;

    formValues.forEach(formValue => {
      for (const [key, value] of Object.entries(formValue)) {
        if (key !== 'goalId' && key !== 'selected') {
          if (key === 'multiChoice') {
            (value as Array<any>).forEach(value => {
              let payload;

              if (value.isChecked) {
                payload = {
                  placeholder: value.key,
                  value: value.value,
                }

                if (value.captureContext && value.captureContext.replace(/\s/g, '').length > 0) {
                  payload.contextValue = value.captureContext;
                }

                data.values.push(payload);
              }
            });
          } else {
            data.values.push({
              placeholder: key,
              value: value instanceof Date ? moment(value).format(DISPLAY_DATE_FORMAT) : value as any,
            });
          }
        }
      }
    });

    return data;
  }

  public setRefreshCarePlanListing(): void {
    this.updateCarePlanFormSub.next(true);
  }

  public getRefreshCarePlanListing(): Observable<boolean> {
    return this.updateCarePlanFormSub.asObservable();
  }

  public setUpdatedPatientConditions(conditions: Array<string>): void {
    this.updatedPatientConditionsFromCDM = conditions;
    this.updatedPatientConditionsFromCDMSub.next(conditions);
  }
  
  public getUpdatedPatientConditions(): Observable<Array<string>> {
    return this.updatedPatientConditionsFromCDMSub.asObservable();
  }

  public synCarePlan(): void {
    this.synCarePlanSub.next(true);
  }

  public getSyncCarePlan(): Observable<boolean> {
    return this.synCarePlanSub.asObservable();
  }
}