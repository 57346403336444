import { VaccinationItem, createVaccinationPrintItem } from './../../../objects/state/Vaccination';
import { AkitaPatientStoreService } from './../../../services/states/akita-patient/akita-patient-store.service';
import { AkitaPatientAppQuery } from './../../../services/states/akita-patient/akita-patient-app.query';
import { PrintTemplateService } from './../../../services/print-template.service';
import { Doctor } from './../../../objects/SpecialityByClinic';
import { SelectItemOptions } from './../../../objects/SelectItemOptions';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  DISPLAY_DATE_FORMAT,
  SILVER_CROSS_GROUP,
  DEFAULT_TEMPLATE_CLINIC_NAME,
  SILVER_CROSS_CLINIC_NAME,
  SILVER_CROSS_CLINIC_LETTERHEAD,
  DEFAULT_TEMPLATE_CLINIC_LETTERHEAD,
} from '../../../constants/app.constants';

import { StoreService } from '../../../services/store.service';
import { AlertService } from '../../../services/alert.service';
import { ApiCmsManagementService } from '../../../services/api-cms-management.service';
import { NgxPermissionsService } from 'ngx-permissions';

import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray, FormControl} from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ApiPatientInfoService } from '../../../services/api-patient-info.service';
import DatePickerConfig from '../../../objects/DatePickerConfig';
import { UtilsService } from '../../../services/utils.service';
import { ClinicCalendarQuery } from '../../../state/appointments/event-types/clinic-calendar/clinic-calendar.query';
import { ApiAppointmentsService } from '../../../services/api-appointments.service';
import { BsModalService } from 'ngx-bootstrap';
import { PatientVaccineDetailsModalComponent } from './patient-vaccine-details-modal/patient-vaccine-details-modal.component';
import { AkitaClinicQuery } from '../../../services/states/akita-clinic.query';
import { Clinic } from '../../../objects/response/Clinic';
import { HsgPateintCondtionService } from '../../../views/components/nhps/hsg/hsg-patient-conditions/hsg-pateint-condtion.service';

@Component({
  selector: 'app-patient-detail-vaccination',
  templateUrl: './patient-detail-vaccination.component.html',
  styleUrls: ['./patient-detail-vaccination.component.scss'],
})
export class PatientDetailVaccinationComponent implements OnInit, OnDestroy {
  @Input() patientInfo;
  @Input() historyInfo;
  @Input() isPatientDetails;
  @Input() vaccineItems;
  @Input() isCareReport: boolean = false;
  @Input() patientId;
  @Input() patientGroupId;

  addFormGroup: FormGroup;
  patientVaccinationsFormGroup: FormGroup;
  nextDoseDateDatePickerConfig: DatePickerConfig;
  nextDoseInItemDateDatePickerConfig: DatePickerConfig;
  givenDateDatePickerConfig: DatePickerConfig;
  minDate: Date = new Date();

  vaccinations: Array<any> = [];
  dose = [];
  nextDoseVaccine = [];
  nextDoseVaccineInFormArr = [];
  patientVaccinations: VaccinationItem[] = [];
  groupedVaccinesForCareReport: any = {};

  vaccineId: AbstractControl;
  doseId: AbstractControl;
  scheduledDate: AbstractControl;
  scheduledTime: AbstractControl;

  private componentDestroyed: Subject<void> = new Subject();
  public vaccinationDetailsAdded: Subject<void> = new Subject();
  hsgVaccineDataSub: Subscription;
  isEditingMode: boolean = false;
  curentEditItem: FormGroup = null;
  availableTimesDropDownList: Array<string>;
  availableTimesDropDownListForAdd: Array<string>;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private alertService: AlertService,
    private printTemplateService: PrintTemplateService,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaPatientStore: AkitaPatientStoreService,
    private apiAppointmentsService: ApiAppointmentsService,
    private hsgPateintCondtionService: HsgPateintCondtionService,
    private modalService: BsModalService,    
    private akitaClinicQuery: AkitaClinicQuery,
  ) {
    this.nextDoseDateDatePickerConfig = new DatePickerConfig(
      'Next Dose Date',
      null,
      new Date(),
      'top',
      'top'
    );
    this.givenDateDatePickerConfig = new DatePickerConfig(
      null,
      new Date(),
      null,
      'top',
      'top'
    );
    this.addFormGroup = this.fb.group({
      vaccineId: [undefined, [Validators.required]],
      doseId: [undefined, [Validators.required]],
      doctorId: [undefined],
      givenDate: [undefined],
      placeGiven: [this.store.clinicCode, [Validators.required]],
      vaccineDosage: [undefined],
      vaccinationSchedules: this.fb.group({
        vaccineId: [undefined],
        doseId: [undefined],
        scheduledDate: [undefined],
        scheduledTime: [undefined],
      }),
      administrator: this.fb.group({
        regNo: [undefined, [Validators.required]],
        name: [undefined],
        profession: [undefined, [Validators.required]],
      }),
      administrationInfo: this.fb.group({
        site: [undefined, [Validators.required]],
        route: [undefined, [Validators.required]],
        notGiven: [false],
        reason: [undefined],
      }),
      subItems: this.fb.array([]),
      remarks: [undefined],
      addedByClinicId: this.store.getClinicId(),
      isEditable : true,
      conditionCodes : [null],
      doseType: [null],
      covidConditionCodes: [null]
    });

    this.vaccinations = this.store.vaccinationList;
    
    if (!this.vaccineItems) {
      this.akitaPatientAppQuery.patientVaccinations$.pipe(takeUntil(this.componentDestroyed)).subscribe(res => {
        this.patientVaccinations = [];
        res.forEach(v => {
          this.patientVaccinations.push(createVaccinationPrintItem(false, v));
        });
        this.initVaccFormGroup();
      });
    }

    this.nextDoseInItemDateDatePickerConfig = new DatePickerConfig(
      null,
      null,
      new Date(),
      'bottom',
      'bottom'
    );
  }

  getDisplayMsg(item) {
     return 'This record is not deletable as it is added by another clinic (' + this.isCareReport ? item.addedByClinicId : this.getClinic(item.get('addedByClinicId').value) + ')';
  }

  getClinic(clinicId: string) {
    if (!!clinicId) {
      const clinic = this.akitaClinicQuery.getEntity(clinicId) as Clinic
      return clinic && clinic.clinicCode;
    } else {
      return '';
    }
  }

  ngOnInit() {
    this.initialiseValues();
    this.subscribeFormGroupChange();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    if (this.hsgVaccineDataSub) {
      this.hsgVaccineDataSub.unsubscribe();
    }
  }

  initialiseValues() {
    if (this.vaccineItems) {
      this.patientVaccinations = [];
      
      if (this.isCareReport) {
        this.groupedVaccinesForCareReport = this.groupBy(this.vaccineItems, 'visitDate');
      }

      this.vaccineItems.forEach(item => {
        this.patientVaccinations.push(createVaccinationPrintItem(false, item));
      })
      this.initVaccFormGroup();
    }
    this.vaccineId = this.addFormGroup.get('vaccinationSchedules').get('vaccineId');

    this.doseId = this.addFormGroup.get('vaccinationSchedules').get('doseId');

    this.scheduledDate = this.addFormGroup.get('vaccinationSchedules').get('scheduledDate');
    this.scheduledTime = this.addFormGroup.get('vaccinationSchedules').get('scheduledTime');
  }

  groupBy(array: any[], key) {
    return array.reduce((result, currentValue) => {
      let groupKey = currentValue[key] as string;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  }

  getVisitNumbers(vaccines: any[]) {
    return vaccines.map(v => v.visitNumber).join(', ');
  }

  getDate(dateString) {
    if (dateString) {
      if (dateString.includes('T')) {
        return moment(dateString).format(DISPLAY_DATE_FORMAT);
      } else {
        return moment(dateString, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
      }
    } else {
      return dateString;
    }
  }

  getConditionDisplay(code) {
    if (code) {
      let exCond = this.store.exemptionConditions.find(exCond => code === exCond.code);
      if (exCond) {
        return exCond.value;
      }
    }
    return '';
  }

  subscribeFormGroupChange() {
    this.hsgVaccineDataSub = this.hsgPateintCondtionService.getVaccineDetails().subscribe({
      next: result => {
        this.vaccineItems = result;
        if (this.isCareReport) {
          this.patientVaccinations = [];
          this.groupedVaccinesForCareReport = this.groupBy(this.vaccineItems, 'visitDate');

          this.vaccineItems.forEach(item => {
            this.patientVaccinations.push(createVaccinationPrintItem(false, item));
          })
          this.initVaccFormGroup();
        }
      }
    });
    
    this.addFormGroup
      .get('vaccineId')
      .valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(vaccineId => {
        const vaccine = this.vaccinations.find(item => item.id === vaccineId);
        if (vaccine) {
          this.clearFields();
          this.populateDose(vaccine);

          this.addFormGroup.get('doseId').markAsTouched();
        }
      });

    this.vaccineId.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)), takeUntil(this.componentDestroyed))
      .subscribe(vaccineId => {
        this.doseId.patchValue(undefined);

        const vaccine = this.vaccinations.find(item => item.id === vaccineId);
        if (vaccine) {
          this.nextDoseVaccine = [...vaccine.dosages];
        }

        if (vaccineId) {
          this.scheduledDate.setValidators([Validators.required]);
          this.scheduledDate.markAsTouched();
          this.scheduledTime.setValidators([Validators.required]);
          this.scheduledTime.markAsTouched();
        } else {
          this.doseId.setValidators(null);
          this.scheduledDate.setValidators(null);
          this.scheduledTime.setValidators(null);
        }

        this.scheduledDate.updateValueAndValidity({ emitEvent: false });
        this.scheduledTime.updateValueAndValidity({ emitEvent: false });
      });

      this.addFormGroup && this.addFormGroup.get('vaccinationSchedules').get('scheduledDate').valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(date => {
        if(date){
          let d = moment(date, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
          const isValid = moment(d, DISPLAY_DATE_FORMAT).isValid();
          this.getAvailableTimesByClinicForAdd(d);
        }
      });
      this.subscribeVaccineDetailAdd();
  }

  clearFields() {
    this.addFormGroup.get('doseId').patchValue(undefined);
    this.vaccineId.patchValue(undefined);
    this.doseId.patchValue(undefined);
    this.scheduledDate.patchValue(undefined);
    this.scheduledTime.patchValue(undefined);
  }

  populateDose(data) {
    const tempDose = [];

    if (data.dosages) {
      data.dosages.map((value, index) => {
        if(!(value.status && value.status === 'INACTIVE')){
          tempDose.push(value);
        }
      });
    }

    this.dose = tempDose;
  }

  deleteItem(vassineAssociateId) {
    const isDelete = confirm('Delete this vaccination?');
    if (isDelete) {
      this.apiPatientInfoService
        .removePatientVaccineAssociation(this.akitaPatientAppQuery.getId() ? this.akitaPatientAppQuery.getId() : this.patientId, vassineAssociateId)
        .subscribe(
          res => {
            alert('Vaccination has been removed successfully.');
            this.isEditingMode = false;
            this.curentEditItem = null;
            if (!this.isCareReport) {
              this.akitaPatientStore.getPatientInfo();
            } else {
              this.alertService.sendReloadCareReportEvent({patientId: this.patientId , patientGroupId: this.patientGroupId});
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    }
  }

  convertUnixDateToDashFormat(date) {
    return moment(moment(date).format('L') || '-').format('DD-MM-YYYY');
  }

  disableVaccinePrint() {
    if (
      this.vaccinations.length !== 0 &&
      this.patientVaccinationsFormGroup &&
      this.patientVaccinationsFormGroup.get('vaccFormArr').value &&
      this.patientVaccinationsFormGroup.get('vaccFormArr').value.some(v => (v['isSelected'])) &&
      !this.patientVaccinationsFormGroup.get('vaccFormArr').value.some(v => (v['isSelected'] && 
      (v.administrator.profession !== 'DOCTOR' && (!v.doctorId || v.doctorId === 'Other/External'))))
    ) {
      return false;
    } else {
      return true;
    }
  }

  getDoseName(doseId) {
    let doseName = '';

    const vaccine = this.vaccinations.find(function(vaccine) {
      return vaccine.dosages
        ? vaccine.dosages.some(dose => dose.doseId === doseId)
        : null;
    });

    if (vaccine) {
      doseName =
        (vaccine.dosages.find(dose => dose.doseId === doseId) || { name: '' })
          .name || '';
    } else {
      doseName = '';
    }
    return doseName;
  }

  selectedCert($event) {}

  getVaccineName(vaccineId) {
    let vaccineName = '';

    if (this.vaccinations) {
      vaccineName =
        (
          this.vaccinations.find(vaccine => vaccine.id === vaccineId) || {
            name: '',
          }
        ).name || '';
    }
    return vaccineName;
  }

  onBtnPrintVCClicked() {
    let vaccines = this.patientVaccinationsFormGroup.get('vaccFormArr').value.filter(x => x.isSelected);

    this.printTemplateService.onBtnPrintVCClicked(this.patientInfo, vaccines);
  }

  updateLabel() {
    // this.printTemplateService.updateAllLabelTemplates();
  }

  getDoctorName(isId, doctorId) {
    if (isId) {
      if (doctorId !== 'Other/External') {
        const doctor = this.store.getDoctors().find(doctor => {
          return doctor.id === doctorId;
        });
        if (doctor) {
          return doctor.name;
        } else {
          return '';
        }
      } else if (doctorId === 'Other/External'){
          return 'Other/External';
      }else {
          return '';
      }
    } else {
      const doctor = this.store.getDoctors().find(doctor => {
        return doctor.mcr === doctorId;
      });
      if (doctor) {
        return doctor.name;
      } else {
        return '';
      }
    }
  }

  searchFn(term: string, option) {
    term = term.toLocaleLowerCase();
    return option.code.toLocaleLowerCase().indexOf(term) > -1 || 
           option.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  cancel(){
    this.isEditingMode = false;
    this.curentEditItem = null;
    this.initVaccFormGroup();
  }

  editItem(vaccination){
    if (this.isCareReport) {
      let controls: AbstractControl[] = (this.patientVaccinationsFormGroup?.get('vaccFormArr') as FormArray).controls;
      vaccination = controls.find(control => control.get('id').value === vaccination.id);
    }
    this.curentEditItem = vaccination;
    this.subscribeDateChange();
    this.isEditingMode = true;
    this.showEditVaccineDetails(vaccination)
  }

  showEditVaccineDetails(vaccination = null) {
    const initialState = {
      title: 'Vaccination additional details',
      addFormGroup: vaccination ? vaccination : this.addFormGroup,
      isEdit: vaccination ? true : false,
      isPatientDetails: this.isPatientDetails,
      patientId: this.patientId,
      isCareReport: this.isCareReport,
      patientGroupId: this.patientGroupId
    };

    this.modalService.show(PatientVaccineDetailsModalComponent, {
      initialState,
      class: 'modal-xl custom-modal',
      backdrop: 'static',
      keyboard: false,
    });

    this.modalService.onHidden.subscribe(res => {
      if (!vaccination) {
        this.resetAddFormGroup();
      }
    });
  }

  initVaccFormGroup(){
    this.patientVaccinationsFormGroup = this.fb.group({
      vaccFormArr : this.fb.array(
        this.patientVaccinations.map(vaccItem=> this.createVaccFormGroup(vaccItem.vaccination))
      )
    })
  }

  createVaccFormGroup(vaccItem): any {
    let addFormGroup = this.fb.group({
      isSelected: false,
      id: vaccItem.id,
      visitId: [vaccItem.visitId],
      vaccineId: [vaccItem.vaccineId, [Validators.required]],
      doseId: [vaccItem.doseId, [Validators.required]],
      doctorId: [vaccItem.doctorId],
      givenDate: [vaccItem.givenDate || ''],
      placeGiven: [this.store.clinicCode, [Validators.required]],
      vaccineDosage: [vaccItem.vaccineDosage],
      vaccinationSchedules: this.fb.group({
        vaccineId: [ vaccItem.vaccinationSchedules[0] && vaccItem.vaccinationSchedules[0].vaccineId],
        doseId: [ vaccItem.vaccinationSchedules[0] && vaccItem.vaccinationSchedules[0].doseId],
        scheduledDate: [ vaccItem.vaccinationSchedules[0] && vaccItem.vaccinationSchedules[0].scheduledDate],
        scheduledTime: [ vaccItem.vaccinationSchedules[0] && vaccItem.vaccinationSchedules[0].scheduledTime],
        nextDoseList: this.fb.array(this.getNextDoseList(vaccItem.vaccinationSchedules[0] && vaccItem.vaccinationSchedules[0].vaccineId))
      }),
      administrator: this.fb.group({
        regNo: [vaccItem.administrator ? vaccItem.administrator.regNo : '', [Validators.required]],
        name: [vaccItem.administrator ? vaccItem.administrator.name : ''],
        profession: [vaccItem.administrator ? vaccItem.administrator.profession : '', [Validators.required]],
      }),
      administrationInfo: this.fb.group({
        site: [vaccItem.administrationInfo ? vaccItem.administrationInfo.site : '', [Validators.required]],
        route: [vaccItem.administrationInfo ? vaccItem.administrationInfo.route : '', [Validators.required]],
        notGiven: [vaccItem.administrationInfo ? vaccItem.administrationInfo.notGiven : false],
        reason: [vaccItem.administrationInfo ? vaccItem.administrationInfo.reason : ''],
      }),
      subItems: this.fb.array(vaccItem.subItems ? vaccItem.subItems : []),
      remarks: [vaccItem.remarks],
      addedByClinicId: vaccItem.addedByClinicId,
      isEditable : vaccItem.addedByClinicId ? (vaccItem.addedByClinicId === this.store.getClinicId()) : true,
      conditionCodes : vaccItem.conditionCodes ? [vaccItem.conditionCodes] : null,
      doseType: vaccItem.doseType ? [vaccItem.doseType] : null,
      covidConditionCodes : vaccItem.covidConditionCodes ? [vaccItem.covidConditionCodes] : null,
    });
    return addFormGroup;
  }

  getNextDoseList(vaccineId: string): any[] {
    let doseList = [];
    if(vaccineId){
      const vaccine = this.vaccinations.find(item => item.id === vaccineId);
      if (vaccine) {
        doseList = [...vaccine.dosages];
      }
    }
    return doseList;
  }

  getNextDosesByVaccine(vaccine){
    if(vaccine.get('vaccinationSchedules') && 
      vaccine.get('vaccinationSchedules').get('nextDoseList') &&
      vaccine.get('vaccinationSchedules').get('nextDoseList').value){
        return vaccine.get('vaccinationSchedules').get('nextDoseList').value;
    }
    return [];
  }

  onChangeNextVaccine(vaccine){
    if(vaccine && vaccine.get('vaccinationSchedules').get('vaccineId') && vaccine.get('vaccinationSchedules').get('vaccineId').value){
      const vaccId = vaccine.get('vaccinationSchedules').get('vaccineId').value;
      const vac = this.vaccinations.find(item => item.id === vaccId);
      if (vac) {
        const nextDoseListFA = <FormArray> vaccine.get('vaccinationSchedules').get('nextDoseList');
        nextDoseListFA.clear();
        vac.dosages.forEach(element => {
          const doseItem = this.fb.group({
            name: element.name,
            description: element.description,
            doseId: element.doseId
          })
          nextDoseListFA.push(doseItem);
        });
      }
      if (vac) {
        vaccine.get('vaccinationSchedules').get('scheduledDate').setValidators([Validators.required]);
        vaccine.get('vaccinationSchedules').get('scheduledDate').markAsTouched();
        vaccine.get('vaccinationSchedules').get('scheduledTime').setValidators([Validators.required]);
        vaccine.get('vaccinationSchedules').get('scheduledTime').markAsTouched();
        vaccine.get('vaccinationSchedules').get('doseId').setValidators([Validators.required]);
        vaccine.get('vaccinationSchedules').get('doseId').markAsTouched();
      } else {
        vaccine.get('vaccinationSchedules').get('scheduledDate').setValidators(null);
        vaccine.get('vaccinationSchedules').get('scheduledTime').setValidators(null);
        vaccine.get('vaccinationSchedules').get('doseId').setValidators(null);
      }
      vaccine.get('vaccinationSchedules').get('scheduledDate').updateValueAndValidity({ emitEvent: false });
      vaccine.get('vaccinationSchedules').get('scheduledTime').updateValueAndValidity({ emitEvent: false });
      vaccine.get('vaccinationSchedules').get('doseId').updateValueAndValidity({ emitEvent: false });
    }
  }

  isValidEditForm(){
    return this.curentEditItem && this.curentEditItem.valid ? true : false;
  }

  onBtnPrintCovid19Card(){
    if(!this.disableVaccinePrint()){
      const vaccines = <FormArray> this.patientVaccinationsFormGroup.get('vaccFormArr');
      const selectedVac = vaccines.controls.filter(x=> x.get('isSelected').value === true);
      const vaccinePayload = [];

      selectedVac.forEach(vaccFG => {
        let temp = {
          doctorId: vaccFG.get('doctorId').value,
          doseId: vaccFG.get('doseId').value,
          givenDate: vaccFG.get('givenDate').value,
          id: vaccFG.get('id').value,
          placeGiven: vaccFG.get('placeGiven').value,
          vaccineId: vaccFG.get('vaccineId').value,
          vaccinationSchedules: this.getVaccShedules(vaccFG.get('vaccinationSchedules'))
        }
        vaccinePayload.push(temp);
      });
      this.printTemplateService.onBtnPrintCovid19Cardlicked(this.patientInfo, vaccinePayload);
    }
  }
  getVaccShedules(sheduleFA) {
    const sheduleList = [];
    let temp = {
      doseId: sheduleFA.get('doseId').value,
      scheduledDate: sheduleFA.get('scheduledDate').value,
      scheduledTime: sheduleFA.get('scheduledTime').value,
      vaccineId: sheduleFA.get('vaccineId').value,
    }
    if(temp.vaccineId && temp.vaccineId !== ''){
      sheduleList.push(temp);
    }
    return sheduleList;
  }

  getAvailableTimesByClinic(dateForApi) {
    this.apiAppointmentsService
      .listAvailableTimeByClinic(
        this.store.getClinicId(),
        dateForApi
      )
      .subscribe(
        res => {
          this.availableTimesDropDownList = new Array<string>();
          res.payload.forEach(slot => {
            this.availableTimesDropDownList.push(slot.start);
          });
          let currentSetTime = this.curentEditItem.get('vaccinationSchedules').get('scheduledTime').value;
          if(!this.availableTimesDropDownList.some(item=> item === currentSetTime)){
            this.curentEditItem.get('vaccinationSchedules').get('scheduledTime').patchValue('');
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  getAvailableTimesByClinicForAdd(dateForApi) {
    this.apiAppointmentsService
      .listAvailableTimeByClinic(
        this.store.getClinicId(),
        dateForApi
      )
      .subscribe(
        res => {
          this.availableTimesDropDownListForAdd = new Array<string>();
          res.payload.forEach(slot => {
            this.availableTimesDropDownListForAdd.push(slot.start);
          });
          let currentSetTime = this.addFormGroup.get('vaccinationSchedules').get('scheduledTime').value;
          if(!this.availableTimesDropDownListForAdd.some(item=> item === currentSetTime)){
            this.addFormGroup.get('vaccinationSchedules').get('scheduledTime').patchValue('');
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  subscribeDateChange(){
    this.curentEditItem && this.curentEditItem.get('vaccinationSchedules').get('scheduledDate').valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      takeUntil(this.componentDestroyed)
    )
    .subscribe(date => {
      if(date){
        let d = moment(date, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
        const isValid = moment(d, DISPLAY_DATE_FORMAT).isValid();
        this.getAvailableTimesByClinic(d);
      }
    });
  }

  subscribeVaccineDetailAdd() {
    this.alertService.getVaccineDetailsAddedEvent().subscribe(res => {
      this.resetAddFormGroup();
    });
  }

  resetAddFormGroup() {
    this.addFormGroup.reset();
    this.addFormGroup.patchValue(
      {
        placeGiven: this.store.clinicCode,
        addedByClinicId:this.store.getClinicId(),
        isEditable : true
      },
      { emitEvent: false }
    );
    this.addFormGroup.markAsUntouched();
    if (!this.isCareReport) {
      this.akitaPatientStore.getPatientInfo();
    }
    this.isEditingMode = false;
    this.curentEditItem = null;
  }
}
