import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {

  private subjectCalendar = new Subject<any>();
  private subjectPcnReminder = new Subject<boolean>();
  private subjectPcnFormDisable = new Subject<boolean>();
  private subjectVisitTypeChange = new Subject<any>();
  private subjectVaccineDetailsAdded = new Subject<any>();
  private subjectSflDetailsAdded = new Subject<any>();
  private subjectReloadCareReport = new Subject<any>();
  private subject = new Subject<any>();
  private subjectSign = new Subject<any>();
  private subjectHistory = new Subject<any>();
  private subjectHistoryClose = new Subject<any>();
  private subjectDocs = new Subject<any>();
  private subjectStoreTempNotes = new Subject<any>();
  private errorMessages = new Observable<Subject<any>>();
  private subjectDiagnosis = new Subject<any>();
  private subjectCharges = new Subject<any>();
  private keepAfterNavigationChange = false;
  private subjectScrollDocs = new Subject<any>();
  private packageItemUpdated: Subject<boolean> = new Subject<boolean>();
  private pcnVisitSelected: Subject<any> = new Subject<any>();
  private pcnVisitIdSelected= new BehaviorSubject<any>('');
  private hsgTargetUpdated: Subject<any> = new Subject<boolean>();
  private hsgSerachFlag: Subject<any> = new Subject<boolean>();
  private subjectPatientIndicator = new Subject<any>();
  private subjectMedicalCoverageUpdated = new Subject<any>();
  private selectPatientFromQueue: Subject<any> = new Subject<any>();
  private documentIndexNumber: Subject<any> = new Subject<any>();
  private patientCareSummaryView: Subject<boolean> = new Subject<boolean>();
  
  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          this.keepAfterNavigationChange = false;
        } else {
          this.subject.next();
        }
      }
    });
  }
  sendstoreTempNotesEvent() {
    this.subjectStoreTempNotes.next();
  }
  storeTempNotes(): Observable<any> {
    return this.subjectStoreTempNotes.asObservable();
  };
  sendClickEvent() {
    this.subjectSign.next();
  }
  getClickEvent(): Observable<any> {
    return this.subjectSign.asObservable();
  };
  sendVisitTypeChangeEvent(visitType) {
    this.subjectVisitTypeChange.next(visitType);
  }
  getVisitTypeChangeEvent(): Observable<any> {
    return this.subjectVisitTypeChange.asObservable();
  };
  sendVaccineDetailsAddedEvent(value: any) {
    this.subjectVaccineDetailsAdded.next(value);
  }
  getVaccineDetailsAddedEvent(): Observable<any> {
    return this.subjectVaccineDetailsAdded.asObservable();
  };
  sendSflDetailsAddedEvent(value: any) {
    this.subjectSflDetailsAdded.next(value);
  }
  getSflDetailsAddedEvent(): Observable<any> {
    return this.subjectSflDetailsAdded.asObservable();
  };
  sendReloadCareReportEvent(value: any) {
    this.subjectReloadCareReport.next(value);
  }
  getReloadCareReportEvent(): Observable<any> {
    return this.subjectReloadCareReport.asObservable();
  };
  sendPcnFormDisable(disable) {
    this.subjectPcnFormDisable.next(disable);
  }
  getPcnFormDisable(): Observable<boolean> {
    return this.subjectPcnFormDisable.asObservable();
  };
  sendPcnReminder(value) {
    this.subjectPcnReminder.next(value);
  }
  getPcnReminder(): Observable<boolean> {
    return this.subjectPcnReminder.asObservable();
  };
  sendCalendarEvent() {
    this.subjectCalendar.next();
  }
  getCalendarEvent(): Observable<any> {
    return this.subjectCalendar.asObservable();
  };
  sendAddDiagnosisEvent() {
    this.subjectDiagnosis.next();
  }
  getAddDiagnosisEvent(): Observable<any> {
    return this.subjectDiagnosis.asObservable();
  };

  sendAddChargesEvent() {
    this.subjectCharges.next();
  }
  getAddChargesEvent(): Observable<any> {
    return this.subjectCharges.asObservable();
  };
  sendExportHistorykEvent() {
    this.subjectHistory.next();
  }
  geteExportHistoryEvent(): Observable<any> {

    return this.subjectHistory.asObservable();
  };
  sendExportHistoryCloseEvent() {
    this.subjectHistoryClose.next();
  }
  geteExportHistoryCloseEvent(): Observable<any> {
    return this.subjectHistoryClose.asObservable();
  };
  
  sendExportDocsEvent() {
    this.subjectDocs.next();
  }
  geteExportDocsEvent(): Observable<any> {
    return this.subjectDocs.asObservable();
  };
  sendScrollDocEvent() {
    this.subjectScrollDocs.next();
  }
  getScrollDocEvent(): Observable<any> {
    return this.subjectScrollDocs.asObservable();
  };
  setPackageItemUpdated(isPackageVaccineValid) {
    return this.packageItemUpdated.next(isPackageVaccineValid);
  }
  getPackageItemUpdated() {
    return this.packageItemUpdated.asObservable();
  }
  setPcnVisitSelected(value) {
    return this.pcnVisitSelected.next(value);
  }
  getPcnVisitSelected() {
    return this.pcnVisitSelected.asObservable();
  }
  setIdsSelected(value) {
    return this.pcnVisitIdSelected.next(value);
  }
  getIdsSelected() {
    return this.pcnVisitIdSelected.asObservable();
  }
  sendIndexNumber(value) {
    return this.documentIndexNumber.next(value);
  }
  getIndexNumber() {
    return this.documentIndexNumber.asObservable();
  }
  setSearchFlag(value) {
    return this.hsgSerachFlag.next(value);
  }
  getSearchFlag() : Observable<boolean> {
    return this.hsgSerachFlag.asObservable();
  }
  setHsgTargetUpdated(value) {
    return this.hsgTargetUpdated.next(value);
  }
  getHsgTargetUpdated() {
    return this.hsgTargetUpdated.asObservable();
  }
  setSelectPatientFromQueue(value) {
    return this.selectPatientFromQueue.next(value);
  }
  getSelectPatientFromQueue() {
    return this.selectPatientFromQueue.asObservable();
  }

  setPatientIndiatorEvent(): void {
    this.subjectPatientIndicator.next();
  }

  getPatientIndicatorEvent(): Observable<any> {
    return this.subjectPatientIndicator.asObservable();
  }

  setMedicalCoverageUpdatedEvent(): void {
    this.subjectMedicalCoverageUpdated.next();
  }

  getMedicalCoverageUpdatedEvent(): Observable<any> {
    return this.subjectMedicalCoverageUpdated.asObservable();
  }

  public setpatientCareSummaryView(status: boolean): void {
    this.patientCareSummaryView.next(status);
  }

  public getPatientCareSummaryView(): Observable<boolean> {
    return this.patientCareSummaryView.asObservable();
  }

  success(
    message: string,
    keepAfterNavigationChange = false,
    showHeader = true
  ) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message, showHeader });
    window.scrollTo(0, 0);
  }
  error(message: string, keepAfterNavigationChange = false, showHeader = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    let errorMessage = '';

    // try to parse to JSON, if fails mean pure string
    try {
      const error = JSON.parse(message);
      errorMessage = this.handleError(error);
    } catch (e) {
   
      errorMessage = message;
    }

    this.subject.next({ type: 'error', text: errorMessage, showHeader });
    window.scrollTo(0, 0);
  }
  warn(message: string, keepAfterNavigationChange = false, showHeader = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'warn', text: message, showHeader });
    window.scrollTo(0, 0);
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  getErrorMessages(): Observable<any> {
    return this.errorMessages;
  }
  handleError(error: HttpErrorResponse): string {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent && error.error.type !== 'error') {
      errorMessage = `An error occurred: ${error.error.message}`;
      return errorMessage;
    } else if (error.status === 0) {
      errorMessage =
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify('Connection Failure')}`;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.message)}`
      );
      return errorMessage;
    } else {
      errorMessage =
        `Backend returned code ${error.status}, ` +
        `body was: ${this.decodeBackendError(error.error)}`;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`
      );
      return errorMessage;
    }
  }
  decodeBackendError(err) {

    if (err && err.message) {
      return err.message;
    } else {
      return JSON.stringify(err);
    }
  }
}
