import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from './app-config.service';

import { HttpResponseBody } from '../objects/response/HttpResponseBody';

@Injectable({
  providedIn: 'root'
})
export class ApiPatientNehrService {

  private API_PATIENT_INFO_URL;
  private NEHR_EXIT_PATIENT_URL;
  private NEHR_LOGOUT_PATIENT_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) {
    this.API_PATIENT_INFO_URL = this.appConfig.getConfig().API_PATIENT_INFO_URL;
    this.NEHR_EXIT_PATIENT_URL = this.appConfig.getConfig().NEHR_EXIT_PATIENT_URL;
    this.NEHR_LOGOUT_PATIENT_URL = this.appConfig.getConfig().NEHR_LOGOUT_PATIENT_URL;
  }

  public getExitNehrPatientUrl(): string {
    return this.NEHR_EXIT_PATIENT_URL
  }

  public getLogoutNehrPatienUrl(): string {
    return this.NEHR_LOGOUT_PATIENT_URL;
  }

  public nehrCcsForm(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/nehr/ccs/get-form/${clinicId}/${patientId}`,
      JSON.stringify({})
    );
  }
}