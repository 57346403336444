import { AkitaVendorQuery } from './akita-vendor.query';
import { AkitaVendorStore } from './akita-vendor.store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest, Subscription, zip } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

import { AkitaAllergyGroupQuery } from './akita-allergy-group.query';
import { AkitaAllergyGroupStore } from './akita-allergy-group.store';
import { AkitaApproverQuery } from './akita-approver.query';
import { AkitaApproverStore, createApprover } from './akita-approver.store';
import { AkitaAssessmentQuery } from './akita-assessment.query';
import { AkitaAssessmentStore } from './akita-assessment.store';
import { AkitaAppQuery } from './akita-app.query';
import { AkitaAppStore } from './akita-app.store';
import { AkitaChargeItemQuery } from './akita-charge-item.query';
import { AkitaChargeItemStore } from './akita-charge-item.store';
import { AkitaChronicConditionQuery } from './akita-chronic-condition.query';
import { AkitaChronicConditionStore } from './akita-chronic-condition.store';
import { AkitaClinicChargeItemQuery } from './akita-clinic-charge-item.query';
import { AkitaClinicChargeItemStore } from './akita-clinic-charge-item.store';
import { AkitaClinicDoctorQuery } from './akita-clinic-doctor.query';
import { AkitaClinicDoctorStore } from './akita-clinic-doctor.store';
import { AkitaClinicQuery } from './akita-clinic.query';
import { AkitaClinicStore } from './akita-clinic.store';
import { AkitaCommunicationTypeQuery } from './akita-communication-type.query';
import { AkitaCommunicationTypeStore } from './akita-communication-type.store';
import { AkitaDefaultAssessmentQuery } from './akita-default-assessment.query';
import { AkitaDefaultAssessmentStore } from './akita-default-assessment.store';
import { AkitaDefaultVitalsQuery } from './akita-default-vitals.query';
import { AkitaDefaultVitalsStore } from './akita-default-vitals.store';
import { AkitaDoctorQuery } from './akita-doctor.query';
import { AkitaDoctorStore } from './akita-doctor.store';
import { AkitaDosageInstructionQuery } from './akita-dosage-instruction.query';
import { AkitaDosageInstructionStore } from './akita-dosage-instruction.store';
import { AkitaInstructionQuery } from './akita-instruction.query';
import { AkitaInstructionStore } from './akita-instruction.store';
import { AkitaInventoryItemClinicDetailQuery } from './akita-inventory-item-clinic-detail.query';
import { AkitaInventoryItemClinicDetailStore } from './akita-inventory-item-clinic-detail.store';
import { AkitaInventoryQuery } from './akita-inventory.query';
import { AkitaInventoryStore } from './akita-inventory.store';
import { AkitaMedicalCoverageQuery } from './akita-medical-coverage.query';
import { AkitaMedicalCoverageStore } from './akita-medical-coverage.store';
import { AkitaNoteTemplateQuery } from './akita-note-template.query';
import { AkitaNoteTemplateStore } from './akita-note-template.store';
import { AkitaPRJustificationQuery } from './akita-pr-justification.query';
import { AkitaPRJustificationStore } from './akita-pr-justification.store';
import { AkitaPatientSourceQuery } from './akita-patient-source.query';
import {
  AkitaPatientSourceStore,
  createPatientSource,
} from './akita-patient-source.store';
import { AkitaReferralQuery } from './akita-referral.query';
import { AkitaReferralStore } from './akita-referral.store';
import { AkitaReferralSpecialityQuery } from './akita-referral-speciality.query';
import { AkitaReferralSpecialityStore } from './akita-referral-speciality.store';
import { AkitaRequestReturnQuery } from './akita-request-return.query';
import { AkitaRequestReturnStore } from './akita-request-return.store';
import { AkitaStockAdjustmentQuery } from './akita-stock-adjustment.query';
import { AkitaStockAdjustmentStore } from './akita-stock-adjustment.store';
import { AkitaUomQuery } from './akita-uom.query';
import { AkitaUomStore } from './akita-uom.store';
import { AkitaVaccinationQuery } from './akita-vaccination.query';
import { AkitaVaccinationStore } from './akita-vaccination.store';
import { AkitaVisitPurposeQuery } from './akita-visit-purpose.query';
import { AkitaVisitPurposeStore } from './akita-visit-purpose.store';
import { AkitaVitalConfigurationQuery } from './akita-vital-configuration.query';
import { AkitaVitalConfigurationStore } from './akita-vital-configuration.store';
import { ApiAssessmentManagementService } from '../api-assessment-management.service';
import { ApiCmsManagementService } from '../api-cms-management.service';
import { ApiCommunicationsService } from '../api-communications.service';
import { ApiInventoryService } from '../api-inventory.service';
import { ApiPatientInfoService } from '../api-patient-info.service';
import { ApiPcnVisitsService } from '../api-pcn-visits.service';
import { API_STATUS, createApiState } from '../../objects/state/ApiState';
import { AuthService } from '../auth.service';
import { Clinic } from '../../objects/state/Clinic';
import { ReferralSpeciality } from '../../objects/state/ReferralSpeciality';
import { StoreService } from './../store.service';
import { User } from '../../objects/response/User';
import { AkitaClinicPurchasableChargeItemStore } from './akita-clinic-purchasable-charge-item.store';
import { AkitaClinicPurchasableChargeItemQuery } from './akita-clinic-purchasable-charge-item.query';
import { AllergyGroup } from '../../objects/response/AllergyGroup';
import { environment } from '../../../environments/environment';
import { SpecialityMaster } from '../../objects/SpecialityMaster';
import { AkitaPCNVisitStore } from './akita-pcn-visit-case.store';
import { AlertService } from '../alert.service';
import { LoaderService } from '../../components/loading/loader.service';
import { ApiHsgService } from '../api-hsg.service';
import { AkitaGoalMasterQuery } from './akita-goal-master.query';
import { AkitaGoalMasterStore } from './akita-goal-master.store';
import { AkitaCIDCMappingQuery } from './akita-cidc-mapping.query';
import { AkitaCIDCMappingStore } from './akita-cidc-mapping.store';
import { AkitaRouteOfAdministrationQuery } from './akita-route-of-administration.query';
import { AkitaRouteOfAdministrationStore } from './akita-route-of-administration.store';
import { AkitaCdlensMappingQuery } from './akita-cdlens-mapping.query';
import { AkitaCdlensMappingStore } from './akita-cdlens-mapping.store';
import { AkitaDeliveryLocationQuery } from './akita-delivery-location.query';
import { AkitaDeliveryLocationStore } from "./akita-delivery-location.store";
import { AkitaWhitelistedItemQuery } from "./akita-whitelisted-items.query";
import { AkitaWhitelistedItemStore } from "./akita-whitelisted-items.store";
import { AkitaRemoteDeliveryOrderStatusQuery } from './akita-remote-delivery-order-status.query';
import { AkitaRemoteDeliveryOrderStatusStore } from './akita-remote-delivery-order-status.store';
import { AkitaSflItemStore } from "./akita-sfl-item.store";
import { AkitaSflItemQuery } from "./akita-sfl-item.query";
import { AkitaSFLSetupQuery } from './akita-sfl-setup.query';
import { AkitaSFLSetupStore } from './akita-sfl-setup.store';
import { AkitaSddCodeQuery } from './akita-sdd-code.query';
import { AkitaSddCodeStore } from './akita-sdd-code.store';
import HSGPlan, { HSGGoal } from '../../model/HSGPlan';
import { ExercisePlanWorkflow } from '../../model/care-plan/CarePlanExerciseRecommendation';
import { DietRecommendation } from '../../model/care-plan/CarePlanDietRecommendation';
import { VaccinationRecommendation } from '../../model/care-plan/CarePlanVaccination';
import { HealthPlanWorkflow } from '../../model/care-plan/CarePlanHealthGoal';

@Injectable({ providedIn: 'root' })
export class AkitaStoreService {
  readonly functionKeyMap = [
    'allergyGroup',
    'approverList',
    'chargeItem',
    'chronicCondition',
    'clinic',
    'communicationType',
    'defaultVitals',
    'doctor',
    'instruction',
    'medicalCoverage',
    'patientSource',
    'referralByType',
    'referralSpeciality',
    'vaccination',
    'visitPurpose',
    'vitalConfiguration',
    'sddCode'
  ];

  readonly envName = environment.envName;
  readonly ENV_CMS = 'cms';
  readonly ENV_SPEC = 'spec';
  readonly ENV_CMS_DUA = 'cmsdua';
  readonly ENV_PROD = 'prod';
  isNavEnvironment =
    this.envName &&
    (this.envName === this.ENV_CMS_DUA || this.envName === this.ENV_PROD || this.envName === this.ENV_CMS);

  private akitaClinicChargePurchasableItemQuerSub: Subscription;

  functions;
  constructor(
    private akitaAllergyGroupQuery: AkitaAllergyGroupQuery,
    private akitaAllergyGroupStore: AkitaAllergyGroupStore,
    private akitaApproverQuery: AkitaApproverQuery,
    private akitaApproverStore: AkitaApproverStore,
    private akitaAssessmentQuery: AkitaAssessmentQuery,
    private akitaAssessmentStore: AkitaAssessmentStore,
    private akitaAppQuery: AkitaAppQuery,
    private akitaAppStore: AkitaAppStore,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaChargeItemStore: AkitaChargeItemStore,
    private akitaChronicConditionQuery: AkitaChronicConditionQuery,
    private akitaChronicConditionStore: AkitaChronicConditionStore,
    private akitaClinicChargeItemQuery: AkitaClinicChargeItemQuery,
    private akitaClinicChargeItemStore: AkitaClinicChargeItemStore,
    private akitaClinicChargePurchasableItemQuery: AkitaClinicPurchasableChargeItemQuery,
    private akitaClinicPurchasableChargeItemStore: AkitaClinicPurchasableChargeItemStore,
    private akitaClinicDoctorQuery: AkitaClinicDoctorQuery,
    private akitaClinicDoctorStore: AkitaClinicDoctorStore,
    private akitaClinicQuery: AkitaClinicQuery,
    private akitaClinicStore: AkitaClinicStore,
    private akitaCommunicationTypeQuery: AkitaCommunicationTypeQuery,
    private akitaCommunicationTypeStore: AkitaCommunicationTypeStore,
    private akitaDefaultAssessmentQuery: AkitaDefaultAssessmentQuery,
    private akitaDefaultAssessmentStore: AkitaDefaultAssessmentStore,
    private akitaDefaultVitalsQuery: AkitaDefaultVitalsQuery,
    private akitaDefaultVitalsStore: AkitaDefaultVitalsStore,
    private akitaDoctorQuery: AkitaDoctorQuery,
    private akitaDoctorStore: AkitaDoctorStore,
    private akitaDosageInstructionQuery: AkitaDosageInstructionQuery,
    private akitaDosageInstructionStore: AkitaDosageInstructionStore,
    private akitaInstructionQuery: AkitaInstructionQuery,
    private akitaInstructionStore: AkitaInstructionStore,
    private akitaInventoryItemClinicDetailQuery: AkitaInventoryItemClinicDetailQuery,
    private akitaInventoryItemClinicDetailStore: AkitaInventoryItemClinicDetailStore,
    private akitaInventoryQuery: AkitaInventoryQuery,
    private akitaInventoryStore: AkitaInventoryStore,
    private akitaMedicalCoverageQuery: AkitaMedicalCoverageQuery,
    private akitaMedicalCoverageStore: AkitaMedicalCoverageStore,
    private akitaNoteTemplateQuery: AkitaNoteTemplateQuery,
    private akitaNoteTemplateStore: AkitaNoteTemplateStore,
    private akitaPRJustificationQuery: AkitaPRJustificationQuery,
    private akitaPRJustificationStore: AkitaPRJustificationStore,
    private akitaPatientSourceQuery: AkitaPatientSourceQuery,
    private akitaPatientSourceStore: AkitaPatientSourceStore,
    private akitaReferralQuery: AkitaReferralQuery,
    private akitaReferralStore: AkitaReferralStore,
    private akitaReferralSpecialityQuery: AkitaReferralSpecialityQuery,
    private akitaReferralSpecialityStore: AkitaReferralSpecialityStore,
    private akitaRequestReturnQuery: AkitaRequestReturnQuery,
    private akitaRequestReturnStore: AkitaRequestReturnStore,
    private akitaStockAdjustmentQuery: AkitaStockAdjustmentQuery,
    private akitaStockAdjustmentStore: AkitaStockAdjustmentStore,
    private akitaUomQuery: AkitaUomQuery,
    private akitaUomStore: AkitaUomStore,
    private akitaVaccinationQuery: AkitaVaccinationQuery,
    private akitaVaccinationStore: AkitaVaccinationStore,
    private akitaVendorQuery: AkitaVendorQuery,
    private akitaVendorStore: AkitaVendorStore,
    private akitaVisitPurposeQuery: AkitaVisitPurposeQuery,
    private akitaVisitPurposeStore: AkitaVisitPurposeStore,
    private akitaVitalConfigurationQuery: AkitaVitalConfigurationQuery,
    private akitaVitalConfigurationStore: AkitaVitalConfigurationStore,
    private akitaPCNVisitStore: AkitaPCNVisitStore,
    private akitaGoalMasterQuery: AkitaGoalMasterQuery,
    private akitaGoalMasterStore: AkitaGoalMasterStore,
    private akitaCIDCMappingQuery: AkitaCIDCMappingQuery,
    private akitaCIDCMappingStore: AkitaCIDCMappingStore,
    private akitaCdlensMappingQuery: AkitaCdlensMappingQuery,
    private akitaCdlensMappingStore: AkitaCdlensMappingStore,
    private akitaDeliveryLocationQuery: AkitaDeliveryLocationQuery,
    private akitaDeliveryLocationStore: AkitaDeliveryLocationStore,
    private akitaWhitelistedItemQuery: AkitaWhitelistedItemQuery,
    private akitaWhitelistedItemStore: AkitaWhitelistedItemStore,
    private akitaSflItemStore: AkitaSflItemStore,
    private akitaSflItemQuery: AkitaSflItemQuery,
    private akitaRouteOfAdministrationQuery: AkitaRouteOfAdministrationQuery,
    private akitaRemoteDeliveryOrderStatusQuery: AkitaRemoteDeliveryOrderStatusQuery,
    private akitaRemoteDeliveryOrderStatusStore: AkitaRemoteDeliveryOrderStatusStore,
    private akitaRouteOfAdministrationStore: AkitaRouteOfAdministrationStore,
    private akitaSFLSetupStore: AkitaSFLSetupStore,
    private akitaSFLSetupQuery: AkitaSFLSetupQuery,
    private akitaSddCodeQuery: AkitaSddCodeQuery,
    private akitaSddCodeStore: AkitaSddCodeStore,
    private apiAsesssmentManagementSvc: ApiAssessmentManagementService,
    private apiCmsManagementSvc: ApiCmsManagementService,
    private apiCommunicationSvc: ApiCommunicationsService,
    private apiInventorySvc: ApiInventoryService,
    private apiPatientInfoSvc: ApiPatientInfoService,
    private apiPcnVisitsSvc: ApiPcnVisitsService,
    private apiHSGService: ApiHsgService,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private store: StoreService,
    private alertService: AlertService,
    private loaderService: LoaderService,
  ) {
    if (localStorage.getItem('access_token')) this.init();
  }

  init(firstTry = true) {
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('clinicCode') &&
      localStorage.getItem('clinicId')
    ) {
      this.akitaAppStore.update({
        clinicCode: localStorage.getItem('clinicCode'),
        clinicId: localStorage.getItem('clinicId'),
      });
    }

    if (this.authService.isAuthenticated()) {
      this.authService
        .getUser()
        .pipe(
          map(res => {
            if (res && res.payload) return res;
            else {
              this.router.navigate(['login']);
              return undefined;
            }
          }),
          filter(res => res && res.payload),
          mergeMap(res => {
            this.store.preInit(res.payload);
            this.akitaAppStore.update({ currentUser: res.payload });
            this.functions = [...this.functionKeyMap];

            if (
              this.permissionsService.getPermission(
                'ROLE_CONSULTATION_TEMPLATE'
              )
            ) {
              this.functions = ['template', ...this.functions];
            }
            if (
              this.permissionsService.getPermission('ROLE_VIEW_SUPPLIER_NAV') &&
              this.isNavEnvironment
            ) {
              this.functions = ['inventory', 'vendor', ...this.functions];
            }
            if (
              this.permissionsService.getPermission('ROLE_PURCHASE_NAV') &&
              this.isNavEnvironment
            ) {
              this.functions = ['inventoryItemClinicDetail', ...this.functions];
            }
            if (this.permissionsService.getPermission('ROLE_ASSESSMENT')) {
              this.functions = ['assessment', ...this.functions];
            }
            if (
              this.permissionsService.getPermission('ROLE_ASSESSMENT_ENQUIRE')
            ) {
              this.functions = ['defaultAssessments', ...this.functions];
            }
            if (
              this.permissionsService.getPermission(
                'ROLE_PR_PO_INVENTORY_NAV'
              ) &&
              this.isNavEnvironment
            ) {
              this.functions = [
                'requestReturn',
                'stockAdjustment',
                'prJustification',
                ...this.functions,
              ];
            }

            if (
              this.permissionsService.getPermission('ROLE_HSG_PROJECT')
            ) {
              this.functions = ['goalMaster', ...this.functions];
            }

            // remaining APIs
            this.functions = [
              'chargeItemByClinic',
              'doctorByClinic',
              'cidcMapping',
              'routeOfAdministration',
              'cdlensMapping',
              ...this.functions,
            ];
            if (firstTry) this.createDefaultApiState();
            this.loadStore();
            return this.akitaAppQuery.select(entity => entity.clinicId);
          }),
          filter(clinicId => !!clinicId && clinicId.length > 0),
          take(1)
        )
        .subscribe(clinicId => {
          if (clinicId && clinicId.length > 0) {
            const currentClinicSubscription = this.akitaClinicQuery
              .selectAll()
              .subscribe((clinicList: Array<Clinic>) => {
                const currentClinic = clinicList.find(
                  clinic => clinic.id === clinicId
                );
                if (currentClinic) {
                  this.updateClinic(currentClinic);
                  if (currentClinic.clinicFeatures.includes("HSG")) {
                    this.getWhitelistedItems()
                  }
                }
                if (currentClinicSubscription)
                  currentClinicSubscription.unsubscribe();
              });

            // if (this.akitaAppQuery.checkClinicFeatureExist('HSG')) {
            //   this.store.getGoalMasterData();
            // }
            this.getClinicChargeItemList();
            this.getClinicDoctorList();
            this.getTemplateList();
            this.getSflItems();
            this.getSflSetup();
            if (
              this.permissionsService.getPermission('ROLE_PURCHASE_NAV') &&
              this.isNavEnvironment
            ) {
              this.getInventoryItemClinicDetail();
            }
            this.getDeliveryLocations();
            this.getRemoteDeliveryOrderStatusList();
          }
        }, error => {
          this.loaderService.setLoading(false);
          if (error.error.status === 'FORBIDDEN') {
            this.router.navigate(['/reset-password']);
          } else {
            this.alertService.error(error.error.message);
          }
        });
    }
  }

  createDefaultApiState() {
    const apiStateArray = [];
    this.functions.forEach(key =>
      apiStateArray.push(createApiState(key, API_STATUS.INIT))
    );
    this.akitaAppStore.set(apiStateArray);
  }

  loadStore() {
    this.akitaAppQuery.getAll().forEach(apiState => {
      this.runDataApi(apiState.name);
    });
  }

  runDataApi(key) {
    switch (key) {
      case 'allergyGroup': {
        this.getAllergyGroupList();
        break;
      }
      case 'approverList': {
        this.getApproverList();
        break;
      }
      case 'assessment': {
        this.getAssessmentList();
        break;
      }
      case 'chargeItem': {
        this.getChargeItemList();
        break;
      }
      case 'chronicCondition': {
        this.getChronicConditionList();
        break;
      }
      case 'clinic': {
        this.getClinicList();
        break;
      }
      case 'communicationType': {
        this.getCommunicationTypeList();
        break;
      }
      case 'defaultAssessments': {
        this.getDefaultAssessmentList();
        break;
      }
      case 'defaultVitals': {
        this.getDefaultVitalConfiguration();
        break;
      }
      case 'doctor': {
        this.getDoctorList();
        break;
      }
      case 'instruction': {
        this.getInstructionList();
        break;
      }
      case 'inventory': {
        this.getInventorySupplierInfo();
        break;
      }
      case 'medicalCoverage': {
        this.getMedicalCoverageList();
        break;
      }
      case 'patientSource': {
        this.getPatientSourceList();
        break;
      }
      case 'referralByType': {
        this.getReferralByType();
        break;
      }
      case 'referralSpeciality': {
        this.getReferralSpecialityList();
        break;
      }
      case 'requestReturn': {
        this.getRequestReturn();
        break;
      }
      case 'stockAdjustment': {
        this.getStockAdjustment();
        break;
      }
      case 'prJustification': {
        this.getPurchaseRequestJustification();
        break;
      }
      case 'vaccination': {
        this.getVaccinationList();
        break;
      }
      case 'sddCode': {
        this.listAllSddCodes();
        break;
      }
      case 'visitPurpose': {
        this.getVisitPurposeList();
        break;
      }
      case 'vendor': {
        this.getVendors();
        break;
      }
      case 'vitalConfiguration': {
        this.getVitalConfigurationList();
        break;
      }
      case 'goalMaster': {
        this.getGoalMasters();
        break;
      }
      case 'cidcMapping': {
        this.getCIDCMappings();
        break;
      }
      case 'routeOfAdministration': {
        this.getRouteOfAdministrations();
        break;
      }
      case 'cdlensMapping': {
        this.getCdlensMappings();
        break;
      }
      case 'deliveryLocation': {
        this.getDeliveryLocations();
        break;
      }
      case 'remoteDeliverStatus': {
        this.getRemoteDeliveryOrderStatusList();
        break;
      }
      case 'sflSetup': {
        this.getSflSetup();
        break;
      }
    }
  }

  updateClinic(clinic) {
    this.akitaAppStore.update({ clinic: clinic });
  }

  updateClinicCode(clinicCode) {
    this.akitaAppStore.update({ clinicCode: clinicCode });
  }

  updateClinicId(clinicId) {
    this.akitaAppStore.update({ clinicId: clinicId });
  }

  updateCurrentUser(user) {
    this.akitaAppStore.update({ currentUser: user });
  }

  getAllergyGroupList(bypass = false) {
    this.checkAndUpdateInit('allergyGroup');

    this.akitaAllergyGroupQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiPatientInfoSvc.listAllergyGroups(true)),
        mergeMap(data => {
          if (data && data.payload) {
            const activeAllergyGroup = data.payload.filter(
              (allergy: AllergyGroup) =>
                allergy.status && allergy.status === 'ACTIVE'
            );
            this.akitaAllergyGroupStore.set(activeAllergyGroup);
            // this.akitaAllergyGroupStore.set(data.payload);
            this.store.afterAllergyGroupDataLoaded(activeAllergyGroup);
          }
          return this.akitaAllergyGroupQuery.selectLoading();
        }),
        catchError(err => this.akitaAllergyGroupQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('allergyGroup');
        else this.updateFailure('allergyGroup');
      });
  }

  getApproverList(bypass = false) {
    this.checkAndUpdateInit('approverList');

    this.akitaApproverQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiPatientInfoSvc.listApprovers(true)),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaApproverStore.set(
              data.payload.map(approver => createApprover(approver))
            );
          }

          return this.akitaApproverQuery.selectLoading();
        }),
        catchError(err => this.akitaApproverQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('approverList');
        else this.updateFailure('approverList');
      });
  }

  getAssessmentList(bypass = false) {
    this.checkAndUpdateInit('assessment');

    this.akitaAssessmentQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiAsesssmentManagementSvc.listAllAssessment(true)
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaAssessmentStore.set(data.payload);
            this.store.afterListAllAssessments(data);
          }
          return this.akitaAssessmentQuery.selectLoading();
        }),
        catchError(err => this.akitaAssessmentQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('assessment');
        else this.updateFailure('assessment');
      });
  }

  getChargeItemList(bypass = false) {
    this.checkAndUpdateInit('chargeItem');

    this.akitaChargeItemQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listChargeItems(true)),
        mergeMap(data => {
          if (data) this.akitaChargeItemStore.addNewData(data);
          return this.akitaChargeItemQuery.selectLoading();
        }),
        catchError(err => this.akitaChargeItemQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('chargeItem');
        else this.updateFailure('chargeItem');
      });
  }

  getChronicConditionList(bypass = false) {
    this.checkAndUpdateInit('chronicCondition');

    this.akitaChronicConditionQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiPcnVisitsSvc.listAllChronicConditions(true)
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.store.primaryCareChronicCondition = [...data.payload];
            this.akitaChronicConditionStore.set(data.payload);
          }
          return this.akitaChronicConditionQuery.selectLoading();
        }),
        catchError(err => this.akitaChronicConditionQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('chronicCondition');
        else this.updateFailure('chronicCondition');
      });
  }

  getClinicChargeItemList(bypass = false) {
    this.checkAndUpdateInit('chargeItemByClinic');

    this.akitaClinicChargeItemQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.akitaAppQuery.appClinicId$),
        filter(clinicId => clinicId && clinicId.length > 0),
        take(1),
        mergeMap(clinicId =>
          this.apiCmsManagementSvc.listChargeItemsByClinicId([clinicId], true)
        ),
        take(1),
        mergeMap(data => {
          if (data) this.akitaClinicChargeItemStore.addNewData(data);
          return this.akitaClinicChargeItemQuery.selectLoading();
        }),
        catchError(err => this.akitaClinicChargeItemQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('chargeItemByClinic');
        else this.updateFailure('chargeItemByClinic');
      });
  }

  getClinicPurchasableChargeItem(bypass = false) {
    this.checkAndUpdateInit('clinicPurchasableChargeItem');

    this.akitaClinicChargePurchasableItemQuerSub = this.akitaClinicChargePurchasableItemQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.akitaAppQuery.appClinicId$),
        filter(clinicId => clinicId && clinicId.length > 0),
        mergeMap(clinicId =>
          this.apiCmsManagementSvc.listPurchasableChargeItemsByClinicId(
            [clinicId],
            true
          )
        ),
        mergeMap(data => {
          if (data) this.akitaClinicPurchasableChargeItemStore.addNewData(data);
          return this.akitaClinicChargePurchasableItemQuery.selectLoading();
        }),
        catchError(err =>
          this.akitaClinicChargePurchasableItemQuery.selectLoading()
        )
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('clinicPurchasableChargeItem');
        else this.updateFailure('clinicPurchasableChargeItem');
      });
  }

  updateConsultationSearchFilter(code, itemType, name, vendorId) {
    this.akitaClinicChargeItemStore.update({
      ui: {
        code: code,
        itemType: itemType,
        name: name,
        supplierId: vendorId,
      },
    });
  }

  getClinicDoctorList(bypass = false) {
    this.checkAndUpdateInit('doctorByClinic');

    this.akitaClinicDoctorQuery
      .selectHasCache()
      .pipe(
        takeUntil(this.authService.isLogout()),
        tap(),
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.akitaAppQuery.appClinicId$),
        filter(clinicId => clinicId && clinicId.length > 0),
        take(1),
        mergeMap(clinicId =>
          this.apiCmsManagementSvc.listDoctorsByClinic(clinicId, true)
        ),
        take(1),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaClinicDoctorStore.set(data.payload);
            this.store.afterListDoctorsByClinic(data);
          }
          return this.akitaClinicDoctorQuery.selectLoading();
        }),
        catchError(err => this.akitaClinicDoctorQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('doctorByClinic');
        else this.updateFailure('doctorByClinic');
      });
  }

  getClinicList(bypass = false) {
    this.checkAndUpdateInit('clinic');

    this.akitaClinicQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiCmsManagementSvc
            .listClinics(true)
            .pipe(takeUntil(this.authService.isLogout()))
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaClinicStore.set(data.payload);
            this.store.afterClinicDataLoaded(data);
          }
          return this.akitaClinicQuery.selectLoading();
        }),
        catchError(err => this.akitaClinicQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('clinic');
        else this.updateFailure('clinic');
      });
  }

  getCommunicationTypeList(bypass = false) {
    this.checkAndUpdateInit('communicationType');

    this.akitaCommunicationTypeQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiCommunicationSvc.getCommunicationTypes(true)
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.store.afterCommunicationTypes(data.payload);
            this.akitaCommunicationTypeStore.set(data.payload);
          }
          return this.akitaCommunicationTypeQuery.selectLoading();
        }),
        catchError(err => this.akitaCommunicationTypeQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('communicationType');
        else this.updateFailure('communicationType');
      });
  }

  getDefaultAssessmentList(bypass = false) {
    this.checkAndUpdateInit('defaultAssessments');

    this.akitaDefaultAssessmentQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiCmsManagementSvc.listDefaultAssessments(true)
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaDefaultAssessmentStore.set(data.payload);
            this.store.afterDefaultAssessments(data);
          }
          return this.akitaDefaultAssessmentQuery.selectLoading();
        }),
        catchError(err => this.akitaDefaultAssessmentQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('defaultAssessments');
        else this.updateFailure('defaultAssessments');
      });
  }

  getDefaultVitalConfiguration(bypass = false) {
    this.checkAndUpdateInit('defaultVitals');

    this.akitaDefaultVitalsQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listDefaultVitals(true)),
        mergeMap(data => {
          if (data) {
            this.store.afterDefaultVitalLoaded(data);
            this.akitaDefaultVitalsStore.set(
              data.payload.map(item => (item = { ...item, disabled: false }))
            );
          }
          return this.akitaDefaultVitalsQuery.selectLoading();
        }),
        catchError(err => this.akitaDefaultVitalsQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('defaultVitals');
        else this.updateFailure('defaultVitals');
      });
  }

  getDoctorList(bypass = false) {
    this.checkAndUpdateInit('doctor');

    this.akitaDoctorQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listDoctors(true)),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaDoctorStore.set(data.payload);
            this.store.setDoctorData(data);
          }
          return this.akitaDoctorQuery.selectLoading();
        }),
        catchError(err => this.akitaDoctorQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('doctor');
        else this.updateFailure('doctor');
      });
  }

  // OK, be careful as 3 stores are loaded at the same time and loading is determined only if the 3 stores are loaded
  getInstructionList(bypass = false) {
    this.checkAndUpdateInit('instruction');

    zip(
      this.akitaDosageInstructionQuery.selectHasCache(),
      this.akitaInstructionQuery.selectHasCache(),
      this.akitaUomQuery.selectHasCache()
    )
      .pipe(
        filter(
          hasCache => !hasCache[0] || !hasCache[1] || !hasCache[2] || bypass
        ),
        mergeMap(hasCache => this.apiCmsManagementSvc.listInstructions(true)),
        take(1),
        mergeMap(data => {
          if (data && data.payload) {
            this.store.afterInstructionsLoaded(data);
            const { payload } = data;
            if (payload['DOSAGE_INSTRUCTIONS'])
              this.akitaDosageInstructionStore.set(
                payload['DOSAGE_INSTRUCTIONS']
              );
            if (payload['INSTRUCTIONS'])
              this.akitaInstructionStore.set(payload['INSTRUCTIONS']);
            if (payload['DOSAGE_UOM']) this.akitaUomStore.set(payload['DOSAGE_UOM']);

            this.akitaDosageInstructionQuery.getActiveDosageInstruction();

            return combineLatest(
              this.akitaDosageInstructionQuery.selectLoading(),
              this.akitaInstructionQuery.selectLoading(),
              this.akitaUomQuery.selectLoading()
            );
          }
        }),
        catchError(err =>
          combineLatest(
            this.akitaDosageInstructionQuery.selectLoading(),
            this.akitaInstructionQuery.selectLoading(),
            this.akitaUomQuery.selectLoading()
          )
        )
      )
      .subscribe(loading => {
        if (!loading[0] && !loading[1] && !loading[2])
          this.updateSuccess('instruction');
        else this.updateFailure('instruction');
      });
  }

  getInventoryItemClinicDetail(bypass = false) {
    this.checkAndUpdateInit('inventoryItemClinicDetail');

    this.akitaInventoryItemClinicDetailQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.akitaAppQuery.select(entity => entity.clinicId)
        ),
        filter(clinicId => clinicId && clinicId.length > 0),
        take(1),
        mergeMap(clinicId =>
          this.apiInventorySvc.getInventoryItemClinicDetail(clinicId, true)
        ),
        take(1),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaInventoryItemClinicDetailStore.set(data.payload);
            this.store.afterInventoryItemClinicDetail(data.payload);
          }
          return this.akitaInventoryItemClinicDetailQuery.selectLoading();
        }),
        catchError(err =>
          this.akitaInventoryItemClinicDetailQuery.selectLoading()
        )
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('inventoryItemClinicDetail');
        else this.updateFailure('inventoryItemClinicDetail');
      });
  }

  getInventorySupplierInfo(bypass = false) {
    this.checkAndUpdateInit('inventory');
    this.akitaInventoryQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        tap(),
        mergeMap(hasCache => this.apiInventorySvc.getSupplierInfo(true)),
        tap(),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaInventoryStore.set(data.payload);
            this.store.afterInventoryInfo(data);
          }
          return this.akitaInventoryQuery.selectLoading();
        }),
        catchError(err => this.akitaInventoryQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('inventory');
        else this.updateFailure('inventory');
      });
  }

  getMedicalCoverageList(bypass = false) {
    this.checkAndUpdateInit('medicalCoverage');
    this.akitaMedicalCoverageQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiCmsManagementSvc.listMedicalCoveragesWithPagination(
            0,
            10000,
            true
          )
        ),
        mergeMap(data => {
          if (data && data.payload && data.payload.content) {
            this.store.afterGettingMedicalCoverageList(data);
            this.akitaMedicalCoverageStore.set(data.payload.content);
          }
          return this.akitaMedicalCoverageQuery.selectLoading();
        }),
        catchError(err => this.akitaMedicalCoverageQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('medicalCoverage');
        else this.updateFailure('medicalCoverage');
      });
  }

  getPurchaseRequestJustification(bypass = false) {
    this.checkAndUpdateInit('prJustification');

    this.akitaPRJustificationQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiInventorySvc.getPurchaseRequestJustification(true)
        ),
        mergeMap(res => {
          if (res && res.payload) {
            this.akitaPRJustificationStore.set(res.payload);
            this.store.afterPurchaseRequestJustification(res);
          }
          return this.akitaPRJustificationQuery.selectLoading();
        }),
        catchError(err => this.akitaPRJustificationQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('prJustification');
        else this.updateFailure('prJustification');
      });
  }

  getPatientSourceList(bypass = false) {
    this.checkAndUpdateInit('patientSource');

    this.akitaPatientSourceQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiPatientInfoSvc.listPatientSources(true)),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaPatientSourceStore.set(
              data.payload.map(eachPatientSource =>
                createPatientSource(eachPatientSource)
              )
            );
          }
          return this.akitaPatientSourceQuery.selectLoading();
        }),
        catchError(err => this.akitaPatientSourceQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('patientSource');
        else this.updateFailure('patientSource');
      });
  }

  getReferralByType(bypass = false) {
    this.checkAndUpdateInit('referralByType');

    this.akitaReferralQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listReferralByType(true)),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaReferralStore.addNewData(data.payload);
            this.store.afterReferralByType(data.payload);
          }
          return this.akitaReferralQuery.selectLoading();
        }),
        catchError(err => this.akitaReferralQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('referralByType');
        else this.updateFailure('referralByType');
      });
  }

  getReferralSpecialityList(bypass = false) {
    this.checkAndUpdateInit('referralSpeciality');

    this.akitaReferralSpecialityQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.store.getReferralSpecialitiesUpdated()
        ),
        mergeMap(data => {
          if (data && data.payload) {
            const referralSpecialistArray = new Array<ReferralSpeciality>();
            Object.keys(data.payload).forEach(key =>
              data.payload[key].forEach(item =>
                referralSpecialistArray.push(item)
              )
            );
            this.store.referralSpecialities = Object.keys(data.payload);
            Object.keys(data.payload).forEach(specialityKey => {
              if (specialityKey != null && specialityKey != '') {
                let typesArray = [];

                //start
                data.payload[specialityKey].forEach(specialityKeyItem => {
                  typesArray.push(specialityKeyItem.type.toString());
                });
                //end
                let obj = {
                  speciality: specialityKey,
                  types: [...new Set(typesArray)],
                };

                let inputObj: SpecialityMaster = <SpecialityMaster>obj;
                this.store.referralSpecialitiesMap.push(inputObj);
              }
            });
            this.akitaReferralSpecialityStore.set(referralSpecialistArray);
          }
          return this.akitaReferralSpecialityQuery.selectLoading();
        }),
        catchError(err => this.akitaReferralSpecialityQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('referralSpeciality');
        else this.updateFailure('referralSpeciality');
      });
  }

  updateReferralSpecialityState(type, speciality, doctor, clinic) {
    this.akitaReferralSpecialityStore.update({
      ui: {
        type: type,
        speciality: speciality,
        doctor: doctor,
        clinic: clinic,
      },
    });
  }

  // OK
  getRequestReturn(bypass = false) {
    this.checkAndUpdateInit('requestReturn');

    this.akitaRequestReturnQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiInventorySvc.getReturnRequestItemReason(true)
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaRequestReturnStore.set(data.payload);
            this.store.afterInventoryReturnRequestItemReasonList(data);
          }
          return this.akitaRequestReturnQuery.selectLoading();
        }),
        catchError(err => this.akitaRequestReturnQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('requestReturn');
        else this.updateFailure('requestReturn');
      });
  }

  getStockAdjustment(bypass = false) {
    this.checkAndUpdateInit('stockAdjustment');

    this.akitaStockAdjustmentQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiInventorySvc.getStockAdjustmentReason(true)
        ),
        mergeMap(data => {
          if (data) {
            this.akitaStockAdjustmentStore.set(data.payload);
            this.store.afterInventoryStockAdjustmentReasonList(data);
          }
          return this.akitaStockAdjustmentQuery.selectLoading();
        }),
        catchError(err => this.akitaStockAdjustmentQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('stockAdjustment');
        else this.updateFailure('stockAdjustment');
      });
  }

  // OK
  getTemplateList(bypass = false) {
    this.checkAndUpdateInit('template');
    this.akitaNoteTemplateQuery
      .selectHasCache()
      .pipe(
        filter(
          hasCache =>
            (!hasCache || bypass) &&
            !!this.permissionsService.getPermission(
              'ROLE_CONSULTATION_TEMPLATE'
            )
        ),
        mergeMap(hasCache => this.akitaAppQuery.appClinicId$),
        mergeMap(clinicId =>
          this.akitaAppQuery.appUser$.pipe(
            map(currentUser => [clinicId, currentUser])
          )
        ),
        filter(
          (data: [string, User]) =>
            data[0] &&
            data[0].length > 0 &&
            data[1] !== undefined &&
            data[1].context !== undefined &&
            data[1].context['cms-user-id'] !== undefined
        ),
        mergeMap(data =>
          this.apiCmsManagementSvc.listTemplates(
            data[0],
            data[1].context['cms-user-id'],
            true
          )
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaNoteTemplateStore.set(data.payload);
            this.store.setTemplates(data.payload);
          }
          return this.akitaNoteTemplateQuery.selectLoading();
        }),
        catchError(err => this.akitaNoteTemplateQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('template');
        else this.updateFailure('template');
      });
  }

  getVaccinationList(bypass = false) {
    this.checkAndUpdateInit('vaccination');
    this.akitaVaccinationQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listVaccinations(true)),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaVaccinationStore.set(data.payload);
            this.store.vaccinationList = data.payload;
          }
          return this.akitaVaccinationQuery.selectLoading();
        })
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('vaccination');
        else this.updateFailure('vaccination');
      });
  }

  listAllSddCodes(bypass = false) {
    this.checkAndUpdateInit('sddCode');
    this.akitaSddCodeQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listAllSddCodes()),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaSddCodeStore.set(data.payload);
            this.store.sddCodeList = data.payload;
          }
          return this.akitaSddCodeQuery.selectLoading();
        })
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('sddCode');
        else this.updateFailure('sddCode');
      });
  }

  getVendors(bypass = false) {
    this.checkAndUpdateInit('vendor');
    this.akitaVendorQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listSupplier()),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaVendorStore.set(data.payload);
          }
          return this.akitaVendorQuery.selectLoading();
        }),
        catchError(err => this.akitaVendorQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('vendor');
        else this.updateFailure('vendor');
      });
  }

  getVisitPurposeList(bypass = false) {
    this.checkAndUpdateInit('visitPurpose');
    this.akitaVisitPurposeQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listVisitPurposes(true)),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaVisitPurposeStore.set(data.payload);
            this.store.visitPurposeList = data.payload;
          }
          return this.akitaVisitPurposeQuery.selectLoading();
        }),
        catchError(err => this.akitaVisitPurposeQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('visitPurpose');
        else this.updateFailure('visitPurpose');
      });
  }

  getGoalMasters(bypass = false) {
    this.checkAndUpdateInit('goalMaster');
    this.akitaGoalMasterQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiHSGService.getGoalMasterData()),
        mergeMap(data => {
          if (data && data.payload) {
            // TO-DO-SM: Refactor this function
            this.akitaGoalMasterStore.set(data.payload);

            this.store.hsgGoalMasterData = data.payload.other;

            this.store.originalHsgHealthPlans = data.payload.other;

            this.store.originalHsgVaccinationRecommendations = data.payload.vaccination[0].vaccinationItems;
            this.store.hsgVaccinationRecommendations = data.payload.vaccination[0].vaccinationItems.map(vaccination => VaccinationRecommendation.adapt(vaccination));

            this.store.originalHsgExerciseRecommendations = data.payload.exercise[0].workflow.subWorkflowList;
            this.store.originalHsgExerciseRecommendationsFromPayload = data.payload.exercise[0];

            this.store.originalHsgDietRecommendations = data.payload.diet[0].dietItems;
            this.store.hsgDietRecommendations = data.payload.diet[0].dietItems.map(diet => DietRecommendation.adapt(diet));
          }
          return this.akitaGoalMasterQuery.selectLoading();
        }),
        catchError(err => this.akitaGoalMasterQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('goalMaster');
        else this.updateFailure('goalMaster');
      });
  }

  getCIDCMappings(bypass = false) {
    this.checkAndUpdateInit('cidcMapping');
    this.akitaCIDCMappingQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiPcnVisitsSvc.listCIDCMappings()),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaCIDCMappingStore.set(data.payload);
            this.store.cidcTreatmentMappings = data.payload;
          }
          return this.akitaCIDCMappingQuery.selectLoading();
        }),
        catchError(err => this.akitaCIDCMappingQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('cidcMapping');
        else this.updateFailure('cidcMapping');
      });
  }

  getRouteOfAdministrations(bypass = false) {
    this.checkAndUpdateInit('routeOfAdministration');
    this.akitaRouteOfAdministrationQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listSystemStoreValuesByKey('DRUG_ROUTE_OF_ADMIN_MAP')),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaRouteOfAdministrationStore.set(data.payload.values);
            this.store.routeOfAdministrators = data.payload.values;
          }
          return this.akitaRouteOfAdministrationQuery.selectLoading();
        }),
        catchError(err => this.akitaRouteOfAdministrationQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('routeOfAdministration');
        else this.updateFailure('routeOfAdministration');
      });
  }

  getCdlensMappings(bypass = false) {
    this.checkAndUpdateInit('cdlensMapping');
    this.akitaCdlensMappingQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listSystemStoreValuesByKey('CDLENS_SETUP_MAPPING')),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaCdlensMappingStore.set(data.payload.values);
            this.store.cdlensMappings = data.payload.values;
          }
          return this.akitaCdlensMappingQuery.selectLoading();
        }),
        catchError(err => this.akitaCdlensMappingQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('cdlensMapping');
        else this.updateFailure('cdlensMapping');
      });
  }

  getDeliveryLocations(bypass = false) {
    this.checkAndUpdateInit("deliveryLocations");
    this.akitaDeliveryLocationQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listDeliveryLocations()),
        mergeMap(data => {
          if (data) {
            this.akitaDeliveryLocationStore.set(data.payload);
            this.store.deliveryLocations = data.payload;
          }
          return this.akitaDeliveryLocationQuery.selectLoading();
        }),
        catchError(err => this.akitaDeliveryLocationQuery.selectLoading())
      ).subscribe(loading => {
        if (!loading) this.updateSuccess("deliveryLocations");
        else this.updateFailure("deliveryLocations");
      })
  }

  getSflSetup(bypass = false) {
    this.checkAndUpdateInit('sflSetup');
    this.akitaSFLSetupQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listSFLSetupMapping()),
        mergeMap(data => {
          if (data) {
            this.akitaSFLSetupStore.set(data.payload.values);
            this.store.SFLSetupData = data.payload.values;
          }
          return this.akitaSFLSetupQuery.selectLoading();
        }),
        catchError(err => this.akitaSFLSetupQuery.selectLoading())
      ).subscribe(loading => {
        if (!loading) this.updateSuccess('sflSetup');
        else this.updateFailure('sflSetup');
      })
  }

  getWhitelistedItems(bypass = false) {
    this.checkAndUpdateInit("whitelistedItems");
    this.akitaWhitelistedItemQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listWhitelistedItems()),
        mergeMap(data => {
          if (data) {
            this.akitaWhitelistedItemStore.set(data.payload.map(a => ({
              ...a,
              _id: a.itemRefId || a.chargeCode
            })));
            this.store.whitelistedItems = data.payload;
          }
          return this.akitaWhitelistedItemQuery.selectLoading();
        }),
        catchError(err => this.akitaWhitelistedItemQuery.selectLoading())
      ).subscribe(loading => {
        if (!loading) this.updateSuccess("whitelistedItems");
        else this.updateFailure("whitelistedItems");
      })
  }

  getSflItems(bypass = false) {
    this.checkAndUpdateInit("sflItems");
    this.akitaSflItemQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listSflItems()),
        mergeMap(data => {
          if (data) {
            this.akitaSflItemStore.set(data.payload.map(a => ({
              ...a,
              _id: a.itemRefId || a.chargeCode
            })));
            this.store.sflItems = data.payload;
          }
          return this.akitaSflItemQuery.selectLoading();
        }),
        catchError(err => this.akitaSflItemQuery.selectLoading())
      ).subscribe(loading => {
        if (!loading) this.updateSuccess("whitelistedItems");
        else this.updateFailure("whitelistedItems");
      })
  }

  // Remote Delivery Status List
  getRemoteDeliveryOrderStatusList(bypass = false) {
    this.checkAndUpdateInit('remoteDeliverStatus');
    this.akitaRemoteDeliveryOrderStatusQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache => this.apiCmsManagementSvc.listRemoteDeliverySetup()),
        map(data => {
          let deliverySetUp;
          let remoteDeliveryOrderStatus: any[] = [];

          for (const prop in data.payload) {
            if (data.payload.key === 'REMOTE_DELIVERY_SETUP') {
              deliverySetUp = data.payload.values;

              remoteDeliveryOrderStatus = (deliverySetUp as Array<any>).find(item => item.name === 'DELIVERY_ORDER_STATUS').values;
            }
          }

          return remoteDeliveryOrderStatus;
        }),
        mergeMap(data => {
          if (data) {
            this.akitaRemoteDeliveryOrderStatusStore.set(data);
            this.store.remoteDeliveryOrderStatus = data;
          }
          return this.akitaRemoteDeliveryOrderStatusQuery.selectLoading();
        }),
        catchError(err => this.akitaRemoteDeliveryOrderStatusQuery.selectLoading())
      ).subscribe(loading => {
        if (!loading) this.updateSuccess('remoteDeliverStatus');
        else this.updateFailure('remoteDeliverStatus');
      })
  }

  getVitalConfigurationList(bypass = false) {
    this.checkAndUpdateInit('vitalConfiguration');
    this.akitaVitalConfigurationQuery
      .selectHasCache()
      .pipe(
        filter(hasCache => !hasCache || bypass),
        mergeMap(hasCache =>
          this.apiCmsManagementSvc.listAllObservations(true)
        ),
        mergeMap(data => {
          if (data && data.payload) {
            this.akitaVitalConfigurationStore.set(
              data.payload.map(item => (item = { ...item, disabled: false }))
            );
            this.store.afterVitalConfigurationLoaded(data);
          }
          return this.akitaVitalConfigurationQuery.selectLoading();
        }),
        catchError(err => this.akitaVitalConfigurationQuery.selectLoading())
      )
      .subscribe(loading => {
        if (!loading) this.updateSuccess('vitalConfiguration');
        else this.updateFailure('vitalConfiguration');
      });
  }

  checkAndUpdateInit(key) {
    const apiState = this.akitaAppQuery.getEntity(key);
    if (apiState && apiState.apiStatus === API_STATUS.FAILURE) {
      this.akitaAppStore.update(key, createApiState(key, API_STATUS.INIT));
    }
  }

  updateFailure(key) {
    this.akitaAppStore.update(key, createApiState(key, API_STATUS.FAILURE));
  }

  updateSuccess(key) {
    this.akitaAppStore.update(key, createApiState(key, API_STATUS.SUCCESS));
  }

  storeReset() {
    this.akitaAllergyGroupStore.setHasCache(false);
    this.akitaApproverStore.setHasCache(false);
    this.akitaAssessmentStore.setHasCache(false);
    this.akitaChargeItemStore.setHasCache(false);
    this.akitaChronicConditionStore.setHasCache(false);
    this.akitaClinicChargeItemStore.setHasCache(false);
    this.akitaClinicDoctorStore.setHasCache(false);
    this.akitaClinicStore.setHasCache(false);
    this.akitaCommunicationTypeStore.setHasCache(false);
    this.akitaDefaultAssessmentStore.setHasCache(false);
    this.akitaDefaultVitalsStore.setHasCache(false);
    this.akitaDoctorStore.setHasCache(false);
    this.akitaDosageInstructionStore.setHasCache(false);
    this.akitaInstructionStore.setHasCache(false);
    this.akitaInventoryItemClinicDetailStore.setHasCache(false);
    this.akitaInventoryStore.setHasCache(false);
    this.akitaMedicalCoverageStore.setHasCache(false);
    this.akitaNoteTemplateStore.setHasCache(false);
    this.akitaPRJustificationStore.setHasCache(false);
    this.akitaPatientSourceStore.setHasCache(false);
    this.akitaReferralStore.setHasCache(false);
    this.akitaReferralSpecialityStore.setHasCache(false);
    this.akitaRequestReturnStore.setHasCache(false);
    this.akitaStockAdjustmentStore.setHasCache(false);
    this.akitaUomStore.setHasCache(false);
    this.akitaVaccinationStore.setHasCache(false);
    this.akitaVendorStore.setHasCache(false);
    this.akitaVisitPurposeStore.setHasCache(false);
    this.akitaVitalConfigurationStore.setHasCache(false);
    this.akitaPCNVisitStore.setHasCache(false);
    this.akitaGoalMasterStore.setHasCache(false);
    this.akitaCIDCMappingStore.setHasCache(false);
    this.akitaRouteOfAdministrationStore.setHasCache(false);
    this.akitaCdlensMappingStore.setHasCache(false);
  }

  appStateReset() {
    this.authService.appStateReset();
  }

  public unsubscribeStore(): void {
    if (this.akitaClinicChargePurchasableItemQuerSub) {
      this.akitaClinicChargePurchasableItemQuerSub.unsubscribe();
    }
  }
}