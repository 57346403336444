export default class PatientNewBorn {
    attendingDoctor: string;
    referralHospital: string;
    referralDoctor: string;
    birthOder: string;
    breastfed: string;
    standby: string;
    modeOfDelivery: string;
    gestationWeeks: string;
    apgar: string;
    apgar2: string;
    weight: string;
    length: string;
    headCircum: string;
    g6pd: string;
    bilirubin1: string;
    bilirubin2: string;
    freeT4: string;
    tsh: string;
    pku: string;
    metabolicScreen: string;
    galactosemia: string;
    bld: string;
    hbs: string;
    hepBVaccine: string;
    hyperIgG: string;
    bcg: string;
    congenital: string;
    neonatalProblem: string;
    hearingTest: string;
    remarks: string;
    birthTime: string;

    constructor(
        attendingDoctor?: string,
        referralHospital?: string,
        referralDoctor?: string,
        birthOder?: string,
        breastfed?: string,
        standby?: string,
        modeOfDelivery?: string,
        gestationWeeks?: string,
        apgar?: string,
        apgar2?: string,
        weight?: string,
        length?: string,
        headCircum?: string,
        g6pd?: string,
        bilirubin1?: string,
        bilirubin2?: string,
        freeT4?: string,
        tsh?: string,
        pku?: string,
        metabolicScreen?: string,
        galactosemia?: string,
        bld?: string,
        hbs?: string,
        hepBVaccine?: string,
        hyperIgG?: string,
        bcg?: string,
        congenital?: string,
        neonatalProblem?: string,
        hearingTest?: string,
        remarks?: string,
        birthTime?: string,
    ) {
        this.attendingDoctor = attendingDoctor || '';
        this.referralHospital = referralHospital || '';
        this.referralDoctor = referralDoctor || '';
        this.birthOder = birthOder || '';
        this.breastfed = breastfed || '';
        this.standby = standby || '';
        this.modeOfDelivery = modeOfDelivery || '';
        this.gestationWeeks = gestationWeeks || '';
        this.apgar = apgar || '';
        this.apgar2 = apgar2 || '';
        this.weight = weight || '';
        this.length = length || '';
        this.headCircum = headCircum || '';
        this.g6pd = g6pd || '';
        this.bilirubin1 = bilirubin1 || '';
        this.bilirubin2 = bilirubin2 || '';
        this.freeT4 = freeT4 || '';
        this.tsh = tsh || '';
        this.pku = pku || '';
        this.metabolicScreen = metabolicScreen || '';
        this.galactosemia = galactosemia || '';
        this.bld = bld || '';
        this.hbs = hbs || '';
        this.hepBVaccine = hepBVaccine || '';
        this.hyperIgG = hyperIgG || '';
        this.bcg = bcg || '';
        this.congenital = congenital || '';
        this.neonatalProblem = neonatalProblem || '';
        this.hearingTest = hearingTest || '';
        this.remarks = remarks || '';
        this.birthTime = birthTime || '';
    };
}
