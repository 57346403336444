<label 
    *ngIf="config.labelPlacement==='top'" 
    class="mb-0 pr-1 align-items-center d-flex" 
    [innerHTML]="config.label">
</label>

<div 
    class="input-group input-group-sm"
    [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
    
    <label 
        *ngIf="config.labelPlacement === 'left' || config.labelPlacement === '' " 
        [ngClass]="customStyles ? '' : 'mb-0 pr-1 align-items-center d-flex' ">
        {{ config.label }}
    </label>
    <input 
        [ngClass]="customStyles ? 'input' : 'form-control form-control-sm' "
        #dp3="bsDatepicker" 
        bsDatepicker 
        [bsConfig]="bsDatePickerConfig()" 
        [placement]="config.datePickerPlacement" 
        [formControl]="dateControl" 
        [placeholder]="config.placeholder"
        [readOnly]="readOnly"
        (input)="preventManualInput($event)">

    <div 
        class="input-group-append" 
        *ngIf="!hideIcon">
        <span class="input-group-text">
            <i (click)="dp3.show()" [attr.aria-expanded]="dp3.isOpen" class="icon-calendar"></i>
        </span>
    </div>
</div>