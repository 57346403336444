import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { filter, tap, withLatestFrom, distinctUntilChanged, map, take } from 'rxjs/operators';

import { AlertService } from '../../services/alert.service';
import { StoreService } from '../../services/store.service';
import { PatientConditionsService } from './patient-conditions/patient-conditions.service';

import { AkitaChronicConditionQuery } from '../../services/states/akita-chronic-condition.query';
import { AkitaPCNVisitQuery } from '../../services/states/akita-pcn-visit-case.query';
import { AkitaAppQuery } from '../../services/states/akita-app.query';
import { AkitaPatientAppQuery } from '../../services/states/akita-patient/akita-patient-app.query';

import { CheckboxModel } from '../../views/components/checkbox/checkbox.model';

import { PCNFORMS, DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, DATE_ASSESSMENT_CODES } from '../../constants/app.constants';

import PCNDetails, { AssessmentResult } from '../../objects/response/pcn/PCNDetails';
import MedicalConditions from '../../objects/response/MedicalConditions';
import { VitalConfiguration } from '../../objects/response/VitalConfiguration';
import { VitalResponseData } from '../../objects/response/VitalResponseData';
import MedicalAssessment from '../../objects/response/MedicalAssessment';
import { ChronicCondition } from '../../objects/state/ChronicCondition';

import { Subject, Observable, BehaviorSubject, combineLatest } from 'rxjs';

import { ArrayValidators } from '../../validators/ArrayValidators';
import { differenceByKey, updateArrayBy, difference } from '../../util/array.util';

import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { arrayUpdate } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { get } from 'lodash'
import * as moment from 'moment'
import { CarePlanService } from '../../views/components/patient/patient-hsg/care-plan/services/care-plan.service';

const getBool = (obj, ltr) => !!get(obj, ltr)
@Injectable({
  providedIn: 'root'
})
export class PcnFormService implements OnInit, OnDestroy {

  isPcnPatient: boolean;
  isPcnClinic: boolean;
  isCdmClinic: boolean;
  footDateFlag: boolean = false;
  eyeDateFlag: boolean = false;
  pcnForm: FormGroup;
  cidcMappings: any[];

  patientExtraDetailsRawValue: any;
  complicationSubject: Subject<Partial<ChronicCondition>[]> = new BehaviorSubject<Partial<ChronicCondition>[]>([]);
  complications$: Observable<Partial<ChronicCondition>[]> = this.complicationSubject.asObservable();

  vitalsSubject: Subject<VitalConfiguration[]> = new BehaviorSubject<VitalConfiguration[]>([])
  vitals$: Observable<VitalConfiguration[]> = this.vitalsSubject.asObservable();

  assessmentSubject: Subject<MedicalAssessment[]> = new BehaviorSubject<MedicalAssessment[]>([])
  assessments$: Observable<MedicalAssessment[]> = this.assessmentSubject.asObservable();

  // medicationSubject: Subject<OnGoingMedication[]> = new BehaviorSubject<OnGoingMedication[]>([])
  // medications$: Observable<OnGoingMedication[]> = this.medicationSubject.asObservable();

  smokingStatusSubject: Subject<string> = new BehaviorSubject<string>('NON_SMOKER');
  smokingStatus$: Observable<string> = this.smokingStatusSubject.asObservable()

  patientExtraDetailFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  patientExtraDetailFormGroup$ = this.patientExtraDetailFormGroupSubject.asObservable();

  conditionFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  conditionFormGroup$ = this.conditionFormGroupSubject.asObservable();

  complicationFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  complicationFormGroup$ = this.complicationFormGroupSubject.asObservable();

  vitalFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  vitalFormGroup$ = this.vitalFormGroupSubject.asObservable();

  assessmentFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  assessmentFormGroup$ = this.assessmentFormGroupSubject.asObservable();

  constructor(
    private fb: FormBuilder,
    private akitaChronicConditionQuery: AkitaChronicConditionQuery,
    private akitaPCNVisitQuery: AkitaPCNVisitQuery,
    private patientConditionService: PatientConditionsService,
    private formManager: AkitaNgFormsManager,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private alertService: AlertService,
    private akitaAppQuery: AkitaAppQuery,
    private store: StoreService,
    private carePlanService: CarePlanService,
  ) { }

  ngOnDestroy() {
    this.formManager.unsubscribe();
    this.formManager.remove('pcnForm');
    if (this.pcnForm) {
      this.pcnForm.removeControl('patientConditionForm')
      this.pcnForm.updateValueAndValidity();
    }

    this.formManager.remove('pcnForm');
  }

  ngOnInit() { }

  resetBasicForms() {
    for (const key in this.getForm(PCNFORMS.PATIENTCONDITIONFORM).controls) {
      if (key == "selected")
        this.getForm(PCNFORMS.PATIENTCONDITIONFORM).get(key).patchValue(false);
    }
  }

  private initFormGroup() {
    this.getUserCDMPInfo();
    this.isCdmClinic = this.akitaAppQuery.checkClinicFeatureExist('HMC_CDM');
    this.isPcnClinic = this.akitaAppQuery.checkPCNClinic();

    this.pcnForm = this.fb.group({
      patientExtraDetailsForm: this.createPatientExtraDetailsForm(),
      patientConditionForm: this.createPatientConditionForm(),
      complicationForm: this.createComplicationForm(),
      vitalForm: this.createVitalForm(),
      assessmentForm: this.createAssessmentForm(),
    });

    this.formManager.upsert('pcnForm', this.pcnForm);
    this.emitIndividualFormGroup();
    this.subscribeValueChanges();
  }

  emitIndividualFormGroup() {
    this.patientExtraDetailFormGroupSubject.next(this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM));
    this.conditionFormGroupSubject.next(this.getForm(PCNFORMS.PATIENTCONDITIONFORM));
    this.complicationFormGroupSubject.next(this.getForm(PCNFORMS.COMPLICATIONFORM));
    this.vitalFormGroupSubject.next(this.getForm(PCNFORMS.VITALFORM));
    this.assessmentFormGroupSubject.next(this.getForm(PCNFORMS.ASSESSMENTFORM));
  }

  initPCNForm() {
    this.initFormGroup();
    this.enableDisablePCNForms()
    this.patientExtraDetailsRawValue = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM).getRawValue();
    this.patchPCNForm();
  }

  resetPCN(resetVisitStore = true) {

    if (resetVisitStore) {
      this.akitaPCNVisitQuery.resetStore();
    }
    this.formManager.remove('pcnForm');
    this.patientConditionService.toggleActiveCondition([]);
    this.complicationSubject.next([]);
    this.vitalsSubject.next([]);
    this.assessmentSubject.next([]);
    this.smokingStatusSubject.next('NON_SMOKER');
    // this.medicationSubject.next([]);
    this.resetForms();
  }

  resetForms() {
    this.initFormGroup();
  }

  getUserCDMPInfo() {
    this.akitaPatientAppQuery.patientInfo$.subscribe(res => {
      this.isPcnPatient = getBool(res, 'primaryCareNetwork.optIn') && !getBool(res, 'primaryCareNetwork.optOut')
    });
  }

  enableDisablePCNForms() {
    this.alertService.getPcnFormDisable().pipe(
      tap(disable => { }),
      untilDestroyed(this)
    ).subscribe(disable => {
      if (disable) {
        this.pcnForm.disable()
      } else {
        this.pcnForm.enable()
        this.pcnForm.updateValueAndValidity();
        const patientConditionForm = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM);

        if (patientConditionForm.get('smokingStatus').value === 'NON_SMOKER' || patientConditionForm.get('smokingStatus').value === 'EX_SMOKER') {
          patientConditionForm.get('yearStartOfSmoke').disable();
        }
        if (!patientConditionForm.get('rightSiting').value) {
          patientConditionForm.get('rightSitingReferenceSource').disable();
        }
      }
    })
  }

  createPatientExtraDetailsForm(): FormGroup {
    return this.fb.group({
      chasUsage: this.isPcnPatient ? ['', Validators.required] : '',
      smokingStatus: null,
      stateOfChange: null,
      yearStartOfSmoke: '',
      rightSiting: false,
      rightSitingReferenceSource: '',
      referenceSource: this.isPcnPatient ? ['', Validators.required] : '',
      remark: ''
    })
  }

  createPatientConditionForm(): FormGroup {
    return this.fb.group({
      conditions: this.buildConditions()
    })
  }

  createComplicationForm(): FormGroup {
    return this.fb.group({
      complications: this.fb.array([])
    })
  }

  createVitalForm(): FormGroup {

    return this.fb.group({
      'nextVisitDate': new FormControl('')
    })
  }

  createAssessmentForm(): FormGroup {
    return this.fb.group({
    })
  }

  getForm(formName: PCNFORMS): FormGroup {
    return this.pcnForm.get(formName) as FormGroup;
  }

  createConditionControl = (value: CheckboxModel) => new FormControl(value);

  buildConditions() {
    const arr = this.akitaChronicConditionQuery.getPatientConditions().
      map(condition => this.createConditionControl(condition));
    return this.fb.array(arr, {
      validators: ArrayValidators.minLengthWithKey(1, 'selected')
    });
  }

  patchPCNForm() {
    this.patchPatientExtraDetails();
    this.patchPatientConditionForm();
    this.patchComplicationsForms();
    this.patchVitalForm();
    this.pathAssessmentForm();
  }

  patchPatientExtraDetails() {
    this.akitaPCNVisitQuery.getSelectedPCNVisitPatientExtraDetails()
      .pipe(
        tap(),
        filter(data => !!data),
        untilDestroyed(this)
      ).subscribe(
        details => {
          const patientExtraDetailsForm = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM);
          const formValues = patientExtraDetailsForm.getRawValue();
          const finalValues = !!formValues.chasUsage ? formValues : details;
          patientExtraDetailsForm.setValue({ ...this.patientExtraDetailsRawValue, ...finalValues });
          this.enableDisableForm(PCNFORMS.PATIENTEXTRADETAILSFORM);
          this.patientExtraDetailFormGroupSubject.next(patientExtraDetailsForm);
        });
  }

subscribeValueChanges() {
  const patientExtraDetailsForm = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM) as FormGroup;
  if (patientExtraDetailsForm) {
    patientExtraDetailsForm.get('smokingStatus').valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(smokingStatus => {
      this.smokingStatusSubject.next(smokingStatus)
      const assessmentForm = this.getForm(PCNFORMS.ASSESSMENTFORM);
      const smokeControl = assessmentForm.get('ASSESS_SMOKE');
      if (smokingStatus === 'SMOKER') {
        if (!!smokeControl) {
          smokeControl.setValidators([Validators.required]);
          smokeControl.enable()
        } else {
          assessmentForm.addControl('ASSESS_SMOKE', new FormControl('', Validators.required));
        }
      } else {
        if (!!smokeControl) {
          assessmentForm.removeControl('ASSESS_SMOKE')
        }
      }
      this.getSmokingStatusValidation(smokingStatus, patientExtraDetailsForm);
    });

    patientExtraDetailsForm.get('rightSiting').valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(rightSiting => {        
      this.getRightSitingValidation(rightSiting, patientExtraDetailsForm);
    });
  }
}

  getSmokingStatusValidation(smokingStatus, patientExtraDetailsForm){
    if (smokingStatus === 'SMOKER') {
      patientExtraDetailsForm.get('yearStartOfSmoke').enable();
      patientExtraDetailsForm.get('yearStartOfSmoke').setValidators(Validators.compose([Validators.required]));
    } else {
      patientExtraDetailsForm.get('yearStartOfSmoke').disable();
      patientExtraDetailsForm.get('yearStartOfSmoke').clearValidators();
    }
    patientExtraDetailsForm.get('yearStartOfSmoke').markAsTouched();
    patientExtraDetailsForm.get('yearStartOfSmoke').updateValueAndValidity();
    patientExtraDetailsForm.updateValueAndValidity();
  }

  getRightSitingValidation(rightSiting, patientExtraDetailsForm){
    if (rightSiting) {
      patientExtraDetailsForm.get('rightSitingReferenceSource').enable();
      patientExtraDetailsForm.get('rightSitingReferenceSource').setValidators(Validators.compose([Validators.required]));
    } else {
      patientExtraDetailsForm.get('rightSitingReferenceSource').disable();
      patientExtraDetailsForm.get('rightSitingReferenceSource').clearValidators();
    }
    patientExtraDetailsForm.get('rightSitingReferenceSource').markAsTouched();
    patientExtraDetailsForm.get('rightSitingReferenceSource').updateValueAndValidity();
    patientExtraDetailsForm.updateValueAndValidity();
  }

  // addDisableMechanism(controlName: string, controlToBeDisabled: string, isSmokingStatus: boolean = false) {
  //   const patientExtraDetailsFG = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM);
  //   const control = patientExtraDetailsFG.get(controlToBeDisabled)
  //   control.disable();

  //   patientExtraDetailsFG.get(controlName).valueChanges.pipe(
  //     untilDestroyed(this)
  //   ).subscribe(value => {
  //     if (!isSmokingStatus ? value : value !== 'NON_SMOKER') {
  //       control.enable();
  //       control.setValidators(Validators.compose([Validators.required]));
  //       control.updateValueAndValidity()
  //     } else {
  //       control.disable();
  //       control.clearValidators();
  //       control.updateValueAndValidity()
  //     }
  //   });
  // }

  patchPatientConditionForm(updateFromDB?:boolean) {

    this.akitaPCNVisitQuery.getSelectedPCNVisitMedicalCondition().pipe(untilDestroyed(this))
      .subscribe(conditions => {
        const conditionForm = this.getForm(PCNFORMS.PATIENTCONDITIONFORM);

        const formManagerValues = !!conditionForm ?
          conditionForm.value.conditions.filter(condition => !!condition.selected) : [];
        const finalSelectedCondition = formManagerValues.length ? formManagerValues : conditions;
        if (updateFromDB && conditionForm) {
          this.resetPatientConditionsForm();
        }
        this.patchConditions(updateFromDB ? conditions : finalSelectedCondition, conditionForm?.get('conditions'));

        this.enableDisableForm(PCNFORMS.PATIENTCONDITIONFORM);
        this.conditionFormGroupSubject.next(conditionForm);
      });
  }

  enableDisableForm(formName: PCNFORMS) {
    const self = this;
    // settimeout is used cz by the time enbale diable is called form is not ready
    // so sending it into timeout

    /**
     * subcription to pcnRequired is used instead of loacl variable cz local variable is
     * not reliable and causing pcn tab to be red warning mark when redirecting
     * to profile page after complete.
     *
     * */


    setTimeout(function () {
      self.alertService.getPcnFormDisable().pipe(take(1)).subscribe(
        disable => {
          const form = self.getForm(formName);
          if (form) {
            if (!disable) {
              form.enable();
              form.markAllAsTouched();
            } else {
              form.disable();
            }
            if (formName === PCNFORMS.PATIENTEXTRADETAILSFORM && (form.get('smokingStatus').value === 'NON_SMOKER' ||
            form.get('smokingStatus').value === 'EX_SMOKER')) {
              form.get('yearStartOfSmoke').disable();
            }
            if (formName === PCNFORMS.PATIENTEXTRADETAILSFORM && !form.get('rightSiting').value) {
              form.get('rightSitingReferenceSource').disable();
            }
          }
        }
      )
    })
  }

  patchConditions(conditions: MedicalConditions[], conditionControl: AbstractControl) {
    const conditionCodes = conditions.map(({ code }) => code);
    conditionControl?.setValue(arrayUpdate(conditionControl.value, condition => conditionCodes.includes(condition.code), { selected: true }));
  }

  patchComplications(formValues: MedicalConditions[]) {
    const control = this.getForm(PCNFORMS.COMPLICATIONFORM).get('complications') as FormArray;
    const updateValues = updateArrayBy(control.getRawValue(), formValues, 'code')

    control.patchValue(updateValues);
  }

  patchCidcTreatments() {
    const controls = this.getForm(PCNFORMS.COMPLICATIONFORM).get('complications') as FormArray;
    const complications: AbstractControl[] = controls.controls;

    complications.forEach(element => {
      const control = element as FormGroup;

      const cidcTreatments = control.get('cidcTreatments') as FormControl;
      let treatmentFGs = [];

      if (cidcTreatments && cidcTreatments.value) {
        const treatments = cidcTreatments.value;
        const minYearStarted = element.get('yearOfOnSet').value === 0 ? 1000 : element.get('yearOfOnSet').value;
        const currentYear = moment().year();

        if (treatments.length > 0) {
          treatmentFGs = [];
          for (let treatment of treatments) {
            if (treatment.isSelected === undefined || treatment.isSelected === true) {
              treatmentFGs.push(this.fb.group({
                code: treatment.code || '',
                description: treatment.description || '',
                yearStarted: [treatment.yearStarted || null, [Validators.required, Validators.min(minYearStarted), Validators.max(currentYear)]],
                remarks: treatment.remarks || '',
                isSelected: true,
              }));
            }
          }
        }
      }

      const cidcMapping = this.store.cidcTreatmentMappings.find(cidc => cidc.cmsCode === control.get('code').value);

      if (cidcMapping && cidcMapping.treatmentList) {
        for (let treatmentMaster of cidcMapping.treatmentList) {
          const selected = treatmentFGs.find(treatment => treatment.get('code').value === treatmentMaster.code);
          if (!selected) {
            treatmentFGs.push(this.fb.group({
              code: treatmentMaster.code,
              description: treatmentMaster.description,
              yearStarted: [null, Validators.min(1000)],
              remarks: '',
              isSelected: false,
            }));
          }
        }
      }

      let treatmentFArray = this.fb.array(treatmentFGs);
      control.setControl('cidcTreatments', treatmentFArray);
    });
  }

  patchComplicationsForms(updateFromDB?: boolean) {

    this.akitaChronicConditionQuery.selectActiveChronicConditionsWithComplication()
      .pipe(
        withLatestFrom(this.akitaPCNVisitQuery.getSelectedPCNVisitMedicalCondition()),
        untilDestroyed(this)
      ).subscribe(([activeComplications, complicationValues]) => {
        const complicationFG = this.getForm(PCNFORMS.COMPLICATIONFORM);
        const complicationArray = complicationFG.get('complications') as FormArray;
        const valueBeforeClear = complicationArray.getRawValue();
        complicationArray.clear();

        this.addComplicationGroup(updateFromDB ? complicationValues : activeComplications);
        const newlyAddedCondition = differenceByKey(valueBeforeClear, complicationValues, 'code')
        let updatedValues = updateArrayBy(complicationValues, valueBeforeClear, 'code');
        updatedValues = updateFromDB ? complicationValues : updatedValues.concat(newlyAddedCondition);

        this.patchComplications(updatedValues);
        this.patchCidcTreatments();

        this.enableDisableForm(PCNFORMS.COMPLICATIONFORM);

        this.complicationFormGroupSubject.next(complicationFG);
        this.complicationSubject.next(activeComplications);
      })
  }

  patchVitalForm() {
    this.akitaChronicConditionQuery.selectVitalsForActiveConditions()
      .pipe(
        withLatestFrom(this.akitaPCNVisitQuery.getSelectedPCNVisitVitals(), this.akitaPCNVisitQuery.getSelectedPCNVisitVitalTestTaken()),
        untilDestroyed(this)
      ).subscribe(([vitalFields, vitalValues, vaitalTakenList]) => {
        const vitalForm: any = this.getForm(PCNFORMS.VITALFORM)

        // setting tentative visit date here cz ifd we set when
        // createing the control  visit query is undefined then.
        if (vitalFields.length) {
          let tenativeNextVisitDate = moment(this.akitaPCNVisitQuery.getStartTime(), 'DD-MM-YYYY').add(6, 'days').endOf('month').toDate();
          vitalForm.get('nextVisitDate').setValue(tenativeNextVisitDate);
        }

        const formValues = !!vitalForm ? { ...vitalForm.getRawValue() } : {}

        vitalFields = this.updateVitalDates(vitalFields, vaitalTakenList);

        this.addVitalControl(vitalFields);
        this.patchVital(vitalValues, formValues);

        this.enableDisableForm(PCNFORMS.VITALFORM);
        this.vitalFormGroupSubject.next(vitalForm);

        this.vitalsSubject.next(vitalFields);
      });
  }

  updateVitalDates(vitalFields, vaitalTakenList) {
    if (vaitalTakenList) {
      return vitalFields.map((vital) => ({
        ...vital,
        takenDate: vaitalTakenList.find(vitalT => vital.code === vitalT.code) && vaitalTakenList.find(vitalT => vital.code === vitalT.code).takenDate ?
          moment(vaitalTakenList.find(vitalT => vital.code === vitalT.code).takenDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
        nextDueDate: vaitalTakenList.find(vitalT => vital.code === vitalT.code) && vaitalTakenList.find(vitalT => vital.code === vitalT.code).nextDueDate ?
          moment(vaitalTakenList.find(vitalT => vital.code === vitalT.code).nextDueDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
      }));
    } else {
      return vitalFields;
    }
  }

  // this method is called to sync the vital from when
  // vitals are updated from vital tab
  updateVitalInFormOnVitalUpdate() {
    const vitalForm: any = this.getForm(PCNFORMS.VITALFORM);
    const vitals = this.akitaPCNVisitQuery.getActive().registryEntity.medicalReferenceEntity.cdmpDetails.vitals;
    const finalValue = {};
    Object.entries(vitalForm.value).forEach(([key, value]) => {
      const vital = vitals.find(v => v.code === key)
      finalValue[key] = !!vital ? vital.value : value;
    })

    vitalForm.setValue(finalValue);
  }

  updateAssessmentInFormOnAssessmentUpdate() {
    const assessmentForm: any = this.getForm(PCNFORMS.ASSESSMENTFORM);
    const assessments = this.akitaPCNVisitQuery.getActive().registryEntity.medicalReferenceEntity.cdmpDetails.assessment.assessments;
    const finalValue = {};
    Object.entries(assessmentForm.value).forEach(([key, value]) => {
      const assessment = assessments[key];
      finalValue[key] = !!assessment ? assessment : (!!value ? value : '');
    })

    assessmentForm.setValue(finalValue);
  }

  addVitalControl(vitals: VitalConfiguration[]) {
    const vitalFormGroup = this.getForm(PCNFORMS.VITALFORM);
    vitals.forEach(vital => {
      vitalFormGroup.addControl(vital.code, new FormControl);
    })

    this.removeUnwantedVitalKeys(vitals);
  }

  removeUnwantedVitalKeys(vitals: VitalConfiguration[]) {
    const vitalFormGroup = this.getForm(PCNFORMS.VITALFORM);
    const keys = Object.keys(vitalFormGroup.controls);
    const detailsKeys = vitals.map(data => data.code);

    const differentKeys = difference(keys, detailsKeys);
    differentKeys.forEach(key => {
      if (key !== 'nextVisitDate') {
        vitalFormGroup.removeControl(key)
      }
    });
  }

  patchVital(vitalValues: VitalResponseData[], storedValues?: any) {

    const vitalFormGroup = this.getForm(PCNFORMS.VITALFORM);

    const finalValue = {};
    // checking for stored values : if user changes values and goes to different
    // page without saving then always take latest for that patient
    const formValue = { ...vitalFormGroup.value }
    Object.keys(formValue).forEach(code => {
      finalValue[code] = !!storedValues[code] || storedValues[code] === '' ? storedValues[code] : formValue[code];
    });


    if (!!vitalValues.length) {
      vitalValues.forEach(({ code, value }) => {
        finalValue[code] = !!storedValues[code] || storedValues[code] === '' ? storedValues[code] : value;
      });
    }
    vitalFormGroup.patchValue(finalValue);
  }

  updateAssessmentDates(assessmentFields, assessmentTakenList) {
    if (assessmentTakenList) {
      return assessmentFields.map((assessment) => ({
        ...assessment,
        takenDate: assessmentTakenList.find(vitalT => assessment.code === vitalT.code) && assessmentTakenList.find(vitalT => assessment.code === vitalT.code).takenDate ?
          moment(assessmentTakenList.find(vitalT => assessment.code === vitalT.code).takenDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
        nextDueDate: assessmentTakenList.find(vitalT => assessment.code === vitalT.code) && assessmentTakenList.find(vitalT => assessment.code === vitalT.code).nextDueDate ?
          moment(assessmentTakenList.find(vitalT => assessment.code === vitalT.code).nextDueDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
      }));
    } else {
      return assessmentFields;
    }

  }

  pathAssessmentForm() {
    this.akitaChronicConditionQuery.selectAssesmentForActiveCondition()
      .pipe(
        withLatestFrom(this.akitaPCNVisitQuery.getSelectedPCNVisitAssesment(), this.akitaPCNVisitQuery.getSelectedPCNVisitAssessmentTestTaken()),
        untilDestroyed(this)
      ).subscribe(([assessmentsFields, assessmentsValues, assessmentTakenList]) => {
        assessmentsFields.push({ id : "", code : "FAGERSTROM_TEST_S", name : "Fagerstrom Test Score"})

        assessmentsFields.sort((a,b) => a.sortIndex && b.sortIndex ? a.sortIndex - b.sortIndex : -1);
        
        const assessmentForm = this.getForm(PCNFORMS.ASSESSMENTFORM);
        
        const formManagerValues = !!assessmentForm ? { ...assessmentForm.getRawValue() } : {}

        assessmentsFields = this.updateAssessmentDates(assessmentsFields, assessmentTakenList);

        this.addAssessmentControl(assessmentsFields);
        this.patchAssessment(assessmentsValues, formManagerValues);

        this.enableDisableForm(PCNFORMS.ASSESSMENTFORM);
        this.assessmentFormGroupSubject.next(assessmentForm);
        this.assessmentSubject.next(assessmentsFields);

        const assessmentFormGroup = this.getForm(PCNFORMS.ASSESSMENTFORM);
      });
  }

  addAssessmentControl(assessments: MedicalAssessment[]) {

    const assessmentFormGroup = this.getForm(PCNFORMS.ASSESSMENTFORM);
    assessments.forEach(assessment => {
      assessmentFormGroup.addControl(assessment.code, new FormControl(''));
    })

    this.removeUnwantedAssessmentKeys(assessments);
  }

  removeUnwantedAssessmentKeys(assessments: MedicalAssessment[]) {
    const assessmentFormGroup = this.getForm(PCNFORMS.ASSESSMENTFORM);
    const keys = Object.keys(assessmentFormGroup.controls);
    const detailsKeys = assessments.map(data => data.code);

    const differentKeys = difference(keys, detailsKeys);
    differentKeys.forEach(key => {
      if (key !== 'ASSESS_SMOKE') {
        assessmentFormGroup.removeControl(key)
      }
    });
  }

  patchAssessment(assesmentResult: AssessmentResult, storedValues: any) {

    const assessments = !!assesmentResult ? assesmentResult.assessments : {};
    let finalValues = {};
    const assessmentFormGroup = this.getForm(PCNFORMS.ASSESSMENTFORM);
    Object.keys(assessmentFormGroup.getRawValue()).forEach(key => {
      if (DATE_ASSESSMENT_CODES.includes(key)) {
        if (assessments[key]) {
          finalValues[key] = moment(assessments[key]).format('DD-MM-YYYY');
        }
      } else {
        finalValues[key] = !!storedValues[key] ? storedValues[key] : assessments[key]
      }
    });

    finalValues = { ...assessments, ...finalValues }
    assessmentFormGroup.patchValue(finalValues);
  }

  addComplicationGroup(conditions: Partial<ChronicCondition>[]) {
    const codes = conditions.map(({ code }) => code);
    const complicationArray = this.getForm(PCNFORMS.COMPLICATIONFORM).get('complications') as FormArray;

    codes.forEach(code => {
      const fg = this.createComplicationControl(code);
      complicationArray.push(fg)
    });
  }

  createComplicationControl = code => this.fb.group({
    code: code,
    yearOfOnSet: [0, [Validators.required, Validators.min(1000)]],
    diagnosisIds: [],
    snomedList: [],
    cidcTreatments: [],
    status: ['ACTIVE'],
  });

  isPCNFormValid(): Observable<boolean> {
    return combineLatest(
      this.patientConditionService.pcnRequired$,
      this.formManager.selectValid('pcnForm')
    ).pipe(
      map(([pcnRequired, pcnFormValidity]) => {
        if (!pcnRequired) {
          return false;
        }

        if (pcnFormValidity) {
          return false;
        }

        return true;
      }),
      untilDestroyed(this)
    );
  }

  isSaveButtonDisabled(): Observable<boolean> {
    return combineLatest(
      this.patientConditionService.pcnRequired$,
      this.formManager.selectValid('pcnForm')
    ).pipe(
      map(([pcnRequired, pcnFormValidity]) => {
        if (!pcnRequired) {
          return false;
        }

        if (pcnFormValidity) {
          return false;
        }
        return true;
      })
    )
  }

  getVitals(vitalFormValues: any[], patientId: string, startTime: string): VitalResponseData[] {
    return Object.entries(vitalFormValues).filter(([key, value]) => key !== 'nextVisitDate')
      .map(([key, value]) => {
        const takenTime = this.getTimeTakenByVitalCode(key);
        return {
          code: key,
          value,
          patientId,
          takenTime: takenTime || startTime,
        } as VitalResponseData
      })
  }

  getTimeTakenByVitalCode(vitalCode: string): string | null {
    const vitals = this.akitaPCNVisitQuery.getActive().registryEntity.medicalReferenceEntity.cdmpDetails.vitals;
    const foundVitals = vitals.find(({ code }) => code === vitalCode);
    return !!foundVitals ? foundVitals.takenTime : null;
  }

  getPCNPayload() {
    const assessments = this.getForm(PCNFORMS.ASSESSMENTFORM).value;
    const complicationFormArray = this.getForm(PCNFORMS.COMPLICATIONFORM).get('complications') as FormArray;
    const complicationControls = complicationFormArray.controls;
    const patientExtraDetails = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM).value;
    const vitalForm = this.getForm(PCNFORMS.VITALFORM).value;

    let updatedComplications = [];
    for (let complication of complicationControls) {
      let cidcTreatments = complication.get('cidcTreatments').value;
      if (cidcTreatments && cidcTreatments.length > 0) {
        cidcTreatments = cidcTreatments.filter(treatment => treatment.isSelected);
      }

      let newComplication = {
        code: complication.get('code').value,
        yearOfOnSet: complication.get('yearOfOnSet').value,
        diagnosisIds: complication.get('diagnosisIds').value,
        snomedList: complication.get('snomedList').value,
        cidcTreatments: cidcTreatments,
        status: complication.get('status').value,
      }

      updatedComplications.push(newComplication);
    }

    if (this.akitaPCNVisitQuery.getActiveId()) {
      const patientId = this.akitaPCNVisitQuery.getPatientId();
      const visitId = '' + this.akitaPCNVisitQuery.getActiveId();
      const startTime = this.akitaPCNVisitQuery.getStartTime();
      const assesmentValues = { ...this.akitaPCNVisitQuery.getPCNInfo().assessment } || new AssessmentResult();
      const vitalTestTakenList = this.akitaPCNVisitQuery.getPCNInfo().vitalTestTakenList || [];
      const assessmentTestTakenList = this.akitaPCNVisitQuery.getPCNInfo().assessmentTestTakenList || [];
      assesmentValues.assessmentId = (assesmentValues.visitId !== visitId) ? null : assesmentValues.assessmentId;
      assesmentValues.patientId = patientId;
      assesmentValues.visitId = (assesmentValues.visitId !== visitId) ? visitId : assesmentValues.visitId;
      assesmentValues.takenTime = startTime;

      return {
        conditions: updatedComplications.map(item => ({ ...item })),
        vitals: this.getVitals(vitalForm, patientId, startTime),
        patientExtraDetails,
        assessment: { ...new AssessmentResult(), ...assesmentValues, assessments },
        assessmentTestTakenList: [...assessmentTestTakenList],
        vitalTestTakenList: [...vitalTestTakenList],
      }
    } else {
      return {
        conditions: updatedComplications.map(item => ({ ...item })),
        vitals: this.getVitals([], "", ""),
        patientExtraDetails,
        assessment: new AssessmentResult(),
        assessmentTestTakenList: [],
        vitalTestTakenList: [],
      }
    }
  }

  getPCNPayloadForConsultation(): PCNDetails {
    if (this.isCdmClinic) {
      return this.getPCNPayload();
    } else {
      return this.getServerPCNDetails();
    }
  }

  getServerPCNDetails(): PCNDetails {
    const pcnVisit = this.akitaPCNVisitQuery.getAll()[0];
    if (pcnVisit && pcnVisit.registryEntity) {
      return pcnVisit.registryEntity.medicalReferenceEntity.cdmpDetails;
    } else {
      return null;
    }
  }

  getSelectedPcnConditions() {
    const patientConditionForm = this.getForm(PCNFORMS.PATIENTCONDITIONFORM);
    return patientConditionForm.value.conditions.filter(condition => condition.selected);
  }

  onWeightHeightValueChange() {
    const vitalFormGroup = this.getForm(PCNFORMS.VITALFORM);
    const weight = +vitalFormGroup.get('weight').value;
    const height = +vitalFormGroup.get('height').value;
    if (!!weight && !!height && weight > 0 && height > 0) {
      const bmi = this.calculateBmi(weight, height);
      const bmiControl = vitalFormGroup.get('bmi');
      bmiControl.setValue(bmi);
    } else {
      const bmiControl = vitalFormGroup.get('bmi');
      if (!!bmiControl) {
        bmiControl.setValue('');
      }
    }
  }

  calculateBmi(weightInKg, heigthInCm) {
    if (weightInKg > 0 && heigthInCm > 0) {
      const heigthInMeter = heigthInCm / 100;
      //Added toFixed by Donna, suggested by Shamil to round BMI to 2 d.p
      const bmi = (weightInKg / (heigthInMeter * heigthInMeter)).toFixed(2);
      return bmi;
    }
  }

  isVitalControlValid(controlName: string): boolean {
    const vitalFormGroup = this.getForm(PCNFORMS.VITALFORM);
    const control = vitalFormGroup.get(controlName);
    return !!control && control.enabled ? (control.value && control.invalid) : false;
  }

  isComplicationControlInvalid(index: number, controlName?: string): boolean {
    const complicationForm = this.getForm(PCNFORMS.COMPLICATIONFORM);
    const formArray = complicationForm.get('complications') as FormArray;
    const control = formArray.controls[index];
    return !!control ? control.get(controlName ? controlName : 'yearOfOnSet').invalid : true;
  }

  isComplicationControlInvalidByCode(code: string, controlName?: string): boolean {
    const complicationForm = this.getForm(PCNFORMS.COMPLICATIONFORM);
    const formArray = complicationForm.get('complications') as FormArray;
    const control = formArray.controls.find(control => control.value.code === code);
    return !!control ? control.get(controlName ? controlName : 'yearOfOnSet').invalid : true;
  }

  isPatientExtraDetailsControlInvalid(controlName: string): boolean {
    const patientExtraDetailsFG = this.getForm(PCNFORMS.PATIENTEXTRADETAILSFORM);
    const control = patientExtraDetailsFG.get(controlName);
    return control.enabled ? (control.value === '' || control.invalid) : false;
  }

  onPatientConditionChange() {
    const patientConditionForm = this.getForm(PCNFORMS.PATIENTCONDITIONFORM);
    const selectedConditionCode = patientConditionForm.value.conditions
      .filter(condition => condition.selected)
      .map(condition => condition.code);
    this.patientConditionService.toggleActiveCondition(selectedConditionCode);
    this.carePlanService.setUpdatedPatientConditions(selectedConditionCode);
  }

  isAssessmentControlValid(controlName: string): boolean {
    const assessmentFormGroup = this.getForm(PCNFORMS.ASSESSMENTFORM);
    const control = assessmentFormGroup.get(controlName);
    return !!control && control.enabled ? (control.value === '' || control.invalid) : false;
  }

  isAssessmentExtraControlValid(controlName: string): boolean {
    const assessmentFormGroup = this.getForm(PCNFORMS.ASSESSMENTFORM);
    const control = assessmentFormGroup.get(controlName);
    if (control != undefined) {
      if (control.value != null || control.value != undefined || control.value != '') {
        return true;
      } else {
        return false
      }
    } else {
      return false;
    }
  }

  resetPatientConditionsForm() {
    const patientConditionForm = this.getForm(PCNFORMS.PATIENTCONDITIONFORM);
    if (patientConditionForm && patientConditionForm.value) {
      let conditionFormValue = patientConditionForm.value.conditions;
      conditionFormValue = conditionFormValue.map(condition => {
        return {...condition, selected: false}
      });

      patientConditionForm.get('conditions').setValue(conditionFormValue);
    }
  }
}