import { AkitaPatientAppQuery } from './../../../../services/states/akita-patient/akita-patient-app.query';
import { AlertService } from './../../../../services/alert.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiCmsManagementService } from './../../../../services/api-cms-management.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { StoreService } from './../../../../services/store.service';
import {
  DB_FULL_DATE_FORMAT,
  DB_FULL_DATE_FORMAT_ZERO_SECOND,
} from './../../../../constants/app.constants';
import { Practice } from './../../../../objects/SpecialityByClinic';
import {
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidatorFn,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import DatePickerConfig from '../../../../objects/DatePickerConfig';
import { Subject } from 'rxjs';
import { AkitaCaseVisitQuery } from '../../../../services/states/akita-case-visit.query';
import { PatientVisitHistoryQuery } from '../../../../services/states/akita-patient/patient-visit-history/patient-visit-history.query';
import { MedicalCoverageByCaseRes } from '../../../../objects/PolicyHolderInfo';
import { Case, Coverages } from '../../../../objects/Case';
import { AkitaReferralQuery } from '../../../../services/states/akita-referral.query';

import { PatientReferralAlertComponent } from '../../../../views/components/patient/patient-referral-alert/patient-referral-alert.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';
import { UtilsService } from '../../../../services/utils.service';
import { logAction } from '@datorama/akita';
@Component({
  selector: 'app-referral-item',
  templateUrl: './referral-item.component.html',
  styleUrls: ['./referral-item.component.scss'],
})
export class ReferralItemComponent implements OnInit, OnDestroy {
  @Input() referralItem: FormGroup;
  @Input() parentComponentDestroyed: Subject<void>;
  @Input() isTemplateHidden: boolean;
  @Output() onDelete = new EventEmitter<number>();
  @Input() index: number;
  @Input() isInModalValidationActive = false;
  @Input() editMode: boolean = false;

  public isPracticeEmpty: boolean = true;
  public minFiveCharachters: boolean = false;

  title: String;
  isExternalReferral: false;
  practices = [];
  availableSpecialities = [];
  doctorOptions = [];
  selectedDoctor = [];
  isPaymentMode: boolean;
  practiceAddTag: boolean;

  clinic: any;
  refDoctor: string;
  refAddress: string;
  private docName: string;
  private docAddress: string;
  refTypeClass: string = 'col-md-2';
  refTypeClassO: string = 'col-md-1';
  doctor: any;

  isCollapsed = false;
  shouldShowSelectDoctorTable = true;

  doctorFilter: FormControl;
  doctorFilterOptions = [];
  externalReferralTypes = [
    { value: 'Fast-Track', label: 'Fast-Track', disabled: false},
    { value: 'Routine', label: 'Routine', disabled: false},
    { value: 'GP First', label: 'GP First', disabled: false},
    { value: 'A&E/UCC', label: 'A&E/UCC', disabled: false},
  ];
  referralTypes = [
    { value: 'INTERNAL', label: 'INTERNAL' },
    { value: 'EXTERNAL', label: 'EXTERNAL' },
    { value: 'PARTNER', label: 'PARTNER' },
    { value: 'HOSPITAL', label: 'HOSPITAL' },
  ];
  referralOrder = ['INTERNAL', 'PARTNER', 'HOSPITAL'];
  isAppend = true;
  templates: any;
  template;
  minDate = new Date();
  datePickerConfig: DatePickerConfig = new DatePickerConfig(
    'Appointment Date&nbsp;<span class="compulsory">*</span>',
    null,
    null,
    'bottom',
    'top'
  );

  appointmentTime: FormControl;
  appointmentDate: FormControl;

  private componentDestroyed: Subject<void> = new Subject();

  priorityMedicalCoverage: string = '';
  patientCoverages: FormArray;
  attachedPlan: FormArray;
  storedAttachedPlan: FormArray;
  medicalCoverageIds = [];
  isNew: boolean = false;
  loggedInClinic: any;

  internalDocName: string;
  internalClinicName: string;
  internalClinicAddress: string;
  isExternalReferralType: boolean = false;
  isHositalType: boolean = false;
  showFullText = []

  constructor(
    private store: StoreService,
    private apiCmsManagementService: ApiCmsManagementService,
    private permissionsService: NgxPermissionsService,
    private alertService: AlertService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private patientVisitHistoryQuery: PatientVisitHistoryQuery,
    private fb: FormBuilder,
    private referralDataQuery: AkitaReferralQuery,
    private bsModalService: BsModalService,
    private akitaAppQuery: AkitaAppQuery,
    private utilsService: UtilsService,
  ) {
    this.appointmentTime = new FormControl(null);
    this.appointmentDate = new FormControl(null);
    this.doctorFilter = new FormControl();
    this.practiceAddTag = true;
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }
  ngOnInit() {
    this.docName = this.referralItem
      .get('externalReferralDetails')
      .get('doctorName').value;
    this.docAddress = this.referralItem
      .get('externalReferralDetails')
      .get('address').value;
    this.patchExternalDetails();
    this.templates = this.store.getTemplatesByTemplateCategory('REFERRAL');
    this.isNew = !!this.referralItem.get('isNew');
    this.title = '';

    this.isPaymentMode =
      this.store.getVisitStatus() === 'PAYMENT' ||
      (this.store.getVisitStatus() !== 'PAYMENT' &&
        this.editMode &&
        !this.isNew)
        ? true
        : false;

    this.getMedicalCoverageIds();
    this.practices = this.getUniqueListBy(
      this.store.referralSpecialitiesMap,
      'speciality'
    );
    this.availableSpecialities = this.store.referralAvailableSpecialitiesMap[0];
    this.practices.sort((a, b) => a.speciality.localeCompare(b.speciality));

    if (this.referralItem.get('appointmentDateTime').value) {
      const dateTime = moment(this.referralItem.get('appointmentDateTime').value, DB_FULL_DATE_FORMAT).toDate()

      this.appointmentDate.patchValue(dateTime);
      this.appointmentTime.patchValue(dateTime);
    }

    this.populateDropDownValues();
    this.configureFieldsValidity();
    if (this.referralItem.get('referralType').value) {
      this.shouldShowSelectDoctorTable =
        this.referralItem.get('referralType').value !== 'EXTERNAL';
      if (this.referralItem.get('referralType').value === 'EXTERNAL') {
        this.practiceAddTag = true;
      } else {
        this.practiceAddTag = false;
      }
    }

    if (this.referralItem.get('referralType').value === 'INTERNAL') {
      const referralMaster = this.referralDataQuery.getReferralByDoctor(this.referralItem.get('referralType').value, 
        this.referralItem.get('practice').value, this.referralItem.get('doctorId').value);

        if (referralMaster && referralMaster.location && referralMaster.location.length > 0) {
          const location = referralMaster.location.find(loc => loc.id === this.referralItem.get('clinicId').value);
          if (location) {
            this.internalDocName = referralMaster.name;
            this.internalClinicName = location.name;
            this.internalClinicAddress = location.address;
          }
        }
    }

    this.subscribeChanges();
    if (this.editMode && !this.isNew) {
      this.referralItem.disable();
    }

    if (this.referralItem.get('referralType').value !== 'INTERNAL') {
      this.referralItem.get('memo').enable();
    }

    this.loggedInClinic = this.store.getClinic();
    this.isHositalType = (this.referralItem.get('referralType').value === 'HOSPITAL') ? true : false;
    this.isExternalReferralType = (this.referralItem.get('referralType').value === 'HOSPITAL' && this.akitaAppQuery.checkClinicFeatureExist('HSG')) ? true : false;
  }

  getMedicalCoverageIds() {
    this.store
      .getAttachedToCaseMedicalCoverageLoadedSubject()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: MedicalCoverageByCaseRes) => {
        const caseDetail: Case = this.store.getCaseDetails();
        if (!data || !this.store.getCaseDetails()) {
          return;
        }

        this.attachedPlan = this.fb.array([]);
        this.storedAttachedPlan = this.fb.array([]);

        caseDetail.coverages.forEach((coverage: Coverages, index) => {
          const policyHolder = data.policyHolder.find(
            policyHolder => policyHolder.planId === coverage.planId
          );
          if (!policyHolder) {
            return;
          }

          const coverageFG = this.fb.group({
            medicalCoverageId: policyHolder.medicalCoverageId,
            planId: policyHolder.planId,
          });
          this.attachedPlan.push(coverageFG);
          this.storedAttachedPlan.push(coverageFG);
        });

        this.storedAttachedPlan.controls.forEach(element => {
          this.medicalCoverageIds.push(element.get('medicalCoverageId').value);
        });
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  configureLabelStrForPrintSection() {
    if (this.referralItem.get('str')) {
      if (!this.referralItem.get('externalReferral').value) {
        this.setLabelForInternalReferral();
      } else {
        this.setLabelForExternalReferral();
      }
    }
  }

  setLabelForInternalReferral() {
    const memo = this.referralItem.get('memo').value;

    if (this.internalDocName && this.internalClinicName) {
      const str = memo
        ? `Referral letter to ${this.internalDocName} (${
          this.internalClinicName
          }@${this.internalClinicAddress.replace('\\n', '<br>') ||
            ''} Singapore)`
        : '';
      this.referralItem.get('str').patchValue(str);
    }
  }

  setLabelForExternalReferral() {
    const externalReferralDetailsFG = this.referralItem.get(
      'externalReferralDetails'
    ) as FormGroup;
    const externalReferralDetails = externalReferralDetailsFG.getRawValue();
    const memo = this.referralItem.get('memo').value;

    const str = memo
      ? `Referral letter to ${externalReferralDetails.doctorName} (${externalReferralDetails.address})`
      : '';
    this.referralItem.get('str').patchValue(str);
  }
  subscribeChanges() {
    let componentDestroyed = this.parentComponentDestroyed
      ? this.parentComponentDestroyed
      : this.componentDestroyed;

    this.referralItem.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(componentDestroyed)
      )
      .subscribe(value => {
        this.isExternalReferral = value.externalReferral;
        if (this.isExternalReferral) {
          this.practiceAddTag = true;
        } else {
          this.practiceAddTag = false;
        }
        this.setMandatoryFields();
        this.configureFieldsValidity();
        this.configureLabelStrForPrintSection();

        /** 
         * From line 313 to 335
         * Disable or enable Referel types, Doctor, Panel, Doctors Name, Address
         * Based on practice selection. If practice empty, disable else enable
         */
        const practice = this.referralItem.get('practice');
        const referralType = this.referralItem.get('referralType');
        const externalReferralDetails = this.referralItem.get(
          'externalReferralDetails'
        );

        if (!practice.value && referralType.value !== 'HOSPITAL') {
          this.referralItem.get('referralType').disable();
          this.doctorFilter.disable();
          externalReferralDetails.get('address').disable();
          externalReferralDetails.get('doctorName').disable();
          this.isPracticeEmpty = true;
        } else if (referralType.value === 'EXTERNAL') {
          this.isPracticeEmpty = false;
          this.doctorFilter.disable();
        } else {
          this.isPracticeEmpty = false;
          if (practice.value && practice.value === 'A&E') {
            this.referralItem.get('referralType').disable();
          } else {
            this.referralItem.get('referralType').enable();
          }
          this.doctorFilter.enable({ emitEvent: false });

          if (!this.doctorFilterOptions[0] || this.doctorFilterOptions[0].name !== 'All'){
            this.doctorFilterOptions.splice(0, 0, { id: '0', name: 'All' });
          }
        }
      });

    this.doctorFilter.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed))
      .subscribe(value => {
        this.triggerFilterEvents();
      });

    this.store
      .getPatientVisitIdRefresh()
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(res => {
        this.isPaymentMode =
          this.store.getVisitStatus() === 'PAYMENT' ||
          (this.store.getVisitStatus() !== 'PAYMENT' &&
            this.editMode &&
            !this.isNew)
            ? true
            : false;
        this.populateDropDownValues();
        this.populateSelectedOption();
        this.configureFieldsValidity();
      });

    this.store
      .getVisitStatusRefresh()
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(res => {
        this.isPaymentMode =
          this.store.getVisitStatus() === 'PAYMENT' ||
          (this.store.getVisitStatus() !== 'PAYMENT' &&
            this.editMode &&
            !this.isNew)
            ? true
            : false;
        this.populateDropDownValues();
        this.populateSelectedOption();
        this.configureFieldsValidity();
      });

    this.appointmentTime.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        if (value) {
          this.patchAppointmentDateTime();
        } else {
          this.referralItem.get('appointmentDateTime').patchValue(null);
        }
      });

    this.appointmentDate.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        if (value) {
          if(value.toString().toLowerCase() === 'invalid date') {
            this.referralItem.get('appointmentDateTime').patchValue(null);
          } else {
            const appointmentDate = moment(value, DB_FULL_DATE_FORMAT).startOf('day');
            const today = moment().startOf('day');
            
            // Check the next 3 working days (excluding weekends)
            let workingDaysCount = 0;
            let tempDate = moment(today);

            while (workingDaysCount < 3) {
                tempDate.add(1, 'days');
                // Skip weekends
                if (tempDate.day() !== 6 && tempDate.day() !== 0) {
                    workingDaysCount++;
                }
            }

            // Show popup if appointmentDate is within 3 working days
            if (appointmentDate.isSameOrBefore(tempDate)) {
                alert('For urgent appointments or those within 3 working days, please contact the clinic directly.');
                this.referralItem.get('appointmentDateTime').patchValue(null);
                return;
            } 
            this.patchAppointmentDateTime();
          }
        }
      });

    this.referralItem.get('externalReferralDetails').get('externalReferralType').valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        const referralType = this.referralItem.get('referralType').value;
        const practice = this.referralItem.get('practice').value;
        if (value && referralType==='HOSPITAL' && practice) {
          this.filterDoctorOptions(practice)
        }
    })
  }

  filterDoctorOptions(practice) {
    this.doctorOptions = [];
    this.doctorFilterOptions = [];
    this.availableSpecialities.forEach(element=>{
      if (element.availableSpecialities.includes(practice)) {
        this.doctorOptions.push({
          id: element.location[0].id,
          name: element.location[0].name,
          speciality: element.speciality,
          locationId: element.location[0].id,
          code: element.location[0].code,
          address: element.location[0].address,
          type: element.type,
          panels: element.panels,
        });

        this.doctorFilterOptions.push({
          id: element.location[0].id,
          name: element.location[0].name,
        })
      }
    })
  }

  triggerFilterEvents() {
    // 1) Clear All Fields
    this.clearSelectedFields();

    // 2) Populate Doctor Dropdown List
    const practice = this.referralItem.get('practice').value;
    const referralType = this.referralItem.get('referralType').value;

    if (practice && referralType) {
      this.populateDoctor(practice, referralType);
    }

    // 3) Populate Selected Doctor, if any
    this.populateSelectedOption();
  }

  private patchAppointmentDateTime() {
    const appointmentTime = moment(
      this.appointmentTime.value,
      DB_FULL_DATE_FORMAT
    );
    const appointmentDateTime = moment(this.appointmentDate.value);
    appointmentDateTime.set({
      hour: appointmentTime.hour(),
      minute: appointmentTime.minute(),
    });
    this.referralItem
      .get('appointmentDateTime')
      .patchValue(appointmentDateTime.format(DB_FULL_DATE_FORMAT_ZERO_SECOND));
    this.referralItem.get('appointmentDateTime').markAsDirty();
  }

  populateDropDownValues() {
    const referralType = this.referralItem.get('referralType');

    if (referralType.value) {
      this.populateDoctor(
        this.referralItem.get('practice').value,
        referralType.value
      );
    }
  }
  populateSelectedOption() {
    const referralType = this.referralItem.get('referralType').value;

    this.setDefaultOption();

    if (referralType === 'INTERNAL') {
      const doctorId = this.referralItem.get('doctorId');
      const clinicId = this.referralItem.get('clinicId');
      if (doctorId.value && clinicId.value) {
        const selected = this.doctorOptions.find(
          doctor =>
            doctor.id === doctorId.value && doctor.locationId === clinicId.value
        );
        this.setSelectedDoctor(selected);
      }
    } else if (referralType === 'HOSPITAL' || referralType === 'PARTNER') {
      const name = this.referralItem
        .get('externalReferralDetails')
        .get('doctorName').value;
      const address = this.referralItem
        .get('externalReferralDetails')
        .get('address').value;
      const selected = this.doctorOptions.find(
        doctor => doctor.name === name && doctor.address === address
      );
      this.setSelectedDoctor(selected);
    }
  }

  patchExternalDetails(selected?) {
    const referralType = this.referralItem.get('referralType').value;
    const practice = this.referralItem.get('practice').value;
    if (referralType === 'EXTERNAL') {
      const name = selected
        ? selected.doctor
        : this.refDoctor ||
          this.referralItem.get('externalReferralDetails').get('doctorName')
            .value ||
          this.docName;
      const address = selected
        ? selected.address
        : this.refAddress || this.docAddress;

      this.referralItem
        .get('externalReferralDetails')
        .get('doctorName')
        .patchValue(name, { emitEvent: false });

      this.referralItem
        .get('externalReferralDetails')
        .get('address')
        .patchValue(address, { emitEvent: false });
    } else if (referralType === 'HOSPITAL') {
      const externalReferralType = this.referralItem.get('externalReferralDetails').get('externalReferralType')
      switch (practice) {
        case 'A&E':
          externalReferralType.patchValue('A&E/UCC', { emitEvent: false });
          this.externalReferralTypes = this.externalReferralTypes.map(item=>{
            (item.value==='Fast-Track' || item.value==='Routine' ||item.value==='GP First') ? 
            item.disabled = true : item.disabled = false;
            return item;
          })
        break;
        case 'Family Medicine':
          externalReferralType.patchValue('GP First', { emitEvent: false });
          this.externalReferralTypes = this.externalReferralTypes.map(item=>{
            (item.value==='Fast-Track' || item.value==='Routine' ||item.value==='A&E/UCC') ? 
            item.disabled = true : item.disabled = false;
            return item;
          })
        break;
        default:
          externalReferralType.reset();
          this.externalReferralTypes =  this.externalReferralTypes.map(item=>{
            (item.value==='GP First' ||item.value==='A&E/UCC') ? 
            item.disabled = true : item.disabled = false;
            return item;
          })
        break;
      }
      const name = selected
        ? selected.name
        : this.referralItem.get('externalReferralDetails').get('doctorName')
            .value ||
          this.docName;
      const address = selected
        ? selected.address
        : this.docAddress;

      this.referralItem
        .get('externalReferralDetails')
        .get('doctorName')
        .patchValue(name, { emitEvent: false });

      this.referralItem
        .get('externalReferralDetails')
        .get('address')
        .patchValue(address, { emitEvent: false });

      if (selected) {
        this.doctorFilter.setValue(selected?.id);
      }
      
    } else if (

      referralType === 'PARTNER' ||
      referralType === 'INTERNAL'
    ) {
      const name = selected ? selected.name : '';
      const address = selected ? selected.address : '';

      this.referralItem
        .get('externalReferralDetails')
        .get('doctorName')
        .patchValue(name ? name : this.docName, { emitEvent: false });

      this.referralItem
        .get('externalReferralDetails')
        .get('address')
        .patchValue(address ? address : this.docAddress, { emitEvent: false });
    }
  }

  setSelectedDoctor(selectedOption) {
    if (selectedOption !== undefined) {
      this.selectedDoctor = [selectedOption];
      this.referralItem.get('selectedDoctorRequired').patchValue('patched');

      if (this.referralItem.get('referralType').value !== 'HOSPITAL') {
        this.referralItem.get('practice').patchValue(selectedOption.speciality);
      }
      this.referralItem.get('referralType').patchValue(selectedOption.type);

      if (!this.isExternalReferral || this.referralItem.get('referralType').value === 'HOSPITAL') {
        this.referralItem.get('doctorId').patchValue(selectedOption.id);
        this.referralItem.get('clinicId').patchValue(selectedOption.locationId);
      }
      this.patchExternalDetails(selectedOption);
    } else {
      this.clearSelectedOption();
    }
  }

  clearSelectedOption() {
    this.selectedDoctor = [];
    this.referralItem.get('selectedDoctorRequired').patchValue(null);
    this.clearDoctorNameAndAddress();
    this.referralItem.get('doctorId').patchValue('', { emitEvent: false });
    this.referralItem.get('clinicId').patchValue('', { emitEvent: false });
  }

  setDefaultOption() {
    const externalReferral = this.referralItem.get('externalReferral').value;
    if (this.doctorOptions.length === 1) {
      if (externalReferral) {
        const selected = this.doctorOptions[0];
        this.patchExternalDetails(selected);
      } else {
        const selected = this.doctorOptions[0];
        if (!this.isExternalReferral) {
          this.referralItem
            .get('doctorId')
            .patchValue(selected.id, { emitEvent: false });
          this.referralItem
            .get('clinicId')
            .patchValue(selected.locationId, { emitEvent: false });
        }
        this.patchExternalDetails(selected);
      }
    }
  }

  inPaymentMode() {
    return this.store.getVisitStatus() === 'PAYMENT' ? true : false;
  }

  configureFieldsValidity() {
    const doctorId = this.referralItem.get('doctorId');
    const clinicId = this.referralItem.get('clinicId');
    const practice = this.referralItem.get('practice');
    const referralType = this.referralItem.get('referralType');
    const externalReferral = this.referralItem.get('externalReferral');
    const externalReferralDetails = this.referralItem.get(
      'externalReferralDetails'
    );
    const internalReferralDateTime = this.referralItem.get('appointmentDateTime');
    
    // Disable all fields if in Payment Mode
    if (this.isPaymentMode) {
      clinicId.disable();
      doctorId.disable();
      practice.disable();
      referralType.disable();
      externalReferral.disable();
      externalReferralDetails.get('address').disable();
      externalReferralDetails.get('doctorName').disable();

      this.appointmentDate.disable({ emitEvent: false });
      this.appointmentTime.disable({ emitEvent: false });
      internalReferralDateTime.disable({ emitEvent: false });
    } else {
      this.appointmentDate.enable({ emitEvent: false });
      this.appointmentTime.enable({ emitEvent: false });
      internalReferralDateTime.enable({ emitEvent: false });
      
      if (!this.toDisableDoctorDetails()) {
        this.onExternalReferralSelected(
          referralType.value,
          doctorId,
          clinicId,
          practice
        );
      } else {
        this.onInternalReferralSelected(doctorId, clinicId, practice);
      }
    }

    if (referralType.value == 'All') {
      externalReferralDetails.get('address').disable();
      externalReferralDetails.get('doctorName').disable();
    } 
  }


  onExternalReferralSelected(referralType, doctorId, clinicId, practice) {
    const externalReferralDetails = this.referralItem.get(
      'externalReferralDetails'
    );

    doctorId.disable();
    clinicId.disable();

    if (referralType === 'EXTERNAL') {
      this.doctorFilter.disable({ emitEvent: false });
      this.doctorFilter.patchValue('', { emitEvent: false });
    }

    if (practice.value) {
      externalReferralDetails.enable();
    }

    doctorId.patchValue('');
    clinicId.patchValue('');

    practice.enable();
    practice.setValidators(null);
    practice.markAsTouched();
    this.practiceAddTag = true;
  }

  getDoctorGroup(doctorId: string): string {
    return (
      this.store.doctorList.find(doctor => doctor.id === doctorId) || {
        displayName: '',
      }
    ).doctorGroup;
  }
  onInternalReferralSelected(
    doctorId: AbstractControl,
    clinicId: AbstractControl,
    practice
  ) {
    const externalReferralDetails = this.referralItem.get(
      'externalReferralDetails'
    );
    const externalReferralDoctor = this.referralItem
      .get('externalReferralDetails')
      .get('doctorName');
    const externalReferralAddress = this.referralItem
      .get('externalReferralDetails')
      .get('address');
    const referralType = this.referralItem.get('referralType').value;

    if (referralType === 'INTERNAL') {
      doctorId.enable();
      clinicId.enable();
    } else {
      doctorId.disable();
      clinicId.disable();
    }
    practice.enable({ emitEvent: false });

    externalReferralDoctor.setValidators(null);
    externalReferralDoctor.markAsTouched();

    externalReferralDetails.get('address').disable();
    externalReferralDetails.get('doctorName').disable();
    this.practiceAddTag = false;
  }

  populatePractice(data: Practice[]) {
    this.practices = data;
    this.practices.sort();
  }

  populateDoctor(practice, type) {
    if (type === 'EXTERNAL') {
      return;
    }

    let doctors;
    if (type === 'HOSPITAL') {
      doctors = this.referralDataQuery.getReferral(type, '');
    } else {
      doctors = this.referralDataQuery.getReferral(type, practice);
    }

    this.doctorOptions = [];
    doctors.forEach(doctor => {
      const id = doctor.id;
      if (this.doctorFilter.value !== '0') {
        if (this.doctorFilter.value && this.doctorFilter.value !== id) {
          return;
        }
      }

      let name = doctor.name;
      const speciality = doctor.speciality || '';

      if (type === 'HOSPITAL') {
        if (doctor.location && doctor.location.length) {
          this.doctorOptions.push({
            id,
            name: doctor.location[0].name,
            speciality,
            locationId: doctor.location[0].id,
            code: doctor.location[0].code,
            address: doctor.location[0].address,
            type: doctor.type,
            panels: doctor.panels,
          });
        }
      } else {
        doctor.location.forEach(location => {
          const clinicList = this.store.getClinicList();
          const clinic = clinicList.find(clinic => clinic.clinicCode === location.code);
          this.doctorOptions.push({
            id,
            name,
            speciality,
            locationId: location.id,
            code: location.code,
            address: clinic && clinic.address ? clinic.address.address : location.address,
            type: doctor.type,
            panels: doctor.panels,
            primary: location.primary,
            phone: clinic ? clinic.contactNumber : location.phone,
          });
        });
      }
    });
    this.sortinRefferalOrder();
    this.updateDoctorAndClinicFilter();
    this.populateSelectedOption();
  }

  sortinRefferalOrder() {
    this.doctorOptions.sort((a, b) => a.name.localeCompare(b.name) || (a.primary === b.primary ? 0 : a.primary ? -1 : b.primary ? 1 : -1));
  }

  updateDoctorAndClinicFilter() {
    this.doctorFilterOptions = [];
    this.doctorFilterOptions = [
      ...this.doctorOptions.reduce((docs, docOption) => {
        if (!docs.find(doc => doc.id === docOption.id)) {
          docs.push({
            id: docOption.id,
            name: docOption.name,
          });
        }
        return docs;
      }, []),
    ];

    this.doctorFilterOptions.sort((a,b) => a.name > b.name ? 1 : -1);

    if (this.doctorFilter.value === '0') {
      this.doctorFilterOptions.splice(0, 0, { id: '0', name: 'All' });
      this.doctorFilter.patchValue('0', { emitEvent: false });
    }
  }

  showAlert(text: string, title: string) {
    const initialState = {
      title: title,
      text: text,
    };
    this.bsModalService
      .show(PatientReferralAlertComponent, {
        initialState,
        class: 'modal-md',
        backdrop: 'static',
      })
      .content.event.subscribe(data => {
        if (data) {
          // do nothing
        } else {
          let option = {
            value: 'INTERNAL',
          };
          this.onReferralTypeSelected(option);
        }
      });
  }
  // 1 --
  onReferralTypeSelected(option) {
    const referralType = option?.value;
    this.doctorOptions = [];
    this.shouldShowSelectDoctorTable = true;

    this.doctorFilter.patchValue('', {
      emitEvent: false,
    });

    this.clearSelectedFields();
    this.isHositalType = (this.referralItem.get('referralType').value === 'HOSPITAL') ? true : false;
    this.isExternalReferralType = (referralType === 'HOSPITAL' && this.akitaAppQuery.checkClinicFeatureExist('HSG')) ? true : false;

    if (referralType === 'INTERNAL') {
      this.referralItem.get('externalReferral').patchValue(false);
      this.referralItem.get('referralType').patchValue(referralType);

      const practice = this.referralItem.get('practice');

      if (practice.value) {
        this.populateDoctor(
          this.referralItem.get('practice').value,
          referralType
        );
      }
    } else if (referralType === 'PARTNER' || referralType === 'HOSPITAL') {
      this.referralItem.get('externalReferral').patchValue(true);
      this.populateDoctor(
        this.referralItem.get('practice').value,
        referralType
      );
    } else if (referralType === 'EXTERNAL') {
      this.referralItem.get('externalReferral').patchValue(true);
      this.doctorOptions = [];
      this.shouldShowSelectDoctorTable = false;
      this.minFiveCharachters = true;
      this.referralItem
        .get('externalReferralDetails')
        .get('doctorName')
        .setValidators([Validators.minLength(5), Validators.required]);
      this.clearDoctorNameAndAddress();
      this.practices.forEach(item => {
        if (item.speciality == this.referralItem.get('practice').value) {
          if (this.isLocumDoctor() && this.loggedInClinic.referralLocked && 
            (item.types.includes('INTERNAL') || item.types.includes('PARTNER'))) {
              this.showAlert(
                ' Per Company Policy, making external referrals are restricted when Internal/Partner Specialists are available',
                'LOCUM'
              );
          }
          else if (
            this.isDoctor() && 
            (item.types.includes('INTERNAL') ||
            item.types.includes('PARTNER'))
          ) {
            this.showAlert(
              ' There are Internal/Partners available for this referral , are you sure you want to continue with External?',
              ''
            );
          }
        }
      });
    } else {
      this.referralItem.get('externalReferral').patchValue(false);
    }
  }

  clearSelectedFields() {
    this.referralItem.get('doctorId').patchValue('', { emitEvent: false });
    this.referralItem.get('clinicId').patchValue('', { emitEvent: false });
    this.clearSelectedOption();
    this.patchExternalDetails();
  }

  isNotFive() {
    if (!this.referralItem.get('externalReferralDetails').get('doctorName').value) {
      return false;
    } else if (
      this.referralItem.get('externalReferralDetails').get('doctorName').value
        .length < 5 &&
      this.referralItem.get('externalReferralDetails').get('doctorName').value
        .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
  // 2 --
  onPracticeSelected(option) {
    this.doctorOptions = [];
    if (option) {
      const referralType = this.referralItem.get('referralType');
      if (option.speciality === 'A&E') {
        referralType.patchValue('HOSPITAL');
        this.onReferralTypeSelected({ value: 'HOSPITAL' });
        referralType.disable();
      } else if (option.types.includes("EXTERNAL") && option.types.length === 1) {
        referralType.patchValue('EXTERNAL');
        this.onReferralTypeSelected({ value: 'EXTERNAL' });
        referralType.enable();
      } else {
        referralType.patchValue('INTERNAL');
        this.onReferralTypeSelected({ value: 'INTERNAL' });
        referralType.enable();
      }

      if (!option.types.includes("INTERNAL") && option.types.includes("PARTNER")) {
        referralType.patchValue('PARTNER');
        this.onReferralTypeSelected({ value: 'PARTNER' });
      }

      // clear doctor and clinic dropdown
      this.referralItem.get('doctorId').patchValue('');
      this.referralItem.get('clinicId').patchValue('');
      this.referralItem.get('clinicId').patchValue('');
      const specialityValue = this.referralItem.get('practice').value;
      if (specialityValue == 'Other') {
        this.referralItem.addControl(
          'practiceO',
          new FormControl('', Validators.required)
        );
      } else {
        this.referralItem.removeControl('practiceO');
      }
      if (this.referralItem.get('referralType').value && this.referralItem.get('referralType').value !== '') {
        this.onReferralTypeSelected({ value: this.referralItem.get('referralType').value });
      } else {
        // Set referralType as INTERNAL for the first time
      }
    } else {
      this.referralItem.get('doctorId').patchValue('');
      this.referralItem.get('clinicId').patchValue('');
    }

    this.clearSelectedOption();

    this.patchExternalDetails();

    if (this.referralItem.get('referralType').value === 'EXTERNAL') {
      this.clearDoctorNameAndAddress();
    }

    this.populateSelectedOption();
  }

  clearDoctorNameAndAddress() {
      this.referralItem
        .get('externalReferralDetails')
        .get('doctorName')
        .patchValue('');
      this.referralItem
        .get('externalReferralDetails')
        .get('address')
        .patchValue('');
  }

  // 3 --
  onDoctorSelected(options) {
    if (options && options.selected && options.selected.length) {
      const option = options.selected[0];
      this.setSelectedDoctor(option);
    }
  }

  toDisableDoctorDetails() {
    const referralType = this.referralItem.get('referralType').value;
    return referralType === 'INTERNAL' ||
      referralType === 'PARTNER' ||
      referralType === 'HOSPITAL'
      ? true
      : false;
  }

  setMandatoryFields() {
    const doctorId = this.referralItem.get('doctorId');
    const clinicId = this.referralItem.get('clinicId');
    const practice = this.referralItem.get('practice');
    const referralType = this.referralItem.get('referralType');
    const memo = this.referralItem.get('memo');
    const externalReferralDoctor = this.referralItem
      .get('externalReferralDetails')
      .get('doctorName');
    const externalReferralAddress = this.referralItem
      .get('externalReferralDetails')
      .get('address');
    const selectedDoctorRequired = this.referralItem.get(
      'selectedDoctorRequired'
    );
    const internalReferralDateTime = this.referralItem.get('appointmentDateTime');

    if (
      externalReferralAddress.value ||
      externalReferralDoctor.value ||
      doctorId.value ||
      practice.value ||
      memo.value ||
      referralType.value === 'HOSPITAL'
    ) {
      if (referralType.value !== 'EXTERNAL') {
        this.configureControl(Validators.required, false, practice);
      }

      if (this.isInModalValidationActive) {
        this.configureControl(Validators.required, false, memo);
      } else {
        if (!!doctorId.value) {
          this.configureControl(Validators.required, false, memo);
        } else {
          this.configureControl(null, false, memo);
        }
      }

      if (this.isInModalValidationActive) {
        this.configureControl(
          Validators.required,
          false,
          selectedDoctorRequired
        );
      } else {
        if (!!memo.value) {
          this.configureControl(
            Validators.required,
            false,
            selectedDoctorRequired
          );
        } else {
          this.configureControl(null, false, selectedDoctorRequired);
        }
      }

    } else {
      this.configureControl(null, false, practice);
      this.configureControl(null, false, memo);
      this.configureControl(null, false, selectedDoctorRequired);
    }

    if (this.toDisableDoctorDetails()) {
      // Internal Referral

      if (clinicId.value || doctorId.value || practice.value || memo.value) {
        if (this.isInModalValidationActive) {
          this.configureControl(Validators.required, false, clinicId);
          this.configureControl(this.mandatorySelection(), false, doctorId);
        } else {
          if (!!memo.value) {
            this.configureControl(Validators.required, false, clinicId);
            this.configureControl(this.mandatorySelection(), false, doctorId);
          } else {
            this.configureControl(null, false, clinicId);
            this.configureControl(null, false, doctorId);
          }
        }

        if (this.referralItem.get('referralType').value === 'INTERNAL') {
          this.configureControl(
            Validators.required,
            false,
            this.appointmentDate
          );
          this.configureControl(
            Validators.required,
            false,
            this.appointmentTime
          );
          this.configureControl(Validators.required, false, internalReferralDateTime);
        } else {
          this.configureControl(null, false, this.appointmentDate);
          this.configureControl(null, false, this.appointmentTime);
          this.configureControl(null, false, internalReferralDateTime);
        }
      } else {
        this.configureControl(null, false, clinicId);
        this.configureControl(null, false, doctorId);
        this.configureControl(null, false, this.appointmentDate);
        this.configureControl(null, false, this.appointmentTime);
        this.configureControl(null, false, internalReferralDateTime);
      }
    } else {
      // External Referral

      this.configureControl(null, false, this.appointmentDate);
      this.configureControl(null, false, this.appointmentTime);
      this.configureControl(null, false, selectedDoctorRequired);
      this.configureControl(null, false, internalReferralDateTime);

      if (
        externalReferralAddress.value ||
        externalReferralDoctor.value ||
        practice.value ||
        memo.value
      ) {
        externalReferralDoctor.setValidators([
          Validators.required,
          Validators.minLength(5),
        ]);

        this.configureControl(
          Validators.required,
          false,
          externalReferralAddress
        );
      } else {
        this.configureControl(null, false, externalReferralDoctor);
        this.configureControl(null, false, externalReferralAddress);
      }
    }
  }

  configureControl(validators, emitEvent, control: AbstractControl) {
    if (validators !== null) {
      control.setValidators([validators]);
    } else {
      control.setValidators(null);
    }

    control.markAsTouched();
    control.updateValueAndValidity({ emitEvent: emitEvent });
  }

  isDoctor() {
    return this.permissionsService.getPermission('ROLE_DOCTOR');
  }

  isLocumDoctor() {
    let doctorId = this.store.getDoctorId();
    const doctor = this.store.doctorList.find(
      doctor => doctor.id === doctorId && doctor.doctorGroup === 'LOCUM'
    );

    return doctor !== undefined;
  }

  onbtnDeleteClicked() {
    this.onDelete.emit(this.index);
  }

  onTemplateChange(event) {
    this.referralItem.get('memo').markAsDirty();
    if (event) {
      const fcMemo = this.referralItem.get('memo');
      this.refDoctor = event.doctor;
      this.refAddress = event.address;
      let visitId = '';
      if (this.akitaCaseVisitQuery.getActiveId())
        visitId = String(this.akitaCaseVisitQuery.getActiveId());
      else if (this.patientVisitHistoryQuery.getActiveId())
        visitId = String(this.patientVisitHistoryQuery.getActiveId());
      else visitId = this.store.getPatientVisitRegistryId();

      this.apiCmsManagementService
        .loadTemplateWithVisitId(
          event.type,
          event.id,
          this.store.getUser().context['cms-user-id'],
          this.akitaPatientAppQuery.getId(),
          visitId
        )
        .subscribe(
          res => {
            if (res.payload) {
              if (this.isAppend) {
                const data = fcMemo.value ? fcMemo.value : '';
                fcMemo.patchValue(data ? data + '<br>' + res.payload : res.payload);
              } else {
                fcMemo.patchValue(res.payload);
              }
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
            console.error(JSON.stringify(err));
          }
        );
      if (this.referralItem.get('referralType').value === 'EXTERNAL') {
        const name = this.referralItem.get('externalReferralDetails').get('doctorName').value || event.doctor;
        const address = this.referralItem.get('externalReferralDetails').get('address').value || event.address;

        this.referralItem
          .get('externalReferralDetails')
          .get('doctorName')
          .patchValue(name, { emitEvent: false });

        this.referralItem
          .get('externalReferralDetails')
          .get('address')
          .patchValue(address, { emitEvent: false });
      }
    }
  }

  onRowClicked() {
    this.isCollapsed = !this.isCollapsed;
  }

  isDoctorItemSelected(id, locationId) {
    return (
      this.selectedDoctor &&
      this.selectedDoctor.length &&
      this.selectedDoctor[0] &&
      this.selectedDoctor[0].id &&
      this.selectedDoctor[0].id === id &&
      this.selectedDoctor[0].locationId &&
      this.selectedDoctor[0].locationId === locationId
    );
  }

  mandatorySelection(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value ? null : { mandatorySelectionError: { value: '' } };
    };
  }

  getCellClass(row: any): any {
    return {
      'row-highlight-color': (row.row.primary && row.row.primary)
    };
  }

  truncate(text, truncateTo) {
    return this.utilsService.textTruncate(text, truncateTo);
  }

  showOptionToggle(event, index, showItem) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    showItem ? this.showFullText[index] = true : this.showFullText[index] = false;
  }

}
