import { AssessmentTestTakenEntity, VitalTestTakenEntity } from "../response/pcn/PCNDetails";

export interface PCNDetail {
  assessment: PCNAssessment;
  conditions: Array<PCNCondition>;
  patientExtraDetails: PCNPatientExtraDetails;
  vitals: Array<PCNVital>;
  assessmentTestTakenList: Array<AssessmentTestTakenEntity>;
  vitalTestTakenList: Array<VitalTestTakenEntity>;
}

export function createPCNDetail(pcnDetail) {
  const {
    assessment = createPCNAssessment({}),
    conditions = new Array<PCNCondition>(),
    patientExtraDetails = createPCNPatientExtraDetails({}),
    vitals = new Array<PCNVital>(),
    assessmentTestTakenList = new Array<AssessmentTestTakenEntity>(),
    vitalTestTakenList = new Array<VitalTestTakenEntity>(),
  } = pcnDetail;

  return {
    assessment,
    conditions,
    patientExtraDetails,
    vitals,
    assessmentTestTakenList,
    vitalTestTakenList
  } as PCNDetail;
}

export interface PCNAssessment {
  assessments: Object;
  comment: string;
  patientId: string;
  takenTime: string;
  visitId: string;
}

export function createPCNAssessment(pcnAssessment) {
  const {
    assessments = {},
    comment = '',
    patientId = '',
    takenTime = '',
    visitId = '',
  } = pcnAssessment;

  return {
    assessments,
    comment,
    patientId,
    takenTime,
    visitId,
  } as PCNAssessment;
}

export interface PCNCondition {
  code: string;
  diagnosisIds: Array<string>;
  yearOfOnSet: number;
}

export function createPCNCondition(pcnCondition) {
  const {
    code = '',
    diagnosisIds = new Array<string>(),
    yearOfOnSet = 0,
  } = pcnCondition;

  return {
    code,
    diagnosisIds,
    yearOfOnSet,
  } as PCNCondition;
}

export interface PCNPatientExtraDetails {
  chasUsage: string;
  referenceSource: string;
  remark: string;
  rightSiting: boolean;
  rightSitingReferenceSource: string;
  smokingStatus: string;
  stateOfChange: string;
  yearStartOfSmoke: string;
  latestNumberOfSticksPerDay: string;
}

export function createPCNPatientExtraDetails(pcnPatientExtraDetails) {
  const {
    chasUsage = '',
    referenceSource = '',
    remark = '',
    rightSiting = false,
    rightSitingReferenceSource = '',
    smokingStatus = '',
    stateOfChange= '',
    yearStartOfSmoke = '',
    latestNumberOfSticksPerDay = '',
  } = pcnPatientExtraDetails;

  return {
    chasUsage,
    referenceSource,
    remark,
    rightSiting,
    rightSitingReferenceSource,
    smokingStatus,
    stateOfChange,
    yearStartOfSmoke,
    latestNumberOfSticksPerDay,
  } as PCNPatientExtraDetails;
}

export interface PCNVital {
  code: string;
  patientId: string;
  takenTime: string;
  value: string;
}

export function createPCNVital(pcnVital) {
  const { code = '', patientId = '', takenTime = '', value = '' } = pcnVital;

  return {
    code,
    patientId,
    takenTime,
    value,
  } as PCNVital;
}
