import { Address } from './../Address';
import { Vaccination } from './../Vaccination';
import { Company } from './../Company';
import { ContactNumber } from './../response/PatientInfo';
import { KycData, UserId } from './../UserRegistration';
import Allergy from '../Allergy';
import OnGoingMedication from '../OnGoingMedication';
import PrimaryCareNetwork from '../PrimaryCareNetwork';
import { PatientPayableInterface } from '../response/PatientPayable';
import PatientNewBorn from '../PatientNewBorn';
import { RelationshipEntity } from '../request/RelationshipEntity';
import HSGEnrollment from '../HSGEnrollment';
import HSGDeEnrollment from '../HSGDeEnrollment';
import { SFLHistoryList } from '../../model/SFLItem';
import PatientHsgInfo from '../PatientHsgInfo';

export default interface PatientInfo {
  address?: Address;
  allergies?: Allergy[];
  company?: Company;
  consentGiven?: boolean;
  contactNumber?: ContactNumber;
  dob?: string;
  emailAddress?: string;
  relationshipEntities?: RelationshipEntity[];
  fileMetaData?: any[];
  gender?: string;
  id?: string;
  lastVisitedDate?: string;
  maritalStatus?: string;
  name?: string;
  nationality?: string;
  onGoingMedications?: OnGoingMedication[];
  marketingOptOut?: boolean;
  nehrOptedInStatus: any;
  patientPayables?: PatientPayableInterface[];
  patientSources: any;
  patientVaccinations?: Vaccination[];
  preferredLanguage?: string;
  preferredMethodOfCommunication?: string;
  primaryCareNetwork?: PrimaryCareNetwork;
  hsgEnrollment?: HSGEnrollment;
  hsgPatient: boolean;
  patientHsgInfo?: PatientHsgInfo;
  hsgDeEnrollment?: HSGDeEnrollment;
  patientNewBorn?: PatientNewBorn;
  race?: string;
  patientIdentifier?: string;
  additionalIdentifier?: string;
  registrationDate?: string;
  secondaryNumber?: ContactNumber;
  status?: string;
  title?: string;
  userId?: UserId;
  kycData?: KycData;
  age?: string;
  sflHistoryList?: SFLHistoryList[];
  patientExtraDetails?: PatientExtraDetails
}

export function createPatientInfo(info?: PatientInfo): PatientInfo {
  return <PatientInfo>{
    address: info ? new Address(info.address.address, info.address.country, info.address.postalCode) : new Address(),
    allergies: info ? info.allergies : new Array<Allergy>(),
    company: info ? info.company
      ? new Company(
        info.company.name || '',
        info.company.address || '',
        info.company.postalCode || '',
        info.company.occupation || ''
      )
      : new Company() : new Company(),
    consentGiven: info ? info.consentGiven : true,
    contactNumber: info ? new ContactNumber(info.contactNumber.number) : new ContactNumber(),
    dob: info ? info.dob : '',
    emailAddress: info ? info.emailAddress : '',
    relationshipEntities: info ? info.relationshipEntities ? info.relationshipEntities : new Array<RelationshipEntity>() : new Array<RelationshipEntity>(),
    fileMetaData: info ? info.fileMetaData : [],
    gender: info ? info.gender : '',
    id: info ? info.id : '',
    nehrOptedInStatus: info ? info.nehrOptedInStatus : '',
    lastVisitedDate: info ? info.lastVisitedDate : '',
    maritalStatus: info ? info.maritalStatus : '',
    name: info ? info.name : '',
    nationality: info ? info.nationality : '',
    onGoingMedications: info ? info.onGoingMedications : new Array<OnGoingMedication>(),
    marketingOptOut: info ? info.marketingOptOut : true,
    patientPayables: info ? info.patientPayables : new Array<PatientPayableInterface>(),
    patientSources: info ? info.patientSources : {},
    patientVaccinations: info ? info.patientVaccinations : new Array<Vaccination>(),
    sflHistoryList: info ? info.sflHistoryList : new Array<SFLHistoryList>(),
    preferredLanguage: info ? info.preferredLanguage : '',
    preferredMethodOfCommunication: info ? info.preferredMethodOfCommunication : '',
    primaryCareNetwork: info ? info.primaryCareNetwork : new PrimaryCareNetwork(),
    hsgPatient: info ? info.hsgPatient : false,
    patientHsgInfo: info ? info.patientHsgInfo : null,
    hsgEnrollment: info ? info.hsgEnrollment : new HSGEnrollment(),
    hsgDeEnrollment: info ? info.hsgDeEnrollment : new HSGDeEnrollment(),
    patientNewBorn: info ? info.patientNewBorn : new PatientNewBorn(),
    race: info ? info.race : '',
    patientIdentifier: info ? info.patientIdentifier : '',
    additionalIdentifier: info ? info.additionalIdentifier : '',
    registrationDate: info ? info.registrationDate : '',
    secondaryNumber: info && info.secondaryNumber ? new ContactNumber(info.secondaryNumber.number) : new ContactNumber(),
    status: info ? info.status : '',
    title: info ? info.title : '',
    userId: info ? new UserId(info.userId.idType, info.userId.number) : new UserId(),
    kycData: info ? new KycData(info.kycData.kycStatus, info.kycData.verifiedDate) : new KycData(),    
    savedFromAllergyTab:  true,
    patientExtraDetails: info ? info.patientExtraDetails : new PatientExtraDetails(),
  };
}


export class PatientExtraDetails {
  chasUsage: string;
  smokingStatus: string;
  rightSiting: boolean;
  referenceSource: string;
  remark: string;
  rightSitingReferenceSource: string;
  yearStartOfSmoke: string;
  stateOfChange: any;
  constructor(chasUsage?: string, smokingStatus?: string, rightSiting?: boolean, referenceSource?: string, remark?: string, rightSitingReferenceSource?: string, yearStartOfSmoke?: string, stateOfChange?: any) {
    this.chasUsage = chasUsage || '';
    this.smokingStatus = smokingStatus || null;
    this.rightSiting = rightSiting || false;
    this.referenceSource = referenceSource || '';
    this.remark = remark || '';
    this.rightSitingReferenceSource = rightSitingReferenceSource || '';
    this.yearStartOfSmoke = yearStartOfSmoke || '';
    this.stateOfChange = stateOfChange || null;
  }
}
