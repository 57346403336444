import { Dosage } from './Dosage';

export interface Vaccination {
  allowNegativeInventory: boolean;
  baseUom: string;
  doctorId : string,
  code: string;
  controlledDrug: boolean;
  dispensingBlocked: boolean;
  dispensingMultiples: number;
  dosageQty: number;
  dosages: Array<Dosage>;
  id: string;
  inventoried: boolean;
  itemFilter: {
    clinicGroupNames: Array<string>;
    clinicIds: Array<string>;
  };
  itemType: string;
  name: string;
  printDrugLabel: boolean;
  purchaseUom: string;
  purchasingBlocked: boolean;
  salesUom: string;
  sellingPrice: {
    price: number;
    taxIncluded: boolean;
  };
  standardDispenseAmount: number;
  status: string;
  subItems: Array<any>;
  conditionCodes: Array<string>;
  doseType: string;
  covidConditionCodes: Array<string>;
}

export function createVaccination({
  allowNegativeInventory = false,
  baseUom = '',
  doctorId = '',
  code = '',
  controlledDrug = false,
  dispensingBlocked = false,
  dispensingMultiples = 0,
  dosageQty = 0,
  dosages = new Array<Dosage>(),
  id = '',
  inventoried = false,
  itemFilter = {
    clinicGroupNames: new Array<string>(),
    clinicIds: new Array<string>(),
  },
  itemType = '',
  name = '',
  printDrugLabel = false,
  purchaseUom = '',
  purchasingBlocked = false,
  salesUom = '',
  sellingPrice = {
    price: 0,
    taxIncluded: false,
  },
  standardDispenseAmount = 0,
  status = '',
  subItems = new Array<any>(),
  conditionCodes = new Array<string>(),
  doseType = null,
  covidConditionCodes = new Array<string>(),
} = {}) {
  return {
    allowNegativeInventory,
    baseUom,
    doctorId,
    code,
    controlledDrug,
    dispensingBlocked,
    dispensingMultiples,
    dosageQty,
    dosages,
    id,
    inventoried,
    itemFilter,
    itemType,
    name,
    printDrugLabel,
    purchaseUom,
    purchasingBlocked,
    salesUom,
    sellingPrice,
    standardDispenseAmount,
    status,
    subItems,
    conditionCodes,
    doseType,
    covidConditionCodes
  } as Vaccination;
}

export interface VaccinationItem {
  isSelected?: boolean;
  vaccination?: Vaccination;
}
export interface VaccinationPrintItem {
  id?: string;
  doctorId?: string;
  vaccineId?: string;
  givenDate?: string;
  vaccinationSchedules?: VaccinationSchedule[];
}

export interface VaccinationSchedule {
  vaccineId?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  doseId?: string;
}

export function createVaccinationPrintItem(isSelected?: boolean, vaccination?: VaccinationPrintItem) {
  return <VaccinationItem>{
    isSelected: isSelected || false,
    vaccination: vaccination !== undefined ? vaccination : createVaccination(),
  };
}
