import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CaseSummeryBillAdjConfirmationComponent } from '../case-summery-bill-adj-confirmation/case-summery-bill-adj-confirmation.component';
import { AkitaCaseVisitQuery } from '../../../../services/states/akita-case-visit.query';
import { AkitaClinicDoctorQuery } from '../../../../services/states/akita-clinic-doctor.query';
import { mergeMap, map, takeUntil, filter, tap, debounceTime, switchMap, catchError, take, pairwise, startWith, distinctUntilChanged, skip} from 'rxjs/operators';
import { Doctor } from '../../../../objects/state/Doctor';
import * as moment from 'moment';
import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, INPUT_DELAY } from '../../../../constants/app.constants';
import { MedicalCoverage } from '../../../../objects/MedicalCoverage';
import { AkitaMedicalCoverageQuery } from '../../../../services/states/akita-medical-coverage.query';
import { COVERAGE_CONFIG } from '../../../../constants/medical-coverage.config';
import { CaseManagerMedicalCoverageComponent } from '../../../../components/case-manager/case-manager-medical-coverage/case-manager-medical-coverage.component';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { AkitaDoctorQuery } from '../../../../services/states/akita-doctor.query';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, of, BehaviorSubject, Subscription } from 'rxjs';
import { CaseManagerService } from '../case-manager.service';
import { StoreService } from '../../../../services/store.service';
import { ApiCaseManagerService } from '../../../../services/api-case-manager.service';
import { AlertService } from '../../../../services/alert.service';
import { CaseCoveragePlan } from '../../../../objects/state/Case';
import { PatientMedicalCoverageQuery } from '../../../../services/states/akita-patient/patient-medical-coverages/patient-medical-coverage.query';
import { AkitaChargeItemQuery } from '../../../../services/states/akita-charge-item.query';
import { HttpResponseBody } from '../../../../objects/response/HttpResponseBody';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import { UtilsService } from '../../../../services/utils.service';
import { AkitaClinicChargeItemQuery } from '../../../../services/states/akita-clinic-charge-item.query';
import { DispatchItemService } from '../../../../services/dispatch-item.service';
import { ChargeItem } from '../../../../objects/state/ChargeItem';
import { get } from 'lodash';
import { AppConfigService } from '../../../../services/app-config.service';
import { ApiPatientVisitService } from '../../../../services/api-patient-visit.service';
import { DocumentManagementService } from '../../../../services/document-management.service';
import { Status } from '../../../../components/doc-viewer/doc-viewer.component';
import { StockAdjustmentReason } from '../../../../objects/request/StockAdjustment';
import { PAYMENT_MODES_ALL } from '../../../../constants/payment-mode';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';
import { CaseChargeFormService } from '../../../../services/case-charge-form.service';
import { Inventories } from '../../../../objects/Case';

const getArr = (obj, ltr) => get(obj, ltr) || []

@Component({
  selector: 'app-case-summery-bill-adj-details',
  templateUrl: './case-summery-bill-adj-details.component.html',
  styleUrls: ['./case-summery-bill-adj-details.component.scss']
})
export class CaseSummeryBillAdjDetailsComponent implements OnInit {

  primaryDoctorList = [];
  primaryDoctorList$ = this.akitaCaseVisitQuery.select(entity => entity.secondaryDoctorIds).pipe(
    mergeMap(secondaryDoctors => this.akitaClinicDoctorQuery.selectActiveDoctors().pipe(map(doctorList => ({ secondaryDoctors: secondaryDoctors, doctorList: doctorList })))),
    map(({ secondaryDoctors, doctorList }) => {
      const secondaryDoctorIds = <Array<string>>secondaryDoctors;
      const doctors = <Array<Doctor>>doctorList;
      if (secondaryDoctorIds && secondaryDoctorIds.length > 0) return doctors.filter((doctor: Doctor) => !secondaryDoctorIds.includes(doctor.id));
      return doctors;
    })
  ).subscribe(res=>{
    this.primaryDoctorList = res;
  })

  attachedMedicalCoverages$ = this.akitaCaseVisitQuery.select(entity => entity.coverages);
  caseEndDate$ = this.akitaCaseVisitQuery.select(entity => entity.caseEndDateTime).pipe(
    map(endDate => endDate && endDate.length > 0 ? moment(endDate, DB_FULL_DATE_FORMAT).format('DD-MM-YYYY') : '-')
  );

  coverageNames$ = this.akitaCaseVisitQuery.select(entity => entity.coverages.map(ccp => ccp.planId)).pipe(
    mergeMap(coverages => this.akitaMedicalCoverageQuery.selectMedicalCoverages(coverages)),
    map((coverages: Array<MedicalCoverage>) => {
      let coverageName = '';
      coverages.forEach(
        (coverage, index) => {
          coverageName +=
            (coverage.name + ((index === coverages.length - 1) ? '' : ', '))
        }
      );
      return coverageName;
    })
  )

discountedInvoice = this.akitaCaseVisitQuery.getValue().salesOrder.invoices.filter(invoice => invoice.invoiceType === 'CREDIT' && invoice.discount);


  caseDetailFormGroup = this.fb.group({
    personIncharge: this.akitaCaseVisitQuery.getValue().personIncharge || '',
    primaryDoctor: this.akitaCaseVisitQuery.getValue().primaryDoctorId,
    remarks: this.akitaCaseVisitQuery.getValue().remarks,
    billAdjReasonCode: this.akitaCaseVisitQuery.getValue().billAdjReasonCode,
    secondaryDoctors: [this.akitaCaseVisitQuery.getValue().secondaryDoctorIds],
  });

  secondaryDoctorList$ = this.akitaCaseVisitQuery.select(entity => entity.primaryDoctorId).pipe(
    mergeMap(primaryDoctor => this.akitaClinicDoctorQuery.selectActiveDoctors().pipe(map(doctorList => ({ primaryDoctor: primaryDoctor, doctorList: doctorList })))),
    map(({ primaryDoctor, doctorList }) => {
      const primaryDoctorId = <string>primaryDoctor;
      const doctors = <Array<Doctor>>doctorList;
      if (primaryDoctorId && primaryDoctorId.length > 0) return doctors.filter((doctor: Doctor) => doctor.id !== primaryDoctorId);
      return doctors;
    })
  );

  primaryDoctor$ = this.akitaCaseVisitQuery.select(entity => [entity.primaryDoctorId, entity.status]).pipe(
    mergeMap(([primaryDoctorId, status]) => this.akitaDoctorQuery.selectEntity(primaryDoctorId).pipe(map((doctor: Doctor) => ([doctor, status])))),
    map(([doctor, status]) => {
      return <Doctor>doctor;
    })
  );

  secondaryDoctors$ = this.akitaCaseVisitQuery.select(entity => entity.secondaryDoctorIds).pipe(
    mergeMap(secondaryDoctorIds => this.akitaDoctorQuery.selectMany(secondaryDoctorIds)),
    map((doctors: Array<Doctor>) => doctors.map(doctor => doctor.id)),
    untilDestroyed(this)
  );

  allInvoices$ = this.akitaCaseVisitQuery.select(entity => entity.salesOrder.invoices.filter(invoice => invoice.invoiceState !== 'DELETED'
  && invoice.invoiceId !== 'DRAFT'));
  
  restrictEditCash = this.akitaAppQuery.checkClinicFeatureExist('BA_CASH_RESTRICTION');

  paymentMethodSelections = PAYMENT_MODES_ALL;
  paymentMethodSelectionsAddNew;

  private componentDestroyed: Subject<void> = new Subject();
  public event: EventEmitter<any> = new EventEmitter();
  salesOrder$ = this.akitaCaseVisitQuery.select(entity => entity.salesOrder);
  billAdjustmentFormGroup: FormGroup;
  @Input() isEdit: boolean;
  @Input() billAdjItem: any;
  @Input() uploader: any;
  @Input() exsistingfiles: any;
  @Input() deliveryLocationId: string;
  @Input() caseId: string;
  discount= 0;
  charges = 0;
  payableWithGST = 0;
  bsModalRefs: BsModalRef;
  currentChargeItems$;
  currentChargeItems = [];
  remoteDeliveryChargeItems = [];
  tempRemoteDeliveryChargeItems = [];
  codesTypeahead = new Subject<string>();
  codesTypeaheadRemote = new Subject<string>();
  loading = false;
  drugId: FormControl;
  INPUT_DELAY_ITEM = 500;
  itemDetailedInfo;  
  billAdjustmentResons: StockAdjustmentReason[] = []; 

  documentTypes: string[];
  hasDropZoneOver = false;
  selectedFileArray = [];

  imgSrc: any;
  documentStatus: Status;
  isPdf = false;
  formGroup: FormGroup;
  remainingAmount: any = 0;
  totalBillValue: any = 0;

  gst: number = UtilsService.getGST();
  batchInventories = [];
  currentBatchDetails = []

  reasonList = [
    { value: 'Duplicate_Entry', label: 'Duplicate Entry' },
    { value: 'Remove_Charges', label: 'Remove Charges' },
    { value: 'Change_Company', label: 'Change Company' },
    { value: 'Copay_amount_not_collected', label: 'Co-pay amount not collected' },
    { value: 'Amount_exceed_limit_not_collected', label: 'Amount exceed limit not collected' },
    { value: 'Bill_Cancellation', label: 'Bill Cancellation' },
    { value: 'Others_Keyed_in_Remarks', label: 'Others – Keyed in Remarks' },
  ];

  allowAddRemoteDelivery: boolean;
  currentBatchDetailsSubscription: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaClinicDoctorQuery: AkitaClinicDoctorQuery,
    private akitaMedicalCoverageQuery: AkitaMedicalCoverageQuery,
    private akitaDoctorQuery: AkitaDoctorQuery,
    private fb: FormBuilder,
    private caseManagerService: CaseManagerService,
    private store: StoreService,
    private apiCaseManagerService: ApiCaseManagerService,
    private alertService: AlertService,
    private patientMedCovQuery: PatientMedicalCoverageQuery,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private apiCmsManagementService: ApiCmsManagementService,
    private utilsService: UtilsService,
    private akitaClinicChargeItemQuery: AkitaClinicChargeItemQuery,    
    private appConfig: AppConfigService,
    private apiPatientVisitService: ApiPatientVisitService,
    private documentManagementService: DocumentManagementService,    
    private dispatchItemService: DispatchItemService,
    private akitaAppQuery: AkitaAppQuery,
    private caseChargeFormService: CaseChargeFormService,
  ) {
    this.billAdjustmentResons = this.store.getStockAdjustmentReasonList().filter(reason =>(reason.billAdjustmentIdentifier)).sort((a, b) => a.description.localeCompare(b.description));
  }

  allowRemoteDelivery = this.akitaAppQuery.checkClinicFeatureExist(
    'ALLOW_REMOTE_DELIVERY'
  );

  ngOnInit() {
    this.allowAddRemoteDelivery = !!this.deliveryLocationId ? true : false;
    this.calculateDiscount();
    this.caseDetailFormGroup.get('primaryDoctor').valueChanges.subscribe(primaryDoctor => {
      this.secondaryDoctorList$ = this.akitaClinicDoctorQuery.selectActiveDoctors().pipe(
        map((doctorList: Array<Doctor>) => doctorList.filter(doctor => doctor.id !== primaryDoctor))
      );
    });

    this.caseDetailFormGroup.get('secondaryDoctors').valueChanges.subscribe(secondaryDoctors => {
      this.primaryDoctorList$ = this.akitaClinicDoctorQuery.selectActiveDoctors().pipe(
        map((doctorList: Array<Doctor>) => doctorList.filter(doctor => !secondaryDoctors.includes(doctor.id)))
      ).subscribe(res=>{
        this.primaryDoctorList = res;
      });
    });

    this.documentManagementService
      .getDocumentType()
      .pipe(untilDestroyed(this))
      .subscribe(docTypes => {
          this.documentTypes = docTypes;
      });

    this.initBillAdjustmentForm();
    this.getChargeItemDetails();
    if(this.isEdit){
      this.setInitialValuesToForm();
      this.billAdjustmentFormGroup.enable();     
      this.getCaseItemPrice(this.billAdjustmentFormGroup.get('purchaseItemsAdj').value);
    } else{
      this.setSelectedValuesToForm();
      this.billAdjustmentFormGroup.disable();
    }

    this.formGroup = this.fb.group({
      documentsArray: this.fb.array([]),
      newDocumentsArray: this.fb.array([]),
      documentType: ['All'],
    });

    this.uploader.response.subscribe(res => {
      if (
        (!!res.status && res.status !== 200) ||
        (!!res.statusCode && res.statusCode.toString() !== 'S0000')
      ) {
        this.alertService.error('File upload failed.');
      }
    });

    this.paymentMethodSelectionsAddNew = this.paymentMethodSelections.filter(method=> method.value !== 'CASH');

    if(this.deliveryLocationId){
      this.getRemoteDeliveryItemDetails(this.deliveryLocationId);
    }
  }

  calculateDiscount() {
    if (this.discountedInvoice) {
      this.discountedInvoice.forEach(element => {
        if (element.discount && element.discount.discountType == 'DOLLAR') {
          this.discount = +(element.discount.discountValue/100).toFixed(2);
        }
      });
    }
  }

  getRemoteDeliveryItemDetails(deliveryLocationId) {
    this.apiCaseManagerService.getRemoteDeliveryItemDetails(deliveryLocationId)
    .pipe(debounceTime(INPUT_DELAY), take(1))
    .subscribe(res =>{
      this.remoteDeliveryChargeItems = res.payload.sort((a,b)=> a.code.localeCompare(b.code));
      this.tempRemoteDeliveryChargeItems = [...this.remoteDeliveryChargeItems]
    })
    this.onFilterRemoteInputChanged();
  }

  onFilterRemoteInputChanged(){
    try {
      this.codesTypeaheadRemote
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(200),
      ).subscribe(value => {
        this.remoteDeliveryChargeItems = [];
        this.remoteDeliveryChargeItems = this.tempRemoteDeliveryChargeItems.filter(element =>{
          return element.code.includes(value.toUpperCase()) || element.name.toLowerCase().includes(value.toLowerCase())
        })
        
      })
    } catch (err) {
    }
  }

  getChargeItemDetails() {
    this.currentChargeItems$ = this.akitaChargeItemQuery.activeChargeItemList$.pipe(
      map(data => {
        if (data) {
          const updatedData = [];
          const filteredData = data.find(
            (item: ChargeItem) =>
              item.id === ''
          );
          if (filteredData) updatedData.push(filteredData);
          return updatedData;
        }
      })
    );

    this.currentChargeItems$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        if (value) {
          this.currentChargeItems = [...value];
        }
      });
    this.onFilterInputChanged();
  }

  onFilterInputChanged() {
    try {
      this.codesTypeahead
        .pipe(
          filter(input => {
            const inputCheck =
              input === undefined || input === null || input.trim().length < 3;
            if (inputCheck) {
              this.currentChargeItems$ = this.akitaClinicChargeItemQuery.activeClinicChargedItem$;
            }
            return !inputCheck;
          }),
          tap(() => (this.loading = true)),
          debounceTime(200),
          switchMap(term => {
            const planIds = this.akitaCaseVisitQuery.getCaseCoveragePlanIds();
            return this.apiCmsManagementService
              .searchListItemByKeyWordInventoryAndClinicId(
                this.store.getClinicId(),
                encodeURI(term),
                planIds
              )
              .pipe(
                catchError(error =>
                  of(<HttpResponseBody>{ page: {}, payload: [] })
                )
              );
          })
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data)
              this.currentChargeItems = data.payload.map(item => item.item);
          },
          err => {
            this.loading = false;
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    } catch (err) {
    }
  }

  initBillAdjustmentForm() {
    this.billAdjustmentFormGroup = this.fb.group({
      purchaseItemsAdj: this.fb.array([]),
      packageAdjEntities: this.fb.array([]),
      invoiceAdjEntities: this.fb.array([]),
      primaryDoctorId: this.fb.control('', Validators.required),
      secondaryDoctorIds: this.fb.control(''),
      billAdjReasonCode: this.fb.control('', Validators.required),
      remark: this.fb.control('',  Validators.required),
    })
  }

  setInitialValuesToForm(){
    this.primaryDoctor$.subscribe(doctor => {
      if(doctor && !this.primaryDoctorList.some(item=> item.id === doctor.id)){
        this.primaryDoctorList.push(doctor);
      }
      this.billAdjustmentFormGroup.get('primaryDoctorId').patchValue(doctor && doctor.id || '');
    });

    this.secondaryDoctors$.subscribe(secDoc => {
      this.billAdjustmentFormGroup.get('secondaryDoctorIds').patchValue(secDoc);
    })

    this.akitaCaseVisitQuery.select(entity => {
      this.gst = UtilsService.getGST(entity.caseStartDateTime);
      return entity.salesOrder.purchaseItem;
    }).subscribe(item => {
      let itemList = [...item].sort((a, b) => Number(a.remoteDelivery) - Number(b.remoteDelivery) || a.itemCode.localeCompare(b.itemCode));
      itemList.map(i=>{
        let item: ChargeItem = this.store.getItemById(i.itemRefId)
        const purchaseItemsAdj = this.fb.group({
          itemRefId: i.itemRefId,
          soldPrice: Number(i.soldPrice / 100).toFixed(2),
          purchaseQty: i.purchaseQty,
          dispenseQty: i.dispenseQty,
          salesItemId: i.salesItemId,
          itemCode: i.itemCode,
          itemName: i.itemName,
          purchaseUom: i.purchaseUom,
          remoteDelivery: i.remoteDelivery,
          batchNo: i.batchNo,
          expiryDate: i.batchNo ? i.expireDate : '',
          inventories: i.inventories,
          inventoried: item.inventoried
        });
        const formArray = <FormArray>this.billAdjustmentFormGroup.get('purchaseItemsAdj');
        formArray.push(purchaseItemsAdj);
      })
    });

    this.allInvoices$.subscribe(invoices=> {
      if(invoices.length === 0){
        if(this.isEdit && this.isShowingAdditionalPaymentMethod()){
          const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
          this.addNewDirectToForm(formArray);
        }
      }
    })

    this.allInvoices$.subscribe(invoices => invoices.map(i=>{
      const paymentInfoArr = this.fb.array([]);
      i.paymentInfos.forEach(payInfo => {
        const paymentInfo = this.fb.group({
          billTransactionId: payInfo.billTransactionId,
          billMode: payInfo.billMode,
          amount: Number(payInfo.amount / 100).toFixed(2),
          invoiceNumber: payInfo.invoiceNumber,
        });
        paymentInfoArr.push(paymentInfo);
      });

      const invoiceAdjEntities = this.fb.group({
        visitId: i.visitId,
        invoiceId: i.invoiceId,
        invoiceType: i.invoiceType,
        claimExpectedAmt: Number(i.claim.claimExpectedAmt / 100).toFixed(2) ,
        paymentInfos: paymentInfoArr,
        payableAmount: i.payableAmount,
        paidAmount: Number(i.paidAmount / 100).toFixed(2),
        planId: i.planId
      });
      const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
      formArray.push(invoiceAdjEntities);
      if(this.isEdit && this.isShowingAdditionalPaymentMethod()){
        this.addNewDirectToForm(formArray);
      }
    }))

    this.allInvoices$.subscribe(
      res=>{
        if(!res.some(inv=> inv.invoiceType === 'CREDIT')){
          // set medical coverage row from coverage info in charges breakdown
          let caseCov: CaseCoveragePlan[] = this.akitaCaseVisitQuery.getCaseCoverage();
          const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
          caseCov.forEach(cov => {
            const invoiceAdjEntities = this.fb.group({
              visitId: String(this.akitaCaseVisitQuery.getActiveId()),
              invoiceId: 'DRAFT',
              invoiceType: "CREDIT",
              paymentInfos: this.fb.array([]),
              payableAmount: 0,
              paidAmount: 0,
              planId: cov.planId
            });
            formArray.push(invoiceAdjEntities);
          });
        }
      }
    )
    this.setChargesAndPayableAmt();
    this.subscribeChangesOnBatchNoAndInventory();
  }

  setChargesAndPayableAmt() {
    this.charges = 0;
    this.payableWithGST = 0;
    let tempCharges = 0;
    this.billAdjustmentFormGroup.get('purchaseItemsAdj').value.forEach(i=> {
      tempCharges += +Number(i.soldPrice);
    });
    this.charges = +(tempCharges).toFixed(2);
    this.payableWithGST = +((this.charges - this.discount) * this.gst).toFixed(2);
    this.calculateRemainingAmount();
  }
  
  calculateRemainingAmount() {    
    this.remainingAmount = this.payableWithGST;
    
    this.billAdjustmentFormGroup.get('invoiceAdjEntities').value.forEach(i=> {
      if (i.invoiceType === 'CREDIT') {
        this.remainingAmount = this.remainingAmount-Number(i.paidAmount);
      } else {
         i.paymentInfos.forEach(infor => {
          this.remainingAmount = this.remainingAmount-Number(infor.amount);
         })
      }
      
    });

    this.remainingAmount = this.remainingAmount.toFixed(2);
    this.calculatTotalBill();
  }

  calculatTotalBill() {    
    this.totalBillValue = 0.0;
    
    this.billAdjustmentFormGroup.get('invoiceAdjEntities').value.forEach(i=> {
      if (i.invoiceType === 'CREDIT') {
        this.totalBillValue = this.totalBillValue+Number(i.paidAmount);
      } else {
         i.paymentInfos.forEach(infor => {
          this.totalBillValue = this.totalBillValue+Number(infor.amount);
         })
      }
      
    });

    this.totalBillValue = this.totalBillValue.toFixed(2);
  }

  onChangeFinalAmt(){
    this.setChargesAndPayableAmt();
  }

  addNewDirectToForm(formArray: FormArray) {
    const visitId = String(this.akitaCaseVisitQuery.getActiveId());
    const paymentInfoArr = this.fb.array([]);
    const paymentInfo = this.fb.group({
      billTransactionId:'',
      billMode: 'CASH',
      amount: 0,
      invoiceNumber: 'DRAFT',
    });
    paymentInfoArr.push(paymentInfo);

    const invoiceAdjEntities = this.fb.group({
      visitId: visitId,
      invoiceId: 'DRAFT',
      invoiceType: "DIRECT",
      claimExpectedAmt: 0 ,
      paymentInfos: paymentInfoArr,
      payableAmount: 0,
      paidAmount: 0,
      planId: null
    });
    formArray.push(invoiceAdjEntities);
  }

  isShowingAdditionalPaymentMethod(){
    let isShowing = false;
    const formArray = this.billAdjustmentFormGroup.get('invoiceAdjEntities').value;
    if(formArray.length === 0){
      return false;
    }
    if(formArray.some(item=> item.invoiceType === 'DIRECT')){
      isShowing = false;
    } else{
      isShowing = true;
    }
    return false;
  }

  setSelectedValuesToForm(){
    if(this.billAdjItem){
      let doctorId = this.billAdjItem.primaryDoctorId;
      let secondaryDocIds = getArr(this.billAdjItem, 'secondaryDoctorIds');
      if(doctorId && !this.primaryDoctorList.some(item=> item.id === doctorId)){
        this.primaryDoctorList.push(this.akitaDoctorQuery.getDoctor(doctorId));
      }

      secondaryDocIds.forEach(secId => {
        let filteredList = this.secondaryDoctorList$.pipe(
          map((doctorList: Array<Doctor>) => doctorList.filter(doctor => doctor.id === secId))
        );
        let filtredListLength;
        filteredList.subscribe(res=> {
          filtredListLength = res.length;
        });
        if(secId && filteredList && filtredListLength === 0){
            this.secondaryDoctorList$.subscribe(list=>{
              list.push(this.akitaDoctorQuery.getDoctor(secId));
              this.secondaryDoctorList$ = of(list);
            })
        }
      });
      this.billAdjustmentFormGroup.get('primaryDoctorId').patchValue(doctorId || '');
      this.billAdjustmentFormGroup.get('secondaryDoctorIds').patchValue(secondaryDocIds);
      this.billAdjustmentFormGroup.get('remark').patchValue(this.billAdjItem.remark);
      this.billAdjustmentFormGroup.get('billAdjReasonCode').patchValue(this.billAdjItem.billAdjReasonCode);

      this.billAdjItem.purchaseItemsAdj.forEach(i=> {
        const purchaseItemsAdj = this.fb.group({
          itemRefId: i.itemRefId,
          soldPrice: Number(i.soldPrice / 100).toFixed(2),
          purchaseQty: i.purchaseQty,
          dispenseQty: i.dispenseQty,
          salesItemId: i.salesItemId,
          itemCode: i.itemCode,
          itemName: i.itemName,
          purchaseUom: i.salesUom,
          remoteDelivery: i.remoteDelivery,
          batchNo: i.batchNumber,
          expiryDate: i.batchNumber ? i.expiryDate : '',
          inventories: i.inventories
        });
        const formArray = <FormArray>this.billAdjustmentFormGroup.get('purchaseItemsAdj');
        formArray.push(purchaseItemsAdj);
      });

      this.billAdjItem.invoiceAdjEntities.forEach(i => {
        const paymentInfoArr = this.fb.array([]);
        i.paymentInfos.forEach(payInfo => {
          const paymentInfo = this.fb.group({
            billTransactionId: payInfo.billTransactionId,
            billMode: payInfo.billMode,
            amount: Number(payInfo.amount / 100).toFixed(2),
            invoiceNumber: payInfo.invoiceNumber,
          });
          paymentInfoArr.push(paymentInfo);
        });

        const invoiceAdjEntities = this.fb.group({
          visitId: i.visitId,
          invoiceId: i.invoiceId,
          invoiceType: paymentInfoArr.length > 0 ? "DIRECT" : "CREDIT",
          paymentInfos: paymentInfoArr,
          payableAmount: i.payableAmount,
          paidAmount: Number(i.paidAmount / 100).toFixed(2),
          planId: i.planId
        });
        const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
        formArray.push(invoiceAdjEntities);
      });
    }
    this.gst = UtilsService.getGST(this.akitaCaseVisitQuery.getCaseDate());
    this.setChargesAndPayableAmt()
  }

  trackById(index: number, item: any) {
    return item.itemRefId;
  }

  trackByInvoiceId(index: number, item: any) {
    return item.invoiceId;
  }

  trackByPaymentInfo(index: number, item: any) {
    return item.invoiceNumber;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  getCheckedInvoice(invoice) {
    return this.caseManagerService.selectCaseSelectedInvoice().pipe(
      map(selectedInvoices => {
        let selectedInvoice = false;
        if (selectedInvoices && selectedInvoices.length > 0) {
          selectedInvoice = selectedInvoices.indexOf(invoice.invoiceId) !== -1;
        }
        return selectedInvoice;
      })
    );
  }

  getMedicalCoverage(planId) {
    const medicalCoverage = this.store.getMedicalCoverageByPlanId(planId);
    return medicalCoverage;
  }

  getMedicalCoveragePlan(planId) {
    return this.akitaMedicalCoverageQuery.getMedicalCoveragePlan(planId);
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  submit(){
    if(this.billAdjustmentFormGroup.valid || this.billAdjItem.status === 'FAILED'){
      let submitForm = true;
      if( this.remainingAmount > 0 && !confirm("You have $ "+this.remainingAmount+" To pay, Click ok to submit or click cancel to pay.")) {
        submitForm = false;
      }

      if (submitForm) {
        const initialState = {
        };

        this.bsModalRefs = this.bsModalService.show(
          CaseSummeryBillAdjConfirmationComponent,
          {
            initialState,
            class: 'modal-md'
          }
        );

        this.bsModalRefs.content.event.subscribe(data => {
          if (data) {      
            this.event.emit(this.getReqPayload());
          } else{
            this.event.emit(false); 
          }
        });
      }
    }
  }

  getReqPayload(): any {
    const purchaseItemsAdj = [];
    this.billAdjustmentFormGroup.get('purchaseItemsAdj').value.forEach(i=> {
      let multipliedNumber = i.soldPrice * 100;
      let soldPrice  = Math.round(multipliedNumber * 100) / 100;
      const pItem = {
        itemRefId: i.itemRefId,
        soldPrice: soldPrice,
        purchaseQty: i.purchaseQty,
        dispenseQty: i.dispenseQty,
        salesItemId: i.salesItemId,
        batchNumber: i.batchNo,
        expiryDate: i.expiryDate ? moment(i.expiryDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
        remoteDelivery: i.remoteDelivery || false,
      };
      purchaseItemsAdj.push(pItem);
    });

    const invoiceAdjEntities = [];
    var visitId='';
    this.billAdjustmentFormGroup.get('invoiceAdjEntities').value.forEach(i => {
      visitId = (i.visitId && i.visitId  != "null") ? i.visitId : visitId;
      const paymentInfoArr = [];
      let newPaidAmt = 0;
      let newPayableAmt = 0;
      if(i.planId !== null){
        newPaidAmt = +Number(i.paidAmount * 100).toFixed(0);
        newPayableAmt = +Number(i.paidAmount * 100).toFixed(0);
      }
      i.paymentInfos.forEach(payInfo => {
        const paymentInfo = {
          billTransactionId: payInfo.billTransactionId,
          billMode: payInfo.billMode,
          amount: +Number((payInfo.amount * 100).toFixed(0)),
          invoiceNumber: payInfo.invoiceNumber,
        };
        newPaidAmt += +Number(payInfo.amount * 100).toFixed(0);
        newPayableAmt += +Number(payInfo.amount * 100).toFixed(0);
        paymentInfoArr.push(paymentInfo);
      });

      const invoiceItem = {
        visitId: visitId,
        invoiceId: i.invoiceId,
        claimExpectedAmt: i.claim && Number(i.claim.claimExpectedAmt).toFixed(2) ,
        paymentInfos: paymentInfoArr,
        payableAmount: newPayableAmt,
        paidAmount: newPaidAmt,
        planId: i.planId
      };
      invoiceAdjEntities.push(invoiceItem);
    });

    let reqObj = {
      purchaseItemsAdj: purchaseItemsAdj,
      invoiceAdjEntities: invoiceAdjEntities,
      packageAdjEntities: [],
      primaryDoctorId: this.billAdjustmentFormGroup.get('primaryDoctorId').value,
      secondaryDoctorIds: this.billAdjustmentFormGroup.get('secondaryDoctorIds').value,
      remark: this.billAdjustmentFormGroup.get('remark').value,
      billAdjReasonCode: this.billAdjustmentFormGroup.get('billAdjReasonCode').value,
    }
    return reqObj;
  }

  updateMedicalCoverage(update = false) {
    const coverageInformation = [];

    const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
      formArray.controls.forEach(element => {
        if(element.get('invoiceType').value === "CREDIT"){
          let coverage =  this.akitaMedicalCoverageQuery.getCoverageByPlanId(element.get('planId').value);
          let limit = 0;
          let remarks = '';
         if ( coverage && coverage.coveragePlans) {
            let plan = coverage.coveragePlans.find(plan=> plan.id === element.get('planId').value );
            limit = plan ? (plan.capPerVisit.limit/100) : 0;
            remarks = plan.remarks;
         }
     
            coverageInformation.push({
              limit: limit,
              medicalCoverageId: coverage.id,
              name: coverage.name,
              planId: element.get('planId').value,
              remarks: remarks,
              updated: true
            });
        } 
      });

    const initialState = {
      config: update ? COVERAGE_CONFIG.CASE_MANAGER.UPDATE_COVERAGE : COVERAGE_CONFIG.CASE_MANAGER.DISPLAY_COVERAGE,
      planIdsFromCase: coverageInformation,
      billAdjustmentMode: true
    };

    this.bsModalRefs = this.bsModalService.show(
      CaseManagerMedicalCoverageComponent,
      {
        initialState,
        class: 'modal-xl'
      }
    );

    this.bsModalRefs.content.event.subscribe(data => {
      if (data) {
        this.patientMedCovQuery.medicalCovToFG$.subscribe(item => {
          item.value.forEach(val => {
            if(val.medicalCoverageId === data[0].medicalCoverageId){
              const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
              let creditInvoiceAvailable = false;
              formArray.controls.forEach(element => {
                // support only for one medical coverage at the moment
                if(element.get('invoiceType').value === "CREDIT"){
                  element.get('planId').patchValue(val.planId);
                  creditInvoiceAvailable = true;
                }
              });

              if (!creditInvoiceAvailable) {
                const visitId = String(this.akitaCaseVisitQuery.getActiveId());
                const paymentInfoArr = this.fb.array([]);
                const invoiceAdjEntities = this.fb.group({
                  visitId: visitId,
                  invoiceId: 'DRAFT',
                  invoiceType: "CREDIT",
                  claimExpectedAmt: 0 ,
                  paymentInfos: paymentInfoArr,
                  payableAmount: 0,
                  paidAmount: 0,
                  planId: val.planId
                });
                formArray.push(invoiceAdjEntities);
              }
            }
          })
        });

        let selectedCoverages$ = new BehaviorSubject<CaseCoveragePlan[]>(data);
        this.attachedMedicalCoverages$ = selectedCoverages$.asObservable();
        const covIds = data.map(d => {return d.medicalCoverageId});

        this.coverageNames$ = this.akitaCaseVisitQuery.select(entity => entity.coverages.map(ccp => ccp.planId)).pipe(
          mergeMap(coverages => this.akitaMedicalCoverageQuery.selectMedicalCoverages(coverages)),
          map((coverages: Array<MedicalCoverage>) => {
            let coverageName = '';
            coverages.forEach(
              (coverage, index) => {
                if(covIds.includes(coverage.id)){
                  coverageName +=
                  (coverage.name + ((index === coverages.length - 1) ? '' : ', '))
                }
              }
            );
            return coverageName;
          })
        );
      }
    });
  }

  addNewItem(event){
    const purchaseItemsAdj = this.fb.group({
      itemRefId: ['', Validators.required],
      soldPrice: 0,
      purchaseQty: [1, Validators.min(1)],
      dispenseQty: 0,
      salesItemId: '',
      itemCode: '',
      itemName: '',
      purchaseUom: '',
      isNew: true,
      batchNo: '',
      expiryDate: '',
      inventories: [],
      inventoried: false,
      remoteDelivery: event ? event : false
    });
    
    const formArray = <FormArray>this.billAdjustmentFormGroup.get('purchaseItemsAdj');
    formArray.push(purchaseItemsAdj);
  }

  onDiagnosisSelected(event, i){
    const formArray = <FormArray>this.billAdjustmentFormGroup.get('purchaseItemsAdj');
    const defaultQty = this.dispatchItemService.getDefaultPurchaseQty(
      event
    );
    this.getCaseItemPrice(event, true)
    
    this.currentBatchDetailsSubscription = this.caseManagerService.getCurrentBatchDetails()
    .pipe(take(1))
    .subscribe((res : Inventories[]) => {
      if (event.inventoried && res && res.length) {
        const inventories = this.dispatchItemService.sortCaseItemInventories(res);
        let item = formArray.controls[i];
        item.get('batchNo').patchValue(inventories[0].batchNo);
        item.get('expiryDate').patchValue(inventories[0].expireDate ? moment(inventories[0].expireDate).format(DISPLAY_DATE_FORMAT) : '');
        item.get('inventories').patchValue(inventories);
      } else {
        item.get('batchNo').patchValue('');
      }
    })

    let item = formArray.controls[i];
    item.get('itemName').patchValue(event.name);
    item.get('purchaseQty').patchValue(defaultQty || 1);
    item.get('purchaseUom').patchValue(event.salesUom);
    item.get('inventoried').patchValue(event.inventoried);
    item.updateValueAndValidity({ emitEvent: false });
    this.subscribeChangesOnBatchNoAndInventory();
  }

  isValidItemForm(){
    return this.billAdjustmentFormGroup.get('purchaseItemsAdj').status === 'VALID';
  }

  onbtnDeleteClicked(item){
    const formArray = <FormArray>this.billAdjustmentFormGroup.get('purchaseItemsAdj');
    formArray.removeAt(formArray.value.findIndex(i=> i.itemRefId === item.itemRefId));
    this.setChargesAndPayableAmt();
  }

  addPaymentMethod(){
    const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
    let directMethods = formArray.controls.find(item => item.get('invoiceType').value === 'DIRECT');
    if(directMethods){
      const paymentInfoArr = <FormArray>directMethods.get('paymentInfos');
      const invoiceNo = directMethods.get('invoiceId');
      const paymentInfo = this.fb.group({
        billTransactionId:'',
        billMode: ['', Validators.required],
        amount: 0,
        invoiceNumber: invoiceNo,
        isNew: true
      });
      paymentInfoArr.push(paymentInfo);

    } else {
      const visitId = String(this.akitaCaseVisitQuery.getActiveId());
      const paymentInfoArr = this.fb.array([]);
      const paymentInfo = this.fb.group({
        billTransactionId:'',
        billMode: ['', Validators.required],
        amount: 0,
        invoiceNumber: 'DRAFT',
        isNew: true
      });
      paymentInfoArr.push(paymentInfo);

      const invoiceAdjEntities = this.fb.group({
        visitId: visitId,
        invoiceId: 'DRAFT',
        invoiceType: "DIRECT",
        claimExpectedAmt: 0 ,
        paymentInfos: paymentInfoArr,
        payableAmount: 0,
        paidAmount: 0,
        planId: null
      });
      formArray.push(invoiceAdjEntities);
    }
  }

  onbtnNewItemDeleteClicked(index){
    const formArray = <FormArray>this.billAdjustmentFormGroup.get('invoiceAdjEntities');
    const directMethods = formArray.controls.find(item => item.get('invoiceType').value === 'DIRECT');
    if(directMethods){
      let paymentInfoArr = <FormArray>directMethods.get('paymentInfos');
      paymentInfoArr.removeAt(index);
    }

    this.calculateRemainingAmount();
  }

  fileOver(event) {
    this.hasDropZoneOver = event;
  }

  openModal(event) {
    console.log(
      this.uploader
    );
  }

deleteDocument() {
  this.documentManagementService.onDeleteDocument(this.selectedFileArray);
}

resetSelectedFileArray() {
  this.selectedFileArray = [];
}


onRemoveDocumemt(index) {
  const item = this.uploader.queue[index];
  item.remove();
  const newDocumentsArray = this.caseDetailFormGroup.get(
    'newDocumentsArray'
  ) as FormArray;
  newDocumentsArray.removeAt(index);
}

public onDocumentClicked(file): void {
  this.documentManagementService.downloadDocumentToLocal(file);
}

isFailed() {
  if (!this.billAdjItem) {
    return false;
  }
  
  return this.billAdjItem.status === 'FAILED';
}

buildChargeDetailsItem(dispatchItemEntities) {
  return dispatchItemEntities.map(item => {
    return this.caseChargeFormService.buildChargeDetailsItem(
      item.itemRefId,
      item.excludedCoveragePlanIds,
      item.purchaseQty
    );
  });
}

getCaseItemPrice(dispatchItemEntities, isNew=false) {
  if(isNew){
    var caseItem = 
      [
        this.caseChargeFormService.buildChargeDetailsItem(
          dispatchItemEntities.id,
          dispatchItemEntities.excludedCoveragePlanIds,
          dispatchItemEntities.standardDispenseAmount
        ),
      ]
  }
  this.apiCaseManagerService
  .getCaseItemPrices(this.caseId, {
    chargeDetails: isNew ? caseItem : this.buildChargeDetailsItem(dispatchItemEntities),
  })
  .pipe(debounceTime(INPUT_DELAY), take(1))
  .subscribe(
    caseItemPrices => {
      if(isNew) {
        this.currentBatchDetails = caseItemPrices.payload.inventoryData;
        this.caseManagerService.setCurrentBatchDetails(this.currentBatchDetails);
        this.currentBatchDetails.map(item=>{
          const inventories = this.batchInventories.find(element=>{
            return element.batchNo === item.batchNo;
          })
          if (!inventories){
            this.batchInventories.push(item);
          }
        })
      } else {
      this.batchInventories = [...caseItemPrices.payload.inventoryData]
      this.getInventoryDataMapped(caseItemPrices.payload.inventoryData, this.billAdjustmentFormGroup.get('purchaseItemsAdj').value);
      }
    }
  )
}

getInventoryDataMapped(inventoryData, dispatchItemEntities) {
  dispatchItemEntities.forEach(element => {
    element.inventories = [];
    const inventories = [];
    
    inventoryData.some(item =>{
      if(element.itemRefId === item.itemId){
        inventories.push(item);
      }   
    })
    element.inventories = JSON.parse(JSON.stringify(inventories));
    element.inventories = !element.inventories.length ? element.inventories : this.dispatchItemService.sortCaseItemInventories(element.inventories);
  });

  this.billAdjustmentFormGroup.get('purchaseItemsAdj').value.forEach(element => {
    if (element.inventories && element.inventories.length && !element.isNew){
      element.inventories.forEach(item=>{
        item.disabled =  true;
      });
    } else if (element.inventories && element.inventories.length && element.isNew) {
      element.inventories.forEach(item=>{
        item.disabled =  false;
      });
    }
  });
}

subscribeChangesOnBatchNoAndInventory() {
  const purchaseItemsAdj = this.billAdjustmentFormGroup
  .get('purchaseItemsAdj') as FormArray

  purchaseItemsAdj.controls.forEach(element => {
    element.valueChanges.pipe(
      debounceTime(this.INPUT_DELAY_ITEM),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed),
      skip(1)
    )
    .subscribe(newBatch => {      
    this.getInventoryDataMapped(this.batchInventories, this.billAdjustmentFormGroup.get('purchaseItemsAdj').value)
    if (newBatch) {      
     this.updateSelectedBatch(newBatch, element, element.value.inventories);
    }
  })
  });  
}

updateSelectedBatch(data, purchaseItemsAdj, inventories) {
  const selectedInventory = inventories.find(
    inventory => inventory.batchNo === data.batchNo
  );
  const expiryDate = selectedInventory
      ? moment(selectedInventory.expireDate).format(DISPLAY_DATE_FORMAT) : '';

  purchaseItemsAdj
  .get('expiryDate')
  .patchValue(expiryDate, { emitEvent: false });
  this.getInventoryDataMapped(this.batchInventories, this.billAdjustmentFormGroup.get('purchaseItemsAdj').value)
}

getRemainingQuantity( element, item) {
  let remainingQuantity;
  if (element.batchNo === item.batchNo){
    remainingQuantity = element.remainingQuantity - item.purchaseQty;
  } else {
    remainingQuantity = element.remainingQuantity;
  }
  return remainingQuantity;
}

}
