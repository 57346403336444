import { UtilsService } from "../services/utils.service";

export class InventoryItem {
  id: string;
  code: string;
  name: string;
  description: string;
  baseUom: string;
  purchaseUom: string;
  cost: Price;
  reorderQty: number;
  itemType: string;
  purchasingBlocked: boolean;
  blockReason: string;
  outOfStockRemarks: string;
  trackingCode: boolean;

  constructor(
    id: string = '',
    name: string = '',
    code: string = '',
    description: string = '',
    baseUom: string = '',
    purchaseUom: string = '',
    cost: Price = undefined,
    reorderQty: number = 0,
    itemType: string = '',
    purchasingBlocked: boolean = false,
    blockReason: string = '',
    outOfStockRemarks: string = '',
    trackingCode: boolean = false
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.description = description;
    this.baseUom = baseUom;
    this.purchaseUom = purchaseUom;
    this.cost = cost;
    this.reorderQty = reorderQty;
    this.itemType = itemType;
    this.purchasingBlocked = purchasingBlocked;
    this.blockReason = blockReason;
    this.outOfStockRemarks = outOfStockRemarks;
    this.trackingCode = trackingCode;
  }
}

export class Price {
  price: number;
  taxIncluded: boolean;

  constructor();
  constructor(price: number, taxIncluded: boolean);
  constructor(price?: number, taxIncluded?: boolean) {
    this.price = price;
    this.taxIncluded = taxIncluded;
  }

  adapt(obj?) {
    if (!obj) {
      return this;
    }

    this.price = obj.taxIncluded
      ? Number((obj.price / UtilsService.getGST()).toFixed(0))
      : obj.price;
    this.taxIncluded = false;

    return this;
  }

  getPriceWithoutTax(): number {
    return this.taxIncluded
      ? Number((this.price || 0 / UtilsService.getGST()).toFixed(0))
      : this.price || 0;
  }
}
