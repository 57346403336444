import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import DatePickerConfig from '../../../../objects/DatePickerConfig';
import { ApiPatientInfoService } from '../../../../services/api-patient-info.service';
import { AkitaPatientAppQuery } from '../../../../services/states/akita-patient/akita-patient-app.query';
import { AlertService } from '../../../../services/alert.service';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';

@Component({
  selector: 'app-patient-sfl-details-modal',
  templateUrl: './patient-sfl-details-modal.component.html',
  styleUrls: ['./patient-sfl-details-modal.component.scss']
})
export class PatientSflDetailsModalComponent implements OnInit {
  disableSaveBtn: boolean = false;
  @Input() allScreeningTypes: Array<any>;
  @Input() screeningTypes: Array<any>;
  @Input() followUpOutcomes: Array<any>;
  //@Input() screeningOutcomes: Array<any>;
  @Input() screeningSubTypes: Array<any>;
  @Input() testOrders: Array<any>;
  @Input() allScreeningExceptionalConditions: Array<any>;
  @Input() screeningExceptionalConditions: Array<any>;
  @Input() isEdit;
  @Input() patientId;
  @Input() patientGroupId;
  @Input() careReportFlow;
  screeningSubTypesFiltered: any[] = [];
  public addFormGroup: FormGroup;
  screeningDateDatePickerConfig: DatePickerConfig;
  title: string;
  constructor(
    public bsModalRef: BsModalRef,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private alertService: AlertService,
  ) {
    this.screeningDateDatePickerConfig = new DatePickerConfig(
      null,
      new Date(),
      null,
      'bottom',
      'none'
    );
  }

  ngOnInit(): void {
    if(!this.isEdit){
      this.addFormGroup.reset();
      setTimeout(() => {
        if (!this.careReportFlow){
          this.addFormGroup.get('screenDate').setValue(new Date());
        }
      }, 0);
    }
    const screeningType = this.addFormGroup.get('screeningType');
    this.setsflScreening(screeningType);
  }

  onBtnExit() {
    this.bsModalRef.hide();
  }

  onBtnSave() {
    this.disableSaveBtn = true;
    const value = this.addFormGroup.getRawValue();
    value.conditionCodes = value.conditionCodes ? value.conditionCodes : null;
    value.visitId = localStorage.getItem('visitId') || '';
    value.screenDate = value.screenDate ? moment(value.screenDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '';
    const patientId = this.akitaPatientAppQuery.getId() || this.patientId;
    if (this.isEdit) {
      this.apiPatientInfoService.modifyPatientSflAssociation(patientId, value).subscribe(
        res => {
          this.saveSflDetails(res);
        },
        err => {
          this.disableSaveBtn = false;
          this.alertService.error(JSON.stringify(err.error.message));
          this.bsModalRef.hide();
        }
      )
    } else {
      this.apiPatientInfoService.addPatientSflAssociation(patientId, value).subscribe(
        res => {
          this.saveSflDetails(res);
        },
        err => {
          this.disableSaveBtn = false;
          this.alertService.error(JSON.stringify(err.error.message));
          this.bsModalRef.hide();
        }
      )
    }
  }

  saveSflDetails(res){
    alert('SFL details has been added successfully.');
    if (this.careReportFlow){
      this.alertService.sendReloadCareReportEvent({patientId: this.patientId , patientGroupId: this.patientGroupId});
    } else {
      this.alertService.sendSflDetailsAddedEvent({itemId: res.payload?.id, isUpdate: false});
    }
    this.disableSaveBtn = false;
    this.bsModalRef.hide();
  }

  sflTestOrderChange(testOrder){
    if (testOrder.value != '4'){
      // this.addFormGroup.get('screeningOutcome').setValue('');
      this.addFormGroup.get('followUpOutcome').setValue('');
      this.addFormGroup.get('followUpOutcome').setValidators(null)
    } else if (testOrder.value == '4') {
      this.addFormGroup.get('followUpOutcome').setValidators([Validators.required]);
    }
    this.addFormGroup.get('followUpOutcome').updateValueAndValidity();
  }

  sflTestTypeChange(){
    this.addFormGroup.get('conditionCodes').setValue('');
    this.setConditionCodesValidations(this.addFormGroup.get('screeningType').value, this.addFormGroup.get('testType').value);
  }

  sflScreeningTypeChange(screeningType){
    this.setsflScreening(screeningType);
    this.addFormGroup.get('testType').setValue('');
    this.addFormGroup.get('conditionCodes').setValue('');
  }

  private setConditionCodesValidations(screeningType, testType): void {
    if (((screeningType === 'BREAST_CANCER_SCREENING' && testType === 'MAMMO') || 
          (screeningType === 'COLORECTAL_CANCER_SCREENING' && testType === 'COLO'))
      ) {
        this.addFormGroup.get('conditionCodes').setValidators([Validators.required]);
      } else {
        this.addFormGroup.get('conditionCodes').setValidators(null);
      }
      this.addFormGroup.get('conditionCodes').updateValueAndValidity();
  };

  setsflScreening(screeningType) {
    let screeningSubTypesFiltered, screeningExceptionalConditions;
    if (screeningType && screeningType.value === 'COLORECTAL_CANCER_SCREENING') {
      screeningSubTypesFiltered = this.screeningSubTypes.filter(item => ['FITT', 'COLO'].includes(item.cmsCode));
      screeningExceptionalConditions = this.allScreeningExceptionalConditions.filter(item => item.cmsCode !== 'SEC04');
    }

    if (screeningType && screeningType.value === 'CHRONIC_SCREENING') {
      screeningSubTypesFiltered = this.screeningSubTypes.filter(item => ['OGTT', 'REPEAT_FASTING', 'CHRONIC_HBA1C'].includes(item.cmsCode));
    }

    if (screeningType && screeningType.value === 'CERVICAL_CANCER_SCREENING') {
      screeningSubTypesFiltered = this.screeningSubTypes.filter(item => ['REPEAT_PAP_SMEAR', 'REPEAT_HPV_DNA'].includes(item.cmsCode));
    }

    if (screeningType && screeningType.value === 'BREAST_CANCER_SCREENING') {
      screeningSubTypesFiltered = this.screeningSubTypes.filter(item => ['MAMMO'].includes(item.cmsCode));
      screeningExceptionalConditions = this.allScreeningExceptionalConditions.filter(item => item.cmsCode === 'SEC04');
    }

    this.screeningSubTypesFiltered = screeningSubTypesFiltered;
    this.screeningExceptionalConditions = screeningExceptionalConditions
  }

}
