export default class HSGEnrollment {
  status: string;
  enrolledDate: string;
  syncDate: string;
  appointmentLinkSent: boolean;
  appointmentLinkSentDate: string;
  enrolledClinicId: string;
  lastPlanSubmittedDate: string;

  constructor(status?: string, enrolledDate?: string) {
    this.status = status || null;
    this.enrolledDate = enrolledDate || null;
  }
}