// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'cmsdua',
  apiPath: {
    API_URL: 'https://cmsapi.healthwaymedical.info',
    API_URL_ENV: 'https://cmsapi.healthwaymedical.info/cms-dua',
    API_DOMAIN: 'cmsapi.healthwaymedical.info',
    API_AA_URL: 'https://cmsapi.healthwaymedical.info/aacore',
    API_PATIENT_VISIT_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/patient-visit',
    API_INVENTORY_URL: 'https://cmsapi.healthwaymedical.info/inventory-module',
    API_DOCUMENT_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/document-management',
    API_INVENTORY_SYSTEM_URL: 'https://cmsapi.healthwaymedical.info/cms-dua',
    API_PATIENT_INFO_URL: 'https://cmsapi.healthwaymedical.info/cms-dua',
    API_VITAL_URL: 'https://cmsapi.healthwaymedical.info/cms-dua/vital',
    API_CASE_INFO_URL: 'https://cmsapi.healthwaymedical.info/cms-dua/case',
    API_PAYMENT_URL: 'https://cmsapi.healthwaymedical.info/cms-dua/charging',
    API_CMS_MANAGEMENT_URL: 'https://cmsapi.healthwaymedical.info/cms-dua',
    API_CLAIM_URL: 'https://cmsapi.healthwaymedical.info/cms-dua',
    API_MANUAL_CLAIM_URL: 'https://cmsapi.healthwaymedical.info/cms-dua',
    API_CONSULATION_TEMPLATE_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/consultation-template',
    API_COMMUNICATIONS_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/communication',
    REPORT_URL: 'https://cmsapi.healthwaymedical.info/reporting-ui/',
    REPORT_URL_NEW: 'https://cmsapi.healthwaymedical.info/report-data-loader/reporting-ui/',
    API_APPOINTMENT_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/appointment',
    WEB_SOCKET_URL:
      'wss://cmsapi.healthwaymedical.info/cms-dua/visit-notify-endpoint',
      WEB_SOCKET_CHAT_URL:
      'wss://cmsapi.healthwaymedical.info/cms-dua/chat-subscription-endpoint',
    API_PACKAGE_ITEM_INFO_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/item',
    SHOW_COPY_PRESCRIPTION_AFTER: '26-07-2018T00:00:00',
    API_PCN_VISITS_URL: 'https://cmsapi.healthwaymedical.info/cms-dua/pcn',
    API_ASSESSMENT_URL: 'https://cmsapi.healthwaymedical.info/cms-dua/assessment',
    API_CLINIC_ROOM_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/clinic-room',
    API_CONSULTATION_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/consultation',
    API_VENDOR_INVOICE_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/vendor-invoice',
    API_SALES_ORDER_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/sales-order',
    API_SMART_CMS_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/smart-cms',
    API_HSG_URL:
      'https://cmsapi.healthwaymedical.info/cms-dua/hsg',
    SURVEY_EXTERNAL_LINK:'https://cmsnonenav.healthwaymedical.info/',
    NEHR_EXIT_PATIENT_URL:'https://portal.onboard.nehr.sg/nehrportal/web/patient-view/exit-patient-page',
    NEHR_LOGOUT_PATIENT_URL:' https://portal.onboard.nehr.sg/nehrportal/c/portal/logout',
  },
  versionCheckURL: './version.json',
};
