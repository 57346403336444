import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import {
  ALLERGY_TYPES,
  ALLERGIES,
  DISPLAY_DATE_FORMAT,
  ALERT_TYPES,
  ALERT_PRIORITY,
} from './../constants/app.constants';
import { UtilsService } from './utils.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import Allergy from '../objects/Allergy';
import { Observable, Subject } from 'rxjs';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class AllergiesFormService {
  allergies;
  alerts;

  // Options
  allergyTypes = [];
  allergyNames = [];
  medicalAlertTypes = [];
  medicalAlertPriorities = [];

  isAllergyUpdatedSubject: Subject<boolean> = new Subject();
  isAllergyUpdated$ = this.isAllergyUpdatedSubject.asObservable();

  isManageAllergyModalClosed = new Subject<boolean>();
  isSaveAlertsAndAllergies = new Subject<boolean>();

  private componentDestroyed: Subject<void> = new Subject();
  constructor(
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private store: StoreService
  ) {
    this.initialiseDropdowns();
  }

  initialiseDropdowns() {
    this.allergyTypes = this.utilsService.mapToDisplayOptions(ALLERGY_TYPES);
    this.allergyTypes = this.allergyTypes.map(option => {
      if (option.value === 'OTHER') {
        return { ...option, label: 'Other (Non Drugs)' };
      }
      return option;
    });
    this.allergyNames = this.utilsService.mapToDisplayOptions(ALLERGIES);
    this.medicalAlertTypes = this.utilsService.mapToDisplayOptions(ALERT_TYPES);
    this.medicalAlertPriorities = this.utilsService.mapToDisplayOptions(
      ALERT_PRIORITY
    );
  }

  createAllergiesFormGroup(alerts: Array<Allergy>, alertFormArray: FormArray) {
    alerts.map(value => {
      alertFormArray.push(this.patchAllergiesToFormGroup(value));
    });
  }

  initAllergyFormGroup() {
    return this.fb.group({
      alertArray: this.fb.array([]),
      state: '',
      isAdd: false,
      specialNotes: '',
      requiredSave: false,
    });
  }

  initMedicalAlertFormGroup() {
    return this.fb.group({
      alertArray: this.fb.array([]),
      trashArray: this.fb.array([]),
      inActivateArray: this.fb.array([]),
      state: '',
      isAdd: false,
      requiredSave: false,
    });
  }

  patchAllergiesToFormGroup(allergy?) {

    const item = this.fb.group({
      allergyTypes: { value: this.allergyTypes },
      type: allergy ? allergy.type : null,
      allergyId: allergy ? allergy.allergyId: null,
      allergyType: allergy.allergyType ? allergy.allergyType : 'SPECIFIC_DRUG',
      allergies: { value: this.allergyNames },
      name: [allergy ? allergy.name : '', Validators.required],
      itemId: allergy ? allergy.itemId : '',
      remarks: allergy ? (allergy.remarks ? allergy.remarks : allergy.updateRemarks) : '',
      addedDate: allergy ? allergy.addedDate : moment().format(DISPLAY_DATE_FORMAT),
      addedBy: allergy ? allergy.addedBy || '' : '',
      isDelete: false,
      addedByClinicId: allergy ? allergy.addedByClinicId || undefined : undefined,
      showDetails: allergy ? false : true,
      deleteIndex: -1,
      migrated: allergy ? allergy.migrated : false,
      additionalNames: allergy ? (allergy.additionalNames ?  new FormControl(allergy.additionalNames): null ) : null,
      infoSourceCode: allergy ? allergy.infoSourceCode : null,
      adverseReaction: allergy ? allergy.adverseReaction : null,
      onsetDate: allergy ? allergy.onsetDate : moment().format(DISPLAY_DATE_FORMAT),
      drugAllergyIndicatorCode: allergy ? allergy.drugAllergyIndicatorCode : null,
      reporter: this.fb.group({
        regNo: [(allergy && allergy.reporter) ? allergy.reporter.regNo : '', null],
        name: [(allergy && allergy.reporter) ? allergy.reporter.name : '', allergy && allergy.migrated ? null : Validators.required],
        profession: [(allergy && allergy.reporter) ? allergy.reporter.profession : undefined],
      }),
      updateRemarks: allergy ? allergy.updateRemarks : '',
      reactionOutcomeList: new FormControl(allergy ? allergy.reactionOutcomeList : [], allergy && allergy.migrated ? null : null),
      drugProbability: [allergy ? allergy.drugProbability : null,  allergy && allergy.migrated ? null : null],
      syncedFromCmis: allergy ? allergy.syncedFromCmis : false,
    });
    return item;
  }

  bindAllergyFormDataToPatientInfo(item: FormGroup, values) {
    const allergyFormArray = item.parent as FormArray;
    const indexOfEditedAllergy = allergyFormArray.value
      .map(value => JSON.stringify(value))
      .indexOf(JSON.stringify(values));

    const allergyToBeSaved = <any>{
      allergyType: values.allergyType? values.allergyType : 'SPECIFIC_DRUG',
      allergyId: values ? values.allergyId: '',
      name: values.name,
      itemId: values.itemId,
      remarks: (values.remarks ? values.remarks : values.updateRemarks),
      type: values.type,
      additionalNames: values.additionalNames,
      showDetails: false,
      infoSourceCode: values.infoSourceCode,
      adverseReaction: values.adverseReaction,
      onsetDate: values.onsetDate ? values.onsetDate : moment().format(DISPLAY_DATE_FORMAT),
      drugAllergyIndicatorCode: values.drugAllergyIndicatorCode,
      migrated: values.migrated,
      updateRemarks: values.updateRemarks,
      reactionOutcomeList: values.reactionOutcomeList,
      drugProbability: values.drugProbability,
      syncedFromCmis:  values.syncedFromCmis,
      addedByClinicId: values ? values.addedByClinicId || undefined : undefined,
      addedDate: values.addedDate
        ? values.addedDate
        : moment().format(DISPLAY_DATE_FORMAT),
        reporter: this.fb.group({
          regNo: [(values && values.reporter) ? values.reporter.regNo : ''],
          name: [(values && values.reporter) ? values.reporter.name : ''],
          profession: [(values && values.reporter) ? values.reporter.profession : undefined],
        }),
    };

    if (!this.alerts[indexOfEditedAllergy]) {
      this.alerts.push(allergyToBeSaved);
    } else {
      this.alerts[indexOfEditedAllergy] = allergyToBeSaved;
    }
  }

  addAllergy(allergy?) {
    const item = this.fb.group({
      allergyTypes: { value: this.allergyTypes },
      type: [allergy ? allergy.type : null, allergy && allergy.migrated ? null : Validators.required],
      allergyId: allergy ? allergy.allergyId: null,
      allergyType: [allergy && allergy.allergyType ? allergy.allergyType : null, allergy && allergy.migrated ? null : Validators.required],
      allergies: { value: this.allergyNames },
      name: [allergy ? allergy.name : '', allergy && allergy.migrated ? null : Validators.required],
      itemId: allergy ? allergy.itemId : '',
      remarks: allergy ? (allergy.remarks ? allergy.remarks : allergy.updateRemarks) : '',
      addedDate: [allergy ? allergy.addedDate : moment().format(DISPLAY_DATE_FORMAT),Validators.required],
      addedBy: allergy ? allergy.addedBy || '' : '',
      isDelete: false,
      addedByClinicId: allergy ? allergy.addedByClinicId || undefined : undefined,
      isNewObject: allergy ? false : true,
      showDetails: allergy ? false : true,
      deleteIndex: -1,
      migrated: allergy ? allergy.migrated : false,
      additionalNames: allergy ? (allergy.additionalNames ?  new FormControl(allergy.additionalNames): null ) : null,
      infoSourceCode: [allergy ? allergy.infoSourceCode : null, allergy && allergy.migrated ? null : null],
      adverseReaction: allergy ? allergy.adverseReaction : null,
      onsetDate: [allergy? allergy.onsetDate : moment().format(DISPLAY_DATE_FORMAT), allergy && allergy.migrated ? null : Validators.required],
      drugAllergyIndicatorCode: [allergy ? allergy.drugAllergyIndicatorCode : null, null],
      updateRemarks: allergy ? allergy.updateRemarks : '',
      reactionOutcomeList: new FormControl(allergy ? allergy.reactionOutcomeList : [], allergy && allergy.migrated ? null : null),
      drugProbability: [allergy ? allergy.drugProbability : null,  allergy && allergy.migrated ? null :  null],
      syncedFromCmis:  allergy ? allergy.syncedFromCmis : false,
      reporter: this.fb.group({
        regNo: [(allergy && allergy.reporter) ? allergy.reporter.regNo : '', null],
        name: [(allergy && allergy.reporter) ? allergy.reporter.name : '', allergy && allergy.migrated ? null : Validators.required],
        profession: [(allergy && allergy.reporter) ? allergy.reporter.profession : undefined],
      }),
    });
    return item;
  }

  // Medical Alert
  patchMedicalAlertsToFormGroup(medicalAlert?) {
    const item = this.fb.group({
      alertId: medicalAlert ? medicalAlert.alertId : '',
      types: { value: ALERT_TYPES },
      type: medicalAlert ? medicalAlert.alertType : undefined,
      alertCode: medicalAlert ? medicalAlert.alertCode : undefined,
      alertStatus: medicalAlert ? medicalAlert.alertStatus : undefined,
      name: [medicalAlert ? medicalAlert.name : '', Validators.required],
      remark: medicalAlert ? medicalAlert.remark : '',
      priority: [
        medicalAlert ? medicalAlert.priority : 'LOW',
        Validators.required,
      ],
      priorityDropDown: { value: ALERT_PRIORITY },
      isDelete: false,
      migrated: medicalAlert ? medicalAlert.migrated : false,
      deleteIndex: -1,
      isEdit: medicalAlert ? false : true,
      requiredSave: false,
      addedDate: medicalAlert
        ? medicalAlert.addedDate
        : moment().format(DISPLAY_DATE_FORMAT),
      expiryDate: medicalAlert
        ? medicalAlert.expiryDate
        : moment().format(DISPLAY_DATE_FORMAT),
        reporter: this.fb.group({
          regNo: [(medicalAlert && medicalAlert.reporter) ? medicalAlert.reporter.regNo : ''],
          name: [(medicalAlert && medicalAlert.reporter) ? medicalAlert.reporter.name : ''],
          profession: [(medicalAlert && medicalAlert.reporter) ? medicalAlert.reporter.profession : undefined],
        }),
        startDate: medicalAlert
        ? medicalAlert.startDate
        : moment().format(DISPLAY_DATE_FORMAT),
        updateReason: medicalAlert ? medicalAlert.updateReason : '',
    });

    return item;
  }

  // Medical Alert
  addMedicalAlert(medicalAlert?) {
    const item = this.fb.group({
      alertId: medicalAlert ? medicalAlert.alertId : '',
      types: { value: ALERT_TYPES },
      type: [medicalAlert ? medicalAlert.alertType : undefined, (medicalAlert && medicalAlert.migrated) ? null : Validators.required],
      alertCode: medicalAlert ? medicalAlert.alertCode : undefined,
      alertStatus: [medicalAlert ? medicalAlert.alertStatus : 'ACTIVE', (medicalAlert && medicalAlert.migrated) ? undefined : Validators.required],
      name: medicalAlert ? medicalAlert.name : undefined,
      remark: medicalAlert ? medicalAlert.remark : undefined,
      priority: [
        medicalAlert ? medicalAlert.priority : 'LOW',
        (medicalAlert && medicalAlert.migrated) ? null : Validators.required
      ],
      priorityDropDown: { value: ALERT_PRIORITY },
      isDelete: false,
      deleteIndex: -1,
      isEdit: medicalAlert ? false : true,
      requiredSave: false,
      addedDate: medicalAlert
        ? medicalAlert.addedDate
        : moment().format(DISPLAY_DATE_FORMAT),
      expiryDate: medicalAlert
        ? medicalAlert.expiryDate
        : moment().add(50, 'years').format(DISPLAY_DATE_FORMAT),
        reporter: this.fb.group({
          regNo: (medicalAlert && medicalAlert.reporter) ? medicalAlert.reporter.regNo : undefined,
          name: (medicalAlert && medicalAlert.reporter) ? medicalAlert.reporter.name : undefined,
          profession: (medicalAlert && medicalAlert.reporter) ? medicalAlert.reporter.profession : undefined,
        }),
        startDate: [medicalAlert
        ? medicalAlert.startDate
        : moment().format(DISPLAY_DATE_FORMAT), (medicalAlert && medicalAlert.migrated) ? null : Validators.required],
        endDate: medicalAlert
        ? medicalAlert.endDate
        : moment().add(50, 'years').format(DISPLAY_DATE_FORMAT),
        updateReason: medicalAlert ? medicalAlert.updateReason : undefined,
    });

    return item;
  }

  getAllergyGroupDescription(code) {
    const allergy = this.store.allergyGroupList.find(
      allergy => allergy.groupCode === code
    );
    return allergy !== undefined ? allergy.description : '';
  }

  public setManageAllergyModalStatus(status: boolean): void {
    this.isManageAllergyModalClosed.next(status);
  }

  public getManageAllergyModalStatus(): Observable<boolean> {
    return this.isManageAllergyModalClosed.asObservable();
  }

  public setSaveAlertsAndAllergies(isSave: boolean): void {
    this.isSaveAlertsAndAllergies.next(isSave);
  }

  public getSaveAlertsAndAllergies(): Observable<boolean> {
    return this.isSaveAlertsAndAllergies.asObservable();
  }
}
