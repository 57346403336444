<!-- // TO-DO-SM: this can be removed after completing the entire HSG -->
<div class="row row-filter mb-2">
    <div class="col-md-6 col-filter-left">
        <button
            class="button-primary" 
            *ngIf="this.router.url.includes('projects/hsg/list')"
            (click)="onGoBack()">
            go back
        </button>
    </div>
    <div class="col-md-6 col-filter-right">
        <button class="button-green ml-2" (click)="onViewQuestionnaireResponses()" style="text-transform: capitalize;">View Questionnaire Responses</button>
        <button class="button-green ml-2" (click)="onSyncPlan()" style="text-transform: capitalize;">Load HSG Data</button>
        <button 
            class="button-primary ml-2" 
            *ngIf="isInConsultation && showCreatePlan"
            (click)="onCreatePlan()">
            create plan
        </button>
    </div>
</div>

<!-- // TO-DO-SM: this can be removed after completing the entire HSG -->
<!-- <app-patient-hsg-plan-listing [patientInfo]="patientInfo"></app-patient-hsg-plan-listing> -->
<!-- <app-patient-hsg-health-plan [isConsultation]="isInConsultation" [isProjectPage]="isProjectPage"></app-patient-hsg-health-plan> -->

<!-- <app-care-plan-form></app-care-plan-form> -->
<!-- <app-patient-hsg-vaccine-and-sfl *ngIf="isInConsultation" [patientInfo]="patientInfo"></app-patient-hsg-vaccine-and-sfl> -->