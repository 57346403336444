<form [formGroup]="consultationInfoFormGroup" novalidate>
    <div class="row">
        <div class="col-md-6 pb-0">
            <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
                <label>Purpose of Visit<span *ngIf="isAppointment" class="compulsory">*</span></label>
            </div>
        </div>
        <div class="col-md-6 pb-0">
            <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
                <label>Preferred Doctor</label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
                <ng-select 
                    [ngClass]="customStyles ? 'custom-select-new custom-select-extra-medium-width' : 'custom-select form-control-sm custom-select-extra-medium-width' " 
                    type="text" 
                    bindValue="value"
                    name="purposeOfVisit" 
                    [items]="visitPurposes" 
                    formControlName="purposeOfVisit">
                </ng-select>
                <errors [control]="consultationInfoFormGroup.get('purposeOfVisit')"></errors>
            </div>
        </div>
        <div class="col-md-6">
            <ng-select
                [ngClass]="customStyles ? 'custom-select-new custom-select-extra-medium-width' : 'custom-select form-control-sm custom-select-extra-medium-width' "
                type="text" 
                bindValue="value"
                bindLabel="label" 
                name="plan" 
                [items]="doctors" 
                formControlName="preferredDoctor">
            </ng-select>
            <errors [control]="consultationInfoFormGroup.get('preferredDoctor')"></errors>
        </div>
    </div>
    <div class="row mt-3" *ngIf="showPrioritySelect">
      <div class="col-md pb-0">
          <label>Priority</label>
          <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" bindValue='value'
              bindLabel='label' [items]="priorities" [clearable]="false" formControlName="priority"></ng-select>
          <errors [control]="consultationInfoFormGroup.get('priority')"></errors>
      </div>
      <div *ngIf="(patientSourcesEnabled$ | async) || (this.clinicGroupName === 'Healthway Medical Group')" class="col-md pb-0">
        <label [ngClass]="{'required': (patientSourcesEnabled$ | async)}">Patient Source</label>
        <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" bindValue="source"
          bindLabel="source" [multiple]=false [items]="patientSourceList$ | async" [clearable]="false" formControlName="patientSources"></ng-select>
        <errors [control]="consultationInfoFormGroup.get('patientSources')"></errors>
      </div>
      <div *ngIf="(approverListEnabled$ | async) && !hidePersonInCharge" class="col-md pb-0">
        <label>Person-in-Charge</label>
        <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" bindValue="approver"
          bindLabel="approver" [items]="approverList$ | async" [clearable]="false" formControlName="personIncharge"></ng-select>
      </div>
    </div>
    <div *ngxPermissionsOnly="['ROLE_NIR_SKIP']">
        <div class="row pt-3" *ngIf="fromPatientRegPopup && !isNEHROpt()">
            <div class="col-md-12 pb-0">
                <input type="checkbox" formControlName="skipNir" /><span class="ml-2">Skip NIR submission</span>
              </div>
        </div>
    </div>
     <div class="row pt-3">
      <div class="col-md-12 pb-0">
        <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
            <label>Remarks</label>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
                <textarea
                    [ngClass]="customStyles ? 'input' : 'form-control form-control-sm' "
                    formControlName="remarks">
                </textarea>
            </div>
        </div>
    </div>
</form>