<div class="modal-header">
    <h4 class="modal-title pull-left">{{ config.title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body-confirmation">
    <div class="confirmation" [ngClass]="{container: false}" style="overflow-x: hidden;">
        <div class="container-fluid h-100">
            <div class="row mt-3 p-2 pb-3">
                <div class="col-12">

                    <ng-container *ngIf="isDoctor() && hsgPatient">
                        <div class="row mb-2">
                            <div class="col-12">
                                <span class="hsg-title">HSG Enrolled:</span> {{hsgEnrollment ? 'Yes' : 'No'}}
                            </div>
                            <div class="col-12" *ngIf="hsgPatient && patientHsgInfo.carePlanAvailable">
                                <span class="hsg-title">Recommended Next Check-in: </span> <span>{{patientHsgInfo.carePlanNextCheckUpDate}}</span> <span *ngIf="patientHsgInfo.carePlanDueStatus" class="ml-2" [ngClass]="getCarePlanDueStatusClass(patientHsgInfo.carePlanDueStatus)">{{patientHsgInfo.carePlanDueStatus}}</span>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <div
                            [ngClass]="{'col-md-6': !hideCaseInfo(), 'col-md-12': hideConsultationInfo() || hideCaseInfo()}">
                            <div class="card" [hidden]="hideConsultationInfo()">
                                <div class="card-header">
                                    Patient Information
                                </div>
                                <div class="card-body">
                                    <alert></alert>
                                    <app-patient-add-consultation 
                                        [hidden]="hideConsultationInfo()"
                                        [consultationInfoFormGroup]="consultationFormGroup" 
                                        [showPrioritySelect]="true">
                                    </app-patient-add-consultation>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6" [hidden]="hideConsultationInfo()" *ngIf="!hideCaseInfo()">
                            <div class="row">
                                <div class="col-md-12 card-wrapper">
                                    <div class="card">
                                        <div class="card-header">Case Information</div>
                                        <div class="card-body card-body-case-info">
                                            <div class="row data-table">
                                                <div class="col-1"></div>
                                                <div class="col-2">
                                                    <label>Date</label>
                                                </div>
                                                <div class="col-3">
                                                    <label>Case Number</label></div>
                                                <div class="col-2 mr-2">
                                                    <label>Outstanding($)</label></div>
                                                <div class="col-2">
                                                    <label>No of Visits</label></div>
                                                <div class="col overflow-hidden" placement="bottom" tooltip="Remarks">
                                                    <label>Remarks</label>
                                                </div>
                                            </div>
                                            <div class="scroll-y mt-2">
                                                <div 
                                                    class="row modal-sub-header cursor px-1"
                                                    [ngClass]="{'bg-lightgrey': case.hasOpenVisits || case.shouldDisable}"
                                                    *ngFor="let case of caseRows; let i = index;">
                                                    <div class="col-1 case-input-wrapper">
                                                        <span 
                                                            class="p-2" 
                                                            placement="right"
                                                            [tooltip]="case.hasOpenVisits ? 'You cannot attach this case to visit as there is already an open visit under this case' : null"
                                                            container="body">
                                                            <input 
                                                                type="checkbox"
                                                                [ngClass]="{'cursor': case.hasOpenVisits, 'pointer-none': case.hasOpenVisits || case.shouldDisable}"
                                                                [name]="i" 
                                                                (click)="onCaseSelect($event, case)"
                                                                >
                                                        </span>
                                                    </div>
                                                    <div class="col-2" placement="bottom">
                                                        {{case.date}}
                                                    </div>
                                                    <div class="col-3 overflow-hidden" placement="auto">
                                                        {{ case.caseNumber }}
                                                    </div>
                                                    <div 
                                                        class="col-2 overflow-hidden text-center" 
                                                        placement="auto"
                                                        [ngClass]="{'text-danger': case.salesOrder?.outstanding > 0 }">
                                                        ({{case.salesOrder?.outstanding | displayDollar}})
                                                    </div>
                                                    <div class="col-2 text-center">{{ case.noOfVisits?.length }}</div>
                                                    <div 
                                                        class="col pr-1" 
                                                        placement="auto">
                                                        {{ case.remarks | titlecase}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="packageRows.length">
                                <div class="col-md-12  card-wrapper">
                                    <div class="card">
                                        <div class="card-header">Package Information</div>
                                        <div class="card-body">
                                            <div class="row data-table cursor">
                                                <div class="col-2">
                                                    <span class="px-1"></span>
                                                    <label>Item Code</label>
                                                </div>
                                                <div class="col">
                                                    <label>Item Name</label>
                                                </div>
                                                <div class="col-2">
                                                        <label>Price ($)</label>
                                                    </div>
                                                <div class="col-2">
                                                    <label>Date</label>
                                                </div>
                                                <div class="col-2">
                                                    <label>Used By</label>
                                                </div>
                                                <div class="col">
                                                    <label>Completed</label>
                                                </div>
                                            </div>
                                            <div class="scroll-y mt-2">
                                                <ng-container *ngFor="let pkg of packageRows">
                                                    <div class="row modal-sub-header bg-white cursor mt-1"
                                                        (click)="expandPackage($event, pkg)">

                                                        <div class="col-2 overflow-hidden" placement="auto"
                                                            [tooltip]="pkg.code">
                                                            <span class="px-1">
                                                                <span *ngIf="!pkg.expanded" placement="auto"
                                                                    tooltip="Click to show Package information"> <i
                                                                        class='icon-right-open-big mr-2'></i></span>
                                                                <span *ngIf="pkg.expanded" placement="auto"
                                                                    tooltip="Click to hide Package items"> <i
                                                                        class='icon-down-open-big mr-2'></i></span>
                                                            </span>
                                                            {{pkg.code}}
                                                        </div>
                                                        <div class="col overflow-hidden " placement="auto"
                                                            [tooltip]="pkg.name">
                                                            {{ pkg.name }}</div>
                                                        <div class="col-2">{{pkg.purchasePrice | displayDollar}}</div>
                                                        <div class="col-2">{{ pkg.purchaseDate }}</div>
                                                        <div class="col-2">{{ pkg.useBy || ''}}</div>
                                                        <div class="col">{{pkg.completed}}</div>
                                                    </div>
                                                    <ng-container *ngFor="let subItem of pkg.dispatches">
                                                        <div class="row modal-sub-header bg-palegrey subItems cursor mx-2"
                                                            *ngIf="pkg.expanded">
                                                            <div class="col-2"></div>
                                                            <div class="col overflow-hidden" placement="auto"
                                                                [tooltip]="subItem.itemName">- {{ subItem.itemName }}
                                                            </div>
                                                            <div class="col-2"></div>
                                                            <div class="col-2 pl-1"></div>
                                                            <div class="col-2">
                                                                <span *ngIf="!!subItem.utilizedDate">-</span>
                                                                {{subItem.utilizedDate || ''}}
                                                            </div>
                                                            <div class="col ">
                                                                <div
                                                                    class="d-flex justify-content-center align-items-center h-100">
                                                                    <input type="checkbox" *ngIf="subItem.utilize"
                                                                        disabled [checked]="subItem.utilize">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <app-medical-coverage 
                                [config]="config" 
                                [visitId]="visitId"
                                [patientCoverages]="patientCoverages"
                                [planIdsFromCaseModal$]="getPlanIdFromCaseObservable()"
                                [planIdsFromCase]="planIdsFromCase" 
                                [selectedCoverages]="selectedCoverages"
                                [isMedicalCoverageSelectionDisabled]="isMedicalCoverageSelectionDisabled"
                                *ngxPermissionsOnly="['ROLE_MEDICAL_COVERAGE_MANAGEMENT']">
                            </app-medical-coverage>
                        </div>
                    </div>

                    <div [hidden]="config.type ==='DISPLAY_MEDICAL_COVERAGE'" class="btn-toolbar float-right mb-2"
                        role="toolbar">
                        <div class="btn-group" role="group">
                            <button class="btn btn-large btn-brand-secondary mr-2" (click)="hideModal()">Back</button>
                            <button class="btn btn-large btn-brand-primary" (click)="onBtnNextClicked()"
                                [disabled]="disableConfirmBtn()">
                                {{ config.isNewVisit ? 'Confirm & Register (F4)': 'Save (F4)'}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>