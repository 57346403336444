<div class="row mb-2">
    <div class="col-md-12">
        <div class="col-md-12 right-align">
            <span>Last synced : {{sflSyncTime}}</span>
        </div>
        <div class="card-custom">
            <div class="card-header">Screening Eligibility</div>
            <table class="custom-table custom-table-light">
                <thead>
                    <tr>
                        <th>Screening Type</th>
                        <th>Screening Eligibility</th>
                        <th>Subsidy Eligibility</th>
                        <th>Last Done</th>
                        <th>Next Due</th>
                    </tr>
                </thead>
                <tbody *ngIf="screeningEligibility">
                    <tr 
                        *ngFor="let item of screeningEligibility.sflEligibilityItems; let i = index;">
                        <td>{{ item.screeningType }}</td>
                        <td>{{ item.screeningEligibility }}</td>
                        <td>{{ item.subsidyEligibility }}</td>
                        <td>{{ getDateString(item.lastScreenDate) }}</td>
                        <td>
                            {{ getDateString(item.nextDueDate) }}
                            <span *ngIf="isDateDue(item.nextDueDate)" class="sfl-due">Due</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="col-md-12">
        <div class="col-md-12 right-align">
            <span>Last synced : {{vaccinationsSyncTime}}</span>
        </div>
        <div class="card-custom">
            <div class="card-header">VACCINATION HISTORY</div>
            <p class="sub-title ml-2 mb-2">National Adult Immunisation Schedule (NAIS) only</p>
            <a class="ml-2 mb-2" href="https://www.healthhub.sg/programmes/163/vaccinate" target="_blank">Find Out More</a>
            <table class="custom-table custom-table-light">
                <thead>
                    <tr>
                        <th>Date Given</th>
                        <th>Vaccination</th>
                        <th>Sequence</th>
                        <th>Product Name</th>
                        <th>Lot/Batch No.</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vaccine of vaccinations; let i = index;">
                        <td>{{ getGivenDate(vaccine) }}</td>
                        <td>{{ getVaccineName(vaccine) }}</td>
                        <td>{{ vaccine?.sequenceID }}</td>
                        <td>{{ vaccine?.productName?.coding?.display }}</td>
                        <td>{{ vaccine?.lotNumber }}</td>
                        <td>{{ vaccine?.institution?.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>