import MedicalConditions from '../MedicalConditions';
import { VitalResponseData } from '../VitalResponseData';
export type VisitType = 'NONE' | 'ACCUTE' | 'CHRONIC';
export default class PCNDetails {
  conditions: Array<MedicalConditions>;
  vitals: Array<VitalResponseData>;
  assessment: AssessmentResult;
  patientExtraDetails: PCNPatientExtraDetails;
  assessmentTestTakenList: Array<AssessmentTestTakenEntity>;
  vitalTestTakenList: Array<VitalTestTakenEntity>;
  // onGoingMedications: Array<OnGoingMedication>;

  constructor(
    conditions?: Array<MedicalConditions>,
    vitals?: Array<VitalResponseData>,
    assessment?: AssessmentResult,
    patientExtraDetails?: PCNPatientExtraDetails,
    assessmentTestTakenList?: Array<AssessmentTestTakenEntity>,
    vitalTestTakenList?: Array<VitalTestTakenEntity>,
    // onGoingMedications?: Array<OnGoingMedication>
  ) {
    this.conditions = conditions === undefined ? new Array<MedicalConditions>() : conditions;
    this.vitals = vitals === undefined ? new Array<VitalResponseData>() : vitals;
    this.assessment = assessment === undefined ? new AssessmentResult() : assessment;
    this.patientExtraDetails = patientExtraDetails === undefined ? new PCNPatientExtraDetails() : patientExtraDetails;
    this.assessmentTestTakenList = assessmentTestTakenList === undefined ? new Array<AssessmentTestTakenEntity>() : assessmentTestTakenList;
    this.vitalTestTakenList = vitalTestTakenList === undefined ? new Array<VitalTestTakenEntity>() : vitalTestTakenList;
    // this.onGoingMedications = onGoingMedications === undefined ? new Array<OnGoingMedication>() : onGoingMedications;
  }
}

export class PCNPatientExtraDetails {
  chasUsage: string;
  smokingStatus: string;
  stateOfChange: string;
  yearStartedSmoking: string;
  rightSiting: boolean;
  rightSitingReferenceSource: string;
  referenceSource: string;
  remark?: string;
  latestNumberOfSticksPerDay?: string;
  
  constructor(
    chasUsage: string = '',
    smokingStatus: string = '',
    stateOfChange: string = '',
    yearStartedSmoking: string = '',
    rightSiting: boolean = false,
    rightSitingReferenceSource: string = '',
    referenceSource: string = ''
  ) {
    this.chasUsage = chasUsage || '';
    this.referenceSource = referenceSource || '';
    this.smokingStatus = smokingStatus;
    this.stateOfChange = stateOfChange;
    this.yearStartedSmoking = yearStartedSmoking;
    this.rightSiting = rightSiting;
    this.rightSitingReferenceSource = rightSitingReferenceSource;
  }
}

export class AssessmentResult {
  patientId: string;
  visitId: string;
  assessmentId: string;
  takenTime: string;
  assessments: object;
  comment: string;

  constructor(
    patientId?: string,
    visitId?: string,
    assessmentId?: string,
    takenTime?: string,
    assessments?: object,
    comment?: string
  ) {
    this.patientId = patientId || '';
    this.visitId = visitId || '';
    this.assessmentId = assessmentId || null;
    this.takenTime = takenTime || '';
    this.assessments = assessments === undefined ? null : assessments;
    this.comment = comment || '';
  }
}

export class VitalTestTakenEntity {
  code: string;
  takenDate: string;
  nextDueDate: string;

  constructor(
    code?: string,
    takenDate?: string,
    nextDueDate?: string,
  ) {
    this.code = code || '';
    this.takenDate = takenDate || '';
    this.nextDueDate = nextDueDate || null;
  }
}

export class AssessmentTestTakenEntity {
  code: string;
  takenDate: string;
  nextDueDate: string;

  constructor(
    code?: string,
    takenDate?: string,
    nextDueDate?: string,
  ) {
    this.code = code || '';
    this.takenDate = takenDate || '';
    this.nextDueDate = nextDueDate || null;
  }
}

export class OnGoingMedication {
  id: string;
  ItemCode: string;
  medicationName: string;
  type: string;
  startDate: string;
  lastPurchasedDate: string;
  dispenseQty: number;
  salesUom: string;
  dosageUom: string;
  dosage: number;
  instruct: string;
  dosageInstruction: string;
  refillDueDate: string;

  constructor(
    ItemCode?: string,
    medicationName?: string,
    type?: string,
    startDate?: string,
    lastPurchasedDate?: string,
    dispenseQty?: number,
    salesUom?: string,
    dosageUom?: string,
    dosage?: number,
    instruct?: string,
    dosageInstruction?: string,
    refillDueDate?: string,
  ) {
    this.ItemCode = ItemCode || '';
    this.medicationName = medicationName || '';
    this.type = type || '';
    this.startDate = startDate || null;
    this.lastPurchasedDate = lastPurchasedDate || null;
    this.dispenseQty = dispenseQty || 0;
    this.salesUom = salesUom || '';
    this.dosageUom = dosageUom || '';
    this.dosage = dosage || 0;
    this.instruct = instruct || '';
    this.dosageInstruction = dosageInstruction || '';
    this.refillDueDate = refillDueDate || '';
  }
}