<div class="card mb-2">
    <div class="card-header">
        VACCINATION CERTIFICATES
    </div>
    <div class="card-body p-3" *ngIf="isCareReport">
        <div *ngIf="patientInfo || vaccineItems">
            <table class="custom-table custom-table-light" style="width: 100%;">
                <thead>
                    <tr>
                        <th>Vaccine</th>
                        <th>SDD Code</th>
                        <th>Given Date</th>
                        <th>Next Dose Due Date</th>
                        <th>Vaccination Exceptional Condition(s)</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngFor="let vaccineGroup of groupedVaccinesForCareReport | keyvalue">
                    <tr *ngIf="vaccineGroup.value && (vaccineGroup.value.length > 0)" class="table-sub-header">
                        <td>{{vaccineGroup.key !== "undefined" ? ("Visit Date: " + vaccineGroup.key) : "Other"}}</td>
                        <td colspan="5">
                            <!-- Visit Number/s: {{vaccineGroup.key !== "undefined" ? getVisitNumbers(vaccineGroup.value) : "-"}} -->
                        </td>
                    </tr>
                    <tr *ngFor="let item of vaccineGroup.value; let i = index;">
                        <td>{{getVaccineName(item.vaccineId)}}</td>
                        <td>{{item.sddCode}}</td>
                        <td>{{getDate(item.givenDate)}}</td>
                        <td>{{item.vaccinationSchedules && item.vaccinationSchedules.length ? getDate(item.vaccinationSchedules[0].scheduledDate) : ''}}</td>
                        <td>
                            <div *ngIf="item.conditionCodes && item.conditionCodes.length > 0">
                                <p *ngFor="let code of item.conditionCodes" class="mb-0">{{getConditionDisplay(code)}}</p>
                            </div>
                        </td>
                        <td>
                            <div *ngxPermissionsOnly="['ROLE_VACCINATION_MODIFY']">
                                <div class="d-flex flex-row-reverse">
                                    <div class="p-2">
                                        <i class="icon-trash cursor-pointer" (click)="deleteItem(item.id)"  *ngIf="item.isEditable"></i>
                                        <i class="icon-trash text-grey-custom"   *ngIf="!item.isEditable" [title]="getDisplayMsg(item)"></i>
                                    </div>
                                    <div class="p-2">
                                        <button class="btn btn-link-grey p-0" (click)="editItem(item)">
                                            <i class="icon-pencil " aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row justify-content-end mr-0 mt-3">
            <div *ngxPermissionsOnly="['ROLE_VIEW_LABEL']">
                <button 
                    *ngxPermissionsOnly="['ROLE_VACCINATION_MODIFY']" 
                    class="btn btn-sm btn-outline-brand-secondary float-right mr-2 mb-2"
                    type="button" 
                    (click)="showEditVaccineDetails()">
                    + Add
                </button>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="!isCareReport">
        <div class="row mb-0">
            <div class="col-md-3">
                <label class="mb-0">Vaccine</label>
            </div>
            <div class="row col-md-9">
                <div class="col-md-1">
                    <label class="mb-0">Dosage</label>
                </div>
                <div class="col-md-1">
                    <label class="mb-0">Given Date</label>
                </div>
                <div class="col-md-2">
                    <label class="mb-0">Administrator</label>
                </div>
                <div class="col-md-4">
                    <label class="mb-0">Next Dose Vaccine</label>
                </div>
                <div class="col-md-1">
                    <label class="mb-0">Next Dose</label>
                </div>
                <div class="col-md-1">
                    <label class="mb-0">Next Dose Date</label>
                </div>
                <div class="col-md-1">
                    <label class="mb-0">Time</label>
                </div>
                <div class="col-md-1 pr-3" style="text-align: end;">
                    <label class="mb-0">Actions</label>
                </div>
            </div>
        </div>
        <div *ngIf="patientInfo || vaccineItems">
            <form #f='ngForm' [formGroup]='patientVaccinationsFormGroup'>
                <div formArrayName="vaccFormArr">
                    <div *ngFor="let item of patientVaccinationsFormGroup?.get('vaccFormArr')?.controls; let i = index;">
                        <div [formGroupName]="i">
                            <div class="row pt-2">
                                <div class="col-md-3">
                                    <span>
                                        <input type="checkbox" value='{{item.value.id}}' formControlName="isSelected" name="vaccine">
                                    </span>
                                    <span class="pl-2">
                                        {{getVaccineName(item.getRawValue().vaccineId)}}
                                    </span>
                                </div>
                                <div class="row col-md-9">
                                    <div class="col-md-1">
                                        {{getDoseName(item.value.doseId)}}
                                    </div>
                                    <div class="col-md-1">
                                        {{item.getRawValue().givenDate}}
                                    </div>
                                    <div class="col-md-2">
                                        {{item.value.administrator.profession === 'DOCTOR' ? (item.value.doctorId ? getDoctorName(true, item.value.doctorId) : getDoctorName(false, item.getRawValue().administrator.regNo)) 
                                            : item.value.administrator.name}}
                                    </div>
                                    <div class="col-md-4">
                                        {{item.value.vaccinationSchedules ? getVaccineName(item.value.vaccinationSchedules.vaccineId) : ''}}
                                    </div>
                                    <div class="col-md-1">
                                        {{item.value.vaccinationSchedules ? getDoseName(item.value.vaccinationSchedules.doseId) : ''}}
                                    </div>
                                    <div class="col-md-1">
                                        {{item.value.vaccinationSchedules ? item.value.vaccinationSchedules.scheduledDate : ''}}
                                    </div>
                                    <div class="col-md-1">
                                        {{item.value.vaccinationSchedules ? item.value.vaccinationSchedules.scheduledTime : ''}}
                                    </div>
                                    <div *ngxPermissionsOnly="['ROLE_VACCINATION_MODIFY']" class="col-md-1">
                                        <div class="d-flex flex-row-reverse">
                                            <div class="p-2">
                                                <i class="icon-trash cursor-pointer" (click)="deleteItem(item.get('id').value)"  *ngIf="item.value.isEditable"></i>
                                                <i class="icon-trash text-grey-custom"   *ngIf="!item.value.isEditable" [title]="getDisplayMsg(item)"></i>
                                            </div>
                                            <div class="p-2">
                                                <button class="btn btn-link-grey p-0" (click)="editItem(item)">
                                                    <i class="icon-pencil " aria-hidden="true"></i>
                                                    <!-- <span *ngIf="!isVaccineItemValid" class="compulsory large-star">*</span> -->
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="row justify-content-end mr-0 mt-3">
            <div *ngxPermissionsOnly="['ROLE_VIEW_LABEL']">
                <button 
                    *ngxPermissionsOnly="['ROLE_VACCINATION_MODIFY']" 
                    class="btn btn-sm btn-brand-primary"
                    type="button" 
                    (click)="showEditVaccineDetails()">
                    + Add
                </button>
        
                <div class="btn-toolbar float-right" role="toolbar">
                    <div class="btn-group" role="group" dropdown>
                        <button class="btn btn-sm btn-medium-dropdown btn-brand-secondary ml-2 btn-print-certificate"
                            (click)="onBtnPrintVCClicked()" [disabled]="disableVaccinePrint()">
                            <span>Print Vaccination Certificate</span>
                        </button>
                        <button id="button-split" type="button" dropdownToggle
                            class="btn btn-brand-secondary dropdown-toggle dropdown-toggle-split btn-print-certificate"
                            aria-controls="dropdown-split">
                            <span class="caret"></span>
                            <span class="sr-only">Split button!</span>
                        </button>
        
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
                            <li class="menuitem" [ngClass]="disableVaccinePrint() ? 'disabled' : 'enabled'" (click)="onBtnPrintCovid19Card()">
                                <a class="dropdown-item" href="javascript:void(0);">
                                    Covid19 vaccination card
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <hr>
    </div>
</div>
