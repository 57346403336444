import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QuillModule } from 'ngx-quill';

import { AddAppointmentComponent } from './components/appointments/add-appointment/add-appointment.component';
import { ConsultationLockedComponent } from './components/consultation-locked/consultation-locked.component';
import { ClinicNotesComponent } from './components/consultation/clinic-notes/clinic-notes.component';
import { ConsultationDocumentsComponent } from './components/consultation/consultation-documents/consultation-documents.component';
import { ConsultationFollowUpComponent } from './components/consultation/consultation-follow-up/consultation-follow-up.component';
import { MedicalCertificateItemControlComponent } from './components/consultation/consultation-medical-certificate/medical-certificate-item-control.component';
import { MedicalCertificateItemsArrayComponent } from './components/consultation/consultation-medical-certificate/medical-certificate-items-array.component';
import { ConsultationMemoComponent } from './components/consultation/consultation-memo/consultation-memo.component';
import { ConsultationNotesComponent } from './components/consultation/consultation-notes/consultation-notes.component';
import { ConsultationOngoingMedicationComponent } from './components/consultation/consultation-ongoing-medication/consultation-ongoing-medication.component';
import { OngoingMedicationItemComponent } from './components/consultation/consultation-ongoing-medication/ongoing-medication-item/ongoing-medication-item.component';
import { ConsultationPatientAlertInfoComponent } from './components/consultation/consultation-patient-info/consultation-patient-alert-info/consultation-patient-alert-info.component';
import { ConsultationPatientInfoComponent } from './components/consultation/consultation-patient-info/consultation-patient-info.component';
import { ConsultationPrescriptionComponent } from './components/consultation/consultation-prescription/consultation-prescription.component';
import { ConsultationSearchComponent } from './components/consultation/consultation-prescription/consultation-search/consultation-search.component';
import { PrescriptionItemComponent } from './components/consultation/consultation-prescription/prescription-item/prescription-item.component';
import { ConsultationProblemListComponent } from './components/consultation/consultation-problem-list/consultation-problem-list.component';
import { ProblemListItemComponent } from './components/consultation/consultation-problem-list/problem-list-item/problem-list-item.component';
import { ConsultationReferralComponent } from './components/consultation/consultation-referral/consultation-referral.component';
import { ReferralItemComponent } from './components/consultation/consultation-referral/referral-item/referral-item.component';
import { DiscountComponent } from './components/consultation/discount/discount.component';
import { ReadOnlyComponent } from './components/consultation/read-only/read-only.component';
import { RefreshComponent } from './components/consultation/refresh/refresh.component';
import { DatePickerWithIconComponent } from './components/date-picker-with-icon/date-picker-with-icon.component';
import { LoadingRetryComponent } from './components/loading-retry/loading-retry.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PatientAddAlertsInfoAddClinicalItemComponent } from './components/patient-add/patient-add-alerts-info/patient-add-alerts-info-add-clinical/patient-add-alerts-info-add-clinical-item/patient-add-alerts-info-add-clinical-item.component';
import { PatientAddAlertsInfoAddClinicalComponent } from './components/patient-add/patient-add-alerts-info/patient-add-alerts-info-add-clinical/patient-add-alerts-info-add-clinical.component';
import { PatientAddConsultationComponent } from './components/patient-add/patient-add-consultation/patient-add-consultation.component';
import { PatientAddQueueConfirmationComponent } from './components/patient-add/patient-add-queue-confirmation/patient-add-queue-confirmation.component';
import { PatientDetailAddDocumentComponent } from './components/patient-detail/patient-detail-document/patient-detail-add-document/patient-detail-add-document.component';
import { PatientDetailTagAddAlertItemComponent } from './components/patient-detail/patient-detail-tag/patient-detail-tag-add-alert/patient-detail-tag-add-alert-item/patient-detail-tag-add-alert-item.component';
import { PatientDetailTagAddAlertComponent } from './components/patient-detail/patient-detail-tag/patient-detail-tag-add-alert/patient-detail-tag-add-alert.component';
import { PatientHistoryDetailAddDocumentComponent } from './components/patient-detail/patient-history-detail/patient-history-detail-add-document/patient-history-detail-add-document.component';
import { PatientHistoryDetailEditCertificateItemComponent } from './components/patient-detail/patient-history-detail/patient-history-detail-edit-certificate/patient-history-detail-edit-certificate-item.component';
import { PatientHistoryDetailEditCertificateComponent } from './components/patient-detail/patient-history-detail/patient-history-detail-edit-certificate/patient-history-detail-edit-certificate.component';
import { PatientHistoryDetailEditNoteComponent } from './components/patient-detail/patient-history-detail/patient-history-detail-edit-note/patient-history-detail-edit-note.component';
import { PatientPayableItemComponent } from './components/patient-payable/patient-payable-item/patient-payable-item.component';
import { PatientPayableComponent } from './components/patient-payable/patient-payable.component';
import { PaymentSelectionComponent } from './components/payment/payment-selection/payment-selection.component';
import { PrimaryCareNetworkComponent } from './components/primary-care-network/primary-care-network.component';
import { CkeditorComponent } from './components/shared/ckeditor/ckeditor.component';
import { DrugInputSearchModalComponent } from './components/shared/drug-input-search-modal/drug-input-search-modal.component';
import { DrugInputSearchComponent } from './components/shared/drug-input-search/drug-input-search.component';
import { ErrorsComponent } from './components/shared/form/errors/errors.component';
import { InfiniteScrollComponent } from './components/shared/infinite-scroll.component';
import { PackageSubItemBatchNoComponent } from './components/shared/package/package-item/package-sub-item-batch-no/package-sub-item-batch-no.component';
import { PackageSubItemExpiryComponent } from './components/shared/package/package-item/package-sub-item-expiry/package-sub-item-expiry.component';
import { QuillEditorComponent } from './components/shared/quill-editor/quill-editor.component';
import { SimpleErrorComponent } from './components/shared/simple-error/simple-error.component';
import { AlertComponent } from './directives/alert/alert.component';
import { ChargeItemTouchedObjectDirective } from './directives/touched/charge.item.touched.object.directive';
import { TouchedObjectDirective } from './directives/touched/touched.object.directive';
import { ApprovedInventoryDisplayOrderSubtotalPipe } from './pipes/approved-inventory-display-order-subtotal.pipe';
import { DisplayCoverageAttributePipe } from './pipes/display-coverage-attribute.pipe';
import { DisplayDateFullDateFormatPipe } from './pipes/display-date-full-date-format.pipe';
import { DisplayDatePipe } from './pipes/display-date.pipe';
import { DisplayDollarAboveZeroPipe } from './pipes/display-dollar-above-zero.pipe';
import { DisplayDollarEmptyPipe } from './pipes/display-dollar-empty.pipe';
import { DisplayDollarPipe } from './pipes/display-dollar.pipe';
import { DisplayDrugPipe } from './pipes/display-drug.pipe';
import { DisplayFullDatePipe } from './pipes/display-full-date.pipe';
import { DisplayHour } from './pipes/display-hour.pipe';
import { DisplayLockOptionsPipe } from './pipes/display-lock-options.pipe';
import { DisplayMCAddressPipe } from './pipes/display-mcaddress.pipe';
import { DisplayMCContactDetailPipe } from './pipes/display-mccontact-detail.pipe';
import { DisplayPlanAttributePipe } from './pipes/display-plan-attribute.pipe';
import { DisplayReturnRequestStatusColorCssClassPipe } from './pipes/display-return-request-status-color-css-class.pipe';
import { DisplayReturnRequestStatusLabelPipe } from './pipes/display-return-request-status-label.pipe';
import { DisplaySalesUomPipe } from './pipes/display-sales-uom.pipe';
import { DisplayStringEmptyPipe } from './pipes/display-string-empty.pipe';
import { DisplayTitleCasePipe } from './pipes/display-title-case.pipe';
import { InventoryDisplayClinicCodePipe } from './pipes/inventory-display-clinic-code.pipe';
import { InventoryDisplayDOStatusPipe } from './pipes/inventory-display-dostatus.pipe';
import { InventoryDisplayGRNStatusPipe } from './pipes/inventory-display-grnstatus.pipe';
import { InventoryDisplayItemSellingPricePipe } from './pipes/inventory-display-item-selling-price.pipe';
import { InventoryDisplayItemsSumQuantityPipe } from './pipes/inventory-display-items-sum-quantity.pipe';
import { InventoryDisplayOrderDetailTitlePipe } from './pipes/inventory-display-order-detail-title.pipe';
import { InventoryDisplayOrderGstPipe } from './pipes/inventory-display-order-gst.pipe';
import { InventoryDisplayOrderNamePipe } from './pipes/inventory-display-order-name.pipe';
import { InventoryDisplayOrderStatusPipe } from './pipes/inventory-display-order-status.pipe';
import { InventoryDisplayOrderSubtotalPipe } from './pipes/inventory-display-order-subtotal.pipe';
import { InventoryDisplayOrderTotalPipe } from './pipes/inventory-display-order-total.pipe';
import { InventoryDisplayReturnRequestGstPipe } from './pipes/inventory-display-return-request-gst.pipe';
import { InventoryDisplayReturnRequestSubtotalPipe } from './pipes/inventory-display-return-request-subtotal.pipe';
import { InventoryDisplayReturnRequestTotalPipe } from './pipes/inventory-display-return-request-total.pipe';
import { InventoryDisplayStockAdjustmentVariancePipe } from './pipes/inventory-display-stock-adjustment-variance.pipe';
import { InventoryDisplayStockTakeCountTypePipe } from './pipes/inventory-display-stock-take-count-type.pipe';
import { InventoryDisplayStockTakeCountedItemSizePipe } from './pipes/inventory-display-stock-take-counted-item-size.pipe';
import { InventoryDisplayStockTakeItemVariancePipe } from './pipes/inventory-display-stock-take-item-variance.pipe';
import { InventoryDisplayStockTakeUncountedItemSizePipe } from './pipes/inventory-display-stock-take-uncounted-item-size.pipe';
import { InventoryDoReceiverAddressPipe } from './pipes/inventory-do-receiver-address.pipe';
import { InventoryDoReceiverContactPipe } from './pipes/inventory-do-receiver-contact.pipe';
import { InventoryDoReceiverFaxPipe } from './pipes/inventory-do-receiver-fax.pipe';
import { InventoryDOReceiverNamePipe } from './pipes/inventory-do-receiver-name.pipe';
import { InventoryDoReceiverPostalCodePipe } from './pipes/inventory-do-receiver-postal-code.pipe';
import { InventoryGrnDeliverAddressPipe } from './pipes/inventory-grn-deliver-address.pipe';
import { InventoryGrnDeliverNamePipe } from './pipes/inventory-grn-deliver-name.pipe';
import { InventoryGrnDeliverPostalCodePipe } from './pipes/inventory-grn-deliver-postal-code.pipe';
import { InventoryOrderDetailDeliverAddressPipe } from './pipes/inventory-order-detail-deliver-address.pipe';
import { InventoryOrderDetailDeliverContactPipe } from './pipes/inventory-order-detail-deliver-contact.pipe';
import { InventoryOrderDetailDeliverFaxPipe } from './pipes/inventory-order-detail-deliver-fax.pipe';
import { InventoryOrderDetailDeliverNamePipe } from './pipes/inventory-order-detail-deliver-name.pipe';
import { InventoryOrderDetailDeliverPostalCodePipe } from './pipes/inventory-order-detail-deliver-postal-code.pipe';
import { PrescriptionItemInvalidPipe } from './pipes/prescription-item-invalid.pipe';
import { SortVaccinePipe } from './pipes/sort-vaccine.pipe';
import { StockAdjustmentItemInvalidPipe } from './pipes/stock-adjustment-item-invalid.pipe';
import { StockItemInvalidPipe } from './pipes/stock-item-invalid.pipe';
import { AppointmentsBlockDoctorTimeComponent } from './views/components/appointments/appointments-block-doctor-time/appointments-block-doctor-time.component';
import { AppointmentsChangeClinicComponent } from './views/components/appointments/appointments-change-clinic/appointments-change-clinic.component';
import { AppointmentsDetailComponent } from './views/components/appointments/appointments-detail/appointments-detail.component';
import { AppointmentsErrorMessageComponent } from './views/components/appointments/appointments-error-message/appointments-error-message.component';
import { AppointmentsNewComponent } from './views/components/appointments/appointments-new/appointments-new.component';
import { ClinicSelectComponent } from './views/components/clinic/clinic-select/clinic-select.component';
// import { FollowUpsComponent } from './views/components/communications/follow-ups/follow-ups.component';
import { BalanceItemComponent } from './views/components/medical-coverage/balance-item/balance-item.component';
import { MedicalCoverageEditItemComponent } from './views/components/medical-coverage/medical-coverage/medical-coverage-edit-item/medical-coverage-edit-item.component';
import { MedicalCoverageEditComponent } from './views/components/medical-coverage/medical-coverage/medical-coverage-edit/medical-coverage-edit.component';
import { MedicalCoverageItemDetailComponent } from './views/components/medical-coverage/medical-coverage/medical-coverage-item-detail/medical-coverage-item-detail.component';
import { MedicalCoverageItemComponent } from './views/components/medical-coverage/medical-coverage/medical-coverage-item/medical-coverage-item.component';
import { MedicalCoverageComponent } from './views/components/medical-coverage/medical-coverage/medical-coverage.component';
import { DisplayLineBreakPipe } from './pipes/display-line-break.pipe';
import { PaymentLabRequestFormComponent } from './components/payment/payment-lab-request-form/payment-lab-request-form.component';
import { PaymentLabRequestFormItemComponent } from './components/payment/payment-lab-request-form-item/payment-lab-request-form-item.component';
import { DisplayDrugItemCodePipe } from './pipes/display-drug-item-code.pipe';
import { DisplayDrugItemNamePipe } from './pipes/display-drug-item-name.pipe';
import { DisplayDosageInstructionPipe } from './pipes/display-dosage-instruction.pipe';
import { DisplayInstructionPipe } from './pipes/display-instruction.pipe';
import { DisplayDiagnosisCodePipe } from './pipes/display-diagnosis-code.pipe';
import { DisplayDiagnosisTermPipe } from './pipes/display-diagnosis-term.pipe';
import { DisplayMedicalCertPipe } from './pipes/display-medical-cert.pipe';
import { DisplayClinicNamePipe } from './pipes/display-clinic-name.pipe';
import { DisplayAllergyGroupDescPipe } from './pipes/display-allergy-group-desc.pipe';
import { DisplayDocDisplayNamePipe } from './pipes/display-doc-display-name.pipe';
import { TextTruncatePipe } from './pipes/text-truncate.pipe';
import { HyphenToSpacePipe } from './pipes/hyphen-to-space.pipe';
import { DrawingBoardModule } from './components/drawing-board/drawing-board.module';
import { AdjustedPricePipe } from './pipes/adjusted-price.pipe';
import { UnitPricePipe } from './pipes/unit-price.pipe';
import { AttachedPlanPipe } from './pipes/attached-plan.pipe';
import { CheckboxModule } from './views/components/checkbox/checkbox.module';
import { ConfirmModalComponent } from './components/shared/confirm-modal/confirm-modal.component';
import { PackageComponent } from './components/shared/package/package.component';
import { PackageItemComponent } from './components/shared/package/package-item/package-item.component';
import { PaymentCollectMethodComponent } from './components/payment/payment-collect-method/payment-collect-method.component';
import { PaymentHistoryComponent } from './components/payment/payment-history/payment-history.component';
import { WarningModalComponent } from './components/shared/warning-modal/warning-modal.component';
import { DisplayPaymentModePipe } from './pipes/display-payment-mode.pipe';
import { RichEditorSwitcherComponent } from './components/shared/rich-editor-switcher/rich-editor-switcher.component';
import { AppointmentSearchResultComponent } from './views/components/appointments/appointment-search-result/appointment-search-result.component';
import { DisplayDollarFourDecimalPipePipe } from './pipes/display-dollar-four-decimal-pipe.pipe';
import { AddendumComponent } from './components/consultation/addendum/addendum.component';
import { ObjectListSortPipe } from './pipes/object-list-sort.pipe';
import { CheckCoverageComponent } from './views/components/medical-coverage/medical-coverage/check-coverage/check-coverage.component';
import { CheckCoverageItemComponent } from './views/components/medical-coverage/medical-coverage/check-coverage-item/check-coverage-item.component';
import { ReplaceBrPipe } from './pipes/replace-br.pipe';
import { PaymentConfirmComponent } from './components/payment/payment-confirm/payment-confirm.component';
import { CaseSummeryBillAdjDetailsComponent } from './views/components/case-manager/case-summery-bill-adj-details/case-summery-bill-adj-details.component';
import { DisplayPercentagePipe } from './pipes/display-percentage.pipe';
import { DisplayTwoDecimalsPipe } from './pipes/display-two-decimals.pipe';
import { VaccineDetailsModalComponent } from './components/consultation/consultation-prescription/vaccine-details-modal/vaccine-details-modal.component';
import { RouterModule } from '@angular/router';
import { PatientDetailAllergyAlertComponent } from './components/patient-detail/patient-detail-allergy-alert/patient-detail-allergy-alert.component';
import { CustomConfirmPopupComponent } from './views/components/custom-confirm-popup/custom-confirm-popup.component';
import { PatientDetailsAllertAdrCmisViewPopupComponent } from './components/patient-detail/patient-detail-allergy-alert/patient-details-allert-adr-cmis-view-popup/patient-details-allert-adr-cmis-view-popup.component';
import { PatientHsgComponent } from './views/components/patient/patient-hsg/patient-hsg.component';
import { PatientHsgHealthPlanComponent } from './views/components/patient/patient-hsg/patient-hsg-health-plan/patient-hsg-health-plan.component';
import { PatientHsgPlanListingComponent } from './views/components/patient/patient-hsg/patient-hsg-plan-listing/patient-hsg-plan-listing.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HsgDisplayStatusPipe } from './pipes/hsg-display-status.pipe';
import { PatientHsgVaccineAndSFLComponent } from './views/components/patient/patient-hsg/patient-hsg-vaccine-and-sfl/patient-hsg-vaccine-and-sfl.component';
import { PatientHsgPlanDeleteComponent } from './views/components/patient/patient-hsg/patient-hsg-plan-delete/patient-hsg-plan-delete.component';
import { PatientHsgConditionComponent } from './views/components/patient/patient-hsg/patient-hsg-condition/patient-hsg-condition.component';
import { DecimalNumberDirectiveDirective } from './directives/decimal-number-directive.directive';
import { KeysPipe } from "./pipes/keys.pipe";
import {
  WhitelistedItemChipComponent
} from "./components/whitelisted/whitelisted-item-chip/whitelisted-item-chip.component";
import {
  WhitelistedItemNoticeComponent
} from "./components/whitelisted/whitelisted-item-notice/whitelisted-item-notice.component";
import { HsgCoverageComparisionComponent } from './views/components/nhps/hsg/hsg-coverage-comparision/hsg-coverage-comparision.component';
import { DisplayAddressPipe } from "./pipes/display-address.pipe";
import { ClinicFeatureDirective } from "./directives/clinic-feature.directive";
import { EnableRemoteDeliveryTabDirective } from "./directives/hsg/hsg-enabled.directive";
import { PatientDetailSflComponent } from './components/patient-detail/patient-detail-sfl/patient-detail-sfl.component';
import { ConsultationDiagnosisItemComponent } from './components/consultation/consultation-diagnosis/consultation-diagnosis-item/consultation-diagnosis-item.component';
import { PatientDetailVaccinationComponent } from './components/patient-detail/patient-detail-vaccination/patient-detail-vaccination.component';
@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    NgOptionHighlightModule,
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    CKEditorModule,
    ChartsModule,
    AngularSvgIconModule,
    FileUploadModule,
    NgxPermissionsModule,
    RouterModule,
    DrawingBoardModule,
    CheckboxModule,
    QuillModule.forRoot(),
  ],
  exports: [
    CommonModule,
    CheckboxModule,
    NgxDatatableModule,
    NgSelectModule,
    NgOptionHighlightModule,
    AlertComponent,
    AccordionModule,
    ErrorsComponent,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    ModalModule,
    ConfirmModalComponent,
    WarningModalComponent,
    PackageComponent,
    DisplayDatePipe,
    DisplayHour,
    DisplayDollarPipe,
    DisplayFullDatePipe,
    DisplayDateFullDateFormatPipe,
    DisplayTitleCasePipe,
    DisplayDollarEmptyPipe,
    DisplayDollarAboveZeroPipe,
    DisplayDollarFourDecimalPipePipe,
    DisplayLineBreakPipe,
    DisplayLockOptionsPipe,
    DisplayDrugItemCodePipe,
    DisplayDrugItemNamePipe,
    DisplayDosageInstructionPipe,
    DisplaySalesUomPipe,
    DisplayInstructionPipe,
    DisplayDiagnosisCodePipe,
    DisplayDiagnosisTermPipe,
    DisplayMedicalCertPipe,
    DisplayClinicNamePipe,
    DisplayTwoDecimalsPipe,
    DisplayAllergyGroupDescPipe,
    DisplayDocDisplayNamePipe,
    DisplayCoverageAttributePipe,
    DisplayPlanAttributePipe,
    DisplayStringEmptyPipe,
    DisplayDrugPipe,
    DisplayMCAddressPipe,
    DisplayMCContactDetailPipe,
    DisplayPaymentModePipe,
    SortVaccinePipe,
    HyphenToSpacePipe,
    TextTruncatePipe,
    PopoverModule,
    TabsModule,
    TooltipModule,
    CollapseModule,
    BsDatepickerModule,
    CKEditorModule,
    TimepickerModule,
    ChartsModule,
    AngularSvgIconModule,
    FileUploadModule,
    NgxPermissionsModule,
    TouchedObjectDirective,
    ChargeItemTouchedObjectDirective,

    SimpleErrorComponent,
    DrugInputSearchComponent,
    DrugInputSearchModalComponent,
    ClinicSelectComponent,
    ReferralItemComponent,
    RouterModule,
    LoadingComponent,
    LoadingRetryComponent,
    PatientAddQueueConfirmationComponent,
    PatientAddConsultationComponent,
    MedicalCoverageComponent,
    MedicalCoverageEditComponent,
    MedicalCoverageItemComponent,
    MedicalCoverageEditItemComponent,
    MedicalCoverageItemDetailComponent,
    CheckCoverageComponent,
    CheckCoverageItemComponent,
    DiscountComponent,
    AddAppointmentComponent,
    AppointmentsNewComponent,
    AppointmentsErrorMessageComponent,
    AppointmentsBlockDoctorTimeComponent,
    AppointmentsChangeClinicComponent,
    ConsultationPatientInfoComponent,
    ConsultationPatientAlertInfoComponent,
    ConsultationNotesComponent,
    ConsultationProblemListComponent,
    ProblemListItemComponent,
    ConsultationOngoingMedicationComponent,
    OngoingMedicationItemComponent,
    ConsultationDocumentsComponent,
    ConsultationPrescriptionComponent,
    PrescriptionItemComponent,
    ConsultationReferralComponent,
    ClinicNotesComponent,
    MedicalCertificateItemsArrayComponent,
    MedicalCertificateItemControlComponent,
    ConsultationFollowUpComponent,
    ConsultationMemoComponent,
    DatePickerWithIconComponent,
    ConsultationSearchComponent,
    PrimaryCareNetworkComponent,
    ReadOnlyComponent,
    BalanceItemComponent,
    PatientPayableComponent,
    PatientPayableItemComponent,
    PatientDetailTagAddAlertItemComponent,
    RefreshComponent,
    CkeditorComponent,
    AppointmentsDetailComponent,
    InfiniteScrollComponent,
    PaymentLabRequestFormComponent,
    PaymentLabRequestFormItemComponent,
    ConsultationLockedComponent,
    AdjustedPricePipe,
    UnitPricePipe,
    AttachedPlanPipe,

    QuillEditorComponent,
    RichEditorSwitcherComponent,
    VaccineDetailsModalComponent,

    //Inventory
    InventoryDisplayItemsSumQuantityPipe,
    InventoryDisplayItemSellingPricePipe,
    DisplayReturnRequestStatusLabelPipe,
    DisplayReturnRequestStatusColorCssClassPipe,
    InventoryDOReceiverNamePipe,
    InventoryDoReceiverAddressPipe,
    InventoryDoReceiverContactPipe,
    InventoryDoReceiverFaxPipe,
    InventoryDoReceiverPostalCodePipe,
    InventoryGrnDeliverNamePipe,
    InventoryGrnDeliverAddressPipe,
    InventoryGrnDeliverPostalCodePipe,
    InventoryDisplayOrderStatusPipe,
    InventoryDisplayOrderNamePipe,
    InventoryDisplayClinicCodePipe,
    InventoryDisplayStockTakeCountTypePipe,
    InventoryDisplayStockTakeCountedItemSizePipe,
    InventoryDisplayStockTakeUncountedItemSizePipe,
    InventoryDisplayStockTakeItemVariancePipe,
    InventoryDisplayReturnRequestSubtotalPipe,
    InventoryDisplayReturnRequestGstPipe,
    InventoryDisplayReturnRequestTotalPipe,
    InventoryDisplayOrderDetailTitlePipe,
    InventoryOrderDetailDeliverNamePipe,
    InventoryOrderDetailDeliverAddressPipe,
    InventoryOrderDetailDeliverContactPipe,
    InventoryOrderDetailDeliverFaxPipe,
    InventoryOrderDetailDeliverPostalCodePipe,
    InventoryDisplayOrderSubtotalPipe,
    InventoryDisplayOrderGstPipe,
    InventoryDisplayOrderTotalPipe,
    InventoryDisplayStockAdjustmentVariancePipe,
    InventoryDisplayDOStatusPipe,
    InventoryDisplayGRNStatusPipe,
    StockItemInvalidPipe,
    PrescriptionItemInvalidPipe,
    StockAdjustmentItemInvalidPipe,

    PaymentCollectMethodComponent,
    PaymentHistoryComponent,
    ApprovedInventoryDisplayOrderSubtotalPipe,
    ObjectListSortPipe,
    ReplaceBrPipe,
    CaseSummeryBillAdjDetailsComponent,
    PatientHsgComponent,
    PatientHsgHealthPlanComponent,
    PatientHsgPlanListingComponent,
    HsgDisplayStatusPipe,
    PatientHsgVaccineAndSFLComponent,
    PatientHsgPlanDeleteComponent,
    PatientHsgConditionComponent,
    HsgCoverageComparisionComponent,

    DecimalNumberDirectiveDirective,
    SafeHtmlPipe,
    KeysPipe,
    WhitelistedItemChipComponent,
    WhitelistedItemNoticeComponent,
    DisplayAddressPipe,
    ClinicFeatureDirective,
    EnableRemoteDeliveryTabDirective,
    PatientDetailSflComponent,
    PatientDetailVaccinationComponent,
    ConsultationDiagnosisItemComponent,
  ],
  declarations: [
    AlertComponent,
    ConfirmModalComponent,
    WarningModalComponent,
    PackageComponent,
    PackageItemComponent,
    PackageSubItemExpiryComponent,
    PackageSubItemBatchNoComponent,
    ErrorsComponent,
    DisplayDatePipe,
    DisplayHour,
    DisplayDollarPipe,
    DisplayDollarEmptyPipe,
    DisplayDollarAboveZeroPipe,
    DisplayDollarFourDecimalPipePipe,
    DisplayFullDatePipe,
    DisplayDateFullDateFormatPipe,
    DisplayLineBreakPipe,
    TextTruncatePipe,
    DisplayTitleCasePipe,
    DisplayLockOptionsPipe,
    DisplayDrugItemCodePipe,
    DisplayDrugItemNamePipe,
    DisplayDosageInstructionPipe,
    DisplaySalesUomPipe,
    DisplayInstructionPipe,
    DisplayDiagnosisCodePipe,
    DisplayDiagnosisTermPipe,
    DisplayMedicalCertPipe,
    DisplayClinicNamePipe,
    DisplayAllergyGroupDescPipe,
    DisplayDocDisplayNamePipe,
    DisplayPlanAttributePipe,
    DisplayCoverageAttributePipe,
    DisplayStringEmptyPipe,
    DisplayMCAddressPipe,
    DisplayMCContactDetailPipe,
    DisplayPaymentModePipe,
    DisplayDrugPipe,
    HyphenToSpacePipe,
    SortVaccinePipe,
    PatientDetailTagAddAlertComponent,
    PatientDetailTagAddAlertItemComponent,
    PatientAddAlertsInfoAddClinicalComponent,
    PatientAddAlertsInfoAddClinicalItemComponent,
    PatientDetailAddDocumentComponent,
    PatientHistoryDetailAddDocumentComponent,
    PatientHistoryDetailEditNoteComponent,
    PatientHistoryDetailEditCertificateComponent,
    PatientHistoryDetailEditCertificateItemComponent,
    PaymentConfirmComponent,
    PaymentSelectionComponent,
    DatePickerWithIconComponent,
    SimpleErrorComponent,
    DrugInputSearchComponent,
    DrugInputSearchModalComponent,
    ClinicSelectComponent,
    ReferralItemComponent,
    LoadingComponent,
    LoadingRetryComponent,
    PatientAddConsultationComponent,
    PatientAddQueueConfirmationComponent,
    MedicalCoverageComponent,
    MedicalCoverageEditComponent,
    CheckCoverageComponent,
    CheckCoverageItemComponent,
    MedicalCoverageItemComponent,
    MedicalCoverageEditItemComponent,
    MedicalCoverageItemDetailComponent,
    DiscountComponent,
    AddAppointmentComponent,
    AppointmentsNewComponent,
    AppointmentsBlockDoctorTimeComponent,
    AppointmentsErrorMessageComponent,
    AppointmentsChangeClinicComponent,
    AppointmentSearchResultComponent,
    ConsultationPatientInfoComponent,
    ConsultationPatientAlertInfoComponent,
    ConsultationPrescriptionComponent,
    PrescriptionItemComponent,
    ConsultationNotesComponent,
    QuillEditorComponent,
    RichEditorSwitcherComponent,
    AddendumComponent,
    ConsultationProblemListComponent,
    ProblemListItemComponent,
    ConsultationOngoingMedicationComponent,
    OngoingMedicationItemComponent,
    ConsultationDocumentsComponent,
    ConsultationReferralComponent,
    MedicalCertificateItemsArrayComponent,
    MedicalCertificateItemControlComponent,
    ConsultationFollowUpComponent,
    ConsultationMemoComponent,
    VaccineDetailsModalComponent,

    ChargeItemTouchedObjectDirective,
    TouchedObjectDirective,
    ClinicNotesComponent,
    ConsultationSearchComponent,
    PrimaryCareNetworkComponent,
    ReadOnlyComponent,
    BalanceItemComponent,
    PatientPayableComponent,
    PatientPayableItemComponent,
    RefreshComponent,
    CkeditorComponent,
    InfiniteScrollComponent,
    AppointmentsDetailComponent,
    InfiniteScrollComponent,
    PaymentLabRequestFormComponent,
    PaymentLabRequestFormItemComponent,
    ConsultationLockedComponent,
    AdjustedPricePipe,
    UnitPricePipe,
    AttachedPlanPipe,
    KeysPipe,

    //Inventory
    InventoryDisplayItemsSumQuantityPipe,
    InventoryDisplayItemSellingPricePipe,
    DisplayReturnRequestStatusLabelPipe,
    DisplayReturnRequestStatusColorCssClassPipe,
    InventoryDOReceiverNamePipe,
    InventoryDoReceiverAddressPipe,
    InventoryDoReceiverContactPipe,
    InventoryDoReceiverFaxPipe,
    InventoryDoReceiverPostalCodePipe,
    InventoryGrnDeliverNamePipe,
    InventoryGrnDeliverAddressPipe,
    InventoryGrnDeliverPostalCodePipe,
    InventoryDisplayOrderStatusPipe,
    InventoryDisplayOrderNamePipe,
    InventoryDisplayClinicCodePipe,
    InventoryDisplayStockTakeCountTypePipe,
    InventoryDisplayStockTakeCountedItemSizePipe,
    InventoryDisplayStockTakeUncountedItemSizePipe,
    InventoryDisplayStockTakeItemVariancePipe,
    InventoryDisplayReturnRequestSubtotalPipe,
    InventoryDisplayReturnRequestGstPipe,
    InventoryDisplayReturnRequestTotalPipe,
    InventoryDisplayOrderDetailTitlePipe,
    InventoryOrderDetailDeliverNamePipe,
    InventoryOrderDetailDeliverAddressPipe,
    InventoryOrderDetailDeliverContactPipe,
    InventoryOrderDetailDeliverFaxPipe,
    InventoryOrderDetailDeliverPostalCodePipe,
    InventoryDisplayOrderSubtotalPipe,
    InventoryDisplayOrderGstPipe,
    InventoryDisplayOrderTotalPipe,
    InventoryDisplayStockAdjustmentVariancePipe,
    InventoryDisplayDOStatusPipe,
    InventoryDisplayGRNStatusPipe,
    StockItemInvalidPipe,
    PrescriptionItemInvalidPipe,
    StockAdjustmentItemInvalidPipe,

    PaymentCollectMethodComponent,
    PaymentHistoryComponent,
    ApprovedInventoryDisplayOrderSubtotalPipe,
    ObjectListSortPipe,
    ReplaceBrPipe,
    CaseSummeryBillAdjDetailsComponent,
    DisplayPercentagePipe,
    DisplayTwoDecimalsPipe,
    PatientDetailAllergyAlertComponent,
    CustomConfirmPopupComponent,
    PatientDetailsAllertAdrCmisViewPopupComponent,
    PatientHsgComponent,
    PatientHsgHealthPlanComponent,
    PatientHsgPlanListingComponent,
    SafeHtmlPipe,
    HsgDisplayStatusPipe,
    PatientHsgVaccineAndSFLComponent,
    PatientHsgPlanDeleteComponent,
    PatientHsgConditionComponent,
    HsgCoverageComparisionComponent,

    DecimalNumberDirectiveDirective,
    WhitelistedItemChipComponent,
    WhitelistedItemNoticeComponent,
    DisplayAddressPipe,
    ClinicFeatureDirective,
    PatientDetailSflComponent,
    PatientDetailVaccinationComponent,
    ConsultationDiagnosisItemComponent,
    EnableRemoteDeliveryTabDirective,
    PatientDetailSflComponent,
  ],
  entryComponents: [
    ConfirmModalComponent,
    WarningModalComponent,
    PatientAddAlertsInfoAddClinicalComponent,
    PatientAddAlertsInfoAddClinicalItemComponent,
    PatientAddConsultationComponent,
    PatientDetailTagAddAlertComponent,
    PatientDetailTagAddAlertItemComponent,
    PatientDetailAddDocumentComponent,
    PatientHistoryDetailAddDocumentComponent,
    PatientHistoryDetailEditNoteComponent,
    PatientHistoryDetailEditCertificateComponent,
    PatientHistoryDetailEditCertificateItemComponent,
    PaymentConfirmComponent,
    PaymentSelectionComponent,
    DrugInputSearchModalComponent,
    ClinicSelectComponent,
    ReferralItemComponent,
    PatientAddQueueConfirmationComponent,
    DatePickerWithIconComponent,
    PatientAddQueueConfirmationComponent,
    MedicalCoverageComponent,
    MedicalCoverageEditComponent,
    CheckCoverageComponent,
    CheckCoverageItemComponent,
    MedicalCoverageItemComponent,
    MedicalCoverageEditItemComponent,
    MedicalCoverageItemDetailComponent,
    AppointmentsNewComponent,
    AppointmentsBlockDoctorTimeComponent,
    AppointmentsErrorMessageComponent,
    AppointmentsChangeClinicComponent,
    AppointmentSearchResultComponent,
    ConsultationPatientInfoComponent,
    ConsultationPatientAlertInfoComponent,
    AddendumComponent,
    ConsultationReferralComponent,
    ConsultationProblemListComponent,
    ProblemListItemComponent,
    ConsultationOngoingMedicationComponent,
    OngoingMedicationItemComponent,
    ConsultationDocumentsComponent,
    ConsultationPrescriptionComponent,
    PrescriptionItemComponent,
    ClinicNotesComponent,
    MedicalCertificateItemsArrayComponent,
    MedicalCertificateItemControlComponent,
    ConsultationFollowUpComponent,
    ConsultationMemoComponent,
    ConsultationSearchComponent,
    ReadOnlyComponent,
    RefreshComponent,
    ConsultationLockedComponent,
    PaymentLabRequestFormComponent,
    PaymentLabRequestFormItemComponent,
    CaseSummeryBillAdjDetailsComponent,
    VaccineDetailsModalComponent,
    PatientDetailAllergyAlertComponent,
    CustomConfirmPopupComponent,
    PatientDetailsAllertAdrCmisViewPopupComponent,
    PatientHsgPlanDeleteComponent,
    HsgCoverageComparisionComponent,
    PatientDetailSflComponent,
    PatientDetailVaccinationComponent,
    ConsultationDiagnosisItemComponent,
  ],
})
export class SharedModule {
}
