import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConsultationSearchComponent } from './../components/consultation/consultation-prescription/consultation-search/consultation-search.component';
import { AkitaPatientAppQuery } from './states/akita-patient/akita-patient-app.query';
import { PvmTabsService } from './pvm-tabs.service';
import { Batch } from './../objects/DrugQtyBalance';
import { NgxPermissionsService } from 'ngx-permissions';
import { DispatchItemService } from './dispatch-item.service';
import { PatientService } from './patient.service';
import { externalReferralDetails } from './../objects/request/PatientReferral';
import { ApiPatientVisitService } from './api-patient-visit.service';
import { ArrayValidators } from './../validators/ArrayValidators';
import { ApiCaseManagerService } from './api-case-manager.service';
import {
  debounceTime,
  distinctUntilChanged,
  take,
  finalize,
  tap, delay, map, first, filter,
} from 'rxjs/operators';
import { ApiCmsManagementService } from './api-cms-management.service';
import {
  CaseChargeFormService,
  validateInventory,
} from './case-charge-form.service';
import { StoreService } from './store.service';

import {
  DISPLAY_DATE_FORMAT,
  INPUT_DELAY,
  INVENTORY_DATE_FORMAT,
  DB_FULL_DATE_TIMEZONE,
  DB_FULL_DATE_FORMAT,
  DB_FULL_DATE_TIMEZONE_NO_SPACE,
  DB_FULL_DATE_TIMEZONE_NO_SPACE_Z,
  DB_FULL_DATE_TIMEZONE_Z,
  DATE_ASSESSMENT_CODES,
} from './../constants/app.constants';
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
  Validators, ValidationErrors,
} from '@angular/forms';
import { Injectable, TemplateRef } from '@angular/core';
import {BehaviorSubject, Subject, Observable, Subscription, combineLatest, of} from 'rxjs';
import { AtLeastOneFieldValidator } from '../validators/AtLeastOneValidator';
import { MedicalCertificateItemsArrayComponent } from './../components/consultation/consultation-medical-certificate/medical-certificate-items-array.component';
import * as moment from 'moment';
import { AlertService } from './alert.service';
import { PaymentService } from './payment.service';
import { VisitManagementService } from './visit-management.service';
import { PatientConditionsService } from '../components/pcn/patient-conditions/patient-conditions.service';
import PCNDetails, { VisitType } from '../objects/response/pcn/PCNDetails';
import { RequiredFalse } from '../validators/RequiredFalse';
import { AkitaChargeItemQuery } from './states/akita-charge-item.query';
import { AkitaClinicChargeItemQuery } from './states/akita-clinic-charge-item.query';
import {
  IPackageItem,
  IDispaches,
  PackageMapper,
  PRICE_ADJUSTMENT_TYPE,
  PACKAGE_TYPE,
} from '../components/shared/package/package.model';
import { AkitaCaseVisitQuery } from './states/akita-case-visit.query';
import { ChargeItem } from '../objects/state/ChargeItem';
import PatientInfo, { createPatientInfo } from '../objects/state/PatientInfo';
import {
  createCase,
  PatientVisitEntities,
  MemoObject,
  createMemoObject,
} from '../objects/state/Case';
import { AkitaCaseVisitStore } from './states/akita-case-visit.store';
import { Case } from '../objects/Case';
import { ApiPatientInfoService } from './api-patient-info.service';
import { BsModalService } from 'ngx-bootstrap';
import { WarningModalComponent } from '../components/shared/warning-modal/warning-modal.component';
import { flat } from '../util/array.util';
import { WarningMadalService } from '../components/shared/warning-modal/warning-madal.service';
import { Router } from '@angular/router';
import { PcnFormService } from '../components/pcn/pcn-form.service';

import { generate } from 'shortid';
import {AkitaDeliveryLocationQuery} from "./states/akita-delivery-location.query";
import {WhitelistedItemService} from "./whitelisted-item.service";
import {
  AttachedMedicalCoveragesQuery
} from "./states/akita-case/akita-attached-medical-coverages/attached-medical-coverages.query";
import {coverageCappedAllowed} from "./states/akita-whitelisted-items.store";

@Injectable()
export class ConsultationFormService {
  bsModalRef: BsModalRef;
  diagnosis: FormArray;
  secondaryDiagnosis: FormArray;
  followupConsultation: FormGroup;

  patientReferral: FormArray;
  visitValueChangeSubscription: Subscription;
  private _minimumNumberOfDiagnosisCodes: number;
  private _maximumNumberOfDiagnosisCodes: number;
  private toPayment = new Subject<number>();

  private messageSource = new BehaviorSubject('');
  currentKYC = this.messageSource.asObservable();

  changeKYC(message: string) {
    this.messageSource.next(message);
  }
  private _isRequesting: boolean;

  public get isRequesting(): boolean {
    return this._isRequesting;
  }
  public set isRequesting(value: boolean) {
    this._isRequesting = value;
  }
  selectedChart: any;

  dispatchItemsLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  advancedSearchLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  visitTypeSubject: Subject<VisitType> = new BehaviorSubject<VisitType>('NONE');
  visitType$: Observable<VisitType> = this.visitTypeSubject.asObservable();

  loadedDispatchItems: any[];

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private caseChargeFormService: CaseChargeFormService,
    private pvmTabs: PvmTabsService,
    private apiCaseManagerService: ApiCaseManagerService,
    private alertService: AlertService,
    private apiPatientVisitService: ApiPatientVisitService,
    private paymentService: PaymentService,
    private patientService: PatientService,
    private permissionsService: NgxPermissionsService,
    private patientConditionsService: PatientConditionsService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaClinicChargeItemQuery: AkitaClinicChargeItemQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaCaseVisitStore: AkitaCaseVisitStore,
    private apiPatientInfoService: ApiPatientInfoService,
    private modalService: BsModalService,
    private warningModalService: WarningMadalService,
    private router: Router,
    private dispatchItemService: DispatchItemService,
    private pcnFormService: PcnFormService,
    private deliveryLocationQuery: AkitaDeliveryLocationQuery,
    private whitelistedItemService: WhitelistedItemService,
    private attachedMedCovQuery: AttachedMedicalCoveragesQuery
  ) {
    this.minimumNumberOfDiagnosisCodes = 1;
    this.isRequesting = false;

    this.visitType$.subscribe(visitType => {
      this.pcnFormService.getUserCDMPInfo();
      const isRequired = visitType === 'CHRONIC' && this.pcnFormService.isPcnPatient;
      this.patientConditionsService.pcnRequiredSubject.next(isRequired);
    })
  }

  resetForm() {
    if (this.diagnosis && this.patientReferral && this.secondaryDiagnosis) {
      while (this.patientReferral.length > 0) {
        this.patientReferral.controls = [];
      }
      this.diagnosis.controls = [];
      this.secondaryDiagnosis.controls = [];

      // this.followupConsultation.reset();
    }
  }

  // getDispatchItemService() {
  //   return this.dispatchItemService;
  // }

  // CONSULTATION
  initConsultation() {
    const formGroup = this.fb.group({
      consultationId: '',
      patientId: '',
      consultationNotes: '',
      clinicNotes: '',
      doctorId: this.store.userHasDoctorRole() ? this.store.getUserId() : '',
      clinicId: '',
      startTime: '',
      drawingBinary: '',
      getDrawingBinary: false,
      lockStatus: 'OPEN',
    });

    if (!!this.permissionsService.getPermission('ROLE_DOCTOR'))
      formGroup.get('consultationNotes').setValidators([Validators.required]);

    return formGroup;
  }
  initKycData() {
    const formGroup = this.fb.group({
      // markVerified: [false, [Validators.requiredTrue]],
      kycStatus: '',
      verifiedDate: '',
    });
    return formGroup;
  }
  createConsultationFormGroup() {
    return this.fb.group({
      kycData: this.initKycData(),
      consultation: this.initConsultation(),
      consultationFollowup: this.initFollowupDua(),
      diagnosisIds: this.initDiagnosis(),
      dispatchItemEntities: this.caseChargeFormService.createEmptyDispatchDetails(),
      medicalCertificates: MedicalCertificateItemsArrayComponent.buildItems(),
      memos: this.initMemos(),
      patientReferral: this.fb.group({
        patientReferrals: this.initPatientReferral(),
      }),
      secondaryDiagnosisIds: this.initSecondaryDiagnosis(),
      remoteDeliveryDetails: this.initRemoteDeliveryDetails()
    });
  }

  validateRemoteDeliveryDetails(group: FormGroup): ValidationErrors | null {
    if (group.get('remoteDeliveryDetails').enabled && group.value.dispatchItemEntities.some((item) => item.remoteDelivery)) {
      if (!group.value.remoteDeliveryDetails?.deliveryDate) {
        return { remoteDeliveryDetails: true };
      } else if (!group.value.remoteDeliveryDetails?.deliveryTimeSlot) {
        return { remoteDeliveryDetails: true };
      }
    }
    return null
  }

  addVisitType(fg: FormGroup, value?: string) {
    if (fg.get('visitType')) {
      if (!this.permissionsService.getPermission('ROLE_DOCTOR'))
        fg.get('visitType').clearValidators();
      fg.get('visitType').patchValue(value || '');
    } else {
      const fc = new FormControl(value || '');
      if (!!this.permissionsService.getPermission('ROLE_DOCTOR'))
        fc.setValidators([Validators.required]);
      fg.addControl('visitType', fc);
    }
    fg.get('visitType').markAsTouched();
    this.visitValueChangeSubscription = fg
      .get('visitType')
      .valueChanges.subscribe(data => {
        this.visitTypeSubject.next(data);
      });
  }

  removeVisitType(fg: FormGroup) {
    fg.removeControl('visitType');
    if (this.visitValueChangeSubscription) {
      this.visitValueChangeSubscription.unsubscribe();
    }
  }

  addPcnDetails(fg: FormGroup) {
    fg.addControl('cdmpDetails', new FormControl('CHRONIC'));
  }

  removePcnDetails(fg: FormGroup) {
    fg.removeControl('cdmpDetails');
  }

  setPatientPcnInfo(patientId, visitId, visitStartTime) {
    // this.patientConditionsService.initconditionsCheckboxFG(this.store.primaryCareChronicCondition);
    this.patientConditionsService.patientId = patientId;
    this.patientConditionsService.visitId = visitId;
    this.patientConditionsService.visitStartTime = visitStartTime;
  }

  resetConsultationFormGroup() {
    return this.fb.group({});
  }

  setPatientInfoForUpdate(patientInfo, profileFormGroup) {
    const basicInfoFormGroup = profileFormGroup.get('basicInfoFormGroup');
    const companyInfoFormGroup = profileFormGroup.get('companyInfoFormGroup');
    const relationshipEntityFormGroup = profileFormGroup.get(
      'relationshipEntityFormGroup'
    );
    const primaryCareNetworkFormGroup = profileFormGroup.get(
      'primaryCareNetworkFormGroup'
    );
    const newBornFormGroup = profileFormGroup.get('newBornFormGroup');

    let infoToBeSaved: PatientInfo = createPatientInfo();

    if (patientInfo) {
      infoToBeSaved = this.patientService.checkBasicDetailInfo(
        infoToBeSaved,
        basicInfoFormGroup
      );

      infoToBeSaved = this.patientService.checkEmergencyContactInfo(
        infoToBeSaved,
        relationshipEntityFormGroup.get('relationshipEntities') as FormArray
      );

      infoToBeSaved = this.patientService.checkCompanyInfo(
        infoToBeSaved,
        companyInfoFormGroup
      );
      // Primary Care Network
      const primaryCareNetwork =
        primaryCareNetworkFormGroup && primaryCareNetworkFormGroup.value;
      if (primaryCareNetwork) {
        primaryCareNetwork.optInDate =
          primaryCareNetwork.optInDate &&
          moment(primaryCareNetwork.optInDate).format(DISPLAY_DATE_FORMAT);
        infoToBeSaved['primaryCareNetwork'] = primaryCareNetwork;
      }

      if (newBornFormGroup && newBornFormGroup.value) {
        infoToBeSaved['patientNewBorn'] = newBornFormGroup.value;
        infoToBeSaved.patientNewBorn.birthTime = infoToBeSaved.patientNewBorn.birthTime ? moment(infoToBeSaved.patientNewBorn.birthTime).format(DB_FULL_DATE_FORMAT) : null
      }

      //Allergies
      infoToBeSaved.allergies = patientInfo.allergies;
    }

    return infoToBeSaved;
  }

  checkConsultation(consultation) {
    delete consultation.consultation['getDrawingBinary'];
    consultation.consultation.clinicId = this.store.getClinicId();
    consultation.consultation.clinicNotes = consultation.consultation
      .clinicNotes
      ? consultation.consultation.clinicNotes
      : '';
    consultation.consultation.drawingBinary =
      consultation.consultation.drawingBinary || '';
    consultation.consultation.patientId = this.akitaPatientAppQuery.getId();
    consultation.consultation.doctorId = consultation.consultation.doctorId
      ? consultation.consultation.doctorId
      : this.store.getUser().context['cms-user-id'];
    consultation.consultation.lockStatus =
      consultation.consultation.lockStatus || 'OPEN';

    if (consultation.consultation.visitType === '') {
      delete consultation.consultation.visitType;
    }

    return consultation;
  }

  checkTimeChit(consultation, startTime) {
    if (!consultation.visitTimeChit) {
      consultation.visitTimeChit = {
        from: startTime
          ? moment(startTime, DB_FULL_DATE_FORMAT).format(DB_FULL_DATE_FORMAT)
          : moment().format(DB_FULL_DATE_FORMAT),
        to: moment().format(DB_FULL_DATE_FORMAT),
      };

      delete consultation.consultation.startTime;
    }

    return consultation;
  }

  patchConsultationToFormGroup(
    refEntity,
    consultation: AbstractControl,
    startTime?
  ) {
    if (refEntity.consultation) {
      const cons = refEntity.consultation;
      let oldNOtes = '';
      let finalNotes = '';

      if (
        localStorage.getItem('tempConsultationNotes') != null &&
        localStorage.getItem('tempConsultationNotesPatientId') ==
        this.akitaPatientAppQuery.getId() &&
        localStorage.getItem('tempConsultationNotes') != 'NoNeed' &&
        localStorage.getItem('tempConsultationNotes') != undefined &&
        localStorage.getItem('tempConsultationNotes') != 'undefined'
      ) {
        oldNOtes = '<br/>' + localStorage.getItem('tempConsultationNotes');
        localStorage.setItem('tempConsultationNotes', 'NoNeed');
        finalNotes = oldNOtes;
      } else {
        oldNOtes = '';

        if (
          cons.consultationNotes == undefined ||
          cons.consultationNotes == '' ||
          cons.consultationNotes == null
        ) {
          finalNotes = oldNOtes;
        } else {
          finalNotes = cons.consultationNotes + oldNOtes;
        }
      }
      consultation.patchValue({
        consultationId: cons.consultationId || '',
        patientId: cons.patientId,
        consultationNotes: finalNotes,

        clinicNotes: cons.clinicNotes || '',
        doctorId: cons.doctorId,
        clinicId: cons.clinicId,
        lockStatus: cons.lockStatus,
        drawingBinary: cons.drawingBinary,
        startTime: startTime ? startTime : '',
      });
    } else {
      consultation.patchValue({
        patientId: this.akitaPatientAppQuery.getId(),
        consultationNotes: '',
        clinicNotes: '',
        doctorId: this.store.getDoctorId(),
        clinicId: this.store.getClinicId(),
        lockStatus: this.getLockState(),
        drawingBinary: '',
        startTime: startTime ? startTime : '',
      });
    }
  }

  getLockState() {
    if (
      localStorage.getItem('notes_excluded_roles') &&
      localStorage.getItem('notes_excluded_roles') !== 'null'
    ) {
      return localStorage.getItem('notes_excluded_roles');
    }
    return '';
  }
  patchKycDataToFormGroup(patientKycData, kycDataForm: AbstractControl) {
    //demo happened here
    kycDataForm.patchValue({
      kycStatus: patientKycData.kycStatus || '',
      verifiedDate: patientKycData.verifiedDate || '',
    });
  }
  makeConsultationNotesNonMandatory(consultation: AbstractControl) {
    const statusIs = status => this.store.getVisitStatus() === status;

    if (
      this.permissionsService.getPermission('ROLE_DOCTOR') &&
      (statusIs('CONSULT') || statusIs('POST_CONSULT'))
    ) {
      consultation.get('consultationNotes').setValidators(Validators.required);
    } else if (
      (this.permissionsService.getPermission('ROLE_CA') ||
        this.permissionsService.getPermission('ROLE_ADMIN')) &&
      (statusIs('POST_CONSULT') || statusIs('PAYMENT'))
    ) {
      consultation.get('consultationNotes').clearValidators();
    } else {
    }
  }

  disableClinicNotes(selectedTabIndex, consultation: AbstractControl) {
    if (
      this.pvmTabs.isConsultationTab() &&
      this.store.getVisitStatus() !== 'PAYMENT'
    ) {
      consultation.get('clinicNotes').enable();
    } else {
      consultation.get('clinicNotes').disable();
    }
  }

  flattenDiagnosis(consultation) {
    const diagnosisIds = [];

    consultation.diagnosisIds.forEach(diagnosis => {
      if (
        diagnosis.id !== '' &&
        diagnosis.id !== undefined &&
        diagnosis.id !== null &&
        diagnosis.id.length > 0
      ) {
        diagnosisIds.push(diagnosis.id);
      }
    });

    consultation.diagnosisIds = diagnosisIds;
    return consultation;
  }

  flattenSnomeds(consultation) {
    const snomedList = [];
    consultation.diagnosisIds.forEach(diagnosis => {
      if (diagnosis.snomedId) {
        snomedList.push(diagnosis.snomedId);
      }
    });

    consultation.snomedList = snomedList;
    return consultation;
  }

  flattenSecondaryDiagnosis(consultation) {
    const secondaryDiagnosisIds = [];

    consultation.secondaryDiagnosisIds.forEach(diagnosis => {
      if (
        diagnosis.id !== '' &&
        diagnosis.id !== undefined &&
        diagnosis.id !== null &&
        diagnosis.id.length > 0
      ) {
        secondaryDiagnosisIds.push(diagnosis.id);
      }
    });

    consultation.secondaryDiagnosisIds = secondaryDiagnosisIds;
    return consultation;
  }

  // ITEM --- CMS-DUA
  buildDoseInstruction(code: string, instruct: string) {
    return new FormGroup({
      code: new FormControl(code),
      instruct: new FormControl(instruct),
    });
  }

  buildInstruction(
    code: string,
    frequencyPerDay: number,
    instruct: string,
    cautionary: string
  ) {
    return new FormGroup({
      code: new FormControl(code),
      frequencyPerDay: new FormControl(frequencyPerDay),
      instruct: new FormControl(instruct),
      cautionary: new FormControl(cautionary),
    });
  }

  buildPriceAdjustment(
    decreaseValue: number,
    increaseValue: number,
    paymentType: string,
    remark: string
  ) {
    return new FormGroup({
      decreaseValue: new FormControl(decreaseValue),
      increaseValue: new FormControl(increaseValue),
      paymentType: new FormControl(paymentType),
      remark: new FormControl(remark),
    });
  }

  buildAttachedMedicalCoverage(medicalCoverageId: string, planId: string) {
    return new FormGroup({
      medicalCoverageId: new FormControl(medicalCoverageId),
      planId: new FormControl(planId),
    });
  }

  // PATIENT REFERRAL //
  initPatientReferral() {
    const practice = new FormControl();
    const clinicId = new FormControl();
    const doctorId = new FormControl();
    const appointmentDateTime = new FormControl(
      moment()
        .hours(10)
        .minutes(0)
        .add(1, 'days')
        .format(DB_FULL_DATE_FORMAT)
    );
    const memo = new FormControl('');
    const str = new FormControl();
    const externalReferral = new FormControl();
    const referralType = new FormControl();
    const externalReferralDetails = this.fb.group({
      doctorName: '',
      address: '',
      phoneNumber: '',
      externalReferralType: ''
    });

    const selectedDoctorRequired = new FormControl();
    const internalNotFound = new FormControl();

    externalReferral.patchValue(false);

    this.addPatientReferral(
      practice,
      clinicId,
      doctorId,
      appointmentDateTime,
      memo,
      str,
      referralType,
      externalReferral,
      externalReferralDetails,
      selectedDoctorRequired,
      internalNotFound,
    );

    return this.patientReferral;
  }

  resetPatientReferral() {
    while (this.patientReferral.length > 0) {
      this.patientReferral.removeAt(0);
    }
  }

  addPatientReferral(
    practice: FormControl = new FormControl(),
    clinicId: FormControl = new FormControl(),
    doctorId: FormControl = new FormControl(),
    appointmentDateTime: FormControl = new FormControl(
      moment().format(DB_FULL_DATE_FORMAT)
    ),
    memo: FormControl = new FormControl(''),
    str: FormControl = new FormControl(),
    referralType = new FormControl(),
    externalReferral: FormControl = new FormControl(),
    externalReferralDetails: FormGroup = this.fb.group({
      doctorName: '',
      address: '',
      phoneNumber: '',
      externalReferralType: ''
    }),
    selectedDoctorRequired: FormControl = new FormControl(),
    internalNotFound: FormControl = new FormControl(),
  ) {
    const newPatientReferral = new FormGroup({
      practice: practice,
      clinicId: clinicId,
      doctorId: doctorId,
      appointmentDateTime: appointmentDateTime,
      memo: memo,
      str: str,
      referralType: referralType,
      externalReferral: externalReferral,
      externalReferralDetails: externalReferralDetails,
      selectedDoctorRequired: selectedDoctorRequired,
      internalNotFound: internalNotFound,
    });

    // If form exists, push referral in
    if (this.patientReferral) {
      this.patientReferral.push(newPatientReferral);
    } else {
      this.patientReferral = new FormArray([newPatientReferral]);
    }
  }

  buildPatientReferral(
    practice: FormControl,
    clinicId: FormControl,
    doctorId: FormControl,
    appointmentDateTime: FormControl,
    memo: FormControl,
    str: FormControl,
    referralType = new FormControl(),
    externalReferral: FormControl,
    externalReferralDetails: FormGroup,
    internalNotFound: FormControl,
  ) {
    const newPatientReferral = this.fb.group({
      practice: practice,
      clinicId: clinicId,
      doctorId: doctorId,
      appointmentDateTime: appointmentDateTime,
      memo: memo,
      str: str,
      referralType: referralType,
      externalReferral: externalReferral,
      externalReferralDetails: externalReferralDetails,
      internalNotFound: internalNotFound,
    });

    return newPatientReferral;
  }

  checkPatientReferral(consultation) {
    if (consultation.patientReferral) {
      const currentPatientReferrals =
        consultation.patientReferral.patientReferrals;

      let newCurrentPatientReferrals = currentPatientReferrals.filter(
        value =>
          (value.practice &&
            value.practice !== '' &&
            value.clinicId &&
            value.clinicId !== '' &&
            value.doctorId &&
            value.doctorId !== '' &&
            value.appointmentDateTime !== '') ||
          (value.practice &&
            value.externalReferralDetails &&
            value.practice !== '' &&
            value.externalReferralDetails.doctorName &&
            value.externalReferralDetails.doctorName !== '' &&
            value.externalReferralDetails.address &&
            value.externalReferralDetails.address !== '') ||
          value.externalReferral
      );

      newCurrentPatientReferrals = newCurrentPatientReferrals.map(x => {
        if (!x.externalReferral) {
          delete x.externalReferralDetails;
        }
        delete x['str'];
        return x;
      });

      consultation.patientReferral.patientReferrals = newCurrentPatientReferrals;
    }
    return consultation;
  }

  fixRemoteDeliveryDetails(consultation) {
    if (consultation.remoteDeliveryDetails) {
      consultation.remoteDeliveryDetails = {
        ...consultation.remoteDeliveryDetails,
        deliveryDate: consultation.remoteDeliveryDetails.deliveryDate ? moment(consultation.remoteDeliveryDetails.deliveryDate).format(DISPLAY_DATE_FORMAT) : null
      }
    }
    return consultation;
  }

  // PATIENT REFERRAL //
  patchReferralToFormArray(refEntity, referrals: FormArray) {
    if (
      refEntity.patientReferral &&
      refEntity.patientReferral.patientReferrals &&
      refEntity.patientReferral.patientReferrals.length
    ) {
      while (referrals.length > 0) {
        referrals.removeAt(0);
        this.resetPatientReferral();
      }
      refEntity.patientReferral.patientReferrals.forEach(referral => {
        this.addPatientReferral();
        referrals.at(referrals.length - 1).patchValue(referral);
        referrals
          .at(referrals.length - 1)
          .get('externalReferralDetails')
          .patchValue({
            doctorName: referral.externalReferralDetails.doctorName,
            address: referral.externalReferralDetails.address,
            phoneNumber: referral.externalReferralDetails.phoneNumber,
            externalReferralType: referral.externalReferralDetails.externalReferralType
          });
      });
      
    } else {
      while (referrals.length > 0) {
        referrals.removeAt(0);
        this.resetPatientReferral();
      }
      this.initPatientReferral();
    }
  }

  /* Memos */
  initMemos() {
    const memosFA = this.fb.array(new Array<MemoObject>());
    memosFA.push(this.addMemos());
    return memosFA;
  }

  addMemos(memoObject?: MemoObject) {
    let memoFG;
    if (memoObject) {
      memoFG = this.fb.group(createMemoObject(memoObject));
    } else memoFG = this.fb.group(createMemoObject());

    return memoFG;
  }

  patchMemosToFormArray(refEntity, memoFormArray: FormArray) {
    if (refEntity.memos) {
      memoFormArray.clear();
    }
    refEntity.memos.forEach(memo =>
      memoFormArray.push(this.fb.group(createMemoObject(memo)))
    );
  }

  checkMemos(consultation) {
    const currentMemos = consultation.memos.filter(
      memo => memo.memo && memo.memo.trim().length > 0
    );
    const currentConsultation = consultation;
    delete currentConsultation.memos;
    currentConsultation['memos'] = currentMemos;

    return currentConsultation;
  }

  // DIAGNOSIS
  public get minimumNumberOfDiagnosisCodes(): number {
    return this._minimumNumberOfDiagnosisCodes;
  }

  public set minimumNumberOfDiagnosisCodes(value: number) {
    this._minimumNumberOfDiagnosisCodes = value;

    if (this.diagnosis) {
      this.diagnosis.setValidators(
        this.maximumNumberOfDiagnosisCodes
          ? [
            ArrayValidators.minLength(this.minimumNumberOfDiagnosisCodes),
            ArrayValidators.maxLength(this.maximumNumberOfDiagnosisCodes),
          ]
          : ArrayValidators.minLength(this.minimumNumberOfDiagnosisCodes)
      );
      this.diagnosis.updateValueAndValidity({ emitEvent: false });

      this.addingDiagnosisRequiredFieldsValidator();
    }
  }

  get maximumNumberOfDiagnosisCodes(): number {
    return this._maximumNumberOfDiagnosisCodes;
  }

  set maximumNumberOfDiagnosisCodes(value: number) {
    this._maximumNumberOfDiagnosisCodes = value;

    if (this.diagnosis) {
      this.diagnosis.setValidators(
        this.maximumNumberOfDiagnosisCodes
          ? [
            ArrayValidators.minLength(this.minimumNumberOfDiagnosisCodes),
            ArrayValidators.maxLength(this.maximumNumberOfDiagnosisCodes),
          ]
          : ArrayValidators.minLength(this.minimumNumberOfDiagnosisCodes)
      );
      this.diagnosis.updateValueAndValidity({ emitEvent: false });
    }
  }

  diagnosisArrayLessThanMinimum() {
    if (this.diagnosis) {
      const cleanedArray = this.diagnosis.controls.filter(control => {
        return (
          control.get('id') &&
          control.get('id').value !== '' &&
          control.get('id').value !== null
        );
      });
      if (
        cleanedArray &&
        cleanedArray.length < this.minimumNumberOfDiagnosisCodes
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  public addingDiagnosisRequiredFieldsValidator() {
    if (this.diagnosisArrayLessThanMinimum()) {
      // Set required validator for first empty diagnosis
      const requiredDiagnosis = this.diagnosis.controls.find(control => {
        return (
          control.get('id').value === '' ||
          control.get('id').value === null ||
          control.get('id').value === undefined
        );
      });
      if (requiredDiagnosis) {
        requiredDiagnosis.get('id').setValidators(Validators.required);
        requiredDiagnosis.get('id').markAsTouched({ onlySelf: true });
        requiredDiagnosis
          .get('id')
          .updateValueAndValidity({ emitEvent: false });
      }
    }

    this.diagnosis.controls.forEach((control: FormGroup) => {
      control
        .get('id')
        .setValidators(
          control.get('id').validator
            ? [control.get('id').validator, RequiredFalse(control)]
            : RequiredFalse(control)
        );
      control.get('id').markAsTouched({ onlySelf: true });
      control.get('id').updateValueAndValidity({ emitEvent: false });
    });
  }

  resetDiagnosis() {
    if (this.diagnosis) {
      while (this.diagnosis.length > 0) {
        this.diagnosis.removeAt(0);
      }
    }

    // return this.diagnosis;
  }

  initDiagnosis() {
    this.addDiagnosis();
    this.addingDiagnosisRequiredFieldsValidator();

    return this.diagnosis;
  }

  addDiagnosis() {
    const newDiagnosisItem = this.fb.group({
      id: [''],
      icd10Id: [''],
      snomedId: [''],
      icd10Code: [''],
      icdType: [''],
      icd10Term: [''],
      status: [''],
      filterablePlanIds: [],
      snomedList:this.fb.array([this.fb.group({
        conceptId: [''],
        term: ['']
      })]),
      invalidUsage: [false],
    });
    if (this.diagnosis) {
      this.diagnosis.push(newDiagnosisItem);
    } else {
      this.diagnosis = new FormArray(
        [newDiagnosisItem],
        this.maximumNumberOfDiagnosisCodes
          ? [
            ArrayValidators.minLength(this.minimumNumberOfDiagnosisCodes),
            ArrayValidators.maxLength(this.maximumNumberOfDiagnosisCodes),
          ]
          : ArrayValidators.minLength(this.minimumNumberOfDiagnosisCodes)
      );
    }
  }

  removeDiagnosis(index) {
    this.diagnosis.removeAt(index);
    this.addingDiagnosisRequiredFieldsValidator();
  }

  patchDiagnosisToFormArray(diagnosisEntities, diagnosisArray: FormArray) {
    while (diagnosisArray.length > 0) {
      diagnosisArray.removeAt(0);
    }
    if (diagnosisEntities && diagnosisEntities.length) {
      diagnosisEntities.forEach(diagnosisEntity => {
        const diagnosis = JSON.parse(JSON.stringify(diagnosisEntity));
        if (diagnosis.snomedId && diagnosis.snomedList && diagnosis.snomedList.length > 0) {
          const snomedId = diagnosis.snomedList.find(snomed => snomed.conceptId === diagnosis.snomedId);
          diagnosis.snomedId = snomedId ? snomedId : diagnosis.snomedId;
          diagnosis.snomedList=this.fb.array(
            diagnosis.snomedList .map(snomedItem =>
              this.fb.control({
                conceptId: snomedItem.conceptId,
                term: snomedItem.term,
              }),
            )
          );
        }
        delete diagnosis['filterablePlanIds'];

        diagnosisArray.push(this.fb.group(diagnosis));
      });
    } else {
      this.initDiagnosis();
    }
  }

  patchCopiedDiagnosisToFormArray(
    diagnosisEntities,
    diagnosisArray: FormArray
  ) {
    if (diagnosisArray.value[0].id === '') {
      diagnosisArray.removeAt(0);
    }
    if (diagnosisEntities && diagnosisEntities.length) {
      diagnosisEntities.forEach(diagnosisEntity => {
        const diagnosis = JSON.parse(JSON.stringify(diagnosisEntity));
        if (diagnosis.snomedList && diagnosis.snomedList.length > 0) {
          // const snomedId = diagnosis.snomedList.find(snomed => snomed.conceptId === diagnosis.snomedId);
          // diagnosis.snomedId = snomedId ? snomedId : diagnosis.snomedId;
          diagnosis.snomedList=this.fb.array(
            diagnosis.snomedList .map(snomedItem =>
              this.fb.control({
                conceptId: snomedItem.conceptId,
                term: snomedItem.term,
              }),
            )
          );
        }
        delete diagnosis['filterablePlanIds'];
        diagnosisArray.insert(0, this.fb.group(diagnosis));
      });
    }
  }

  /* Secondary Diagnosis */
  resetSecondaryDiagnosis() {
    if (this.secondaryDiagnosis) {
      while (this.secondaryDiagnosis.length > 0) {
        this.secondaryDiagnosis.removeAt(0);
      }
    }
  }

  initSecondaryDiagnosis() {
    this.addSecondaryDiagnosis();

    return this.secondaryDiagnosis;
  }

  initRemoteDeliveryDetails() {

    return this.fb.group({
      deliveryLocationId: [],
      deliveryDate: [],
      deliveryTimeSlot: [],
      deliveryInstruction: '',
      deliveryAddress: this.fb.group({
        address: ['', Validators.required],
        country: [],
        postalCode: [],
      })
    })
  }

  timeSlotValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      let deliveryLocationId = group.get('deliveryLocationId').value;
      let deliveryLocation = this.deliveryLocationQuery.getEntity(deliveryLocationId);

      if (!deliveryLocation) {
        return null;
      }

      let deliveryTimeSlot = deliveryLocation.deliveryTimeSlots
        .find(timeSlot => timeSlot.label === group.get('deliveryTimeSlot').value);

      if (!deliveryTimeSlot) return null;
      let [hour, minute] = deliveryTimeSlot.endTime.split(':');

      if (moment(group.get('deliveryDate').value)
        .set({hour: parseInt(hour), minute: parseInt(minute)})
        .isBefore(moment().add(72, 'hours'))) {
        return { 'deliveryTimeSlot': true };
      }
      return null;
    }
  }

  getItemPriceAjustmentType(itemRefID): string {
    const masterItem: Partial<ChargeItem> = this.akitaChargeItemQuery.getEntity(
      itemRefID
    );
    return !!masterItem ? masterItem.priceAdjustmentType : '';
  }

  addSecondaryDiagnosis() {
    const newDiagnosisItem = this.fb.group({
      id: [''],
      icd10Id: [''],
      snomedId: [''],
      icd10Code: [''],
      icd10Term: [''],
      status: [''],
      filterablePlanIds: [],
      invalidUsage: [false],
    });

    if (this.secondaryDiagnosis) {
      this.secondaryDiagnosis.push(newDiagnosisItem);
    } else {
      this.secondaryDiagnosis = new FormArray([newDiagnosisItem]);
    }
  }

  removeSecondaryDiagnosis(index) {
    this.secondaryDiagnosis.removeAt(index);
  }

  patchSecondaryDiagnosisToFormArray(
    diagnosisEntities,
    diagnosisArray: FormArray
  ) {
    while (diagnosisArray.length > 0) {
      diagnosisArray.removeAt(0);
    }
    if (diagnosisEntities && diagnosisEntities.length) {
      diagnosisEntities.forEach(diagnosisEntity => {
        const diagnosis = JSON.parse(JSON.stringify(diagnosisEntity));
        if (diagnosis.snomedId && diagnosis.snomedList && diagnosis.snomedList.length > 0) {
          const snomedId = diagnosis.snomedList.find(snomed => snomed.conceptId === diagnosis.snomedId);
          diagnosis.snomedId = snomedId ? snomedId : diagnosis.snomedId;
          diagnosis.snomedList=this.fb.array(
            diagnosis.snomedList .map(snomedItem =>
              this.fb.control({
                conceptId: snomedItem.conceptId,
                term: snomedItem.term,
              }),
            )
          );
        }
        delete diagnosis['filterablePlanIds'];
        diagnosisArray.push(this.fb.group(diagnosis));
      });
    } else {
      this.initSecondaryDiagnosis();
    }
  }
  /* END Secondary Diagnosis */

  getCaseItemPrices(items) {
    const chargeDetailItems = [];
    items.forEach(item => {
      const tempItem = {
        itemId: item.itemId,
        quantity: item.quantity || '',
        excludedPlans: item.excludedCoveragePlanIds,
      };
      chargeDetailItems.push(tempItem);
    });

    const chargeDetails = { chargeDetails: chargeDetailItems };
    return this.apiCaseManagerService.getCaseItemPrices(
      this.store.getCaseId(),
      chargeDetails
    );
  }

  isEmptyOrNull(value) {
    if (value === '' || value === null || value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  // Patch Dispatch to Formgroup
  patchDispatchItemsToFormArray(
    dispatchItemEntities,
    caseItemPrice,
    dispatchItemEntitiesFormArray: FormArray
  ) {
    if (dispatchItemEntities && dispatchItemEntities.length) {
      // Clear Drug array
      this.resetDispatchItemFormArray(dispatchItemEntitiesFormArray);

      const caseItems = caseItemPrice.payload.chargeDetails;
      const caseItemsInventories = caseItemPrice.payload.inventoryData;
      // filtering out the dispatch entities that are under packages
      dispatchItemEntities.forEach((purchaseItem, index) => {
        const item = this.akitaChargeItemQuery.getChargeItem(
          purchaseItem.itemId
        );

        const caseItem = caseItems[index];
        const price = parseFloat((caseItem.chargeBigDeci.price).toFixed(5));
        const caseItemInventories = caseItemsInventories.filter(data => {
          return data.itemId === caseItem.itemId;
        });

        // Populating inventory and drug quantity balance in service
        if (
          !this.dispatchItemService.drugBalanceAvailable(purchaseItem.itemId)
        ) {
          this.dispatchItemService.addNewDrugQtyBalance(
            purchaseItem.itemId,
            caseItemInventories
          );
        }

        const selectedInventory = caseItemInventories.length
          ? caseItemInventories[0]
          : undefined;

        const quantity = purchaseItem.quantity || 1;

        const adjustedUnitValue = purchaseItem.itemPriceAdjustment.adjustedValue || 0;
        // const adjustedTotalPriceInCents =
        //   +(price + adjustedUnitValue) * quantity;
        // const adjustedTotalPriceInCents = +((price + adjustedUnitValue) * 1000 * 100 * quantity) / 1000;
        const adjustedTotalPriceInCents = parseFloat((+(price + adjustedUnitValue) * 100 * quantity).toFixed(5));
        const adjustedTotalPrice = (adjustedTotalPriceInCents / 100).toFixed(2);
        const adjustedUnitPriceInCents = adjustedTotalPriceInCents / quantity;
        // const adjustedUnitPriceInDollars = parseFloat(
        //   (adjustedUnitPriceInCents / 100).toFixed(2)
        // );
        const adjustedUnitPriceInDollars = adjustedUnitPriceInCents / 100;
        // const calculatedAmount = (adjustedUnitPriceInCents * quantity) / 100;
        const calculatedAmount = parseFloat(((adjustedUnitPriceInCents * quantity) / 100).toFixed(5));
        const batchNo = purchaseItem.batchNo
          ? purchaseItem.batchNo
          : selectedInventory
            ? selectedInventory.batchNo
            : '';

        const shorIdValue = generate();
        this.dispatchItemService.setDrugDispenseQtyFromBatch(
          purchaseItem.itemId,
          batchNo,
          purchaseItem.quantity || 0,
          shorIdValue
        );

        const inventoryInvalidMsg = (!purchaseItem.remoteDelivery) && this.inventoryIsValid(
          batchNo,
          purchaseItem.itemId,
          shorIdValue
        );

        if (item) {
          const excludedCoveragePlanIds =
            purchaseItem.excludedCoveragePlanIds === undefined ||
            purchaseItem.excludedCoveragePlanIds === null
              ? ''
              : purchaseItem.excludedCoveragePlanIds;

          const masterItem: Partial<ChargeItem> = this.akitaChargeItemQuery.getEntity(
            purchaseItem.itemId
          );
          const itemType = !!masterItem ? masterItem.itemType : '';

          const dosgeInstruction =
            this.store
              .getAllDosageInstructions()
              .find(
                instruction =>
                  instruction.code === purchaseItem.dosageInstruction
              ) || itemType === 'VACCINATION'
              ? { code: purchaseItem.dosageInstruction, instruct: '' }
              : { code: '', instruct: '' };

          let vaccinationInfoFG: any = this.fb.control(null);

          if (itemType === 'VACCINATION') {
            const vaccinationInfo = purchaseItem.vaccinationInfo ? purchaseItem.vaccinationInfo : {};

            let multivaccineItems = [];
            let vaccinationSchedules = [{vaccineId: '', doseId: '', scheduledDate: '', scheduledTime: ''}];
            if (vaccinationInfo.multiVaccineSubItems && vaccinationInfo.multiVaccineSubItems.length > 0) {
              const item = this.store.getItemById(purchaseItem.itemId);
              if (item) {
                for (let subItem of vaccinationInfo.multiVaccineSubItems) {
                  let tmpSubItem = {code: subItem.code, description: subItem.description, doseId: subItem.doseId, dosages: []};
                  const masterSubItem = item.multiVaccineSubItems.find(item => item.code === tmpSubItem.code);
                  tmpSubItem.dosages = masterSubItem ? masterSubItem.dosages : [];
                  multivaccineItems.push(tmpSubItem);
                }
              }
            }
            if (vaccinationInfo.vaccinationSchedules && vaccinationInfo.vaccinationSchedules.length > 0) {
              vaccinationSchedules = [];
              for (let schedule of vaccinationInfo.vaccinationSchedules) {
                let tmpSchedule = {vaccineId: schedule.vaccineId, doseId: schedule.doseId,
                  scheduledDate: (schedule.scheduledDate ? schedule.scheduledDate : ''), scheduledTime: schedule.scheduledTime};
                vaccinationSchedules.push(tmpSchedule);
              }
            }
            vaccinationInfoFG = this.getVaccinationInfoFG(vaccinationInfo, multivaccineItems, vaccinationSchedules);
          }

          const dispatchItemFB = this.fb.group({
            chargeBigDeci: caseItem.chargeBigDeci,
            drugId: purchaseItem.itemId,
            batchNumber: batchNo,
            expiryDate: purchaseItem.expiryDate
              ? purchaseItem.expiryDate
              : selectedInventory && selectedInventory.expireDate
                ? moment(
                  selectedInventory.expireDate,
                  INVENTORY_DATE_FORMAT
                ).format(DISPLAY_DATE_FORMAT)
                : null,
            remark: purchaseItem.remarks || '',
            dose: this.fb.group({
              // uom: purchaseItem.dosageUom || '',
              uom: masterItem.dosageUom || '',
              quantity: purchaseItem.dosage || 0,
            }),
            salesUom: masterItem.salesUom,
            dosageInstruction: this.fb.group(dosgeInstruction),
            instruction: this.fb.group({
              code: purchaseItem.instruct || '',
            }),
            priceAdjustment: this.fb.group({
              adjustedValue:
                purchaseItem.itemPriceAdjustment.adjustedValue || 0,
              paymentType:
                purchaseItem.itemPriceAdjustment.paymentType || 'DOLLAR',
              remark: purchaseItem.itemPriceAdjustment.remark || '',
            }),
            attachedMedicalCoverages: purchaseItem.medicalCoverages || [],
            purchaseQty: [purchaseItem.quantity || 0, purchaseItem.remoteDelivery ? [Validators.required, Validators.min(1)] : null],
            duration: [purchaseItem.duration || '', purchaseItem.remoteDelivery ? [Validators.required, Validators.min(1), Validators.max(1000)] : null],
            stock: selectedInventory
              ? selectedInventory.stockBalance || ''
              : '',
            inventoryInvalid: inventoryInvalidMsg,
            excludedCoveragePlanIds: this.fb.control(excludedCoveragePlanIds),
            cautionary: item.cautionary || '',
            isChecked: false,
            // unitPrice: this.fb.group({
            //   // price: item.item.sellingPrice.price / 100,
            //   price: price / 100,
            //   taxIncluded: item.sellingPrice.taxIncluded,
            // }),
            unitPrice: this.fb.group({
              // price: item.item.sellingPrice.price / 100,
              price: price,
              taxIncluded: item.sellingPrice.taxIncluded,
            }),
            // oriTotalPrice:
            //   price !== purchaseItem.oriTotalPrice
            //     ? price * (purchaseItem.quantity || 0)
            //     : purchaseItem.oriTotalPrice,
            oriTotalPrice:
              price !== purchaseItem.oriTotalPrice
                ? (price * 100 * (purchaseItem.quantity || 0)) / 100
                : purchaseItem.oriTotalPrice,
            adjustedTotalPrice: [adjustedTotalPrice, {asyncValidators: [this.caseChargeFormService.validateWhitelistedItemPrice.bind(this.caseChargeFormService)]}],
            // adjustedUnitPrice: (adjustedUnitPriceInCents / 100).toFixed(2),
            // adjustedUnitPrice: ((adjustedUnitPriceInCents * 1000) / 100) / 1000,
            adjustedUnitPrice: parseFloat((adjustedUnitPriceInCents / 100).toFixed(5)),
            calculatedTotalPrice: calculatedAmount,
            inventories: this.fb.array(
              caseItemInventories.map(inventory =>
                this.fb.group({ ...inventory })
              )
            ),
            drugDispenseShortId: shorIdValue,
            packageId: purchaseItem.packageId || purchaseItem.salesItemId || '',
            salesItemId: purchaseItem.salesItemId || '',
            itemType: itemType || '',
            vaccineDosageAvailableList:
              purchaseItem.vaccineDosageAvailableList &&
              this.fb.array(
                purchaseItem.vaccineDosageAvailableList.map(vaccItem =>
                  JSON.parse(vaccItem)
                )
              ),

            routeOfAdministration: purchaseItem.routeOfAdministration || '',
            vaccinationInfo: vaccinationInfoFG,
            remoteDelivery: purchaseItem.remoteDelivery || false,
            sflData: this.fb.group({
              id: [purchaseItem.sflData?.id],
              screenDate: [purchaseItem.sflData?.screenDate],
              screeningType: [purchaseItem.sflData?.screeningType],
              testType: [purchaseItem.sflData?.testType],
              testOrder: [purchaseItem.sflData?.testOrder],
              followUpOutcome: [purchaseItem.sflData?.followUpOutcome],
              screeningOutcome: [purchaseItem.sflData?.screeningOutcome],
              followUpDate: [purchaseItem.sflData?.followUpDate],
              visitId: [purchaseItem.sflData?.visitId],
              conditionCodes: [purchaseItem.sflData?.conditionCodes]
            })
          }, {
            asyncValidators: [this.caseChargeFormService.validateWhitelistedItem.bind(this.caseChargeFormService)]
          });

          //Add sfl optional validation based on the drug id.
          let drugId = dispatchItemFB.get('drugId');
          [
            'screenDate',
            'screeningType',
            'testType',
            'testOrder',
          ].forEach(field => {
            dispatchItemFB.get('sflData').get(field).addValidators(this.caseChargeFormService.requiredIfSfl(drugId))
          })

          this.akitaCaseVisitQuery.selectCaseCoverage().pipe(
            distinctUntilChanged(),
          ).subscribe(caseCoverage => {
            dispatchItemFB.markAsTouched()
          })

          // Check if current batch is available in list
          const selectedBatchNo = dispatchItemFB.get('batchNumber').value;
          if (
            !this.getDrugBatch(
              dispatchItemFB.get('drugId').value,
              selectedBatchNo
            )
          ) {
            const defaultDrugBatch: Batch = this.getDefaultDrugBatch(
              dispatchItemFB.get('drugId').value
            );
            const selectedBatchNo = defaultDrugBatch
              ? defaultDrugBatch.batchNo
              : '';
            if (selectedBatchNo.length > 0) {
              dispatchItemFB.get('batchNumber').patchValue(selectedBatchNo);
            }
          }

          // Update Batch with the particular quantity
          this.updateSelectedBatchQuantity(
            dispatchItemFB.get('drugId').value,
            selectedBatchNo,
            dispatchItemFB.get('purchaseQty').value,
            dispatchItemFB.get('drugDispenseShortId').value
          );

          // Retrieve dropdown list for inventory
          this.updateInventoryList(dispatchItemFB);

          dispatchItemEntitiesFormArray.push(dispatchItemFB);
          this.setPriceAdjustmentRemarkValidators(dispatchItemFB);
          if (dispatchItemFB.get('remoteDelivery').value !== true) {
            this.setInventoryValidators(dispatchItemFB.get('inventoryInvalid'));
          }
        }
      });
    } else {
      this.resetDispatchItemFormArray(dispatchItemEntitiesFormArray);
    }
  }

  getVaccinationInfoFG(vaccinationInfo, multivaccineItems, vaccinationSchedules) {
    return this.fb.group({
      givenDate: this.fb.control(vaccinationInfo.givenDate),
      vaccineDosage: this.fb.control(vaccinationInfo.vaccineDosage),
      administrator: this.fb.group({
        regNo: vaccinationInfo.administrator ? vaccinationInfo.administrator.regNo : '',
        name: vaccinationInfo.administrator ? vaccinationInfo.administrator.name : '',
        profession: vaccinationInfo.administrator ? vaccinationInfo.administrator.profession : 'DOCTOR',
      }),
      administrationInfo: this.fb.group({
        site: vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.site : '',
        route: vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.route : '',
        notGiven: vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.notGiven : false,
        reason: vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.reason : ''
      }),
      multiVaccineSubItems: this.fb.array(
        multivaccineItems.map(subItem =>
          this.fb.group({
            code: subItem.code ? subItem.code : '',
            description: subItem.description ? subItem.description : '',
            doseId: subItem.doseId ? subItem.doseId : '',
            dosages: this.fb.array(subItem.dosages)
          })
        )
      ),
      vaccinationSchedules: this.fb.array(
        vaccinationSchedules.map(schedule =>
          this.fb.group({ ...schedule })
        )
      ),
      conditionCodes: this.fb.control(vaccinationInfo.conditionCodes),
      doseType: this.fb.control(vaccinationInfo.doseType),
      covidConditionCodes: this.fb.control(vaccinationInfo.covidConditionCodes),
    })
  }

  resetDispatchItemFormArray(dispatchItemEntitiesFormArray: FormArray) {
    while (dispatchItemEntitiesFormArray.length > 0) {
      const entity = dispatchItemEntitiesFormArray.at(0).value;
      const drugBalanceAvailable = this.dispatchItemService.drugBalanceAvailable(
        entity.drugId
      );
      if (!!drugBalanceAvailable) {
        this.dispatchItemService.deleteDrugSubscriptionFromBatch(
          entity.drugId,
          entity.batchNumber,
          entity.drugDispenseShortId
        );
      }

      dispatchItemEntitiesFormArray.removeAt(0);
    }
  }

  setDispatchItemsLoaded(value) {
    this.dispatchItemsLoaded.next(value);
  }

  getDispatchItemsLoaded() {
    return this.dispatchItemsLoaded.asObservable();
  }

  resetDispatchItemsLoaded() {
    this.setDispatchItemsLoaded(undefined);
  }

  patchTimeChitToPrintFormGroup(consultationInfo, printFormGroup) {
    const refEntity = consultationInfo.medicalReferenceEntity;
    const registryStartTime = consultationInfo.startTime || '';
    const consultationEndTime = refEntity.consultation
      ? refEntity.consultation.consultationEndTime
      : '';

    let timeChitFrom;
    let timeChitTo;

    if (consultationInfo.medicalReferenceEntity.visitTimeChit) {
      timeChitFrom =
        consultationInfo.medicalReferenceEntity.visitTimeChit.from || '';
      timeChitTo =
        consultationInfo.medicalReferenceEntity.visitTimeChit.to || '';
    } else {
      timeChitFrom = registryStartTime;
      timeChitTo = consultationEndTime;
    }

    printFormGroup
      .get('timeChitFrom')
      .patchValue(
        timeChitFrom
          ? moment(timeChitFrom, DB_FULL_DATE_FORMAT).toDate()
          : moment().toDate()
      );

    printFormGroup
      .get('timeChitTo')
      .patchValue(
        timeChitTo
          ? moment(timeChitTo, DB_FULL_DATE_FORMAT).toDate()
          : moment().toDate()
      );
  }

  updateInventoryList(dispatchItem) {
    const inventories: FormArray = dispatchItem.get('inventories') as FormArray;
    while (inventories.length) {
      inventories.removeAt(0);
    }
    const batchList =
      this.dispatchItemService.getDrugBatchDropdownList(
        dispatchItem.get('drugId').value
      ) || [];
    batchList.forEach(inventory => {
      inventories.push(this.fb.group({ ...inventory }));
    });
  }

  inventoryIsValid(batchNumber, drugId, drugDispenseShortId) {
    const storeItem = this.akitaChargeItemQuery.getChargeItem(drugId);

    if (!storeItem) return;

    if (storeItem.inventoried) {
      // if (this.store.getVisitStatus() === 'INITIAL') {
      //   // DO NOTHING
      // } else {
      return this.dispatchItemService.getInventoryValidMsg(
        batchNumber,
        drugId,
        drugDispenseShortId,
        this.permissionsService.getPermission('ROLE_CA'),
        this.pvmTabs.isConsultationTab()
      );
      // }
    } else {
      return null;
    }
  }

  updateSelectedBatchQuantity(itemId, batchNo, qty, index) {
    if (batchNo !== '' && batchNo !== undefined && batchNo !== null) {
      const drugQtyBalance = this.dispatchItemService.getDrugQtyBalance(itemId);
      drugQtyBalance.setBatchDispenseQty(batchNo, qty, index);
    }
  }

  getDrugBatch(drugId, batchNo) {
    return this.dispatchItemService.getDrugBatch(drugId, batchNo);
  }

  getDefaultDrugBatch(drugId) {
    return (
      this.dispatchItemService.getDrugQtyBalance(drugId).getBatchList()[0] ||
      null
    );
  }

  setInventoryValidators(control: AbstractControl) {
    control.setValidators(validateInventory);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  setPriceAdjustmentRemarkValidators(formGroup: FormGroup) {
    const adjustedUnitValue = formGroup
      .get('priceAdjustment')
      .get('adjustedValue').value
      ? formGroup.get('priceAdjustment').get('adjustedValue').value
      : 0;

    const priceAdjustmentRemark = formGroup
      .get('priceAdjustment')
      .get('remark');
    if (
      this.dispatchItemService.adjustedPriceLowerThanOriginal(adjustedUnitValue) && !formGroup.get('remoteDelivery').value
    ) {
      priceAdjustmentRemark.setValidators([Validators.required]);
    } else {
      priceAdjustmentRemark.setValidators(null);
    }

    priceAdjustmentRemark.markAsTouched();
    priceAdjustmentRemark.updateValueAndValidity({ emitEvent: false });
  }

  clearArray(array) {
    while (array.length > 0) {
      array.removeAt(0);
    }
  }

  filterArrayForDrugItems(dispatchItemEntities) {
    const drugItems = dispatchItemEntities.filter(dispatchItem => {
      const item = this.akitaChargeItemQuery.getChargeItem(dispatchItem.id);
      return item && item.itemType === 'DRUG';
    });

    return drugItems;
  }

  getItemDetailedInfo(purchaseItemId) {
    return this.akitaChargeItemQuery.getChargeItem(purchaseItemId);
  }

  checkIfIDrugOrVaccineIsAvailableInClinic(
    dispatchItems,
    availableItems: Array<any>,
    unavailableItems: Array<any>
  ) {
    dispatchItems.forEach(dispatchItem => {
      const itemDetails = this.akitaChargeItemQuery.getChargeItem(
        dispatchItem.itemId
      );

      if (
        itemDetails &&
        this.caseChargeFormService.isDrugOrVaccine(itemDetails)
      ) {
        // const itemPresentInClinic = this.store.chargeItemListByClinic.find(item => item.item.id === itemDetails.item.id) || null;
        const itemPresentInClinic =
          this.akitaClinicChargeItemQuery.getChargeItem(itemDetails.id) || null;

        if (itemPresentInClinic !== null) {
          availableItems.push(dispatchItem);
        } else {
          unavailableItems.push(itemDetails);
        }
      }
    });

    this.alertUnavailableItems(unavailableItems);
  }

  checkIfAvailableInClinic(
    dispatchItems,
    availableItems: Array<any>,
    unavailableItems: Array<any>
  ) {
    dispatchItems.forEach(dispatchItem => {
      const itemDetails = this.akitaChargeItemQuery.getChargeItem(
        dispatchItem.itemId
      );

      if (itemDetails) {
        // const itemPresentInClinic = this.store.chargeItemListByClinic.find(item => item.item.id === itemDetails.item.id) || null;
        const itemPresentInClinic =
          this.akitaClinicChargeItemQuery.getChargeItem(itemDetails.id) || null;

        if (itemPresentInClinic !== null) {
          availableItems.push(dispatchItem);
        } else {
          unavailableItems.push(itemDetails);
        }
      }
    });

    this.alertUnavailableItems(unavailableItems);
  }

  alertUnavailableItems(unavailableItems: Array<any>) {
    if (unavailableItems.length > 0) {
      let itemListString = '';
      unavailableItems.forEach(item => {
        itemListString += item.code + ' - ' + item.name + '\n';
      });

      const msg =
        'The following items are currently not available in this clinic: \n' +
        itemListString;
      this.alertService.error(msg, false, false);
      alert(msg);
    }
  }

  findPackageItemInDispatchedEntities(dispatchEntities: FormArray, packageId) {
    return dispatchEntities.controls.find(
      fg =>
        fg.get('packageId').value === packageId ||
        fg.get('salesItemId').value === packageId
    );
  }

  // FOLLOW UP
  initFollowup() {
    return this.fb.group({ followupDate: '', remarks: '' });
  }

  setFollowup(followupDate?: string, remarks?: string) {
    if (this.followupConsultation) {
      this.followupConsultation.patchValue({
        followupDate: followupDate || '',
        remarks: remarks || '',
      });
    } else {
      this.followupConsultation = this.fb.group({
        followupDate: followupDate || '',
        remarks: remarks || '',
      });
    }
    return this.followupConsultation;
  }

  initFollowupDua() {
    return this.fb.group({
      patientId: this.akitaPatientAppQuery.getId(),
      patientVisitId: this.store.getPatientVisitRegistryId(),
      doctorId: this.store.userHasDoctorRole() ? this.store.getUserId() : '',
      clinicId: this.store.getClinicId(),
      followupDate: '',
      remarks: '',
      reminderStatus: this.fb.group({
        reminderSent: '',
        reminderSentTime: '',
        sentSuccessfully: '',
        remark: '',
        externalReferenceNumber: '',
      }),
    });
  }

  checkFollowUp(consultation) {
    if (consultation.consultationFollowup) {
      const currentPatientFollowUp = consultation.consultationFollowup;

      if (
        currentPatientFollowUp.followupDate === '' ||
        currentPatientFollowUp.followupDate === null ||
        !currentPatientFollowUp.followupDate ||
        currentPatientFollowUp.remarks === '' ||
        !currentPatientFollowUp.remarks
      ) {
        delete consultation.consultationFollowup;
      } else {
        consultation.consultationFollowup.followupDate =
          consultation.consultationFollowup.followupDate &&
          moment(
            consultation.consultationFollowup.followupDate,
            DISPLAY_DATE_FORMAT
          ).format(DISPLAY_DATE_FORMAT);
        consultation.consultationFollowup.clinicId = this.store.getClinicId();
        consultation.consultationFollowup.doctorId = currentPatientFollowUp.doctorId
          ? currentPatientFollowUp.doctorId
          : consultation.consultation.doctorId;
        consultation.consultationFollowup.patientId = this.akitaPatientAppQuery.getId();
        consultation.consultationFollowup.patientVisitId = this.store.getPatientVisitRegistryId();
      }
    }

    return consultation;
  }

  patchFollowUpToFormGroup(refEntity, consultationFollowUp: AbstractControl) {
    consultationFollowUp.reset();
    if (refEntity.consultationFollowup) {
      const c = refEntity.consultationFollowup;
      consultationFollowUp.patchValue({
        patientId: c.patientId,
        patientVisitId: c.patientVisitId,
        doctorId: c.doctorId,
        clinicId: c.clinicId,
        followupDate: c.followupDate,
        remarks: c.remarks,
        reminderStatus: this.fb.group({
          reminderSent: c.reminderStatus.reminderSent,
          reminderSentTime: c.reminderStatus.reminderSentTime,
          sentSuccessfully: c.reminderStatus.sentSuccessfully,
          remark: c.reminderStatus.remark,
          externalReferenceNumber: c.reminderStatus.externalReferenceNumber,
        }),
      });
    }
  }

  // VITAL FORM
  generateVitalForm(): FormGroup {
    const formGroup = new FormGroup(
      {
        weight: new FormControl(''),
        height: new FormControl(''),
        bmi: new FormControl(''),
        bp: this.fb.group(
          {
            systolic: new FormControl(''),
            diastolic: new FormControl(''),
          },
          AtLeastOneFieldValidator
        ),
        pulse: new FormControl(''),
        respiration: new FormControl(''),
        temperature: new FormControl(''),
        sa02: new FormControl(''),
        others: new FormControl(''),
      },
      AtLeastOneFieldValidator
    );
    return formGroup;
  }

  bindChargeItemsToDispatchitemEntities(formArr: FormArray) {
    let newPurchaseitems = [];
    if (formArr) {
      newPurchaseitems = formArr
        .getRawValue()
        .filter(function(payload) {
          if (
            payload.drugId === '' ||
            payload.drugId === undefined ||
            payload.drugId == null
          )
            return false;
          return true;
        })
        .map(payload => {
          if (payload.drugId === '') return null;
          const isService =
            payload.dose && payload.instruction && payload.instruction.code
              ? false
              : true;
          const isVaccine =
            payload.itemType === 'VACCINATION' ||
            (isService === true && payload.itemType === '')
              ? true
              : false;

          const tempItem = {
            itemId: payload.drugId,
            duration: payload.duration,
            quantity: payload.purchaseQty,
            // oriTotalPrice: Math.round(payload.oriTotalPrice),
            oriTotalPrice: Math.round(payload.oriTotalPrice * 100),
            batchNo: payload.batchNumber,
            expiryDate: payload.expiryDate,
            remarks: payload.remark,
            itemPriceAdjustment: {
              adjustedValue: Number(payload.priceAdjustment.adjustedValue),
              paymentType: payload.priceAdjustment.paymentType || 'DOLLAR',
              remark: payload.priceAdjustment.remark,
            },
            excludedCoveragePlanIds: payload.excludedCoveragePlanIds || [],
            packageId: !!payload.salesItemId ? '' : payload.packageId,
            salesItemId: payload.salesItemId,
            dispenseQty: payload.purchaseQty,
            dosageUom: payload.dose.uom || '',
            salesUom: payload.salesUom || '',
            remoteDelivery: payload.remoteDelivery,
            deliveryLocationId: payload.deliveryLocationId,
            sflData: payload.sflData
          };
          if (!isService) {
            tempItem['instruct'] = payload.instruction.code;
            tempItem['dosage'] = payload.dose.quantity;
            tempItem['dosageInstruction'] = payload.dosageInstruction.code;
            tempItem['routeOfAdministration'] = payload.routeOfAdministration;
          } else if (isVaccine) {
            tempItem['dosageInstruction'] = payload.dosageInstruction.code;
            tempItem['vaccineDosageAvailableList'] =
              payload.vaccineDosageAvailableList &&
              payload.vaccineDosageAvailableList.map(item => {
                return JSON.stringify(item);
              });
            tempItem['vaccinationInfo'] = payload.vaccinationInfo;
          }
          // const salesUOM = this.akitaChargeItemQuery.getSalesUOM(payload.drugId);

          // if (!!salesUOM) {
          //   tempItem['dosageUom'] = salesUOM;
          // }
          return tempItem;
        });
    }
    return newPurchaseitems;
  }

  isService(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (
        itemType === 'LABORATORY' ||
        itemType === 'SERVICE' ||
        itemType === 'VACCINATION'
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  // buildDispatchItemsForPackages() {
  //   this.akitaPackageItemQuery.getAll().map(packageItem => {
  //     return packageItem.subItems.map(subItem => {
  //       // const isService = payload.value.dose && payload.value.instruction.code ? false : true;
  //       // const tempItem = {
  //       //   itemId: subItem.drugId,
  //       //   duration: payload.value.duration,
  //       //   quantity: payload.value.purchaseQty,
  //       //   oriTotalPrice: Math.round(payload.value.oriTotalPrice),
  //       //   batchNo: payload.value.batchNumber,
  //       //   expiryDate: payload.value.expiryDate,
  //       //   remarks: payload.value.remark,
  //       //   itemPriceAdjustment: {
  //       //     adjustedValue: Math.round(payload.value.priceAdjustment.adjustedValue),
  //       //     paymentType: payload.value.priceAdjustment.paymentType || 'DOLLAR',
  //       //     remark: payload.value.priceAdjustment.remark
  //       //   },
  //       //   excludedCoveragePlanIds: payload.value.excludedCoveragePlanIds || []
  //       // };
  //       // if (!isService) {
  //       //   tempItem['instruct'] = payload.value.instruction.code;
  //       //   tempItem['dosageUom'] = payload.value.dose.uom;
  //       //   tempItem['dosage'] = payload.value.dose.quantity;
  //       //   tempItem['dosageInstruction'] = payload.value.dosageInstruction.code;
  //       // }
  //       return [];
  //     });
  //   });
  // }

  // Validation for Diagnosis
  // Only works for FormArray with FormControl as direct child
  emptyFormArrayValidator(validationKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const controlArray = <FormArray>control;
      const controlArrayValue = controlArray.value;
      let isValid = false;

      // loop through the Array
      controlArrayValue.forEach((value, key) => {
        // loop through the content of the Array which could be object
        for (const innerKey of Object.keys(value)) {
          if (innerKey === validationKey) {
            if (value[innerKey] && value[innerKey].length > 0) {
              isValid = true;
              return;
            }
          }
        }
      });
      return isValid ? null : { formArrayRequired: { value: control.value } };
    };
  }

  clearDrugQtyBalance() {
    this.dispatchItemService.clearDrugQtyBalance();
  }

  updateCaseInStore(payload, visitId: string) {
    const caseOverview = createCase(payload);
    this.dispatchItemService.clearDrugQtyBalance();
    this.akitaCaseVisitStore.reset();
    this.akitaCaseVisitStore.update(caseOverview);
    if (
      payload.patientVisitEntities &&
      payload.patientVisitEntities.length > 0
    ) {
      const visitEntitiesForStore = this.getPatientVisitentiesForCaseVisitStore(
        payload.patientVisitEntities
      );
      this.akitaCaseVisitStore.set(visitEntitiesForStore);
      this.akitaCaseVisitStore.setActive(visitId);
      // this.updateInventoryForPackagePayloadItems();
      // this.updateAlleryOfPackages();
    } else this.akitaCaseVisitStore.setLoading(false);
  }

  updateVisitInStore(visit, dispatchItemFormArray) {
    const visitEntitiesForStore = this.mapPackageInfo(visit);
    this.akitaCaseVisitStore.update(visit.visitId, visitEntitiesForStore);
    this.akitaCaseVisitQuery.selectActive(visit.visitId);
    this.dispatchItemService.clearDrugQtyBalance();
    // this.updateInventoryForPackagePayloadItems();
    this.updateInventoryAndAllergyForVisit([], true);
    if (
      !!dispatchItemFormArray &&
      !!visit.medicalReferenceEntity.dispatchItemEntities &&
      visit.medicalReferenceEntity.dispatchItemEntities.length
    ) {
      const caseID = this.akitaCaseVisitQuery.getValue().caseId;
      this.updateDispatchItems(
        caseID,
        visit.medicalReferenceEntity.dispatchItemEntities,
        dispatchItemFormArray
      );

    }
  }

  updateDispatchItems(caseID, dispatchItems, dispatchItemFormArray) {
    this.getPriceInventoryInformation(caseID, dispatchItems).subscribe(
      priceInventoryInfo => {
        this.reloadDispatchItemsToFormArray(
          dispatchItems,
          priceInventoryInfo,
          dispatchItemFormArray,
          true
        );
      }
    );
  }

  getPriceItemApiPayload(dispatchItemEntities) {
    return dispatchItemEntities.map(item => {
      return this.caseChargeFormService.buildChargeDetailsItem(
        item.itemId,
        item.excludedCoveragePlanIds,
        item.quantity
      );
    });
  }

  getPriceInventoryInformation(caseId, dispatchItems) {
    const payload = this.getPriceItemApiPayload(dispatchItems);
    return this.apiCaseManagerService.getCaseItemPrices(caseId, {
      chargeDetails: payload,
    });
  }

  reloadDispatchItemsToFormArray(
    dispatchItemEntities,
    caseItemPrice,
    dispatchItemEntitiesFormArray: FormArray,
    disableNotificationFormArrayLoaded?: boolean
  ) {
    this.patchDispatchItemsToFormArray(
      dispatchItemEntities,
      caseItemPrice,
      dispatchItemEntitiesFormArray
    );

    // if(!disableNotificationFormArrayLoaded){
    // this.setDispatchItemsLoaded(dispatchItemEntitiesFormArray.value);
    this.setDispatchItemsLoaded(this.akitaCaseVisitQuery.getActive().visitId);
    //  }
    this.caseChargeFormService.chargeItemDetails = dispatchItemEntitiesFormArray;
  }

  setConsultationDataForApi(
    consultation,
    visitManagementFormGroup,
    pcnDetails,
    hsgPlan?
  ) {
    const conFormGroup = visitManagementFormGroup.get(
      'consultationFormGroup'
    ) as FormGroup;
    consultation = conFormGroup.getRawValue();

    if (pcnDetails.assessment && pcnDetails.assessment.assessments) {
      for (let key of Object.keys(pcnDetails.assessment.assessments)) {
        if (DATE_ASSESSMENT_CODES.includes(key) && pcnDetails.assessment.assessments[key]) {
          if (typeof pcnDetails.assessment.assessments[key] !== 'object' && !pcnDetails.assessment.assessments[key].includes('T')) {
            pcnDetails.assessment.assessments[key] = moment(pcnDetails.assessment.assessments[key], 'DD-MM-YYYY').toDate();
          }
        }
      }
    }

    consultation.cdmpDetails = pcnDetails;
    if (hsgPlan) {
      consultation.hsgHealthPlan = hsgPlan;
    }

    delete consultation['vitalSigns'];
    consultation.dispatchItemEntities = this.bindChargeItemsToDispatchitemEntities(
      visitManagementFormGroup
        .get('consultationFormGroup')
        .get('dispatchItemEntities') as FormArray
    );

    const packageFromStore = this.akitaCaseVisitQuery.getPackagesForPayload();

    consultation.packages = packageFromStore.map(packageItem => {
      const packageMapper = new PackageMapper(packageItem);
      const packageForPayload = packageMapper.getPackageForPayload();
      return packageForPayload;
    });
    consultation = this.flattenSnomeds(consultation);
    consultation = this.flattenDiagnosis(consultation);
    consultation = this.flattenSecondaryDiagnosis(consultation);
    consultation = this.checkConsultation(consultation);
    consultation = this.checkMemos(consultation);
    consultation = this.checkPatientReferral(consultation);
    consultation = this.fixRemoteDeliveryDetails(consultation)
    consultation = MedicalCertificateItemsArrayComponent.checkMedicalCertificates(
      consultation
    );
    consultation = this.checkFollowUp(consultation);
    return consultation;
  }

  setConsultationDataForPaymentAPI(
    paymentRequestInfo,
    visitManagementFormGroup,
    chargeFormGroup,
    pcnDetails
  ) {
    if (paymentRequestInfo) {
      paymentRequestInfo = this.setConsultationDataForApi(
        paymentRequestInfo,
        visitManagementFormGroup,
        pcnDetails
      );

      const maxUsageValue = chargeFormGroup
        .get('coverageLimitFormGroup')
        .get('coverageLimitArray').value;
      if (maxUsageValue) {
        paymentRequestInfo.planMaxUsage = maxUsageValue.map(coverage => ({
          medicalCoverageId: coverage.medicalCoverageId,
          planId: coverage.planId,
          name: coverage.name,
          limit: coverage.updatedLimit,
          remarks: coverage.referenceRemarks,
          updated: coverage.updated,
        }));
      }

      paymentRequestInfo.visitTimeChit = {
        from: moment(
          chargeFormGroup.get('printFormGroup').get('timeChitFrom').value || '',
          DB_FULL_DATE_TIMEZONE
        ).format(DB_FULL_DATE_FORMAT),
        to: moment(
          chargeFormGroup.get('printFormGroup').get('timeChitTo').value || '',
          DB_FULL_DATE_TIMEZONE
        ).format(DB_FULL_DATE_FORMAT),
      };
    }
    return paymentRequestInfo;
  }

  onBtnCollectPaymentClicked(
    visitManagementFormGroup,
    nextTabIndex,
    pcnDetails
  ) {
    if (visitManagementFormGroup.get('consultationFormGroup').valid) {
      const chargeFormGroup = visitManagementFormGroup.get('chargeFormGroup');
      const consultationFormGroup = visitManagementFormGroup.get(
        'consultationFormGroup'
      );

      let paymentRequestInfo = {
        ...(<FormGroup>(
          visitManagementFormGroup.get('consultationFormGroup')
        )).getRawValue(),
      };
      paymentRequestInfo.patientReferral.patientReferrals = consultationFormGroup
        .get('patientReferral')
        .get('patientReferrals').value;

      // Do checking for Data
      paymentRequestInfo = this.setConsultationDataForPaymentAPI(
        paymentRequestInfo,
        visitManagementFormGroup,
        chargeFormGroup,
        pcnDetails
      );
      const visitId = this.store.getPatientVisitRegistryId();
      // Call Payment
      this.apiPatientVisitService
        .payment(this.store.getPatientVisitRegistryId(), paymentRequestInfo)
        .pipe(
          debounceTime(INPUT_DELAY),
          finalize(() => {
            this.isRequesting = false;
          })
        )
        .subscribe(
          res => {
            this.paymentService.setConsultationInfo(null);
            if (res.payload.visitStatus === 'PAYMENT') {
              this.store.setVisitStatus('PAYMENT');
              this.store.setVisitStatusRefresh('PAYMENT');
            }

            // update case visit store
            const dispatchItemFormArray = visitManagementFormGroup
              .get('consultationFormGroup')
              .get('dispatchItemEntities') as FormArray;
            this.updateVisitInStore(res.payload, dispatchItemFormArray);

            // Switch to Printing Tab
            // this.store.setPatientVisitRegistryId(this.store.getPatientVisitRegistryId(), true); // --> To refresh
            this.toPayment.next(nextTabIndex);
          },
          err => this.alertService.error(JSON.stringify(err.message))
        );
    }
  }

  setToPayment(tabName) {
    this.toPayment.next(tabName);
  }

  getToPayment() {
    return this.toPayment;
  }

  deletePackageItem(uniquePackageId: string) {
    const packageItem = this.akitaCaseVisitQuery.getPackageFromActiveVisit(
      uniquePackageId
    );
    if (packageItem) {
      this.updateDrugQtyOnPackageDelete(packageItem);
      this.akitaCaseVisitQuery.deletePackageItem(uniquePackageId);
    } else {
      console.warn(
        `Package you are trying to delete is not found in case visit store`
      );
    }
  }

  updateDrugQtyOnPackageDelete(packageItem: IPackageItem) {
    packageItem.dispatches
      .filter(item => item.inventoried)
      .forEach(subItem => {
        this.dispatchItemService.deleteDrugSubscriptionFromBatch(
          subItem.itemId,
          subItem.batchNo,
          subItem.drugDispenseShortId
        );
      });
  }

  getAllInventoriedItemInAllPackages(): IPackageItem[] {
    const utilizedPackage = this.akitaCaseVisitQuery.getAllInventoriedSubItemPackageForActiveVisit();
    return utilizedPackage;
  }

  getPatientVisitentiesForCaseVisitStore(
    patientVisitEntities: PatientVisitEntities[]
  ): PatientVisitEntities[] {
    return patientVisitEntities.map(visit => {
      return this.mapPackageInfo(visit);
    });
  }

  mapPackageInfo(visit) {
    const packages = visit.medicalReferenceEntity.packages.map(packageItem => {
      const packageMapper = new PackageMapper(packageItem);
const packageForVisitStore = packageMapper.getPackageForStore();
      const masterItem: Partial<ChargeItem> = this.akitaChargeItemQuery.getEntity(
        packageItem.itemRefId
      );
      packageForVisitStore.priceAdjustmentType =
        masterItem.priceAdjustmentType || PRICE_ADJUSTMENT_TYPE.FIXED;
      return packageForVisitStore;
    });
    return {
      ...visit,
      medicalReferenceEntity: {
        ...visit.medicalReferenceEntity,
        packages,
      },
    };
  }

  private updateInventoryAndAllergyForVisit(
    inventoriedItemIds = [],
    isUpdate = false
  ) {
    const inventoriedSubItemIDs = !!inventoriedItemIds.length
      ? inventoriedItemIds
      : this.getUniqueInventoriedSubItems();
    if (!!inventoriedSubItemIDs.length) {
      const payload = this.buildCaseChargePayload(inventoriedSubItemIDs);
      const caseID = this.akitaCaseVisitQuery.getValue().caseId;
      this.apiCaseManagerService
        .getCaseItemPrices(caseID, payload)
        .subscribe(data => {
          if (!!data.payload) {
            this.updateAllergyAndInventory(
              data.payload.inventoryData,
              isUpdate
            );
          }
        });
    }
  }

  getUniqueInventoriedSubItems(): string[] {
    const subItemIds = this.akitaCaseVisitQuery
      .getPackages()
      .map(packageItem => {
        return packageItem.dispatches.map(subItem => subItem.itemId);
      });
    const flattenSubItemIds = flat(subItemIds);
    const uniqueArray = new Set(flattenSubItemIds);
    return Array.from(uniqueArray);
  }

  showAllergyMessageForPackages() {
    const allergicSubItems = this.getUniqueAllergicSubItemsNames();
    allergicSubItems.forEach(name => this.showAllergyWarningMessage(name));
  }

  showAllergyWarningMessage(allergyMsg?: string, adrMsg?: string) {
    if (!this.warningModalService.isModalAlive()) {
      const initialState = {
        messages: [`Patient is allergic to following medicines: `],
      };
      this.warningModalService.show(WarningModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
      });

      if(allergyMsg) {
        this.warningModalService.setMessage(allergyMsg);
      }

      if (adrMsg) {
        this.warningModalService.setADRMessage('Patient has Adverse Drug Reaction to Following Drugs: ');
        this.warningModalService.setADRMessage(adrMsg);
      }

    } else {
      const warningModal = this.warningModalService.getModal();
      if (!!warningModal) {
        const currentMessages = warningModal.content.messages;
        if (allergyMsg && !currentMessages.includes(allergyMsg)) {
          this.warningModalService.setMessage(allergyMsg);
        }
        const currentADRMessages = warningModal.content.adrmessages;
        const adrInitialMsg = 'Patient has Adverse Drug Reaction to Following Drugs: '
        if (adrMsg && !currentADRMessages.includes(adrInitialMsg)) {
          this.warningModalService.setADRMessage(adrInitialMsg);
        }

        if (adrMsg && !currentADRMessages.includes(adrMsg)) {
          this.warningModalService.setADRMessage(adrMsg);
        }
      }
    }
  }

  showPackageAvailableWarningMessage(msg: string) {
    if (!this.warningModalService.isModalAlive()) {
      const initialState = {
        messages: [`Patient has package for item: `],
      };
      this.warningModalService.show(WarningModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
      });
      this.warningModalService.setMessage(msg);
    } else {
      const warningModal = this.warningModalService.getModal();
      if (!!warningModal) {
        const currentMessages = warningModal.content.messages;
        if (!currentMessages.includes(msg)) {
          this.warningModalService.setMessage(msg);
        }
      }
    }
  }

  showIcdTypeWarningMessage() {
    if (!this.warningModalService.isModalAlive()) {
      const initialState = {
        messages: [`Diagnosis code does not match the drug type selected. `],
      };
      this.warningModalService.show(WarningModalComponent, {
        initialState,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
      });
      this.warningModalService.setMessage('');
    } else {
      const warningModal = this.warningModalService.getModal();
      if (!!warningModal) {
        const currentMessages = warningModal.content.messages;
        if (!currentMessages.includes('')) {
          this.warningModalService.setMessage('');
        }
      }
    }
  }

  getUniqueAllergicSubItemsNames(): string[] {
    const subItemName = this.akitaCaseVisitQuery
      .getPackages()
      .map(packageItem => {
        return packageItem.dispatches
          .filter(subItem => subItem.isPatientAllergic)
          .map(subItem => subItem.itemName);
      });
    const flattenSubItemNames = flat(subItemName);
    const uniqueArray = new Set(flattenSubItemNames);
    return Array.from(uniqueArray);
  }

  updateAllergyAndInventory(inventoryData: any[], isUpdate = false) {
    const subItemIds = this.getUniqueInventoriedSubItems();
    this.updateInventoryForPackagePayloadItems(inventoryData, isUpdate);
    this.checkAllergyForSubItems(subItemIds);
  }

  checkAllergyForSubItems(ids: string[]) {
    this.apiPatientInfoService
      .checkAllergies(this.akitaPatientAppQuery.getId(), ids)
      .pipe(debounceTime(INPUT_DELAY))
      .subscribe(
        res => {
          if (res.payload && res.payload.allergies) {
            this.updatePackageAllergy(res.payload.allergies, ids);
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error['message']));
        }
      );
  }

  updatePackageAllergy(allergies: any, subItemIds: string[]) {
    // const drugAllergies: Array<string> = allergies;
    // const subItemSet = new Set(subItemIds);
    const drugAllergySet = new Set(allergies.allergies);

    const seenPackages: Map<string, IPackageItem> = new Map();
    const allergicSubItem = [];

    subItemIds.forEach(subItemId => {
      const isSubItemAllergic = drugAllergySet.has(subItemId);
      const packageItems = this.akitaCaseVisitQuery.getPackageBySubItemId(
        subItemId
      );
      packageItems.forEach(pkg => {
        let updatedPackageItem;
        if (seenPackages.has(pkg.packageId)) {
          const seenPackageItem = seenPackages.get(pkg.packageId);
          updatedPackageItem = this.getPackageWithAllergyInfo(
            seenPackageItem,
            subItemId,
            isSubItemAllergic
          );
        } else {
          updatedPackageItem = this.getPackageWithAllergyInfo(
            pkg,
            subItemId,
            isSubItemAllergic
          );
        }
        const { packageItem, subItem } = updatedPackageItem;
        if (isSubItemAllergic) {
          allergicSubItem.push(subItem);
        }
        seenPackages.set(packageItem.packageId, packageItem);
      });
    });

    seenPackages.forEach(packageItem => {
      this.akitaCaseVisitQuery.updatePackageInActiveVisit(packageItem);
    });

    if (!!allergicSubItem.length) {
      allergicSubItem.forEach(({ itemName }) => {
        this.showAllergyWarningMessage(itemName);
      });
    }
  }

  private getPackageWithAllergyInfo(
    pkg: IPackageItem,
    subItemId: string,
    isSubItemAllergic: boolean
  ): { packageItem: IPackageItem; subItem: any } {
    let allergicSubItem = {};
    const tempPackage = {
      ...pkg,
      dispatches: pkg.dispatches.map(subItem => {
        if (subItem.itemId === subItemId) {
          allergicSubItem = {
            itemCode: subItem.itemCode,
            itemName: subItem.itemName,
          };
          return {
            ...subItem,
            isPatientAllergic: isSubItemAllergic,
          };
        } else {
          return subItem;
        }
      }),
    };

    return {
      packageItem: tempPackage,
      subItem: allergicSubItem,
    };
  }

  mapPackageForFirstVisitItems(packageItems: any[]): IPackageItem[] {
    return packageItems.map(item => {
      const uniquePackageId = generate();
      return {
        itemRefId: item.id,
        code: item.code,
        name: item.name,
        packageId: uniquePackageId,
        packageQty: 1,
        purchasePrice: item.sellingPrice.price,
        purchaseDate: moment().format(DISPLAY_DATE_FORMAT),
        expireDate: '',
        packageType: item.packageType || PACKAGE_TYPE.PRE,
        // status: item.status,
        itemIsValid: true,
        priceAdjustmentType:
          item.priceAdjustmentType || PRICE_ADJUSTMENT_TYPE.FIXED,
        excludedPlans: [],
        noInventoryInfo: false,
        //fields required by frontend only
        dispatches: item.subItems.map(subItem => {
          const masterItem: Partial<ChargeItem> = this.akitaChargeItemQuery.getEntity(
            subItem.itemRefId
          );
          const finalSubItem: IDispaches = {
            itemId: masterItem.id,
            itemCode: masterItem.code,
            itemName: masterItem.name,
            duration: 0,
            dosage: 0,
            quantity: 1,
            oriTotalPrice: subItem.sellingPrice.price,
            itemPriceAdjustment: {
              adjustedValue: 0,
              paymentType: 'DOLLAR',
              remark: '',
            },
            batchNo: '',
            expiryDate: '',
            excludedCoveragePlanIds: [],
            utilize: false,
            payable: false,
            utilizedDate: '',
            inventoried: masterItem.inventoried,
            visitId: '',
            salesItemId: '',
            drugDispenseShortId: generate(),
            isAvailable: true,
            isPatientAllergic: false,
            parentItemPackageId: uniquePackageId,
            dosageInstruction: '',
            vaccinationInfo: {
              givenDate: '',
              vaccineDosage: undefined,
              administrator: {
                regNo: '',
                name: '',
                profession: 'DOCTOR'
              },
              administrationInfo: {
                site: '',
                route: '',
                notGiven: false,
                reason: ''
              },
              multiVaccineSubItems: [],
              vaccinationSchedules: [{
                vaccineId: '',
                doseId: '',
                scheduledDate: '',
                scheduledTime: ''
              }]
            },
            routeOfAdministration: '',
            remarks: '',
          };

          return finalSubItem;
        }),
      } as IPackageItem;
    });
  }

  private buildCaseChargePayload(ids: string[]) {
    const caseItem = {
      chargeDetails: [],
    };
    ids.forEach(id => {
      caseItem.chargeDetails.push(
        this.caseChargeFormService.buildChargeDetailsItem(id, [], 1)
      );
    });

    return caseItem;
  }

  checkPackageItemInventory(packageItems: IPackageItem) {
    this.akitaCaseVisitQuery.addPackageToActiveVisit(packageItems);

    const inventoriedSubItems = packageItems.dispatches.filter(
      item => item.inventoried
    );

    if (inventoriedSubItems.length) {
      const ids = inventoriedSubItems.map(item => item.itemId);
      this.updateInventoryAndAllergyForVisit(ids);
    } else {
      const subItemIds = this.getUniqueInventoriedSubItems();
      this.checkAllergyForSubItems(subItemIds);
    }
  }

  private updateInventoryForPackagePayloadItems(
    inventoryData: any[],
    isUpdate: boolean
  ) {

    const inventoriedPackages: IPackageItem[] = this.getAllInventoriedItemInAllPackages();
    inventoriedPackages.forEach(pkg => {
      let packageItem = { ...pkg };
      const inventoriedSubItems = packageItem.dispatches.filter(
        subItem => subItem.inventoried
      );

      const subItemsWithAvailability = inventoriedSubItems.map(
        (inventoriedSubItem: IDispaches) => {
          const copiedInventoriedSubItem = { ...inventoriedSubItem };
          const caseItemsInventories = inventoryData;
          const caseItemInventories = caseItemsInventories.filter(inventory => {
            return inventory.itemId === copiedInventoriedSubItem.itemId;
          });

          const batchMasterData = caseItemInventories.find(inventory => {
            return inventory.remainingQuantity > 0;
          });

          if (caseItemInventories.length && !!batchMasterData) {
            copiedInventoriedSubItem.batchNo = copiedInventoriedSubItem.batchNo
              ? copiedInventoriedSubItem.batchNo
              : batchMasterData.batchNo;
            copiedInventoriedSubItem.expiryDate = copiedInventoriedSubItem.expiryDate
              ? copiedInventoriedSubItem.expiryDate
              : moment(
                batchMasterData.expireDate,
                INVENTORY_DATE_FORMAT
              ).format(DISPLAY_DATE_FORMAT);

            if (
              !this.dispatchItemService.drugBalanceAvailable(
                copiedInventoriedSubItem.itemId
              )
            ) {
              this.dispatchItemService.addNewDrugQtyBalance(
                copiedInventoriedSubItem.itemId,
                caseItemInventories
              );
            }
            // here setting dispense qty to 0 because we only dispense when we select the item from subitem package list
            this.dispatchItemService.setDrugDispenseQtyFromBatch(
              copiedInventoriedSubItem.itemId,
              copiedInventoriedSubItem.batchNo,
              copiedInventoriedSubItem.utilize ? ((copiedInventoriedSubItem.visitId  && copiedInventoriedSubItem.visitId === this.akitaCaseVisitQuery.getActiveVisitId()) ? 1 :0) : 0,
              copiedInventoriedSubItem.drugDispenseShortId
            );
            if (isUpdate && copiedInventoriedSubItem.utilize) {
              const isAvailable =
                this.dispatchItemService.getRemainingQty(
                  copiedInventoriedSubItem.itemId
                ) > -1;
              copiedInventoriedSubItem.isAvailable = isAvailable;
              packageItem.itemIsValid = isAvailable;
            }
          } else {
            // copiedInventoriedSubItem.isAvailable = false;
            // packageItems.itemIsValid = false;
            // packageItems.noInventoryInfo = true;
          }
          return copiedInventoriedSubItem;
        }
      );

      const nonInventoriedItems = packageItem.dispatches.filter(
        item => !item.inventoried
      );
      const finalSubItems = nonInventoriedItems.concat(
        subItemsWithAvailability
      );
      packageItem = {
        ...packageItem,
        dispatches: finalSubItems,
      };
      this.akitaCaseVisitQuery.updatePackageInActiveVisit(packageItem);
    });
  }

  onSearchClicked(itemsFormArray: FormArray) {
    let selectedItems = [];
    if (!this.pvmTabs.isPaymentState()) {
      const initialState = {
        title: 'Advanced Search',
        itemsFormArray: <FormArray>itemsFormArray,
      };

      this.bsModalRef = this.modalService.show(ConsultationSearchComponent, {
        initialState,
        class: 'modal-lg',
        keyboard: false,
      });

      this.bsModalRef.content.event.subscribe(data => {
        if (data !== 'close' && data.length > 0) {
          data.forEach(item => {
            const uniqueId = generate();
            selectedItems.push({
              shortId: uniqueId,
            });
            this.onDrugSelect(itemsFormArray, item, uniqueId);
          });

          this.advancedSearchLoaded.next(selectedItems);
        }

        this.bsModalRef.content.event.unsubscribe();
        this.bsModalRef.hide();

        // this.advancedSearchLoaded.next(null);
        // this.advancedSearchLoaded.unsubscribe();
      });
    }
  }

  onDrugSelect(itemsFormArray, selectedOption?, shortid?) {
    let option = selectedOption ? { ...selectedOption } : undefined;

    let mappedPackageItems: IPackageItem[] = [];
    if (option.itemType.toLowerCase() === 'package') {
      mappedPackageItems = this.mapPackageForFirstVisitItems([option]);
      option.packageId = mappedPackageItems[0].packageId;
      this.checkPackageItemInventory(mappedPackageItems[0]);
    }

    itemsFormArray = this.caseChargeFormService.buildDrugDispatchDetails(
      option,
      false,
      shortid
    );

    itemsFormArray.markAsDirty();
    this.caseChargeFormService.chargeItemDetails.markAsDirty();

    if (option.itemType.toLowerCase() === 'package') {
      const formGroupForPackage = this.findPackageItemInDispatchedEntities(
        itemsFormArray,
        option.packageId
      );
      if (!!formGroupForPackage) {
        this.changeDispatchItemPropertiesForPackage(
          formGroupForPackage as FormGroup,
          mappedPackageItems[0]
        );
      }
    }
  }

  changeDispatchItemPropertiesForPackage(
    formGroupForPackage: FormGroup,
    mappedPackageItem: IPackageItem
  ) {
    formGroupForPackage.get('purchaseQty').disable();
    formGroupForPackage.get('packageId').setValue(mappedPackageItem.packageId);
    if (
      mappedPackageItem.priceAdjustmentType !== PRICE_ADJUSTMENT_TYPE.PACKAGE
    ) {
      formGroupForPackage.get('adjustedTotalPrice').disable();
    }
  }

  mapPackageData(packages: any[]) {
    return packages.map(pckg => {
      return {
        ...pckg,
        useBy: pckg.expireDate
          ? moment(pckg.expireDate, DB_FULL_DATE_FORMAT).format(
            DISPLAY_DATE_FORMAT
          )
          : '',
        purchaseDate: moment(pckg.purchaseDate, DB_FULL_DATE_FORMAT).format(
          DISPLAY_DATE_FORMAT
        ),
        completed: `${pckg.dispatches.filter(item => item.utilize).length} / ${
          pckg.dispatches.length
        }`,
        dispatches: pckg.dispatches.map(subItem => {
          return {
            ...subItem,
            utilizedDate: subItem.utilizedDate
              ? moment(subItem.utilizedDate, DB_FULL_DATE_FORMAT).format(
                DISPLAY_DATE_FORMAT
              )
              : '',
          };
        }),
      };
    });
  }

  reloadPVM() {
    this.router.navigateByUrl(this.router.url, {
      state: {
        reload: true,
      },
    });
  }

  getSelectedChart(): any {
    return this.selectedChart;
  }

  setSelectedChart(chart: any) {
    this.selectedChart = chart;
  }

  filterForHsgConfirmation(dispatchItemEntities: any[]): Observable<{ whitelistedItem: any, chargeItem: ChargeItem }[]> {
    let attachedMedicalCoverages$ = this.attachedMedCovQuery.attachedMedicalCoverages$.pipe(first());
    let caseCoverage$ = this.akitaCaseVisitQuery.selectCaseCoverage().pipe(first());
    let whitelistedItemInfo$ = this.whitelistedItemService.withWhitelistedItems(dispatchItemEntities.map(value => value.drugId));
    return combineLatest([whitelistedItemInfo$, caseCoverage$, attachedMedicalCoverages$])
      .pipe(map(([whitelistedItemInfo, caseCoverage, attachedMedicalCoverages]) => {
        return (whitelistedItemInfo as any)
          .filter(a => !!a)
          .filter(({whitelistedItem, chargeItem}) => {
            if (!whitelistedItem) {
              return false;
            }
            if (whitelistedItem.itemRefId) return false;
            if (!whitelistedItem.declarationRequired) return false;

            let medicalCoverageIds = caseCoverage.map(({medicalCoverageId}) => medicalCoverageId);
            let coverageInAllowedCoverages = medicalCoverageIds.some(medicalCoverageId => coverageCappedAllowed(whitelistedItem, medicalCoverageId));
            if (coverageInAllowedCoverages) return false;

            return true;
          })
      }))
  }
}


export function mulitplierValidator(multiplier: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return Math.round(control.value * 1000) % Math.round(multiplier * 1000) !==
    0
      ? { multiplierError: { multiplier: multiplier } }
      : null;
  };
}
