import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';

import { ApiPatientInfoService } from '../../../../services/api-patient-info.service';

import DatePickerConfig from '../../../../objects/DatePickerConfig';

import { BsModalRef } from 'ngx-bootstrap';

import { AlertService } from '../../../../services/alert.service';

import { StoreService } from '../../../../services/store.service';

import * as moment from 'moment'
import { DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';
import { AkitaPatientAppQuery } from '../../../../services/states/akita-patient/akita-patient-app.query';

import { ApiPatientVisitService } from '../../../../services/api-patient-visit.service';
import { AkitaClinicQuery } from '../../../../services/states/akita-clinic.query';
import { Clinic } from '../../../../objects/response/Clinic';

@Component({
  selector: 'app-patient-vaccine-details-modal',
  templateUrl: './patient-vaccine-details-modal.component.html',
  styleUrls: ['./patient-vaccine-details-modal.component.scss'],
})
export class PatientVaccineDetailsModalComponent implements OnInit, OnDestroy {
  public addFormGroup: FormGroup;
  public isEdit: boolean = false;
  public dosageMin = 1;
  dosageUom: string;
  title: string;
  isPatientDetails: boolean;
  patientId: string;
  isCareReport: boolean = false;
  patientGroupId: string;

  vaccinations: Array<any> = [];
  allVaccinations: Array<any> = [];
  nextDoseVaccine = [];
  doctors: any[];
  routeOfAdministrations: any[] = [];
  bodySites: any[];
  vaccineSubItems: AbstractControl[];
  dose = [];
  disableSaveBtn: boolean = false;
  administratorTypes: any[] = [
    {name: 'Dentist', value: 'DENTIST'},
    {name: 'Doctor', value: 'DOCTOR'},
    {name: 'Pharmacist', value: 'PHARMACIST'},
    {name: 'Nurse', value: 'NURSE'},
  ];
  doseTypes: any[] = [
    {code: "PRIMARY", display: "Primary regimen"}, 
    {code: "BOOSTER", display: "Additional dose/booster"}
  ];
  isNotGiven: boolean = true;
  visitId: string = null;
  INPUT_DELAY_ITEM = 500;
  isBodySiteRequired: boolean = false;

  givenDateDatePickerConfig: DatePickerConfig;
  nextDoseDateDatePickerConfig: DatePickerConfig;
  vaccineId: AbstractControl;
  doseId: AbstractControl;
  scheduledDate: AbstractControl;
  scheduledTime: AbstractControl;
  administrator: AbstractControl;

  availableTimesDropDownList: Array<string>;
  availableTimesDropDownListForAdd: Array<string>;

  isExit: boolean = false;
  exemptionConditions: any[] = [];
  codesTypeahead = new Subject<string>();
  private componentDestroyed: Subject<void> = new Subject();
  
  isCovidVaccine: boolean = false;
  covidVaccineSddCodes: any[] = [];
  covidVaccineConditions: any[] = [];

  sddCodes: Array<any> = [];
  hsgVaccineSddList: Array<any> = [];

  constructor(
    public bsModalRef: BsModalRef,
    private apiCmsManagementService: ApiCmsManagementService,
    private apiAppointmentsService: ApiAppointmentsService,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private apiPatientVisitService: ApiPatientVisitService,
    private alertService: AlertService,
    private store: StoreService,        
    private akitaClinicQuery: AkitaClinicQuery,
  ) {
    this.givenDateDatePickerConfig = new DatePickerConfig(
      null,
      new Date(),
      null,
      'bottom',
      'none'
    );
    this.nextDoseDateDatePickerConfig = new DatePickerConfig(
      null,
      null,
      new Date(),
      'top',
      'top'
    );

    this.vaccinations = this.store.vaccinationList;
    this.allVaccinations = this.store.vaccinationList;
    this.sddCodes = this.store.sddCodeList;
    this.hsgVaccineSddList = this.store.hsgVaccineSddList;
  }

  ngOnInit() {
    this.store.listDoctorsByClinic();
    this.getRouteOfAdministrations();
    this.getBodySites();
    this.initialiseValues();
    this.subscribeFormGroupChange();
    this.onFilterInputChanged();
  }

  initialiseValues() {
    this.vaccineId = this.addFormGroup.get('vaccinationSchedules').get('vaccineId');
    this.doseId = this.addFormGroup.get('vaccinationSchedules').get('doseId');

    this.scheduledDate = this.addFormGroup.get('vaccinationSchedules').get('scheduledDate');
    this.scheduledTime = this.addFormGroup.get('vaccinationSchedules').get('scheduledTime');

    this.doctors = this.store.doctorList;
    const administrator = this.addFormGroup.get('administrator') as FormGroup;
    const administratorInfo = this.addFormGroup.get('administrationInfo');

    this.store.mhcpVaccineMapping.filter(vMap => vMap.covidVaccine).forEach(vMap => this.covidVaccineSddCodes.push(...vMap.sddCodes));
    
    this.vaccinations = this.vaccinations.map(obj => {
      const sddCodeObj = this.sddCodes.find(sdd => sdd.code === obj.sddCode);
    
      return {
        ...obj,
        sddCode: sddCodeObj ? sddCodeObj.code : null,
        sddTerm: sddCodeObj ? sddCodeObj.term : null
      };
    });

    if (this.isCareReport) {
      this.vaccinations = this.vaccinations.filter(obj => this.hsgVaccineSddList.includes(obj.sddCode));
    }

    this.allVaccinations = this.allVaccinations.map(obj => {
      const sddCodeObj = this.sddCodes.find(sdd => sdd.code === obj.sddCode);
    
      return {
        ...obj,
        sddCode: sddCodeObj ? sddCodeObj.code : null,
        sddTerm: sddCodeObj ? sddCodeObj.term : null
      };
    });

    if (this.isCareReport) {
      this.allVaccinations = this.allVaccinations.filter(obj => this.hsgVaccineSddList.includes(obj.sddCode));
    }

    if (this.isEdit) {
      const vaccineId = this.addFormGroup.get('vaccineId');
      const vaccine = this.allVaccinations.find(item => item.id === vaccineId.value);

      if (vaccine) {
        this.dosageUom = vaccine.dosageUom;
        this.populateDose(vaccine);
        this.populateSubItems(vaccine);

        this.addFormGroup.get('doseId').markAsTouched();
        vaccineId.disable();

        this.addFormGroup.get('givenDate').markAsTouched();
        this.addFormGroup.get('administrationInfo').get('route').markAsTouched();
      }

      const scheduleVvaccine = this.allVaccinations.find(item => item.id === this.vaccineId.value);
      if (scheduleVvaccine) {
        this.nextDoseVaccine = [...scheduleVvaccine.dosages];
      }

      if (!this.scheduledDate) {
        let scheduleGroup = this.addFormGroup.get('vaccinationSchedules') as FormGroup;
        scheduleGroup.addControl('scheduledDate', new FormControl(''));
      }

      if(this.scheduledDate.value){
        let d = moment(this.scheduledDate.value, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
        this.getAvailableTimesByClinicForAdd(d);
      }

      this.visitId = this.addFormGroup.get('visitId').value;
      this.populateDoctor(administrator);
      
      if (administratorInfo.get('route').value && this.routeOfAdministrations.length > 0) {
        this.onRouteOfAdministrationSelect(this.routeOfAdministrations.find(roa => roa.name === administratorInfo.get('route').value));
      }

      this.isCovidVaccine = this.covidVaccineSddCodes.includes(vaccine.sddCode);
      this.filterCovidVaccineCondition(this.addFormGroup.get('doseType').value);
      this.populateExemptionConditions(vaccine);
      this.validateCovidRelatedFields();
    } else {
      const loggedInDoctor = this.doctors.find(doc => doc.id === this.store.getUser().context['cms-user-id']);
      administrator.get('profession').patchValue('DOCTOR');
      if (loggedInDoctor) {
        administrator.get('regNo').patchValue(loggedInDoctor.mcr);
        administrator.get('name').patchValue(loggedInDoctor.displayName);
        this.addFormGroup.get('doctorId').patchValue(loggedInDoctor.id);
      }
      // if (loggedInDoctor) {
      //   administrator.get('profession').patchValue('DOCTOR');
      //   administrator.get('regNo').patchValue(loggedInDoctor.mcr);
      //   administrator.get('name').patchValue(loggedInDoctor.displayName);
      //   this.addFormGroup.get('doctorId').patchValue(loggedInDoctor.id);
      // } else {
      //   administrator.get('profession').patchValue('NURSE');
      // }
      this.addFormGroup.get('administrationInfo').get('notGiven').patchValue(true);
    }

    if (!this.visitId) {
      const notgiven = this.addFormGroup.get('administrationInfo').get('notGiven');
      this.updateNotGivenValidation(notgiven.value);
    } else {
      this.isNotGiven = false;
      if (administrator.get('profession').value === 'DOCTOR') {
        administrator.get('name').setValidators(null);
        administrator.get('regNo').disable();
      } else {
        administrator.get('name').setValidators([Validators.required]);
        administrator.get('regNo').enable();
      }
      administrator.get('name').markAsTouched();
      administrator.get('regNo').markAsTouched();
    }
    this.addFormGroup.get('givenDate').setValidators([Validators.required]);
    this.addFormGroup.get('givenDate').markAsTouched();
    this.addFormGroup.get('givenDate').updateValueAndValidity({ emitEvent: true });
  }

  onFilterInputChanged() {
    this.codesTypeahead
      .pipe(debounceTime(this.INPUT_DELAY_ITEM))
      .subscribe(searchTerm => {
        if (searchTerm) {
          this.vaccinations = this.searchVaccine(searchTerm);
        }
      });
  }

  searchVaccine(term: string) {
    return this.allVaccinations.filter(vaccine => {
      term = term.toLocaleLowerCase();
      const hasWhiteSpace = term.indexOf(' ') > -1 ? true : false;

      // If string is less than 4 characters and does not contain space,
      // search only through code
      if (!hasWhiteSpace) {
        if (term.length < 4) {
          return vaccine.code.toLocaleLowerCase().indexOf(term) > -1;
        }
      }

      term = term.trim();
      return (
        vaccine.name.toLocaleLowerCase().indexOf(term) > -1 ||
        vaccine.code.toLocaleLowerCase().indexOf(term) > -1
      );
    });
  }

  searchFn(term: string, option) {
    term = term.toLocaleLowerCase();
    term = term.trim();
    return (
      option.name.toLocaleLowerCase().indexOf(term) > -1 ||
      option.description.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  subscribeFormGroupChange() {
    this.addFormGroup
      .get('vaccineId')
      .valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(vaccineId => {
        if (!this.isExit) {
          const vaccine = this.allVaccinations.find(item => item.id === vaccineId);
          if (vaccine) {
            this.dosageUom = vaccine.dosageUom;
            this.clearFields();
            this.populateDose(vaccine);
            this.populateSubItems(vaccine);
            this.populateRouteOfAdmin(vaccine);
            this.populateExemptionConditions(vaccine);
            this.isCovidVaccine = this.covidVaccineSddCodes.includes(vaccine.sddCode);
            this.validateCovidRelatedFields();
            
            this.addFormGroup.get('doseId').markAsTouched();
            this.addFormGroup.get('givenDate').markAsTouched();
            this.addFormGroup.get('administrationInfo').get('route').markAsTouched();
          }
        }
      });

    this.vaccineId.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)), takeUntil(this.componentDestroyed))
      .subscribe(vaccineId => {
        this.doseId.patchValue(undefined);

        const vaccine = this.allVaccinations.find(item => item.id === vaccineId);
        if (vaccine) {
          this.nextDoseVaccine = [...vaccine.dosages];
        }

        if (vaccineId) {
          this.doseId.setValidators([Validators.required]);
          this.doseId.markAsTouched();
          this.scheduledDate.setValidators([Validators.required]);
          this.scheduledDate.markAsTouched();
          this.scheduledTime.setValidators([Validators.required]);
          this.scheduledTime.markAsTouched();
        } else {
          this.doseId.setValidators(null);
          this.scheduledDate.setValidators(null);
          this.scheduledTime.setValidators(null);
        }

        this.doseId.updateValueAndValidity({ emitEvent: false });
        this.scheduledDate.updateValueAndValidity({ emitEvent: false });
        this.scheduledTime.updateValueAndValidity({ emitEvent: false });
      });

      this.addFormGroup && this.addFormGroup.get('vaccinationSchedules').get('scheduledDate').valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(date => {
        if(date){
          let d = moment(date, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
          this.getAvailableTimesByClinicForAdd(d);
        }
      });
  }

  validateCovidRelatedFields() {
    if (this.isCovidVaccine) {
      this.addFormGroup.get('doseType').setValidators([Validators.required]);
      this.addFormGroup.get('covidConditionCodes').setValidators([Validators.required]);
    } else {
      this.addFormGroup.get('doseType').setValidators(null);
      this.addFormGroup.get('covidConditionCodes').setValidators(null);
    }
    this.addFormGroup.get('doseType').markAsTouched();
    this.addFormGroup.get('doseType').updateValueAndValidity({ emitEvent: false });
    this.addFormGroup.get('covidConditionCodes').markAsTouched();
    this.addFormGroup.get('covidConditionCodes').updateValueAndValidity({ emitEvent: false });
  }

  onNotGivenCheck(event) {
    this.updateNotGivenValidation(event.target.checked);
    const administratorInfo = this.addFormGroup.get('administrationInfo');
    if (administratorInfo.get('route').value) {
      this.onRouteOfAdministrationSelect(this.routeOfAdministrations.find(roa => roa.name === administratorInfo.get('route').value));
    }
  }

  updateNotGivenValidation(notGiven: boolean) {
    this.isNotGiven = notGiven;
    const administrationInfo = this.addFormGroup.get('administrationInfo');
    const administrator = this.addFormGroup.get('administrator');
    if (notGiven) {
      administrationInfo.get('site').setValidators(null);
      administrator.get('profession').setValidators(null);
      administrator.get('regNo').setValidators(null);
      administrator.get('name').setValidators(null);
    } else {
      administrationInfo.get('site').setValidators([Validators.required]);
      administrator.get('profession').setValidators([Validators.required]);
      administrator.get('regNo').setValidators([Validators.required]);
      if (administrator.get('profession').value !== 'DOCTOR') {
        administrator.get('name').setValidators([Validators.required]);
      }
    }

    administrationInfo.get('site').updateValueAndValidity({ emitEvent: true });
    administrator.get('profession').updateValueAndValidity({ emitEvent: true });
    administrator.get('regNo').updateValueAndValidity({ emitEvent: true });
    administrator.get('name').updateValueAndValidity({ emitEvent: true });
  }

  getAvailableTimesByClinicForAdd(dateForApi) {
    this.apiAppointmentsService
      .listAvailableTimeByClinic(
        this.store.getClinicId(),
        dateForApi
      )
      .subscribe(
        res => {
          this.availableTimesDropDownListForAdd = new Array<string>();
          res.payload.forEach(slot => {
            this.availableTimesDropDownListForAdd.push(slot.start);
          });
          let currentSetTime = this.addFormGroup.get('vaccinationSchedules').get('scheduledTime').value;
          if(!this.availableTimesDropDownListForAdd.some(item=> item === currentSetTime)){
            this.addFormGroup.get('vaccinationSchedules').get('scheduledTime').patchValue('');
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  populateDose(data) {
    const tempDose = [];

    if (data.dosages) {
      data.dosages.map((value, index) => {
        if(!(value.status && value.status === 'INACTIVE')){
          tempDose.push(value);
        }
      });
    }

    this.dose = tempDose;
  }

  populateDoctor(administrator: FormGroup) {
    if (administrator.get('profession').value === 'DOCTOR' && administrator.get('regNo').value) {
      const doctor = this.doctors.find(doc => doc.mcr === administrator.get('regNo').value);
      if (!doctor) {
        const findDoctor = this.store.getDoctorList().find(doc => doc.mcr === administrator.get('regNo').value);
        findDoctor ? this.doctors.push(findDoctor) : administrator.get('regNo').setErrors({ invalid:'Can not find doctor with this mcr'})
      }
    }    
  }

  populateRouteOfAdmin(data) {
    const route = this.addFormGroup.get('administrationInfo').get('route');
    if (!route.value && data.routeOfAdministration) {
      route.patchValue(data.routeOfAdministration);
      this.onRouteOfAdministrationSelect(this.routeOfAdministrations.find(roa => roa.name === data.routeOfAdministration));
    }
  }

  populateExemptionConditions(vaccine) {
    var mhcpMapping = this.store.mhcpVaccineMapping.find(vMap => vMap.sddCodes.includes(vaccine.sddCode));
    var exConditionCodes = mhcpMapping ? mhcpMapping.conditionCodes : [];
    this.exemptionConditions = this.store.exemptionConditions.filter(ec => exConditionCodes.includes(ec.code));
  }

  populateSubItems(data) {
    const vaccineSubItems = data.multiVaccineSubItems ? data.multiVaccineSubItems : [];
    const groupArray = this.addFormGroup.get('subItems') as FormArray;
    let subItemValues = [];

    if (groupArray && groupArray.length > 0) {
      groupArray.controls.forEach(control => {
        const subItem = vaccineSubItems.find(item => item.code === control.value.code);
        if (subItem) {
          let value = control.value;
          value.dosages = subItem.dosages;
          subItemValues.push(value);
        }
      });
      groupArray.clear();
    } else if (vaccineSubItems.length > 0){
      vaccineSubItems.forEach(subItem => {
        subItemValues.push({code: subItem.code, description: subItem.description, dosages: subItem.dosages, doseId: ''});
      });
    }

    subItemValues.map(item => {
      groupArray.push(new FormGroup({
        code: new FormControl(item.code),
        description: new FormControl(item.description),
        doseId: new FormControl(item.doseId, Validators.required),
        dosages: new FormControl(item.dosages)
      }))
    });
    
    this.vaccineSubItems = groupArray.controls;
  }

  clearFields() {
    this.addFormGroup.get('doseId').patchValue(undefined);
    this.vaccineId.patchValue(undefined);
    this.doseId.patchValue(undefined);
    this.scheduledDate.patchValue(undefined);
    this.scheduledTime.patchValue(undefined);
  }

  getRouteOfAdministrations() {
    const routes = this.store.routeOfAdministrators;
    this.routeOfAdministrations = routes.filter(route => route.nehrCodeVaccine);
  }

  getBodySites() {
    this.apiCmsManagementService
      .listSystemStoreValuesByKey('VACCINE_BODY_SITE_VALUES')
      .subscribe(
        res => {
          const sites = res.payload;
          if (sites && sites.values) {
            this.bodySites = sites.values.map(site => {
              return {name: site}
            });
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  getDisplayMsg() {
    return 'This record is not editable as it is added by another clinic (' + this.getClinic(this.addFormGroup.get('addedByClinicId').value) + ')';
 }

 getClinic(clinicId: string) {
  if (!!clinicId) {
    const clinic = this.akitaClinicQuery.getEntity(clinicId) as Clinic
    return clinic && clinic.clinicCode;
  } else {
    return '';
  }
}

  onDosageInstructionSelect(option) {
    if (option) {
      if (this.vaccineSubItems && this.vaccineSubItems.length > 0) {
        this.vaccineSubItems.forEach(vaccine => {
          if (vaccine.get('dosages')) {
            let sameDose = vaccine.get('dosages').value.find(dose => dose.doseId === option.doseId);
            if (sameDose) {
              vaccine.get('doseId').patchValue(option.doseId);
            } else {
              vaccine.get('doseId').patchValue('');
            }
          }
        })
      }

      this.addFormGroup.get('vaccineDosage').patchValue(option.vaccineDosage);
    }
  }

  onDoseTypeSelect(option) {
    if (option) {
      this.filterCovidVaccineCondition(option.code);
    } else {
      this.covidVaccineConditions = [];
    }
    let selectedCovidConditionCodes = this.addFormGroup.get('covidConditionCodes').value;
    if (selectedCovidConditionCodes && selectedCovidConditionCodes.length > 0) {
      selectedCovidConditionCodes = selectedCovidConditionCodes.filter(ccc => this.covidVaccineConditions.map(cvc => cvc.code).includes(ccc));
      this.addFormGroup.get('covidConditionCodes').patchValue(selectedCovidConditionCodes);
    }
  }

  filterCovidVaccineCondition(doseType) {
    if (doseType === 'PRIMARY') {
      this.covidVaccineConditions = this.store.hsgVaccineConditions.filter(cond => cond.covidCondition && cond.cmsDoseType !== 'BOOSTER');
    } else if (doseType === 'BOOSTER') {
      this.covidVaccineConditions = this.store.hsgVaccineConditions.filter(cond => cond.covidCondition && cond.cmsDoseType !== 'PRIMARY');
    } else {
      this.covidVaccineConditions = [];
    }
  }

  onAdministratorTypeSelect(option) {
    if (option) {
      const administrator = this.addFormGroup.get('administrator');
      if (option.value !== 'DOCTOR') {
        this.addFormGroup.get('doctorId').patchValue('');
        administrator.get('regNo').patchValue('');
        administrator.get('regNo').enable();
        administrator.get('name').patchValue('');
        administrator.get('name').setValidators([Validators.required]);
      } else {
        if (this.visitId) {
          this.apiPatientVisitService
          .patientVisitSearch(this.visitId)
          .subscribe(res => {
            if (res) {
              if (res.payload.medicalReferenceEntity && res.payload.medicalReferenceEntity.consultation) {
                const doctorId = res.payload.medicalReferenceEntity.consultation.doctorId;
                const doctor = this.doctors.find(doc => doc.id === doctorId);
                if (doctor) {
                  administrator.get('regNo').patchValue(doctor.mcr);
                  administrator.get('name').patchValue(doctor.displayName);
                }
              }
            }
          });
          
          administrator.get('regNo').disable();
        }
        administrator.get('name').setValidators(null);
      }

      administrator.get('name').updateValueAndValidity({ emitEvent: true });
    }
  }

  onRouteOfAdministrationSelect(option) {
    if (option) {
      const bodySite = this.addFormGroup.get('administrationInfo').get('site');
      if (option.bodySiteRequired && !this.isNotGiven) {
        bodySite.setValidators([Validators.required]);
        this.isBodySiteRequired = true;
      } else {
        bodySite.setValidators(null);
        this.isBodySiteRequired = false;
      }

      bodySite.updateValueAndValidity({ emitEvent: true });
    }
  }

  formatDates(value) {
    if (value.vaccinationSchedules.scheduledDate && typeof value.vaccinationSchedules.scheduledDate !== 'string') {
      value.vaccinationSchedules.scheduledDate = moment(value.vaccinationSchedules.scheduledDate).format(DISPLAY_DATE_FORMAT);
    }
    if (value.givenDate && typeof value.givenDate !== 'string') {
      value.givenDate = moment(value.givenDate).format(DISPLAY_DATE_FORMAT);
    }
  }

  onBtnExit() {
    this.isExit = true;
    this.addFormGroup.get('vaccineId').enable();
    this.formatDates(this.addFormGroup.getRawValue());
    this.bsModalRef.hide();
  }

  onBtnSave() {
    this.disableSaveBtn = true;
    const value = this.addFormGroup.getRawValue();
    this.formatDates(value);

    if (
      !value.vaccinationSchedules.vaccineId &&
      !value.vaccinationSchedules.doseId &&
      !value.vaccinationSchedules.scheduledDate &&
      !value.vaccinationSchedules.scheduledTime 
    ) {
      value.vaccinationSchedules = undefined;
    } else {
      value.vaccinationSchedules = [value.vaccinationSchedules];
    }

    if (this.isEdit) {
      const vaccineId = value.id;
      delete value.id;
      delete value.isSelected;

      this.apiPatientInfoService.modifyPatientVaccineAssociation(!this.isCareReport ? this.akitaPatientAppQuery.getId() : this.patientId, vaccineId, value).subscribe(
        res => {
          alert('Vaccination has been updated successfully.');
          this.disableSaveBtn = false;
          if (!this.isCareReport) {
            this.alertService.sendVaccineDetailsAddedEvent({itemId: value.vaccineId, isUpdate: true});
          } else {
            this.alertService.sendReloadCareReportEvent({patientId: this.patientId , patientGroupId: this.patientGroupId});
          }
          this.bsModalRef.hide();
        },
        err => {
          this.disableSaveBtn = false;
          this.alertService.error(err.error.message);
          this.bsModalRef.hide();
        }
      );
    } else {
      this.apiPatientInfoService.addPatientVaccineAssociation(!this.isCareReport ? this.akitaPatientAppQuery.getId() : this.patientId, value).subscribe(
        res => {
          alert('Vaccination has been added successfully.');
          this.disableSaveBtn = false;
          if (!this.isCareReport) {
            this.alertService.sendVaccineDetailsAddedEvent({itemId: value.vaccineId, isUpdate: false});
          } else {
            this.alertService.sendReloadCareReportEvent({patientId: this.patientId , patientGroupId: this.patientGroupId});
          }
          this.bsModalRef.hide();
        },
        err => {
          this.disableSaveBtn = false;
          this.alertService.error(JSON.stringify(err.error.message));
          this.bsModalRef.hide();
        }
      );
    }
  }

  onAdministratorChange(event) {
    if (event) {
      this.addFormGroup.get('doctorId').patchValue(event.id);
      this.addFormGroup.get('administrator').get('name').patchValue(event.displayName);
    } else {
      this.addFormGroup.get('doctorId').patchValue('');
      this.addFormGroup.get('administrator').get('name').patchValue('');
    }
  }

  searchRouteFn(term: string, option) {
    term = term.toLocaleLowerCase();
    const hasWhiteSpace = term.indexOf(' ') > -1 ? true : false;

    if (!hasWhiteSpace) {
      if (term.length < 4) {
        return option.name.toLocaleLowerCase().indexOf(term) > -1;
      }
    }

    term = term.trim();
    return option.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  isPackagedPrescriptionItem(): boolean {
    return (
      this.addFormGroup.get('packageId').value !== '' &&
      this.addFormGroup.get('itemType').value === 'PACKAGE'
    );
  }

  getSalesUom() {
    const salesUom =
      this.addFormGroup.get('salesUom') &&
      !!this.addFormGroup.get('salesUom').value
        ? this.addFormGroup.get('salesUom').value
        : '';
    return salesUom;
  }

  onInstructionSelect(option) {
    this.addFormGroup.get('instruction').patchValue(option);
  }

  markAsTouched(form: AbstractControl) {
    form.markAsTouched();
    form.updateValueAndValidity({ emitEvent: false });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}